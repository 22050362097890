import IconProps from './common/icon.interface';

const ArrowIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.35765 12.7501L9.68078 16.0732C9.81923 16.2117 9.89006 16.3857 9.89328 16.5954C9.89648 16.8049 9.82564 16.9822 9.68078 17.127C9.53591 17.2719 9.36027 17.3444 9.15385 17.3444C8.94745 17.3444 8.77182 17.2719 8.62695 17.127L4.13273 12.6328C4.03914 12.5392 3.97312 12.4405 3.93465 12.3367C3.89619 12.2328 3.87695 12.1207 3.87695 12.0001C3.87695 11.8796 3.89619 11.7675 3.93465 11.6636C3.97312 11.5598 4.03914 11.4611 4.13273 11.3675L8.62695 6.87325C8.7654 6.73478 8.93944 6.66395 9.14905 6.66075C9.35867 6.65753 9.53591 6.72837 9.68078 6.87325C9.82564 7.01812 9.89808 7.19375 9.89808 7.40015C9.89808 7.60655 9.82564 7.78218 9.68078 7.92705L6.35765 11.2502H19.75C19.9628 11.2502 20.141 11.322 20.2846 11.4655C20.4282 11.6091 20.5 11.7873 20.5 12.0001C20.5 12.213 20.4282 12.3912 20.2846 12.5347C20.141 12.6783 19.9628 12.7501 19.75 12.7501H6.35765Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowIcon;
