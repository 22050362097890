import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import InitiativeDTO from './dto/initiative.dto';
import CreateInitiativeDTO from './dto/createInitiative.dto';
import UpdateInitiativeDTO from './dto/updateInitiative.dto';

export default class InitiativeRepository {
  private url: string = '/api/initiative';

  async find(
    options?: FindOptionsDTO<InitiativeDTO>,
  ): Promise<PaginatedDTO<InitiativeDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const initiativeResponse = await axios.get<PaginatedDTO<InitiativeDTO>>(
      `${this.url}${stringOption}`,
    );
    return initiativeResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<InitiativeDTO>,
  ): Promise<InitiativeDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const initiativeResponse = await axios.get<InitiativeDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return initiativeResponse.data;
  }

  async save(initiative: CreateInitiativeDTO) {
    const createdInitiativeResponse = await axios.post<InitiativeDTO>(
      `${this.url}`,
      initiative,
    );
    return createdInitiativeResponse.data;
  }

  async update(id: string, changes: UpdateInitiativeDTO) {
    const updatedInitiativeResponse = await axios.put<InitiativeDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedInitiativeResponse.data;
  }

  async delete(id: string) {
    const deletedInitiativeResponse = await axios.delete<InitiativeDTO>(
      `${this.url}/${id}`,
    );
    return deletedInitiativeResponse.data;
  }
}
