import DocumentUserDTO from '@modules/documentModule/documentUser/dto/documentUser.dto';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Selection,
  User,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import React, { Key, useEffect, useState } from 'react';
import DocumentDTO from '../dto/document.dto';
import { TeamItemDTO, UserItemDTO } from '../dto/permissionItem.dto';
import DocumentAccessUserSelector from './documentAccessUserSelector';
import Alert from '@components/alert/alert.component';
import DocumentUserRepository from '@modules/documentModule/documentUser/documentUser.repository';
import DocumentTeamDTO from '@modules/documentModule/documentUser/dto/documentTeam.dto';
import DocumentTeamRepository from '@modules/documentModule/documentUser/documentTeam.repository copy';
import DocumentRepository from '@modules/documentModule/document.repository';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';

interface DocumentAccessModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
  document: DocumentDTO;
}

type ItemListType = (
  | DocumentUserDTO
  | DocumentTeamDTO
  | { id: 'all'; name: string }
)[];

const DocumentAccessModal = ({
  document,
  isOpen,
  onOpenChange,
}: DocumentAccessModalProps) => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [autocompleteItems, setAutocompleteItems] = useState<ItemListType>([]);
  const [itemsInputvalue, setItemsInputValue] = useState('');
  const [isItemsInputLoading, setIsItemsInputLoading] = useState(false);
  const [itemsHasMore, setItemsHasMore] = useState(true);
  const [isItemsAutocompleteOpen, setIsItemsAutocompleteOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<Exclude<Key, bigint>>('');
  const [editorItems, setEditorItems] = useState<
    (DocumentUserDTO | DocumentTeamDTO)[] | 'all'
  >([]);
  const [viewerItems, setViewerItems] = useState<
    (DocumentUserDTO | DocumentTeamDTO)[] | 'all'
  >([]);
  const PAGINATION_TAKE = 5;
  const [permissionSelected, setPermissionSelected] = useState<Selection>(
    new Set(['viewer']),
  );
  const [userRepository] = useState(new DocumentUserRepository());
  const [teamRepository] = useState(new DocumentTeamRepository());
  const [users, setUsers] = useState<DocumentUserDTO[]>([]);
  const [teams, setTeams] = useState<DocumentTeamDTO[]>([]);
  const [documentRepository] = useState(new DocumentRepository());

  const sortUsersAndTeams = (
    usersList: DocumentUserDTO[],
    teamsList: DocumentTeamDTO[],
  ) => {
    const list = [...usersList, ...teamsList];
    list.sort((a, b) => {
      const nameA = 'name' in a ? a.name : a.fullName;
      const nameB = 'name' in b ? b.name : b.fullName;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return list;
  };

  const loadDocumentAccess = async () => {
    const editors = document.editorAll
      ? 'all'
      : sortUsersAndTeams(document.editors, document.editorTeams);
    setEditorItems(editors);

    const viewers =
      document.viewerAll || document.editorAll
        ? 'all'
        : sortUsersAndTeams(document.viewers, document.viewerTeams);
    setViewerItems(viewers);
    const usersList = await userRepository.find({
      order: { fullName: 'DESC' },
    });

    setUsers(usersList.results);
    const teamsList = await teamRepository.find({
      order: { name: 'DESC' },
    });
    setTeams(teamsList.results);
  };

  useEffect(() => {
    if (isOpen) {
      loadDocumentAccess();
      return;
    }

    setViewerItems([]);
    setEditorItems([]);
    setPermissionSelected(new Set(['viewer']));
    setItemsInputValue('');
    setSelectedKey('');
  }, [isOpen]);

  // Autocomplete handling

  const getItemsForAutocomplete = async (
    amount: number = PAGINATION_TAKE,
    searchValue?: string,
  ) => {
    setIsItemsInputLoading(true);
    const usersList = await userRepository.find({
      take: amount,
      ...(searchValue && {
        where: { fullName: `lk=${searchValue}` },
        order: { fullName: 'DESC' },
      }),
    });
    const teamsList = await teamRepository.find({
      take: amount,
      ...(searchValue && {
        where: { name: `lk=${searchValue}` },
        order: { name: 'DESC' },
      }),
    });
    const itemsList = sortUsersAndTeams(
      usersList.results,
      teamsList.results,
    ) as ItemListType;
    itemsList.unshift({
      id: 'all',
      name: t('user.document.browser.item.share.allUsers'),
    });
    setAutocompleteItems(itemsList);
    if (usersList.total <= amount && teamsList.total <= amount)
      setItemsHasMore(false);
    setIsItemsInputLoading(false);
  };

  const loadMoreItems = () => {
    const newOffset = autocompleteItems.length + PAGINATION_TAKE;
    getItemsForAutocomplete(newOffset, itemsInputvalue);
  };

  const [, scrollerRef] = useInfiniteScroll({
    hasMore: itemsHasMore,
    isEnabled: isItemsAutocompleteOpen,
    shouldUseLoader: false,
    onLoadMore: loadMoreItems,
  });

  const handleItemsInputChange = (inputValue: string) => {
    setItemsInputValue(inputValue);
    getItemsForAutocomplete(PAGINATION_TAKE, inputValue);
  };

  useEffect(() => {
    if (isItemsAutocompleteOpen) getItemsForAutocomplete();
  }, [isItemsAutocompleteOpen]);

  //

  const handleGiveAccess = () => {
    if (permissionSelected === 'all') return;
    const selectedItems = permissionSelected.has('viewer')
      ? viewerItems
      : editorItems;
    const setSelectedItems = permissionSelected.has('viewer')
      ? setViewerItems
      : setEditorItems;
    if (selectedKey === 'all') return setSelectedItems('all');
    const item = autocompleteItems.find(
      (i) => i.id === selectedKey && i.id !== 'all',
    ) as UserItemDTO | TeamItemDTO | undefined;
    if (!item) return;
    if (selectedItems === 'all') {
      return setSelectedItems([item]);
    }
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id))
      return;
    if (
      permissionSelected.has('editor') &&
      viewerItems !== 'all' &&
      viewerItems.includes(item)
    )
      setViewerItems(viewerItems.filter((it) => it.id !== item.id));
    setSelectedItems([...selectedItems, item]);
  };

  const handleAccessChange = async () => {
    document.viewerAll = false;
    document.viewers = [];
    document.viewerTeams = [];
    document.editorAll = false;
    document.editors = [];
    document.editorTeams = [];
    if (viewerItems === 'all') document.viewerAll = true;
    else {
      viewerItems.forEach((viewer) => {
        const user = users.find((user) => user.id === viewer.id);
        if (user) document.viewers.push(user);
        else {
          const team = teams.find((team) => team.id === viewer.id);
          if (team) document.viewerTeams.push(team);
        }
      });
    }
    if (editorItems === 'all') document.editorAll = true;
    else {
      editorItems.forEach((editor) => {
        const user = users.find((user) => user.id === editor.id);
        if (user) document.editors.push(user);
        else {
          const team = teams.find((team) => team.id === editor.id);
          if (team) document.editorTeams.push(team);
        }
      });
    }
    await documentRepository.updatePermissions(document);
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              {t('user.document.browser.item.share.title')}
            </ModalHeader>
            <ModalBody>
              <Autocomplete
                items={autocompleteItems}
                label={t('user.document.browser.item.share.autocompleteLabel')}
                placeholder={t(
                  'user.document.browser.item.share.autocompletePlaceholder',
                )}
                onOpenChange={setIsItemsAutocompleteOpen}
                isLoading={isItemsInputLoading}
                scrollRef={scrollerRef}
                inputValue={itemsInputvalue}
                onInputChange={handleItemsInputChange}
                onSelectionChange={(key) => {
                  setSelectedKey(key);
                  setIsItemsAutocompleteOpen(false);
                  if (key) return setItemsInputValue(key.toString());
                  return setItemsInputValue('');
                }}
                allowsCustomValue={false}
                variant="bordered"
                selectedKey={selectedKey}
              >
                {(item) => (
                  <AutocompleteItem
                    key={item.id}
                    textValue={'name' in item ? item.name : item.fullName}
                  >
                    {item.id !== 'all' ? (
                      <User
                        name={'name' in item ? item.name : item.fullName}
                        description={''}
                        avatarProps={{
                          src: `${'avatar' in item && `https://giveit-system-assets.s3.amazonaws.com/${item.avatar}`}`,
                          isBordered: item.id === profile?.id,
                          color:
                            item.id === profile?.id ? 'primary' : 'secondary',
                        }}
                      />
                    ) : (
                      <div className="py-2">
                        {'name' in item ? item.name : item.fullName}
                      </div>
                    )}
                  </AutocompleteItem>
                )}
              </Autocomplete>
              <Select
                label={t(
                  'user.document.browser.item.share.permissionSelectLabel',
                )}
                selectedKeys={permissionSelected}
                onSelectionChange={setPermissionSelected}
                disallowEmptySelection={true}
                variant="bordered"
              >
                <SelectItem
                  key="viewer"
                  value={'viewer'}
                  description={t(
                    'user.document.browser.item.share.viewerDescription',
                  )}
                >
                  {t('user.document.browser.item.share.viewer')}
                </SelectItem>
                <SelectItem
                  key="editor"
                  value={'editor'}
                  description={t(
                    'user.document.browser.item.share.editorDescription',
                  )}
                >
                  {t('user.document.browser.item.share.editor')}
                </SelectItem>
              </Select>
              <Button
                onPress={handleGiveAccess}
                isDisabled={selectedKey === ''}
                variant="bordered"
                color="primary"
              >
                {t('user.document.browser.item.share.giveAccess')}
              </Button>
              <span className="text-lg">
                {t('user.document.browser.item.share.usersWithAccess')}
              </span>
              {viewerItems !== 'all' && editorItems !== 'all' ? (
                viewerItems.map((item) =>
                  !editorItems.includes(item) ? (
                    <DocumentAccessUserSelector
                      key={item.id}
                      item={item}
                      viewerItems={viewerItems}
                      setViewerItems={setViewerItems}
                      editorItems={editorItems}
                      setEditorItems={setEditorItems}
                      selected="viewer"
                    />
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <p>{t('user.document.browser.item.share.allUsersView')}</p>
              )}
              {editorItems !== 'all' ? (
                editorItems.map((item) => (
                  <DocumentAccessUserSelector
                    key={item.id}
                    item={item}
                    viewerItems={viewerItems}
                    setViewerItems={setViewerItems}
                    editorItems={editorItems}
                    setEditorItems={setEditorItems}
                    selected="editor"
                  />
                ))
              ) : (
                <p>{t('user.document.browser.item.share.allUsersEdit')}</p>
              )}
            </ModalBody>
            <ModalFooter className="flex-col">
              {editorItems !== 'all' && editorItems.length === 0 && (
                <Alert type="danger">
                  {t('user.document.browser.item.share.noEditorWarning')}
                </Alert>
              )}
              <Button
                onPress={() => {
                  handleAccessChange();
                  onClose();
                }}
                isDisabled={editorItems !== 'all' && editorItems.length === 0}
                color="primary"
                className="w-full"
              >
                {t('user.document.browser.item.share.confirm')}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DocumentAccessModal;
