import React from 'react';
import { useEffect, useState } from 'react';
import PostsRepository from '../posts.repository';
import PostDTO from '../../createPost/dto/post.dto';
import Post from './post';
import CreatePost from '../../createPost/components/createPost';
import { Card, CardBody, Spinner } from '@nextui-org/react';
import FeedFilters from './filters';
import InfiniteScroll from 'react-infinite-scroll-component';
import FeedFiltersDTO from '../dto/feedFilters.dto';
import DateUtil from '@utils/date';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import { useTranslation } from 'react-i18next';
import ChallengeBanner from '@modules/voteModule/challenges/vote/components/challengeBanner';
import ChallengesRepository from '@modules/voteModule/challenges/challenges.repository';
import ChallengeDTO from '@modules/voteModule/challenges/configuration/dto/challenge.dto';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import DashboardVotes from '@modules/voteModule/components/dashboardVotes';
import MobileEventsDashboardCard from '@modules/eventModule/components/mobileDashboardCard';
import MobileTasksDashboardCard from '@modules/objectiveModule/initiative/components/myTasksMobile';

const Feed = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState<PostDTO[]>([]);
  const [filters, setFilters] = useState<FeedFiltersDTO>({
    sortBy: 'recent',
    tags: [],
    showPinned: true,
    search: '',
  });
  const [postsShownNumber, setPostsShownNumber] = useState(PAGINATION_TAKE);
  const [morePostsAvailable, setMorePostsAvailable] = useState(true);
  const [postsRepository] = useState(new PostsRepository());
  const [challengesRepository] = useState(new ChallengesRepository());
  const [currentChallenges, setCurrentChallenges] = useState<ChallengeDTO[]>();
  const [currentChallengeValid, setCurrentChallengeValid] = useState<boolean>();
  const profile = useSelector(selectProfile);

  const getPosts = async () => {
    setIsLoading(true);
    const today = new Date();
    const todayJson = today.toJSON();
    let postsPinnedArray: PostDTO[] = [];
    let where = {};
    if (filters.search != '') {
      where = { cleanContent: `lk=${filters.search}` };
    }
    if (filters.tags.length > 0) {
      const categoriesArray = filters.tags.map((category) => {
        return { id: category.id };
      });
      where = { ...where, categories: categoriesArray };
    }
    if (filters.showPinned) {
      postsPinnedArray = (
        await postsRepository.getPosts({
          where: [
            {
              ...where,
              isPinned: true,
              pinnedUntil: `>=${todayJson}`,
            },
            {
              ...where,
              isPinned: true,
              pinnedUntil: '=null',
            },
          ],
          take: postsShownNumber,
          order: { createdDate: 'DESC' },
        })
      ).results;
    }
    const postsResponse = await postsRepository.getPosts({
      where: [
        {
          ...where,
          isPinned: false,
        },
        {
          ...where,
          isPinned: true,
          pinnedUntil: `<${todayJson}`,
        },
      ],
      take: postsShownNumber,
      order: { createdDate: 'DESC' },
    });
    let postsArray: PostDTO[] = postsResponse.results;
    if (filters.sortBy === 'relevant') {
      postsArray = postsArray.sort(compareByRelevance);
    }
    if (postsPinnedArray.length > 0) {
      postsArray = postsPinnedArray.concat(postsArray);
    }
    postsArray.forEach((post) => {
      if (
        post.isPinned &&
        post.pinnedUntil != null &&
        DateUtil.isFutureDate(post.pinnedUntil, today)
      )
        post.isPinned = false;
    });
    setPosts(postsArray);
    if (postsResponse.total < postsShownNumber) {
      setMorePostsAvailable(false);
    }
    setIsLoading(false);
  };

  const compareByRelevance = (a: PostDTO, b: PostDTO) => {
    const relevanceA = a.comments.length * 4 + a.interactions.length;
    const relevanceB = b.comments.length * 4 + b.interactions.length;
    return relevanceB - relevanceA;
  };

  const getCurrentChallenge = async () => {
    setIsLoading(true);
    const today = new Date().toJSON();
    const results = await challengesRepository.get({
      where: {
        dateFrom: `<=${today}`,
        dateTo: `>=${today}`,
        processedDate: '=null',
      },
      relations: ['user'],
      order: { dateTo: 'ASC', dateFrom: 'ASC' },
    });
    if (results.length >= 1) {
      setCurrentChallenges(results);
      setCurrentChallengeValid(true);
    } else setCurrentChallengeValid(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getPosts();
    if (
      profile?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.CHALLENGE &&
          permission.methods === AllowedMethods.VIEW,
      )
    )
      getCurrentChallenge();
  }, [filters]);

  const getMorePosts = () => {
    if (morePostsAvailable) {
      setPostsShownNumber(postsShownNumber + PAGINATION_TAKE);
      getPosts();
    }
  };

  const findOrSaveTag = async (name: string): Promise<string> => {
    const results = await postsRepository.getCategory({
      where: { name },
      take: 1,
    });
    if (results[0] && results[0].id) return results[0].id;
    return '-1';
  };

  return (
    <div className="flex flex-col gap-5">
      <FeedFilters
        filters={filters}
        setFilters={setFilters}
        findOrSaveTag={findOrSaveTag}
      />
      <DashboardVotes getPosts={getPosts} />
      {profile?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.CHALLENGE &&
          permission.methods === AllowedMethods.VIEW,
      ) &&
        currentChallenges?.map((challenge) => (
          <ChallengeBanner
            key={challenge.id}
            className="rounded-xl"
            challengeDateTo={challenge.dateTo}
            challengeId={challenge.id}
            withButton={currentChallengeValid}
          />
        ))}
      {window.innerWidth < 1024 && (
        <>
          <MobileEventsDashboardCard />
          <MobileTasksDashboardCard />
        </>
      )}
      {profile?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.POST &&
          permission.methods === AllowedMethods.ADD,
      ) && (
        <Card className="overflow-visible shadow-none bg-content4">
          <CardBody className=" overflow-visible p-0">
            <CreatePost refresh={getPosts} />
          </CardBody>
        </Card>
      )}
      {isLoading ? (
        <Spinner className="mt-20 mb-40" />
      ) : (
        <div>
          <InfiniteScroll
            dataLength={posts.length}
            next={getMorePosts}
            className="flex flex-col items-center gap-5 mb-10"
            hasMore={morePostsAvailable}
            loader={<Spinner className="mb-10" />}
            endMessage={
              <p>
                {posts.length
                  ? t('user.dashboard.feed.posts.noMorePosts')
                  : t('user.dashboard.feed.posts.noPosts')}
              </p>
            }
          >
            {posts.map((post) => (
              <Post
                data={post}
                refresh={getPosts}
                filters={filters}
                setFilters={setFilters}
                key={post.id}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default Feed;
