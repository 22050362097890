import { Avatar, Card, CardBody, CardHeader } from '@nextui-org/react';
import ChallengeBanner from './challengeBanner';
import DateUtil from '@utils/date';
import ChallengeDTO from '../../configuration/dto/challenge.dto';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';

interface ChallengeAssignmentProps {
  challenge: ChallengeDTO;
}

const ChallengeAssignment = ({ challenge }: ChallengeAssignmentProps) => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();

  return (
    <Card className="shadow-none bg-content4">
      <CardHeader className="p-0">
        <ChallengeBanner
          withButton={false}
          challengeDateTo={
            !challenge.processedDate
              ? challenge.dateTo
              : challenge.processedDate
          }
        />
      </CardHeader>
      <CardBody className="lg:px-10">
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-5">
            <Avatar
              src={
                challenge.user.avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${challenge.user.avatar}`
              }
              name={challenge.user.fullName}
              showFallback
              isBordered={challenge.createdBy === profile?.email}
              color={
                challenge.createdBy === profile?.email ? 'primary' : 'default'
              }
              className="cursor-pointer flex-shrink-0"
              onClick={() =>
                navigate(`/enterprise/profile/${challenge.user.id}`)
              }
            />
            <div className="flex flex-col">
              <div className="flex flex-col items-start md:flex-row md:items-center md:gap-2">
                <span
                  className="m-0 cursor-pointer leading-none md:leading-normal hover:underline"
                  onClick={() =>
                    navigate(`/enterprise/profile/${challenge.user.id}`)
                  }
                >
                  {challenge.user.fullName}
                </span>
                {/* Puesto de quien publica - COMENTADO HASTA PODER ACCEDER AL PUESTO DEL PERFIL*/}
                {/* <span className="m-0 text-gray-400">- Head of Sales</span> */}
              </div>
              <span className="m-0 text-gray-400 text-sm">
                {DateUtil.dateToFullDate(challenge.createdDate)}
              </span>
            </div>
          </div>
        </div>
        <div
          className="text-default-600"
          dangerouslySetInnerHTML={{ __html: challenge.assignment }}
        ></div>
      </CardBody>
    </Card>
  );
};

export default ChallengeAssignment;
