import React from 'react';
import {
  BlockNoteView,
  Theme,
  darkDefaultTheme,
  lightDefaultTheme,
} from '@blocknote/react';
import Alert from '@components/alert/alert.component';
import { useTheme } from 'next-themes';
import { useTranslation } from 'react-i18next';
import { CustomBlockNoteEditor } from './customSchema';
import '@blocknote/react/style.css';
import '@blocknote/core/fonts/inter.css';
import { isStyledTextInlineContent } from '@blocknote/core';
import { useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteItem,
  Modal,
  ModalBody,
  ModalContent,
  User,
  useDisclosure,
} from '@nextui-org/react';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import FeedUserRepository from '@modules/feed/feedUser/feedUser.repository';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import FeedUserDTO from '@modules/feed/feedUser/dto/feedUser.dto';
import CustomToolbar from './customToolbar';

export const validateBlockNote = (editor: CustomBlockNoteEditor) => {
  const blocks = editor.document;
  let editorEmpty = true;
  blocks.forEach((block) => {
    if (Array.isArray(block.content) && block.content.length)
      editorEmpty = false;
  });
  return editorEmpty;
};

interface CustomBlockNoteProps {
  editor: CustomBlockNoteEditor;
  className?: string;
  error?: boolean;
  taggedUsers?: FeedUserDTO[];
}

const CustomBlockNote = ({
  editor,
  className,
  error,
  taggedUsers,
}: CustomBlockNoteProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [users, setUsers] = useState<FeedUserDTO[]>([]);
  const [tagCharacterIntroduced, setTagCharacterIntroduced] = useState(false);
  const [usersHasMore, setUsersHasMore] = useState(true);
  const [usersInputvalue, setUsersInputValue] = useState('');
  const [isUsersInputLoading, setIsUsersInputLoading] = useState(false);
  const [isUsersAutocompleteOpen, setIsUsersAutocompleteOpen] = useState(false);

  // USERTAG HANDLING
  const handleInput = () => {
    const { block } = editor.getTextCursorPosition();
    if (!block || !Array.isArray(block.content)) return;
    const lastContent = block.content[block.content.length - 1];
    if (
      lastContent &&
      isStyledTextInlineContent(lastContent) &&
      lastContent.text.endsWith('@')
    ) {
      setTagCharacterIntroduced(true);
    }
  };

  const getUsers = async (
    amount: number = PAGINATION_TAKE,
    searchValue?: string,
  ) => {
    setIsUsersInputLoading(true);
    const usersList = await new FeedUserRepository().find({
      take: amount,
      ...(searchValue && {
        where: { fullName: `lk=${searchValue}` },
        order: { fullName: 'DESC' },
      }),
    });
    setUsers(usersList.results);
    if (usersList.total <= amount) setUsersHasMore(false);
    setIsUsersInputLoading(false);
  };

  useEffect(() => {
    if (!tagCharacterIntroduced) return;
    onOpen();
    getUsers();
  }, [tagCharacterIntroduced]);

  const loadMoreUsers = () => {
    const newOffset = users.length + PAGINATION_TAKE;
    getUsers(newOffset, usersInputvalue);
  };

  const handleUsersInputChange = (inputValue: string) => {
    setUsersInputValue(inputValue);
    getUsers(PAGINATION_TAKE, inputValue);
  };

  const [, scrollerRef] = useInfiniteScroll({
    hasMore: usersHasMore,
    isEnabled: isUsersAutocompleteOpen,
    shouldUseLoader: false,
    onLoadMore: loadMoreUsers,
  });

  const addUserTag = async (userId: string, onClose: () => void) => {
    const user = (
      await new FeedUserRepository().find({ where: { id: userId } })
    ).results[0];
    editor.insertInlineContent([
      { type: 'mention', props: { userName: user.fullName, userId: user.id } },
    ]);
    if (
      taggedUsers &&
      !taggedUsers.find((taggedUser) => taggedUser.id === user.id)
    )
      taggedUsers.push(user);
    setUsersInputValue('');
    onClose();
  };

  // THEME HANDLING

  const theme = useTheme();

  const lightTheme: Theme = {
    ...lightDefaultTheme,
    colors: {
      ...lightDefaultTheme.colors,
      editor: {
        text: '',
        background: '',
      },
    },
  };

  const darkTheme: Theme = {
    ...darkDefaultTheme,
    colors: {
      ...darkDefaultTheme.colors,
      editor: {
        text: '#FFFFFF',
        background: '#3F3F46',
      },
    },
  };

  const themes = {
    light: lightTheme,
    dark: darkTheme,
  };

  return (
    <div>
      <BlockNoteView
        theme={theme.resolvedTheme === 'dark' ? themes.dark : themes.light}
        editor={editor}
        className={`w-full pr-0 bg-content4 ${className ? className : ''}`}
        onChange={() => handleInput()}
        formattingToolbar={false}
      >
        <CustomToolbar />
      </BlockNoteView>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={() => setTagCharacterIntroduced(false)}
        className="p-5"
      >
        <ModalContent>
          {(onClose) => (
            <ModalBody className="px-2 md:px-6">
              <Autocomplete
                items={users}
                label={t('user.dashboard.feed.createPost.blocknote.userTag')}
                allowsCustomValue
                onOpenChange={setIsUsersAutocompleteOpen}
                isLoading={isUsersInputLoading}
                scrollRef={scrollerRef}
                inputValue={usersInputvalue}
                onInputChange={handleUsersInputChange}
                onSelectionChange={(user) =>
                  addUserTag(user.toString(), onClose)
                }
                inputProps={{
                  classNames: {
                    inputWrapper:
                      'bg-content2 group-data-[focus=true]:bg-content2 data-[hover=true]:bg-content2',
                  },
                }}
              >
                {(item) => (
                  <AutocompleteItem key={item.id} textValue={item.fullName}>
                    <User
                      name={item.fullName}
                      description={''}
                      avatarProps={{ src: '' }}
                    />
                  </AutocompleteItem>
                )}
              </Autocomplete>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>

      {error && (
        <Alert type="danger">
          {t('user.dashboard.feed.createPost.blocknote.error')}
        </Alert>
      )}
    </div>
  );
};

export default CustomBlockNote;
