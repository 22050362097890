import Navbar from '@components/navbar/navbar.componen';
import { RouteObject } from 'react-router-dom';
import Dashboard from './dashboard/dashboard';
import EnterpriseType from '@modules/userModule/enterprise/enum/enterpriseType.enum';

const administrationRoutes: Array<RouteObject> = [
  {
    path: '',
    element: <Navbar enterpriseType={EnterpriseType.ADMINISTRATOR} />,
    children: [{ path: '', element: <Dashboard /> }],
  },
];

export default administrationRoutes;
