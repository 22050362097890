import React from 'react';
import { RouteObject } from 'react-router-dom';
import LicenseHome from './list/license.home';
import CreateLicenseTypePage from './createLicenseType/createLicenseType.page';
import LicenseTypeList from './list/licenseType.list';

const licenseRoutes: Array<RouteObject> = [
  { path: '', element: <LicenseHome /> },
  { path: 'licenseType', element: <LicenseTypeList /> },
  { path: 'licenseType/create', element: <CreateLicenseTypePage /> },
];

export default licenseRoutes;
