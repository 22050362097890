import React from 'react';
import ReactEChart from 'echarts-for-react';
import DateUtil from '@utils/date';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import KeyResultRepository from '@modules/objectiveModule/keyResult/keyResult.repository';
import InitiativeStatus from '@modules/objectiveModule/initiative/enum/initiativeStatus.enum';

interface InitiativeMetricProps {
  period: 'Q1' | 'Q2' | 'Q3' | 'Q4';
}

const InitiativeMetric = (props: InitiativeMetricProps) => {
  const { t } = useTranslation();
  const periodDates = DateUtil.quarterToDate(props.period);

  const [statuses, setStatuses] = useState({
    COMPLETED: 0,
    PENDING: 0,
    IN_PROGRESS: 0,
  });

  const calculateStatus = async () => {
    const keyResults = await new KeyResultRepository().find({
      where: [
        {
          periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ],
      relations: ['initiatives'],
    });

    const initiativeStatuses = {
      COMPLETED: 0,
      PENDING: 0,
      IN_PROGRESS: 0,
    };

    keyResults.results.map((keyResult) => {
      keyResult.initiatives.map((initiative) => {
        if (initiative.status === InitiativeStatus.COMPLETED)
          initiativeStatuses.COMPLETED++;
        if (initiative.status === InitiativeStatus.PENDING)
          initiativeStatuses.PENDING++;
        if (initiative.status === InitiativeStatus.IN_PROGRESS)
          initiativeStatuses.IN_PROGRESS++;
      });
    });

    setStatuses(initiativeStatuses);
  };

  useEffect(() => {
    calculateStatus();
  }, []);

  useEffect(() => {
    calculateStatus();
  }, [props.period]);

  return (
    <div>
      <div className="font-bold text-lg">
        {t(`entities.metric.objective.initiative.title`)}
      </div>
      <div className="text-default-400 text-sm">
        {t(`entities.metric.objective.initiative.description`)}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <div className="relative">
            <ReactEChart
              option={{
                tooltip: {
                  trigger: 'item',
                },
                series: [
                  {
                    name: t(`entities.metric.objective.initiative.count`),
                    type: 'pie',
                    radius: ['40%', '70%'],
                    center: ['50%', '70%'],
                    startAngle: 180,
                    endAngle: 360,
                    color: ['#d4d4d8', '#99c7fb', '#a2e9c1'],
                    data: [
                      {
                        value: statuses.PENDING,
                        name: t(
                          'entities.metric.objective.initiative.status.PENDING.label',
                        ),
                      },
                      {
                        value: statuses.IN_PROGRESS,
                        name: t(
                          'entities.metric.objective.initiative.status.IN_PROGRESS.label',
                        ),
                      },
                      {
                        value: statuses.COMPLETED,
                        name: t(
                          'entities.metric.objective.initiative.status.COMPLETED.label',
                        ),
                      },
                    ],
                  },
                ],
              }}
            />
            <div className="absolute top-[160px] left-[46.5%] lg:left-[47.5%]">
              <div className="text-default-400 text-sm">
                {t('entities.metric.objective.initiative.total')}
              </div>
              <div className={`text-[30px] font-bold text-center`}>
                {statuses.COMPLETED + statuses.PENDING + statuses.IN_PROGRESS}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="flex gap-3 items-center">
            <div className="w-4 h-4 rounded-full bg-[#d4d4d8]"></div>
            <span className="lg:text-lg">
              {t(
                'entities.metric.objective.initiative.status.PENDING.description',
                { count: statuses.PENDING },
              )}
            </span>
          </div>
          <div className="flex gap-3 items-center">
            <div className="w-4 h-4 rounded-full bg-[#99c7fb]"></div>
            <span className="lg:text-lg">
              {t(
                'entities.metric.objective.initiative.status.IN_PROGRESS.description',
                { count: statuses.IN_PROGRESS },
              )}
            </span>
          </div>
          <div className="flex gap-3 items-center">
            <div className="w-4 h-4 rounded-full bg-[#a2e9c1]"></div>
            <span className="lg:text-lg">
              {t(
                'entities.metric.objective.initiative.status.COMPLETED.description',
                { count: statuses.COMPLETED },
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiativeMetric;
