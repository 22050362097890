import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChallengesRepository from '@modules/voteModule/challenges/challenges.repository';
import ChallengeDTO from '@modules/voteModule/challenges/configuration/dto/challenge.dto';
import ChallengeAnswer from '@modules/voteModule/challenges/vote/components/challengeAnswer';
import ChallengeAnswerDTO from '@modules/voteModule/challenges/vote/dto/challengeAnswer.dto';
import { Spinner } from '@nextui-org/react';
import ErrorPage from '@pages/error/errorPage';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChallengeAnswerPage = () => {
  const { id, ansId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [challenge, setChallenge] = useState<ChallengeDTO | null>(null);
  const [answer, setAnswer] = useState<ChallengeAnswerDTO | null>(null);
  const navigate = useNavigate();

  const getChallenge = async (id: string) => {
    const challengeData = await new ChallengesRepository().getOne(id, {
      relations: ['user'],
    });
    setChallenge(challengeData);
  };

  const getAnswer = async (ansId: string) => {
    const answerData = await new ChallengesRepository().getOneChallengeAnswer(
      ansId,
    );
    setAnswer(answerData);
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) getChallenge(id);
    if (ansId) getAnswer(ansId);
    setIsLoading(false);
  }, [ansId]);

  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {answer && challenge ? (
            <div>
              <h3 className="flex items-center gap-2 mt-0">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="cursor-pointer"
                  onClick={() => navigate(`/enterprise/challenge/view/${id}`)}
                />
                Respuesta al desafío {challenge.title}
              </h3>
              <ChallengeAnswer data={answer} challenge={challenge} />
            </div>
          ) : (
            <ErrorPage />
          )}
        </>
      )}
    </>
  );
};

export default ChallengeAnswerPage;
