import TeamDTO from '@modules/userModule/team/dto/team.dto';
import TeamRepository from '@modules/userModule/team/team.repository';
import RecognitionVoteDTO from '@modules/voteModule/recognition/recognitionVote/dto/recognitionVote.dto';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RocketsPerTeamProps {
  recognitions: RecognitionVoteDTO[];
}

const RocketsPerTeam = ({ recognitions }: RocketsPerTeamProps) => {
  const { t } = useTranslation();
  const [rocketsPerTeam, setRocketsPerTeam] =
    useState<{ team: string; rocketsSum: number }[]>();

  const getRocketsPerTeam = async () => {
    const recognitionRocketsSum: { [key: string]: number } =
      recognitions.reduce(
        (acc, recognition) => {
          const team = recognition.to.externalTeam;
          const rockets = recognition.rockets;
          if (acc[team]) {
            acc[team] += rockets;
          } else {
            acc[team] = rockets;
          }
          return acc;
        },
        {} as { [key: string]: number },
      );
    const teamIds = Object.keys(recognitionRocketsSum).filter(
      (team) => team !== 'null',
    );
    const teams = (
      await new TeamRepository().find({
        where: { id: `in=${teamIds}` },
      })
    ).results;
    const teamsMap = teams.reduce(
      (acc, team) => {
        acc[team.id] = team;
        return acc;
      },
      {} as { [key: string]: TeamDTO },
    );
    const recognitionRocketsSumArray = Object.entries(
      recognitionRocketsSum,
    ).map(([teamId, rocketsSum]) => ({
      team: teamsMap[teamId].name,
      rocketsSum,
    }));
    setRocketsPerTeam(recognitionRocketsSumArray);
  };

  useEffect(() => {
    if (recognitions.length) getRocketsPerTeam();
    else setRocketsPerTeam([]);
  }, [recognitions]);

  return (
    <div className="flex flex-col gap-2 items-center">
      {rocketsPerTeam?.length ? (
        <>
          <p className="text-lg">
            {t('entities.metric.recognition.rocketsPerTeam')}
          </p>
          {rocketsPerTeam?.map((team, index) => {
            if (index < 4)
              return (
                <div key={index} className="w-full flex justify-between">
                  <span className="text-default-400">{team.team}</span>
                  <span className="rounded-full text-primary bg-primary-50 px-2">
                    {team.rocketsSum} rockets
                  </span>
                </div>
              );
          })}{' '}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RocketsPerTeam;
