import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import StrengthDTO from './dto/strength.dto';
import axios from 'axios';
import CreateStrengthDTO from './dto/createStrength.dto';
import UpdateStrengthDTO from './dto/updateStrength.dto';

export default class StrengthRepository {
  private url: string = '/api/strength';

  async find(
    options?: FindOptionsDTO<StrengthDTO>,
  ): Promise<PaginatedDTO<StrengthDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const strengthResponse = await axios.get<PaginatedDTO<StrengthDTO>>(
      `${this.url}${stringOption}`,
    );
    return strengthResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<StrengthDTO>,
  ): Promise<StrengthDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const strengthResponse = await axios.get<StrengthDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return strengthResponse.data;
  }

  async save(pulseSurvey: CreateStrengthDTO) {
    const createdStrengthResponse = await axios.post<StrengthDTO>(
      `${this.url}`,
      pulseSurvey,
    );
    return createdStrengthResponse.data;
  }

  async update(id: string, changes: UpdateStrengthDTO) {
    const updatedStrengthResponse = await axios.put<StrengthDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedStrengthResponse.data;
  }

  async delete(id: string) {
    const deletedStrengthResponse = await axios.delete<StrengthDTO>(
      `${this.url}/${id}`,
    );
    return deletedStrengthResponse.data;
  }
}
