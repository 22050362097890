import { BlockNoteSchema, defaultInlineContentSpecs } from '@blocknote/core';
import { createReactInlineContentSpec } from '@blocknote/react';

const Mention = createReactInlineContentSpec(
  {
    type: 'mention',
    propSchema: {
      userName: {
        default: 'Unknown',
      },
      userId: {
        default: '',
      },
    },
    content: 'none',
  },
  {
    render: (props) => (
      <a href={`/enterprise/profile/${props.inlineContent.props.userId}`}>
        {props.inlineContent.props.userName}
      </a>
    ),
  },
);

const Rockets = createReactInlineContentSpec(
  {
    type: 'rockets',
    propSchema: {
      amount: {
        default: '0',
      },
    },
    content: 'none',
  },
  {
    render: (props) => (
      <span className="flex items-center gap-2 py-2 bg-primary-50 px-3 rounded-full w-fit my-3 text-primary text-sm">
        <img
          src="https://giveit-system-assets.s3.amazonaws.com/assets/giveit.svg"
          className="h-4 w-4 m-0"
        />
        +{props.inlineContent.props.amount}
      </span>
    ),
  },
);

export const customSchema = BlockNoteSchema.create({
  inlineContentSpecs: {
    ...defaultInlineContentSpecs,
    mention: Mention,
    rockets: Rockets,
  },
});

export type CustomBlock = typeof customSchema.Block;
export type CustomPartialBlock = typeof customSchema.PartialBlock;
export type CustomBlockNoteEditor = typeof customSchema.BlockNoteEditor;
