import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  User,
} from '@nextui-org/react';
import DateUtil from '@utils/date';
import React, { useState } from 'react';
import CommentDTO from '../dto/comment.dto';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import OptionsIcon from '@components/icons/options.icon';
import CreateComment from './createComment';
import CommentRepository from '../comment.repository';

interface ObjectiveCommentProps {
  comment: CommentDTO;
  refresh: () => void;
}

const ObjectiveComment = ({ comment, refresh }: ObjectiveCommentProps) => {
  const profile = useSelector(selectProfile);
  const editModal = useDisclosure();
  const deleteModal = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const deleteComment = async () => {
    setIsLoading(true);
    await new CommentRepository().deleteComment(comment.id);
    deleteModal.onClose();
    setIsLoading(false);
    refresh();
  };

  return (
    <>
      <Card className="shadow-none">
        <CardHeader className="justify-between">
          <User
            name={comment.user.fullName}
            description={DateUtil.dateToFullDate(comment.createdDate)}
            avatarProps={{
              isBordered: profile?.id === comment.user.externalUser,
              color:
                profile?.id === comment.user.externalUser
                  ? 'primary'
                  : 'secondary',
              src:
                comment.user.avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${comment.user.avatar}`,
            }}
          />
          {profile?.id === comment.user.externalUser && (
            <Dropdown>
              <DropdownTrigger>
                <Button radius="full" isIconOnly className="bg-transparent">
                  <OptionsIcon />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  onPress={editModal.onOpen}
                  className="font-poppins"
                >
                  Editar
                </DropdownItem>
                <DropdownItem
                  onPress={deleteModal.onOpen}
                  className="font-poppins text-danger"
                >
                  Eliminar
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </CardHeader>
        <CardBody>
          <div dangerouslySetInnerHTML={{ __html: comment.content }}></div>
        </CardBody>
      </Card>
      <Modal
        isOpen={editModal.isOpen}
        onOpenChange={editModal.onOpenChange}
        size="3xl"
        className="py-5 lg:px-10 font-poppins bg-content4"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalHeader>Editar comentario</ModalHeader>
          <ModalBody>
            <CreateComment data={comment} refresh={refresh} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteModal.isOpen}
        onOpenChange={deleteModal.onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Eliminar comentario </ModalHeader>
              <ModalBody>
                <p>¿Estás seguro que deseas eliminar el comentario?</p>
              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose}>Cancelar</Button>
                <Button
                  onPress={deleteComment}
                  color="danger"
                  isLoading={isLoading}
                >
                  Eliminar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ObjectiveComment;
