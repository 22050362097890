import DocumentationIcon from '@components/icons/documentation.icon';
import PlusIcon from '@components/icons/plus.icon';
import { Button, Card, CardBody, useDisclosure } from '@nextui-org/react';
import React from 'react';
import DocumentNameModal from './documentNameModal';
import { useTranslation } from 'react-i18next';
import { useDocumentContext } from '../context/document.context';

const OnBoardingMessage = () => {
  const { t } = useTranslation();
  const { documents } = useDocumentContext();
  const nameModal = useDisclosure();

  return (
    <>
      <Card
        className={`shadow-none py-7 px-5 lg:min-h-96 ${documents.length ? 'hidden' : ''} lg:flex`}
      >
        <CardBody className="gap-5 lg:justify-center lg:items-center">
          <DocumentationIcon />
          <div className="lg:text-center">
            <p className="text-xl font-semibold">
              {t('user.document.onBoarding.title')}
            </p>
            <p className="text-default-500">
              {t('user.document.onBoarding.text')}
            </p>
          </div>
          <Button color="primary" className="w-fit" onPress={nameModal.onOpen}>
            <PlusIcon className="w-10 h-10" />{' '}
            {t('user.document.onBoarding.button')}
          </Button>
        </CardBody>
      </Card>
      <DocumentNameModal
        isOpen={nameModal.isOpen}
        onOpenChange={nameModal.onOpenChange}
      />
    </>
  );
};

export default OnBoardingMessage;
