import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '@nextui-org/react';
import LicenseTypeForm from '@modules/licenseModule/licenseType/components/licenseTypeForm.component';
import CreateLicenseTypeDTO from '@modules/licenseModule/licenseType/dto/createLicenseType.dto';
import LicenseTypeRepository from '@modules/licenseModule/licenseType/licenseType.repository';

const CreateLicenseTypePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (values: CreateLicenseTypeDTO) => {
    await new LicenseTypeRepository().save(values);
    navigate('/enterprise/license/licenseType');
  };

  return (
    <div className="flex flex-col gap-7">
      <h3 className="flex items-center gap-4">
        <div className="w-12 h-12 rounded-2xl flex items-center justify-center bg-white dark:bg-black drop-shadow-md">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <span className="text-3xl">{t('user.license.view.title')}</span>
      </h3>
      <Card>
        <CardBody className="p-7 flex flex-col gap-7">
          <div className="flex flex-col gap-2">
            <span className="font-semibold text-xl">
              {t('user.licenseType.new.title')}
            </span>
            <span className="text-[#94A3B8] text-sm">
              {t('user.licenseType.new.subtitle')}
            </span>
          </div>
          <LicenseTypeForm onSubmit={handleSubmit} />
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateLicenseTypePage;
