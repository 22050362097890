import { Card, CardBody } from '@nextui-org/react';
import CreateKeyResultDTO from '../dto/createKeyResult.dto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface KeyResultAddedListProps {
  keyResults: CreateKeyResultDTO[];
  handleDelete: (index: number) => void;
}

const KeyResultAddedList = (props: KeyResultAddedListProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {props.keyResults.length > 0 && (
        <p className="text-sm font-bold">
          {t('user.keyResult.add.keyResultsAdded')}
        </p>
      )}
      <div className="flex gap-3">
        {props.keyResults.map((keyResult, index) => (
          <Card key={keyResult.name}>
            <CardBody className="flex-row p-3 items-center gap-3">
              <span className="bg-primary text-primary-foreground font-bold rounded p-3">
                KR
              </span>
              <span>{keyResult.name}</span>
              <FontAwesomeIcon
                icon={faTimes}
                className="cursor-pointer"
                onClick={() => props.handleDelete(index)}
              />
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default KeyResultAddedList;
