import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Select,
  SelectItem,
  Skeleton,
  Tooltip,
} from '@nextui-org/react';
import React, { useEffect, useRef, useState } from 'react';
import RocketsIndicator from './rocketsIndicator';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import star from '@assets/gif/star.gif';
import goldMedal from '@assets/img/goldMedal.png';
import silverMedal from '@assets/img/silverMedal.png';
import bronzeMedal from '@assets/img/bronzeMedal.png';
import InfoIcon from '@components/icons/info.icon';
import { UserRankingDTO } from '../../dto/userRanking.dto';
import LevelRepository from '../../level.repository';
import { useTranslation } from 'react-i18next';

type PeriodType = 'weekly' | 'monthly' | 'yearly';

const UsersRanking = () => {
  const { t } = useTranslation();
  const [topUsers, setTopUsers] = useState<UserRankingDTO[]>([]);
  const [levelRepository] = useState(new LevelRepository());
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodType>('yearly');
  const [isLoading, setIsLoading] = useState(false);

  const profile = useSelector(selectProfile);
  const profileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (profileRef.current)
      profileRef.current.scrollIntoView({ block: 'center' });
  }, [profileRef]);

  const getRanking = async () => {
    setIsLoading(true);
    let users: UserRankingDTO[] = [];
    switch (selectedPeriod) {
      case 'yearly':
        users = await levelRepository.findRanking();
        break;
      case 'monthly':
        users = await levelRepository.findRankingMonthly();
        break;
      case 'weekly':
        users = await levelRepository.findRankingWeekley();
        break;
    }
    setTopUsers(users);
    setIsLoading(false);
  };

  useEffect(() => {
    getRanking();
  }, [selectedPeriod]);

  return (
    <Card className="shadow-none overflow-hidden h-full lg:w-[45%]">
      <CardHeader className="flex-col items-start pb-0">
        <div className="flex justify-between items-center w-full pb-2">
          <div className="flex gap-2 items-center">
            <p className="font-bold">{t('user.level.ranking.title')}</p>
            <Tooltip
              content={
                <div className="text-xs max-w-40">
                  {t('user.level.ranking.tooltip')}
                </div>
              }
              isOpen={infoOpen}
              onOpenChange={(open) => setInfoOpen(open)}
              showArrow={true}
              placement="right"
            >
              <Button
                isIconOnly
                variant="light"
                size="sm"
                color="primary"
                onPress={() => setInfoOpen(!infoOpen)}
              >
                <InfoIcon className="text-primary" />
              </Button>
            </Tooltip>
          </div>
          <Select
            label={t('user.level.ranking.period.label')}
            size="sm"
            color="primary"
            className="w-1/2 shadow-none"
            selectedKeys={[selectedPeriod]}
            onChange={(e) => setSelectedPeriod(e.target.value as PeriodType)}
          >
            <SelectItem key={'yearly'}>
              {t('user.level.ranking.period.yearly')}
            </SelectItem>
            <SelectItem key={'monthly'}>
              {t('user.level.ranking.period.monthly')}
            </SelectItem>
            <SelectItem key={'weekly'}>
              {t('user.level.ranking.period.weekly')}
            </SelectItem>
          </Select>
          <img src={star} alt="Star" className="size-8 m-0" />
        </div>
        {topUsers.find((user) => user.id === profile?.id) && (
          <p>{t('user.level.ranking.userInRanking')}</p>
        )}
      </CardHeader>
      <CardBody className="gap-2">
        {isLoading ? (
          <>
            <Skeleton className="w-full h-10 rounded-lg"></Skeleton>
            <Skeleton className="w-full h-10 rounded-lg"></Skeleton>
            <Skeleton className="w-full h-10 rounded-lg"></Skeleton>
          </>
        ) : topUsers.length ? (
          topUsers.map((user, index) => (
            <div
              key={index}
              className={`text-nowrap flex items-center justify-between rounded-lg p-2 gap-2 border-primary-50 dark:border-primary-300 ${user.id === profile?.id ? 'border-3' : ''} ${index === 0 ? 'bg-warning-50 border-warning-100 dark:border-warning-100' : ''} ${index === 1 ? 'bg-slate-100 border-slate-200 dark:bg-slate-600 dark:border-slate-400' : ''} ${index === 2 ? 'bg-warning-100 border-warning-200 dark:border-warning-200' : ''}`}
              ref={user.id === profile?.id ? profileRef : undefined}
            >
              <div className="flex gap-2 items-center">
                {index === 0 && (
                  <img
                    src={goldMedal}
                    alt="Gold medal"
                    className="size-5 m-0"
                  />
                )}
                {index === 1 && (
                  <img
                    src={silverMedal}
                    alt="Silver medal"
                    className="size-5 m-0"
                  />
                )}
                {index === 2 && (
                  <img
                    src={bronzeMedal}
                    alt="Bronze medal"
                    className="size-5 m-0"
                  />
                )}
                {index > 2 && (
                  <span className="rounded-full size-5 text-center text-white bg-purple-400 text-xs flex flex-col justify-center">
                    {index + 1}
                  </span>
                )}
                <Avatar
                  src={`https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`}
                  className="w-7 h-7 flex-shrink-0"
                  color={profile?.id === user.id ? 'primary' : 'secondary'}
                  isBordered={profile?.id === user.id}
                />
              </div>
              <span className="max-w-full truncate">
                {user.firstName} {user.lastName}
              </span>
              <div className="flex gap-2 items-center">
                <span className="text-default-400 text-xs">
                  {t('user.level.level', { number: user.level })}
                </span>
                {/*<RocketsIndicator amount={user.rocketsInWeek} />*/}
                <RocketsIndicator amount={user.rockets} />
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm text-center">
            {t('user.level.ranking.noUsers')}
          </p>
        )}
      </CardBody>
    </Card>
  );
};

export default UsersRanking;
