import React from 'react';
import vacationIcon from '@assets/icons/vacation.svg';
import { Button } from '@nextui-org/react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateLicenseRequestAnounce = (props: {
  onClickAnnouncement: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white dark:bg-black rounded-2xl p-7 flex flex-col items-center gap-7">
      <img src={vacationIcon} alt="vacation" className="w-9 m-0" />
      <span className="text-xl font-semibold">
        {t('user.license.view.user.card.title')}
      </span>
      <p className="text-[#94A3B8] w-[80%] text-center">
        {t('user.license.view.user.card.description')}
      </p>
      <Button
        color="primary"
        className="border border-[#CCE3FD]"
        onClick={props.onClickAnnouncement}
      >
        <div className="flex items-center gap-3 p-3">
          <FontAwesomeIcon icon={faPlus} />
          {t('user.license.view.user.card.button')}
        </div>
      </Button>
    </div>
  );
};

export default CreateLicenseRequestAnounce;
