import i18next from 'i18next';
import * as Yup from 'yup';

const UserGroupFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18next.t('validations.required')),
    default: Yup.bool(),
    permissions: Yup.array(),
  });
};

export default UserGroupFormValidation;
