import React from 'react';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import PostCategoryDTO from '@modules/feed/createPost/dto/postCategory.dto';
import PostsRepository from '@modules/feed/posts/posts.repository';
import ChallengesRepository from '@modules/voteModule/challenges/challenges.repository';
import { Autocomplete, AutocompleteItem } from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

interface TagInputProps {
  tags: PostCategoryDTO[] | [];
  setTags: Dispatch<SetStateAction<PostCategoryDTO[]>>;
  findOrSaveTag: (name: string) => Promise<string>;
  repository?: PostsRepository | ChallengesRepository;
}

const TagInput = ({
  tags,
  setTags,
  findOrSaveTag,
  repository,
}: TagInputProps) => {
  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState<PostCategoryDTO[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedKey, setSelectedKey] = useState('');

  const handleSelection = (selection: PostCategoryDTO) => {
    setSelectedKey(selection.name);
    if (!tags.find((t) => t.name === selection.name)) {
      setTags([...tags, selection]);
    }
  };

  const handleInputChange = (inputValue: string) => {
    setValue(inputValue);
    getCategories(PAGINATION_TAKE, inputValue);
  };

  const handleEnterPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && value.trim() !== '') {
      const id = await findOrSaveTag(value);
      setTags([...tags, { name: value, id }]);
      setValue('');
    }
  };

  const getCategories = async (
    amount: number = PAGINATION_TAKE,
    searchValue?: string,
  ) => {
    setIsLoading(true);
    let categories;
    if (repository)
      categories = await repository.getCategory({
        take: amount,
        ...(searchValue && {
          where: { name: `lk=${searchValue}` },
          order: { name: 'DESC' },
        }),
      });
    else {
      categories = await new PostsRepository().getCategory({
        take: amount,
        ...(searchValue && {
          where: { name: `lk=${searchValue}` },
          order: { name: 'DESC' },
        }),
      });
    }
    if (categories) setCategoriesList(categories);
    if (categories.length < amount) setHasMore(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getCategories();
  }, [tags]);

  const loadMore = () => {
    const newOffset = categoriesList.length + PAGINATION_TAKE;
    getCategories(newOffset, value);
  };

  const [, scrollerRef] = useInfiniteScroll({
    hasMore,
    isEnabled: isOpen,
    shouldUseLoader: false,
    onLoadMore: loadMore,
  });

  return (
    <>
      <Autocomplete
        items={categoriesList}
        onOpenChange={setIsOpen}
        isLoading={isLoading}
        scrollRef={scrollerRef}
        inputValue={value}
        onInputChange={handleInputChange}
        onKeyDown={handleEnterPress}
        allowsCustomValue={true}
        selectedKey={selectedKey}
        onSelectionChange={() => {}}
        popoverProps={{ placement: 'bottom' }}
        inputProps={{
          classNames: {
            inputWrapper:
              'bg-content2 group-data-[focus=true]:bg-content2 data-[hover=true]:bg-content2',
          },
        }}
      >
        {(item) => (
          <AutocompleteItem
            key={item.name}
            onClick={() => handleSelection(item)}
          >
            {item.name}
          </AutocompleteItem>
        )}
      </Autocomplete>
    </>
  );
};

export default TagInput;
