import loginBackground from '@assets/img/login_background.png';
import logo from '@assets/img/giveit.svg';
import googleLogo from '@assets/img/google_logo.svg';
import { Button, Checkbox, Input, Link } from '@nextui-org/react';
import styles from './signin.module.css';
import { Formik, Form, FormikProps } from 'formik';
import signinValidation from './signin.validation';
import SigninDTO from '@modules/userModule/auth/dto/signin.dto';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import AuthRepository from '@modules/userModule/auth/auth.repository';
import UnauthorizedException from '@modules/userModule/auth/exceptions/unauthorized.exception';
import { useNavigate } from 'react-router-dom';
import PaymentRequiredException from '@modules/userModule/auth/exceptions/paymentRequired.exception';

const SignIn = () => {
  const initialValues: SigninDTO = {
    email: '',
    password: '',
    rememberMe: false,
  };
  const { t } = useTranslation();
  const [viewPassword, setViewPassword] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="h-screen flex items-center justify-center">
        <div className="w-[90%] md:w-[70%] flex flex-col gap-10">
          <div className="flex flex-col gap-5">
            <img className="m-0 w-8" src={logo} alt="logo" />
            <span className="text-background-foreground text-2xl font-bold">
              {t('login.title')}
            </span>
            <div>
              <span className="text-gray-500">
                {t('login.notMember')}{' '}
                <Link
                  className="cursor-pointer"
                  onClick={() => navigate('/auth/signup/options')}
                >
                  {t('login.freeTier')}
                </Link>
              </span>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, helpers) => {
              try {
                await new AuthRepository().signin(values);
                navigate('/');
              } catch (err) {
                if (err instanceof UnauthorizedException) {
                  helpers.setFieldError('email', t('login.failLogin'));
                  helpers.setFieldError('password', t('login.failLogin'));
                }

                if (err instanceof PaymentRequiredException) {
                  helpers.setFieldError(
                    'email',
                    t('login.paymentRequiredFailed'),
                  );
                  helpers.setFieldError(
                    'password',
                    t('login.paymentRequiredFailed'),
                  );
                }
              }
            }}
            validationSchema={signinValidation}
          >
            {({
              errors,
              setFieldValue,
              isSubmitting,
            }: FormikProps<SigninDTO>) => (
              <Form className="flex flex-col gap-10">
                <div className="flex flex-col gap-5">
                  <Input
                    name="email"
                    color={errors.email ? 'danger' : 'default'}
                    isInvalid={Boolean(errors.email)}
                    errorMessage={errors.email}
                    label={t('login.inputs.email.label')}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  <Input
                    type={viewPassword ? 'text' : 'password'}
                    endContent={
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        onClick={() => setViewPassword(!viewPassword)}
                        icon={viewPassword ? faEyeSlash : faEye}
                      />
                    }
                    name="password"
                    color={errors.password ? 'danger' : 'default'}
                    isInvalid={Boolean(errors.password)}
                    errorMessage={errors.password}
                    label={t('login.inputs.password.label')}
                    onChange={(e) => setFieldValue('password', e.target.value)}
                  />
                </div>
                <div className="flex justify-between flex-wrap gap-3">
                  <Checkbox
                    name="rememberMe"
                    onValueChange={(e) => setFieldValue('rememberMe', e)}
                  >
                    {t('login.inputs.rememberMe.label')}
                  </Checkbox>
                  <Link className="cursor-pointer">
                    {t('login.forgetPassword')}
                  </Link>
                </div>
                <Button isLoading={isSubmitting} type="submit" color="primary">
                  {t('login.buttonLogin')}
                </Button>
              </Form>
            )}
          </Formik>
          <span className={`${styles.loginAuthSeparator} text-gray-500`}>
            {t('login.or')}
          </span>
          <Button
            className="bg-background text-background-foreground border-[0.5px] border-gray-400"
            startContent={<img src={googleLogo} alt="G" />}
            onClick={() => {
              new AuthRepository().signinGoogle();
            }}
          >
            {t('login.googleLogin')}
          </Button>
        </div>
      </div>
      <img
        className="hidden md:block m-0 w-full h-screen object-cover"
        src={loginBackground}
        alt="login background"
      />
    </div>
  );
};

export default SignIn;
