import IconProps from './common/icon.interface';

const SidebarOpenIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
      id="sidebar"
      className={className}
    >
      <rect
        width="97"
        height="97"
        x="113"
        y="112"
        stroke="currentColor"
        strokeWidth="7"
        rx="27"
        transform="rotate(180 113 112)"
      ></rect>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="7"
        d="M82 111L82 16"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7"
        d="M55 49L53.0973 50.3431C48.7198 53.4331 44.7866 57.1088 41.4077 61.2674V61.2674C40.5875 62.2769 40.5875 63.7231 41.4077 64.7326V64.7326C44.7866 68.8912 48.7198 72.5669 53.0973 75.6569L55 77"
      ></path>
    </svg>
  );
};

export default SidebarOpenIcon;
