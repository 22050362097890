import React from 'react';
import Table from '@components/table/table';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import FindOptionsDTO from 'lib/paginated/findOptions.dto';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import UserRepository from '../user.repository';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface UserListProps {
  options?: FindOptionsDTO<UserDTO>;
  configurations?: {
    add: {
      isAllowed: boolean;
    };
    view: {
      isAllowed: boolean;
    };
    edit: {
      isAllowed: boolean;
    };
    delete: {
      isAllowed: boolean;
    };
  };
}

const UserList = ({
  options,
  configurations = {
    add: {
      isAllowed: true,
    },
    view: {
      isAllowed: true,
    },
    edit: {
      isAllowed: true,
    },
    delete: {
      isAllowed: true,
    },
  },
}: UserListProps) => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const getUsers = useCallback(async (option?: FindOptionsDTO<UserDTO>) => {
    return await new UserRepository().find({
      ...option,
      order: { firstName: 'ASC', lastName: 'ASC', email: 'ASC' },
    });
  }, []);

  const handleSearcher = useCallback(
    (textSearched: string, option?: FindOptionsDTO<UserDTO>) => {
      return {
        ...option,
        where: [
          { email: `lk=${textSearched}` },
          { firstName: `lk=${textSearched}` },
          { lastName: `lk=${textSearched}` },
        ],
      };
    },
    [],
  );

  const handleAddButton = useCallback(() => {
    navigate('/enterprise/configuration/user/add');
  }, [navigate]);

  const handleEditButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/user/edit/${id}`);
    },
    [navigate],
  );

  const handleViewButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/user/view/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback(async (id: string) => {
    await new UserRepository().delete(id);
  }, []);

  return (
    <Table<UserDTO>
      headers={['email', 'firstName', 'lastName']}
      handlerRequest={(option) => getUsers({ ...options, ...option })}
      handlerSearcher={handleSearcher}
      options={options}
      configurations={{
        translationLabel: 'user',
        add: {
          handler: handleAddButton,
          isAllowed:
            configurations.add.isAllowed &&
            Boolean(
              profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.USER &&
                  permission.methods === AllowedMethods.ADD,
              ),
            ),
        },
        edit: {
          handler: handleEditButton,
          isAllowed:
            configurations.edit.isAllowed &&
            Boolean(
              profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.USER &&
                  permission.methods === AllowedMethods.MODIFY,
              ),
            ),
        },
        view: {
          handler: handleViewButton,
          isAllowed:
            configurations.view.isAllowed &&
            Boolean(
              profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.USER &&
                  permission.methods === AllowedMethods.VIEW,
              ),
            ),
        },
        delete: {
          field: 'email',
          isAllowed:
            configurations.delete.isAllowed &&
            Boolean(
              profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.USER &&
                  permission.methods === AllowedMethods.DELETE,
              ),
            ),
          handler: handleDelete,
        },
      }}
    />
  );
};

export default UserList;
