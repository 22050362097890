import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import UserDetail from '@modules/userModule/user/components/userDetail';
import React from 'react';

const ViewUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.users.detail')}
      </h3>
      <UserDetail id={id as string} />
    </div>
  );
};
export default ViewUser;
