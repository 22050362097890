import ArrowIcon from '@components/icons/arrow.icon';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { LevelDTO } from '@modules/userModule/level/dto/level.dto';
import LevelRepository from '@modules/userModule/level/level.repository';
import LevelList from '@modules/userModule/level/user/components/levelList';
import LevelProfile from '@modules/userModule/level/user/components/levelProfile';
import RewardsModal from '@modules/userModule/level/user/components/rewardsModal';
import TrendingUsers from '@modules/userModule/level/user/components/trendingUsers';
import UsersRanking from '@modules/userModule/level/user/components/usersRanking';
import { Skeleton } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UserViewPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const [levelRepository] = useState(new LevelRepository());
  const [levels, setLevels] = useState<LevelDTO[]>();
  const [currentLevel, setCurrentLevel] = useState<LevelDTO>();
  const [nextLevel, setNextLevel] = useState<LevelDTO>();

  const getLevels = async () => {
    const levelResult = (
      await levelRepository.find({
        where: { users: { id: profile?.id } },
      })
    ).results[0];
    setCurrentLevel(levelResult);
    const levelsResult = await levelRepository.find({
      order: { rockets: 'ASC' },
    });
    const levelsEdited: LevelDTO[] = [];
    let foundNext = false;
    levelsResult.results.forEach((level) => {
      if (levelResult.number > level.number) level.state = 'achieved';
      else if (levelResult.number < level.number) {
        if (!foundNext) {
          foundNext = true;
          setNextLevel(level);
        }
        level.state = 'blocked';
      } else level.state = 'current';
      level.reward = level.reward ? level.reward : '-';
      levelsEdited.push(level);
    });
    if (!foundNext) setNextLevel(levelResult);
    setLevels(levelsEdited);
  };

  useEffect(() => {
    getLevels();
  }, [profile]);

  return (
    <div className="pb-5">
      <div className="flex justify-between gap-5">
        <h3 className="text-primary flex gap-5 items-center mt-6">
          {' '}
          <span
            className="cursor-pointer bg-content4 rounded-xl p-1.5 w-9 h-9"
            onClick={() => navigate(-1)}
          >
            <ArrowIcon />
          </span>
          {t('user.level.title')}
        </h3>
        {levels && ( //.filter((level) => level.reward)
          <RewardsModal levels={levels} />
        )}
      </div>
      <div className="flex flex-col lg:flex-row gap-5">
        <LevelProfile currentLevel={currentLevel} nextLevel={nextLevel} />
        <div className="flex flex-col gap-5 h-fit lg:w-3/4 lg:h-screen lg:max-h-[650px]">
          {levels ? (
            <LevelList levels={levels} />
          ) : (
            <Skeleton className="w-full h-52 lg:h-1/3 rounded-xl" />
          )}
          <div className="flex flex-col lg:flex-row gap-5 lg:h-2/3">
            <TrendingUsers />
            <UsersRanking />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserViewPage;
