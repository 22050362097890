import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PulseSurveyMetricRepository from '../pulseSurvey/pulseSurveyMetric.repository';
import ThermometerSurveyMetricRepository from '../thermometerSurvey/thermometerSurveyMetric.repository';
import CardMetric from './cardMetric';

interface SatisfactionSurveyMetricProps {
  options: { from: string; to: string; bt: string };
  className?: string;
}

const SatisfactionSurveyMetric = (props: SatisfactionSurveyMetricProps) => {
  const { t } = useTranslation();
  const [percent, setPercent] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  const voteToPercentPulse = (vote: 'AGREE' | 'NOT_QUITE' | 'NOT_AGREE') => {
    const percentResults = {
      AGREE: 100,
      NOT_QUITE: 50,
      NOT_AGREE: 0,
    };

    return percentResults[vote];
  };

  const voteToPercentThermometer = (vote: 'GOOD' | 'NETRUAL' | 'BAD') => {
    const percentResults = {
      GOOD: 100,
      NETRUAL: 50,
      BAD: 0,
    };

    return percentResults[vote];
  };

  const getPulseMetricPercentResponse = async () => {
    const pulseSurveysResult = await new PulseSurveyMetricRepository().find({
      where: {
        dateFrom: props.options.from,
        dateTo: props.options.to,
      },
      relations: ['votes'],
    });

    const arrSumPulsePercents = pulseSurveysResult.results.map(
      (pulseSurveys) => {
        const votesPercent = pulseSurveys.votes.map((vote) => {
          return voteToPercentPulse(vote.vote);
        });

        const sumVotes = votesPercent.reduce((a, b) => a + b, 0);

        return sumVotes / pulseSurveys.votes.length;
      },
    );

    const sumPulsePercents = arrSumPulsePercents.reduce((a, b) => a + b, 0);

    return sumPulsePercents / arrSumPulsePercents.length;
  };

  const getThermometerMetricPercentResponse = async () => {
    const thermometerSurveysResult =
      await new ThermometerSurveyMetricRepository().find({
        where: { createdDate: props.options.bt },
      });

    const arrSumThermometerPercents = thermometerSurveysResult.results.map(
      (thermometerSurveys) => {
        return voteToPercentThermometer(thermometerSurveys.vote);
      },
    );

    const sumThermometerPercents = arrSumThermometerPercents.reduce(
      (a, b) => a + b,
      0,
    );

    return sumThermometerPercents / arrSumThermometerPercents.length;
  };

  const getSatisfactionPercent = async () => {
    setLoading(true);
    setPercent(
      ((await getThermometerMetricPercentResponse()) +
        (await getPulseMetricPercentResponse())) /
        2,
    );
    setLoading(false);
  };

  useEffect(() => {
    getSatisfactionPercent();
  }, []);

  useEffect(() => {
    getSatisfactionPercent();
  }, [props.options]);

  return (
    <CardMetric
      helpTitle={t('entities.metric.satisfaction.helpTitle')}
      title={t('entities.metric.satisfaction.title')}
      result={percent}
      isLoading={isLoading}
      className={`${props.className}`}
    />
  );
};

export default SatisfactionSurveyMetric;
