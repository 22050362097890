import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import DateUtil from '@utils/date';
import PulseSurveyDTO from './dto/pulseSurvey.dto';

export default class PulseSurveyMetricRepository {
  private url: string = '/api/pulseSurvey';

  async find(
    options?: FindOptionsDTO<PulseSurveyDTO>,
  ): Promise<PaginatedDTO<PulseSurveyDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const pulseResponse = await axios.get<PaginatedDTO<PulseSurveyDTO>>(
      `${this.url}${stringOption}`,
    );
    pulseResponse.data.results.forEach((pulseSurvey) => {
      pulseSurvey.dateFrom = DateUtil.stringToDate(
        String(pulseSurvey.dateFrom),
      );
      pulseSurvey.dateTo = DateUtil.stringToDate(String(pulseSurvey.dateTo));
      pulseSurvey.isActive =
        new Date() > pulseSurvey.dateFrom && new Date() < pulseSurvey.dateTo;
    });
    return pulseResponse.data;
  }

  async voteResponseAverage(
    options?: FindOptionsDTO<PulseSurveyDTO>,
  ): Promise<{ sum: number; avg: number }> {
    const pulsesSurvey = await this.find({
      ...options,
      relations: Object.assign([], options?.relations, ['votes']),
    });

    let voteSum = 0;

    pulsesSurvey.results.forEach((survey) => {
      voteSum += survey.votes.length;
    });

    if (pulsesSurvey.total === 0)
      return { sum: pulsesSurvey.total, avg: pulsesSurvey.total };

    return { sum: voteSum, avg: voteSum / pulsesSurvey.total };
  }
}
