import { useTranslation } from 'react-i18next';
import registerBackground from '@assets/img/register_background.svg';
import { Form, Formik, FormikProps } from 'formik';
import signupContactValidation from '@modules/userModule/auth/validations/contact.validation';
import UserSignupDTO from '@modules/userModule/auth/dto/userSignup.dto';
import { Button, Input, Link } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthRepository from '@modules/userModule/auth/auth.repository';
import {
  getSignupContact,
  setSignupContact,
} from '@modules/userModule/auth/auth.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SignupType from '@modules/userModule/auth/enum/signupType.enum';

interface UserSignupForm extends UserSignupDTO {
  repeatPassword: string;
  country: string;
}

const SignupContact = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const signupType = params.get('t') as SignupType;
  const prevContactValues = useSelector(getSignupContact);
  const initialValues = {
    email: prevContactValues.email || '',
    password: prevContactValues.password || '',
    firstName: prevContactValues.firstName || '',
    lastName: prevContactValues.lastName || '',
    phone: prevContactValues.phone || '',
    repeatPassword: prevContactValues.phone || '',
    country: '',
  };
  const [viewPassword, setViewPassword] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="flex">
      <div className="hidden md:block w-[33%]">
        <div
          className="fixed w-[27%] h-screen bg-no-repeat p-8"
          style={{ backgroundImage: `url(${registerBackground})` }}
        >
          <div className="flex h-screen flex-col justify-center">
            <h2 className="text-white">
              {t('signup.form.sloganFirst')}
              <span className="text-primary-300">
                {t('signup.form.sloganSecond')}
              </span>
            </h2>
            <p className="text-xs text-white">{t('signup.form.platform')}</p>
          </div>
        </div>
      </div>
      <div className="w-full px-16">
        <h3 className="text-foreground">{t('signup.form.title')}</h3>
        <p className="text-gray-500 text-sm">{t('signup.form.subtitle')}</p>
        <div className="py-5">
          <div className="border-b-[2px]">
            <span className="text-xs text-gray-400">
              {t('signup.form.personal.step', { from: '1', to: '2' })}
            </span>
            <h4 className="text-primary mt-0">
              {t('signup.form.personal.title')}
            </h4>
            <p className="text-gray-500 text-sm">
              {t('signup.form.personal.subtitle')}
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={signupContactValidation}
            onSubmit={async (val, helpers) => {
              try {
                await new AuthRepository().validateContactSignup(val);
                helpers.setSubmitting(false);
                dispatch(setSignupContact(val));
                navigate(`/auth/signup/enterprise?t=${signupType}`);
              } catch (err: any) {
                const errors: any = {};
                Object.keys(err).forEach((errKey) => {
                  errors[errKey] = err[errKey].join(' ');
                });
                helpers.setErrors(errors);
                helpers.setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              setFieldValue,
              isSubmitting,
              values,
            }: FormikProps<UserSignupForm>) => (
              <Form className="py-5 flex flex-col gap-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <Input
                    name="firstName"
                    label={t('signup.form.personal.fields.firstName.label')}
                    isInvalid={Boolean(errors.firstName)}
                    errorMessage={errors.firstName}
                    color={errors.firstName ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('firstName', e.target.value)}
                    value={values.firstName}
                  />
                  <Input
                    name="lastName"
                    label={t('signup.form.personal.fields.lastName.label')}
                    isInvalid={Boolean(errors.lastName)}
                    errorMessage={errors.lastName}
                    color={errors.lastName ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('lastName', e.target.value)}
                    value={values.lastName}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <Input
                    name="phone"
                    label={t('signup.form.personal.fields.phone.label')}
                    isInvalid={Boolean(errors.phone)}
                    errorMessage={errors.phone}
                    color={errors.phone ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('phone', e.target.value)}
                    value={values.phone}
                  />
                  <Input
                    name="email"
                    label={t('signup.form.personal.fields.email.label')}
                    isInvalid={Boolean(errors.email)}
                    errorMessage={errors.email}
                    color={errors.email ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    value={values.email}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <Input
                    name="country"
                    label={t('signup.form.personal.fields.country.label')}
                    isInvalid={Boolean(errors.country)}
                    errorMessage={errors.country}
                    color={errors.country ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('country', e.target.value)}
                    value={values.country}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <Input
                    name="password"
                    label={t('signup.form.personal.fields.password.label')}
                    description={t(
                      'signup.form.personal.fields.password.description',
                    )}
                    isInvalid={Boolean(errors.password)}
                    errorMessage={errors.password}
                    color={errors.password ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('password', e.target.value)}
                    endContent={
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        onClick={() => setViewPassword(!viewPassword)}
                        icon={viewPassword ? faEyeSlash : faEye}
                      />
                    }
                    type={viewPassword ? 'text' : 'password'}
                    value={values.password}
                  />
                  <Input
                    name="repeatPassword"
                    label={t(
                      'signup.form.personal.fields.repeatPassword.label',
                    )}
                    isInvalid={Boolean(errors.repeatPassword)}
                    errorMessage={errors.repeatPassword}
                    color={errors.repeatPassword ? 'danger' : 'default'}
                    onChange={(e) =>
                      setFieldValue('repeatPassword', e.target.value)
                    }
                    endContent={
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        onClick={() => setViewPassword(!viewPassword)}
                        icon={viewPassword ? faEyeSlash : faEye}
                      />
                    }
                    type={viewPassword ? 'text' : 'password'}
                    value={values.repeatPassword}
                  />
                </div>
                <Button type="submit" color="primary" isLoading={isSubmitting}>
                  {t('signup.form.next')}
                </Button>
              </Form>
            )}
          </Formik>
          <div className="py-5 flex justify-center">
            <p className="text-gray-500">
              {t('signup.form.help')}{' '}
              <Link className="text-primary cursor-pointer">
                {t('signup.form.contact')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupContact;
