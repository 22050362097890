import i18next from 'i18next';
import * as Yup from 'yup';

const signupEnterpriseValidation = () => {
  return Yup.object().shape({
    taxId: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    enterpriseName: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    taxAddress: Yup.string().required(i18next.t('validations.required')),
    activity: Yup.string().required(i18next.t('validations.required')),
    employeeCount: Yup.number()
      .required(i18next.t('validations.required'))
      .min(1, i18next.t('validations.min', { min: 1 })),
  });
};

export default signupEnterpriseValidation;
