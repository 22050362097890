import i18next from 'i18next';
import * as Yup from 'yup';

const UserFormValidation = () => {
  return Yup.object().shape({
    email: Yup.string()
      .required(i18next.t('validations.required'))
      .email(i18next.t('validations.email')),
    password: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    firstName: Yup.string().required(i18next.t('validations.required')),
    lastName: Yup.string().required(i18next.t('validations.required')),
    group: Yup.object().shape({
      id: Yup.string().required(i18next.t('validations.required')),
    }),
    team: Yup.object()
      .nullable()
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
  });
};

export default UserFormValidation;
