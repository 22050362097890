/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
} from '@nextui-org/react';
import React from 'react';
import EditLevelModal from './editLevelModal';
import { LevelDTO } from '../../dto/level.dto';
import ViewLevelUsersModal from './viewLevelUsersModal';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useTranslation } from 'react-i18next';

interface LevelsConfigurationListProps {
  levels: LevelDTO[];
  refresh: () => void;
}

const LevelsConfigurationList = ({
  levels,
  refresh,
}: LevelsConfigurationListProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectProfile);
  return (
    <>
      <Table
        classNames={{
          wrapper: 'shadow-none px-0 pt-3 pb-0',
          table: 'mt-0',
          thead: '[&>tr]:first:border-b-0 [&>tr]:first:rounded-none border-0',
          tr: 'border-b-2 border-divider',
          th: 'text-primary bg-primary-50 font-normal text-md ',
        }}
      >
        <TableHeader>
          <TableColumn key={'number'}>
            {t('entities.level.table.level')}
          </TableColumn>
          <TableColumn key={'rockets'}>
            {t('entities.level.table.objective')}
          </TableColumn>
          <TableColumn key={'reward'}>
            {t('entities.level.table.reward')}
          </TableColumn>
          <TableColumn key={'actions'}>
            <></>
          </TableColumn>
        </TableHeader>
        <TableBody>
          {levels.map((level, index) => (
            <TableRow key={level.number}>
              {(columnKey) => {
                if (columnKey === 'actions') {
                  if (level.number === 1)
                    return (
                      <TableCell className="flex gap-2">
                        {user?.group.permissions.some(
                          (permission) =>
                            permission.entity === AllowedEntities.LEVEL &&
                            permission.methods === AllowedMethods.VIEW,
                        ) && <ViewLevelUsersModal level={level} />}
                      </TableCell>
                    );
                  return (
                    <TableCell className="flex gap-2">
                      {user?.group.permissions.some(
                        (permission) =>
                          permission.entity === AllowedEntities.LEVEL &&
                          permission.methods === AllowedMethods.VIEW,
                      ) && <ViewLevelUsersModal level={level} />}

                      {user?.group.permissions.some(
                        (permission) =>
                          permission.entity === AllowedEntities.LEVEL &&
                          permission.methods === AllowedMethods.MODIFY,
                      ) && (
                        <EditLevelModal
                          level={level}
                          minRockets={levels[index - 1]?.rockets}
                          maxRockets={levels[index + 1]?.rockets}
                          refresh={refresh}
                        />
                      )}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    className={`${columnKey === 'reward' ? 'w-1/2' : ''}`}
                  >
                    {getKeyValue(level, columnKey)}{' '}
                    {columnKey === 'rockets' && 'rockets'}
                  </TableCell>
                );
              }}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default LevelsConfigurationList;
