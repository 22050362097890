import React from 'react';
import metricRecognitionLogo from '@assets/img/metric_recognition_logo.svg';
import { Skeleton } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuartersType } from '@lib/type/quarters.type';
import RecognitionVoteDTO from '@modules/voteModule/recognition/recognitionVote/dto/recognitionVote.dto';

interface DeliverRocketsMetricProps {
  recognitions: RecognitionVoteDTO[];
  period: QuartersType;
}

const DeliveryRockets = ({
  recognitions,
  period,
}: DeliverRocketsMetricProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [recognitionsCount, setRecognitionCount] = useState<number>(0);

  const calculateParticipation = async () => {
    setLoading(true);
    let totalRocketsRecongitions = 0;
    recognitions.map((recognition) => {
      totalRocketsRecongitions += recognition.rockets;
    });
    setRecognitionCount(totalRocketsRecongitions);
    setLoading(false);
  };

  useEffect(() => {
    calculateParticipation();
  }, [recognitions]);

  return (
    <div className="flex flex-col items-center gap-2">
      <img className="max-h-44" src={metricRecognitionLogo} />
      <span className="text-default-400 text-sm">
        {t(`entities.metric.participation.period.${period}`)}
      </span>
      <span className="font-bold text-xl">
        {t(`entities.metric.deliveryRocket.title`)}
      </span>
      <Skeleton isLoaded={!isLoading}>
        <span className={`text-5xl font-bold text-primary`}>
          {recognitionsCount}
        </span>
      </Skeleton>
    </div>
  );
};

export default DeliveryRockets;
