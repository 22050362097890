import IconProps from './common/icon.interface';

const RankingIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 19.1115L10.4 20.3288C10.2193 20.4659 10.0401 20.4701 9.86252 20.3413C9.68496 20.2124 9.63143 20.041 9.70195 19.8269L10.3308 17.8346L8.7558 16.7173C8.57503 16.5968 8.52151 16.4311 8.59522 16.2202C8.66894 16.0093 8.81285 15.9038 9.02695 15.9038H10.9346L11.6538 13.5654L8.18077 11.5231C7.86795 11.3397 7.62501 11.0932 7.45195 10.7836C7.27886 10.474 7.19232 10.1307 7.19232 9.75383V4.09613C7.19232 3.59743 7.36892 3.17147 7.72212 2.81825C8.07532 2.46505 8.50128 2.28845 9 2.28845H15C15.4987 2.28845 15.9247 2.46505 16.2779 2.81825C16.6311 3.17147 16.8077 3.59743 16.8077 4.09613V9.75383C16.8077 10.1243 16.7179 10.4612 16.5384 10.7644C16.359 11.0676 16.1128 11.3141 15.8 11.5038L12.3461 13.5654L13.0654 15.9038H14.973C15.1871 15.9038 15.3311 16.0093 15.4048 16.2202C15.4785 16.4311 15.425 16.5968 15.2442 16.7173L13.6692 17.8346L14.298 19.8269C14.3686 20.041 14.315 20.2124 14.1375 20.3413C13.9599 20.4701 13.7807 20.4659 13.6 20.3288L12 19.1115ZM8.69227 4.09613V9.75383C8.69227 9.84998 8.71631 9.93812 8.7644 10.0183C8.81248 10.0984 8.87819 10.1641 8.96152 10.2154L11.25 11.5693V3.78843H9C8.91025 3.78843 8.83652 3.81728 8.77882 3.87498C8.72112 3.93266 8.69227 4.00638 8.69227 4.09613ZM15 3.78843H12.75V11.5693L15.0385 10.2154C15.1218 10.1641 15.1875 10.0984 15.2356 10.0183C15.2837 9.93812 15.3077 9.84998 15.3077 9.75383V4.09613C15.3077 4.00638 15.2789 3.93266 15.2212 3.87498C15.1635 3.81728 15.0897 3.78843 15 3.78843Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RankingIcon;
