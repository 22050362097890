import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ObjectiveDTO from '../dto/objective.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ObjectiveRepository from '../objective.repository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseye,
  faRocket,
  faTrash,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import ObjectivePeriodLabel from './objectivePeriodLabel';
import {
  Button,
  Chip,
  CircularProgress,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Tab,
  Tabs,
  User,
} from '@nextui-org/react';
import ObjectiveStatusLabel from './objectiveStatusLabel';
import CommentRepository from '@modules/objectiveModule/comment/comment.repository';
import CommentList from '@modules/objectiveModule/comment/components/commentList';
import { useNavigate } from 'react-router-dom';
import KeyResultList from '@modules/objectiveModule/keyResult/components/keyResultList';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface ObjectiveDetailProps {
  id: string;
}

const ObjectiveDetail = (props: ObjectiveDetailProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const [objective, setObjective] = useState<ObjectiveDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const getObjective = async (options?: FindOptionsDTO<ObjectiveDTO>) => {
    setLoading(true);
    const userRequest = await new ObjectiveRepository().findOne(props.id, {
      ...options,
      relations: Object.assign([], options?.relations, [
        'manager',
        'notified',
        'categories',
      ]),
    });
    setObjective(userRequest);
    setLoading(false);
  };

  useEffect(() => {
    getObjective();
  }, []);

  const Loading = () => {
    return (
      <div className="py-5 flex flex-col gap-5">
        <div>
          <Skeleton className="h-6 w-64"></Skeleton>
        </div>
        <div className="flex flex-col">
          <Skeleton className="w-64 h-5"></Skeleton>
          <Skeleton className="w-64 h-5"></Skeleton>
        </div>
        <div className="flex flex-wrap gap-8">
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
        </div>
      </div>
    );
  };

  const handleCommentSubmit = async (content: string) => {
    await new CommentRepository().save({
      content: content,
      objective: { id: props.id },
      parent: null,
    });
  };

  const handlerDelete = async () => {
    await new ObjectiveRepository().delete(props.id);
    navigate('/enterprise/objective');
  };

  return isLoading || !objective ? (
    <Loading />
  ) : (
    <div>
      <div className="py-3 flex flex-col gap-8">
        <div className="flex gap-5">
          <div>
            <span className="flex gap-3 items-center font-bold text-lg text-foreground">
              <FontAwesomeIcon icon={faBullseye} className="text-primary" />
              {objective.name}
            </span>
          </div>
          <div className="flex gap-3">
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.OBJECTIVE &&
                permission.methods === AllowedMethods.MODIFY,
            ) && (
              <Button
                color="warning"
                size="sm"
                variant="flat"
                onClick={() =>
                  navigate(`/enterprise/objective/edit/${props.id}`)
                }
              >
                <FontAwesomeIcon icon={faWrench} />
              </Button>
            )}
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.OBJECTIVE &&
                permission.methods === AllowedMethods.DELETE,
            ) && (
              <Button
                color="danger"
                size="sm"
                variant="flat"
                onClick={() => setModalOpen(!isModalOpen)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-bold text-foreground-500">
            {t('entities.objective.fields.description')}
          </span>
          <span className="text-foreground">{objective.description}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-bold text-foreground-500">
            {t('entities.objective.fields.categories')}
          </span>
          <div className="flex gap-3 pt-1">
            {objective.categories.length === 0 && ' - '}
            {objective.categories.map((category) => (
              <Chip key={category.id} color="primary">
                {category.name}
              </Chip>
            ))}
          </div>
        </div>
        <div className="flex flex-wrap gap-16">
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.objective.fields.period')}
            </span>
            <div className="h-full flex items-center">
              <ObjectivePeriodLabel
                periodFrom={objective.periodFrom as Date}
                periodTo={objective.periodTo as Date}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.objective.fields.manager')}
            </span>
            <div className="h-full flex items-center">
              <User
                classNames={{ name: 'text-foreground' }}
                name={objective.manager.fullName}
                description={objective.manager.email}
                avatarProps={{
                  src:
                    objective.manager.avatar &&
                    `https://giveit-system-assets.s3.amazonaws.com/${objective.manager.avatar}`,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.objective.fields.status')}
            </span>
            <div className="h-full flex items-center">
              <ObjectiveStatusLabel status={objective.status} />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.objective.fields.progress')}
            </span>
            <div className="h-full flex items-center">
              <CircularProgress
                classNames={{ svg: 'w-16 h-16' }}
                value={objective.progress}
                showValueLabel={true}
                color="success"
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.objective.fields.rocketValued')}
            </span>
            <div className="h-full flex items-center gap-3">
              <FontAwesomeIcon icon={faRocket} className="text-primary" />
              {objective.rocketValued}
            </div>
          </div>
        </div>
        <Tabs>
          <Tab title={t('user.objective.tabs.keyResult')}>
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.KEY_RESULT &&
                permission.methods === AllowedMethods.ADD,
            ) && (
              <div className="flex justify-end">
                <Button
                  color="primary"
                  onClick={() =>
                    navigate(`/enterprise/objective/${props.id}/keyResult/add`)
                  }
                >
                  {t('user.keyResult.add.title')}
                </Button>
              </div>
            )}
            <KeyResultList
              paginable={true}
              options={{ where: { objective: { id: props.id } } }}
              refresh={getObjective}
            />
          </Tab>
          <Tab title={t('user.objective.tabs.comments')}>
            <CommentList
              objective={props.id}
              handleSubmit={handleCommentSubmit}
            />
          </Tab>
        </Tabs>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)}>
        <ModalContent>
          <ModalHeader>{t(`entities.objective.table.deleteTitle`)}</ModalHeader>
          <ModalBody>
            {t(`entities.objective.table.delete`, { name: objective.name })}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModalOpen(!isModalOpen)}>
              {t(`entities.objective.table.back`)}
            </Button>
            <Button color="danger" onClick={handlerDelete}>
              {t(`entities.objective.table.deleteButton`)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ObjectiveDetail;
