import { RouteObject } from 'react-router-dom';
import SignIn from './signin/signin';
import signupRoutes from './signup/routes';
import SigninGoogle from './signin/signinGoogle';

const authRoutes: Array<RouteObject> = [
  { path: 'signin', element: <SignIn /> },
  { path: 'signin/google', element: <SigninGoogle /> },
  { path: 'signup', children: signupRoutes },
];

export default authRoutes;
