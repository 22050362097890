import { Outlet } from 'react-router-dom';
import AdministratorMenu from './adminNavbar.component';
import EnterpriseType from '@modules/userModule/enterprise/enum/enterpriseType.enum';
import UserMenu from './userNavbar.component';
import React, { useEffect, useState } from 'react';
import AuthRepository from '@modules/userModule/auth/auth.repository';
import ProfileDTO from '@modules/userModule/auth/dto/profile.dto';
import { useDispatch } from 'react-redux';
import { setProfile } from '@modules/userModule/auth/auth.reducer';

const Navbar = ({ enterpriseType }: { enterpriseType: EnterpriseType }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useState<null | ProfileDTO>(null);
  const dispatch = useDispatch();
  const getProfile = async () => {
    const profile = await new AuthRepository().getProfile();
    dispatch(setProfile(profile));
    setUser(profile);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const enterpriseNavbar = {
    [EnterpriseType.ADMINISTRATOR]: <AdministratorMenu />,
    [EnterpriseType.PARTICULAR]: <UserMenu />,
    [EnterpriseType.CONSULTANT]: <UserMenu />,
  };
  return (
    <div className="relative">
      <div className="fixed w-full top-0 z-50">
        {enterpriseNavbar[enterpriseType]}
      </div>
      <div className="container mx-auto px-5 top-20 relative">
        <Outlet />
      </div>
    </div>
  );
};

export default Navbar;
