import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import appRoutes from './router';
import setupAxios from './config/axios.config';
import { Provider as ReduxProvider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import './index.scss';
import './config/i18n.config';
import { NextUIProvider, Spinner } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hotjar from '@hotjar/browser';

setupAxios();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="light">
        <ReduxProvider store={store}>
          <PersistGate
            loading={
              <div className="h-screen flex items-center justify-center">
                <Spinner />
              </div>
            }
            persistor={persistor}
          >
            <Suspense
              fallback={
                <div className="h-screen flex items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <ToastContainer theme="colored" />
              <div className="prose font-poppins max-w-none">
                <RouterProvider router={appRoutes} />
              </div>
            </Suspense>
          </PersistGate>
        </ReduxProvider>
      </NextThemesProvider>
    </NextUIProvider>
  </React.StrictMode>,
);

const siteId = 3927401;
const hotjarVersion = 6;
if (process.env.NODE_ENV !== 'development') {
  Hotjar.init(siteId, hotjarVersion);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
