import ConfigurationIcon from '@components/icons/configuration.icon';
import Input from '@components/input/input';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import LevelRepository from '../../level.repository';
import { LevelDTO } from '../../dto/level.dto';
import { useTranslation } from 'react-i18next';

interface GeneralLevelConfigurationModalProps {
  originalLevels: LevelDTO[];
  levelRepository: LevelRepository;
  refresh: () => void;
}

const GeneralLevelConfigurationModal = ({
  originalLevels,
  levelRepository,
  refresh,
}: GeneralLevelConfigurationModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [amountOfLevels, setAmountOfLevels] = useState('');

  useEffect(() => {
    setAmountOfLevels(originalLevels.length.toString());
  }, [originalLevels]);

  const saveLevels = async () => {
    const levels = Number(amountOfLevels);
    const originalLevelsLength = originalLevels.length;
    if (levels > originalLevelsLength) {
      let lastNumber = originalLevels[originalLevelsLength - 1].number;
      let lastRockets = originalLevels[originalLevelsLength - 1].rockets;
      for (let i = 1; i <= levels - originalLevelsLength; i++) {
        lastNumber++;
        lastRockets = lastRockets + 10;
        await levelRepository.save({
          number: lastNumber,
          rockets: lastRockets,
        });
      }
    } else if (levels < originalLevelsLength) {
      for (let i = levels; i < originalLevelsLength; i++) {
        await levelRepository.delete(originalLevels[i].id);
      }
    }
    refresh();
  };

  return (
    <>
      <Button
        onPress={onOpen}
        startContent={<ConfigurationIcon className="w-5 flex-shrink-0" />}
      >
        <span className="hidden lg:inline">
          {t('entities.level.configuration.button')}
        </span>
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                {t('entities.level.configuration.title')}
              </ModalHeader>
              <ModalBody>
                <p className="text-sm text-foreground-400">
                  {t('entities.level.configuration.description')}
                </p>
                <Input
                  type="number"
                  min={1}
                  max={100}
                  value={amountOfLevels}
                  onValueChange={setAmountOfLevels}
                />
              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose}>
                  {t('entities.level.configuration.cancel')}
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    const levels = Number(amountOfLevels);
                    if (levels > 0 && levels <= 100) {
                      saveLevels();
                      onClose();
                    }
                  }}
                >
                  {t('entities.level.configuration.save')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default GeneralLevelConfigurationModal;
