import { RouteObject } from 'react-router-dom';
import InitiativeAddPage from './add/initiativeAdd.page';
import InitiativeViewPage from './view/initiativeView.page';
import InitiativeEditPage from './edit/initiativeEdit.page';

const initiativeRoutes: Array<RouteObject> = [
  {
    children: [
      { path: 'add/:id', element: <InitiativeAddPage /> },
      { path: 'view/:id', element: <InitiativeViewPage /> },
      { path: 'edit/:id', element: <InitiativeEditPage /> },
    ],
  },
];

export default initiativeRoutes;
