import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import DocumentDTO from './browser/dto/document.dto';
import axios from 'axios';

export default class DocumentRepository {
  private url = '/api/document';

  async get(options?: FindOptionsDTO<DocumentDTO>): Promise<DocumentDTO[]> {
    return (
      await axios.get(
        `${this.url}?options=${encodeURIComponent(JSON.stringify(options))}`,
      )
    ).data.results;
  }

  async getOne(
    id: string,
    options?: FindOptionsDTO<DocumentDTO>,
  ): Promise<DocumentDTO> {
    return (
      await axios.get(
        `${this.url}/${id}?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data;
  }

  async save(document: Partial<DocumentDTO>): Promise<DocumentDTO> {
    return (await axios.post(`${this.url}`, document)).data;
  }

  async updateName(newTitle: string, id: string): Promise<DocumentDTO> {
    return (await axios.put(`${this.url}/name/${id}`, { newTitle })).data;
  }

  async updatePermissions(
    document: Partial<DocumentDTO>,
  ): Promise<DocumentDTO> {
    return (await axios.put(`${this.url}/permissions/${document.id}`, document))
      .data;
  }

  async update(
    document: Partial<DocumentDTO>,
    id: string,
  ): Promise<DocumentDTO> {
    return (await axios.put(`${this.url}/${id}`, document)).data;
  }

  async delete(id: string): Promise<DocumentDTO> {
    return (await axios.delete(`${this.url}/${id}`)).data;
  }

  async getTree() {
    return (await axios.get(`${this.url}/tree`)).data;
  }
}
