import {
  clearSignup,
  getSignupContact,
  getSignupEnterprise,
  setSignupEnterprise,
} from '@modules/userModule/auth/auth.reducer';
import AuthRepository from '@modules/userModule/auth/auth.repository';
import SignupType from '@modules/userModule/auth/enum/signupType.enum';
import signupEnterpriseValidation from '@modules/userModule/auth/validations/enterprise.validation';
import { Formik, FormikProps, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import registerBackground from '@assets/img/register_background.svg';
import { Button, Input, Link } from '@nextui-org/react';

interface EnterpriseSignupForm {
  taxId: string;
  enterpriseName: string;
  taxAddress: string;
  activity: string;
  employeeCount: number;
}

const SignupEnterprise = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signupType = params.get('t') as SignupType;
  const prevContactValues = useSelector(getSignupContact);
  const prevEnterpriseValues = useSelector(getSignupEnterprise);
  const initialValues = {
    taxId: prevEnterpriseValues.taxId,
    enterpriseName: prevEnterpriseValues.enterpriseName,
    taxAddress: prevEnterpriseValues.taxAddress,
    activity: prevEnterpriseValues.activity,
    employeeCount: prevEnterpriseValues.employeeCount,
  };
  return (
    <div className="flex">
      <div className="hidden md:block w-[33%]">
        <div
          className="fixed w-[27%] h-screen bg-no-repeat p-8"
          style={{ backgroundImage: `url(${registerBackground})` }}
        >
          <div className="flex h-screen flex-col justify-center">
            <h2 className="text-white">
              {t('signup.form.sloganFirst')}
              <span className="text-primary-300">
                {t('signup.form.sloganSecond')}
              </span>
            </h2>
            <p className="text-xs text-white">{t('signup.form.platform')}</p>
          </div>
        </div>
      </div>
      <div className="w-full px-16">
        <h3 className="text-foreground">{t('signup.form.title')}</h3>
        <p className="text-gray-500 text-sm">{t('signup.form.subtitle')}</p>
        <div className="py-5">
          <div className="border-b-[2px]">
            <span className="text-xs text-gray-400">
              {t('signup.form.personal.step', { from: '2', to: '2' })}
            </span>
            <h4 className="text-primary mt-0">
              {t('signup.form.enterprise.title')}
            </h4>
            <p className="text-gray-500 text-sm">
              {t('signup.form.personal.subtitle')}
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={signupEnterpriseValidation}
            onSubmit={async (val, helpers) => {
              try {
                dispatch(
                  setSignupEnterprise({
                    ...val,
                    type: signupType,
                    contact: prevContactValues,
                  }),
                );
                await new AuthRepository().signup({
                  ...val,
                  type: signupType,
                  contact: prevContactValues,
                });
                helpers.setSubmitting(false);
                dispatch(clearSignup());
                navigate(`/`);
              } catch (err: any) {
                const errors: any = {};
                Object.keys(err).forEach((errKey) => {
                  errors[errKey] = err[errKey].join(' ');
                });
                helpers.setErrors(errors);
                helpers.setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              setFieldValue,
              isSubmitting,
              values,
            }: FormikProps<EnterpriseSignupForm>) => (
              <Form className="py-5 flex flex-col gap-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <Input
                    name="enterpriseName"
                    label={t(
                      'signup.form.enterprise.fields.enterpriseName.label',
                    )}
                    isInvalid={Boolean(errors.enterpriseName)}
                    errorMessage={errors.enterpriseName}
                    color={errors.enterpriseName ? 'danger' : 'default'}
                    onChange={(e) =>
                      setFieldValue('enterpriseName', e.target.value)
                    }
                    value={values.enterpriseName}
                  />
                  <Input
                    name="taxId"
                    label={t('signup.form.enterprise.fields.taxId.label')}
                    isInvalid={Boolean(errors.taxId)}
                    errorMessage={errors.taxId}
                    color={errors.taxId ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('taxId', e.target.value)}
                    value={values.taxId}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <Input
                    name="activity"
                    label={t('signup.form.enterprise.fields.activity.label')}
                    isInvalid={Boolean(errors.activity)}
                    errorMessage={errors.activity}
                    color={errors.activity ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('activity', e.target.value)}
                    value={values.activity}
                  />
                  <Input
                    name="employeeCount"
                    label={t(
                      'signup.form.enterprise.fields.employeeCount.label',
                    )}
                    isInvalid={Boolean(errors.employeeCount)}
                    errorMessage={errors.employeeCount}
                    color={errors.employeeCount ? 'danger' : 'default'}
                    onChange={(e) =>
                      setFieldValue('employeeCount', Number(e.target.value))
                    }
                    value={String(values.employeeCount)}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <Input
                    name="taxAddress"
                    label={t('signup.form.enterprise.fields.taxAddress.label')}
                    isInvalid={Boolean(errors.taxAddress)}
                    errorMessage={errors.taxAddress}
                    color={errors.taxAddress ? 'danger' : 'default'}
                    onChange={(e) =>
                      setFieldValue('taxAddress', e.target.value)
                    }
                    value={values.taxAddress}
                  />
                </div>
                <Button
                  color="primary"
                  variant="flat"
                  onClick={() => {
                    navigate(-1);
                  }}
                  isLoading={isSubmitting}
                >
                  {t('signup.form.goBack')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  isLoading={isSubmitting}
                  isDisabled={Object.values(errors).some((val) => val)}
                >
                  {t('signup.form.finish')}
                </Button>
              </Form>
            )}
          </Formik>
          <div className="py-5 flex justify-center">
            <p className="text-gray-500">
              {t('signup.form.help')}{' '}
              <Link className="text-primary cursor-pointer">
                {t('signup.form.contact')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupEnterprise;
