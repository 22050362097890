import axios from 'axios';
import CreatePulseVoteDTO from './dto/createPulseVote.dto';

export default class PulseVoteRepository {
  private url: string = '/api/pulseVote';

  async vote(entity: CreatePulseVoteDTO) {
    return await axios.post(`${this.url}/vote`, entity);
  }
}
