import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem } from '@nextui-org/react';
import InitiativeStatus from '../enum/initiativeStatus.enum';

const InitiativeStatusChange = ({
  status,
  onChange,
  disabled,
  options,
}: {
  status: InitiativeStatus;
  onChange: (status: InitiativeStatus) => void;
  disabled?: boolean;
  options?: string;
}) => {
  const { t } = useTranslation();

  const styles: { [key in InitiativeStatus]: string } = {
    PENDING:
      'bg-default-200 text-default-800 border border-default-600 data-[hover=true]:bg-default-200 ',
    IN_PROGRESS:
      'bg-primary-200 text-primary-800 border border-primary-600 data-[hover=true]:bg-primary-200 data-[selected=true]:bg-primary-200',
    COMPLETED:
      'bg-success-200 text-success-800 border border-success-600 data-[hover=true]:bg-success-200',
  };

  return (
    <Select
      isDisabled={disabled}
      classNames={{
        trigger: `${styles[status as InitiativeStatus]}`,
        value: `text-sm p-2 rounded` + options,
      }}
      selectedKeys={new Set([status])}
      onSelectionChange={(keys) => {
        onChange(new Set(keys).values().next().value);
      }}
    >
      {Object.keys(InitiativeStatus).map((selectionStatus) => (
        <SelectItem
          className={`text-sm px-2 py-3 rounded ${styles[selectionStatus as InitiativeStatus]}`}
          key={selectionStatus}
          value={selectionStatus}
        >
          {t(`entities.initiative.values.status.${selectionStatus}`)}
        </SelectItem>
      ))}
    </Select>
  );
};

export default InitiativeStatusChange;
