import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnterpriseDTO from '../dto/enterprise.dto';
import FindOptions from 'lib/paginated/findOptions.dto';
import EnterpriseRepository from '../enterprise.repository';

interface EnterpriseDetailProps {
  id: string;
  options?: FindOptions<EnterpriseDTO>;
}

const EnterpriseDetail = (props: EnterpriseDetailProps) => {
  const { t } = useTranslation();
  const [enterprisesResult, setEnterpriseResult] =
    useState<EnterpriseDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getEnterprise = async (options?: FindOptions<EnterpriseDTO>) => {
    setLoading(true);
    const enterpriseResult = await new EnterpriseRepository().getEnterprise(
      props.id,
      options,
    );
    setEnterpriseResult(enterpriseResult);
    setLoading(false);
  };

  useEffect(() => {
    getEnterprise(props.options);
  }, []);

  return (
    <Card className="my-5">
      <CardBody className="flex gap-5">
        <div className="grid grid-cols-1 md:grid-cols-2  gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p className="text-foreground">
              <strong className="text-foreground">
                {t('entities.enterprise.fields.taxId')}:
              </strong>{' '}
              {enterprisesResult?.taxId}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p className="text-foreground">
              <strong className="text-foreground">
                {t('entities.enterprise.fields.taxAddress')}:
              </strong>{' '}
              {enterprisesResult?.taxAddress}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p className="text-foreground">
              <strong className="text-foreground">
                {t('entities.enterprise.fields.activity')}:
              </strong>{' '}
              {enterprisesResult?.activity}
            </p>
          </Skeleton>
        </div>
      </CardBody>
    </Card>
  );
};

export default EnterpriseDetail;
