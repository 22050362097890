import i18next from 'i18next';
import * as Yup from 'yup';

const ObjectiveFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18next.t('validations.required')),
    description: Yup.string(),
    periodFrom: Yup.date().required(i18next.t('validations.required')),
    periodTo: Yup.date().required(i18next.t('validations.required')),
    manager: Yup.object()
      .required(i18next.t('validations.required'))
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
    rocketValued: Yup.number()
      .typeError(i18next.t('validations.number'))
      .min(0, i18next.t('validations.min', { min: 0 }))
      .required(i18next.t('validations.required')),
  });
};

export default ObjectiveFormValidation;
