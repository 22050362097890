import { Form, Formik, FormikProps } from 'formik';
import { Button, Input, Select, SelectItem } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import UpdateUserFileDTO from '../dto/updateUserFile.dto';
import MaritalStatus from '../enum/maritalStatus.enum';
import UserFileFormValidation from '../validation/userFileForm.validation';

interface UserFormProps {
  initialValues?: UpdateUserFileDTO;
  onSubmit: (values: UpdateUserFileDTO) => void | Promise<void>;
}

export const initialUserFormValues: UpdateUserFileDTO = {
  taxId: '',
  hireDate: new Date(),
  birthDate: new Date(),
  jobTitle: '',
  address: '',
  phone: '',
  inTime: '',
  outTime: '',
  bankAccountNumber: '',
  employeeFileNumber: '',
  emergencyPhone: '',
  emergencyName: '',
  secondaryEmergencyPhone: '',
  secondaryEmergencyName: '',
  maritalStatus: MaritalStatus.SINGLE,
};

const UserFileForm = ({
  initialValues = initialUserFormValues,
  onSubmit,
}: UserFormProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFileFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<UpdateUserFileDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="taxId"
              label={t('entities.userFile.fields.taxId')}
              isInvalid={Boolean(errors.taxId)}
              errorMessage={errors.taxId}
              color={errors.taxId ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('taxId', e.target.value)}
              value={values.taxId}
            />
            <Input
              name="hireDate"
              label={t('entities.userFile.fields.hireDate')}
              type="date"
              isInvalid={Boolean(errors.hireDate)}
              errorMessage={String(errors.hireDate || '')}
              color={errors.hireDate ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('hireDate', e.target.value)}
              value={values.hireDate?.toString() || new Date().toString()}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="birthDate"
              label={t('entities.userFile.fields.birthDate')}
              type="date"
              isInvalid={Boolean(errors.birthDate)}
              errorMessage={String(errors.birthDate || '')}
              color={errors.birthDate ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('birthDate', e.target.value)}
              value={values.birthDate?.toString() || new Date().toString()}
            />
            <Input
              name="jobTitle"
              label={t('entities.userFile.fields.jobTitle')}
              isInvalid={Boolean(errors.jobTitle)}
              errorMessage={errors.jobTitle}
              color={errors.jobTitle ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('jobTitle', e.target.value)}
              value={values.jobTitle}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="address"
              label={t('entities.userFile.fields.address')}
              isInvalid={Boolean(errors.address)}
              errorMessage={errors.address}
              color={errors.address ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('address', e.target.value)}
              value={values.address}
            />
            <Input
              name="phone"
              label={t('entities.userFile.fields.phone')}
              isInvalid={Boolean(errors.phone)}
              errorMessage={errors.phone}
              color={errors.phone ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('phone', e.target.value)}
              value={values.phone}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="inTime"
              label={t('entities.userFile.fields.inTime')}
              isInvalid={Boolean(errors.inTime)}
              errorMessage={errors.inTime}
              color={errors.inTime ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('inTime', e.target.value)}
              value={values.inTime}
            />
            <Input
              name="outTime"
              label={t('entities.userFile.fields.outTime')}
              isInvalid={Boolean(errors.outTime)}
              errorMessage={errors.outTime}
              color={errors.outTime ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('outTime', e.target.value)}
              value={values.outTime}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="bankAccountNumber"
              label={t('entities.userFile.fields.bankAccountNumber')}
              isInvalid={Boolean(errors.bankAccountNumber)}
              errorMessage={errors.bankAccountNumber}
              color={errors.bankAccountNumber ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('bankAccountNumber', e.target.value)
              }
              value={values.bankAccountNumber}
            />
            <Input
              name="employeeFileNumber"
              label={t('entities.userFile.fields.employeeFileNumber')}
              isInvalid={Boolean(errors.employeeFileNumber)}
              errorMessage={errors.employeeFileNumber}
              color={errors.employeeFileNumber ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('employeeFileNumber', e.target.value)
              }
              value={values.employeeFileNumber}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="emergencyPhone"
              label={t('entities.userFile.fields.emergencyPhone')}
              isInvalid={Boolean(errors.emergencyPhone)}
              errorMessage={errors.emergencyPhone}
              color={errors.emergencyPhone ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('emergencyPhone', e.target.value)}
              value={values.emergencyPhone}
            />
            <Input
              name="emergencyName"
              label={t('entities.userFile.fields.emergencyName')}
              isInvalid={Boolean(errors.emergencyName)}
              errorMessage={errors.emergencyName}
              color={errors.emergencyName ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('emergencyName', e.target.value)}
              value={values.emergencyName}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="secondaryEmergencyPhone"
              label={t('entities.userFile.fields.secondaryEmergencyPhone')}
              isInvalid={Boolean(errors.secondaryEmergencyPhone)}
              errorMessage={errors.secondaryEmergencyPhone}
              color={errors.secondaryEmergencyPhone ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('secondaryEmergencyPhone', e.target.value)
              }
              value={values.secondaryEmergencyPhone}
            />
            <Input
              name="secondaryEmergencyName"
              label={t('entities.userFile.fields.secondaryEmergencyName')}
              isInvalid={Boolean(errors.secondaryEmergencyName)}
              errorMessage={errors.secondaryEmergencyName}
              color={errors.secondaryEmergencyName ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('secondaryEmergencyName', e.target.value)
              }
              value={values.secondaryEmergencyName}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Select
              label={t('entities.userFile.fields.maritalStatus')}
              selectedKeys={[values.maritalStatus]}
              onSelectionChange={(value) =>
                setFieldValue(
                  'maritalStatus',
                  new Set(value).values().next().value,
                )
              }
            >
              <SelectItem
                key={MaritalStatus.SINGLE}
                value={MaritalStatus.SINGLE}
              >
                {t(
                  `entities.userFile.values.maritalStatus.${MaritalStatus.SINGLE}`,
                )}
              </SelectItem>
              <SelectItem
                key={MaritalStatus.MARRIED}
                value={MaritalStatus.MARRIED}
              >
                {t(
                  `entities.userFile.values.maritalStatus.${MaritalStatus.MARRIED}`,
                )}
              </SelectItem>
            </Select>
          </div>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserFileForm;
