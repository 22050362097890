import React from 'react';
import { useTranslation } from 'react-i18next';
import ThermometerSurveyMetricRepository from '../thermometerSurveyMetric.repository';
import { useEffect, useState } from 'react';
import goodIcon from '@assets/icons/goodicon.svg';
import neutralIcon from '@assets/icons/neutralIcon.svg';
import badIcon from '@assets/icons/bardicon.svg';
import DateUtil from '@utils/date';
import { useMetricFiltersContext } from '@modules/metricModule/context/metricFilters.context';

const ThermometerVotesMetric = () => {
  const [votes, setVotes] = useState({
    BAD: 0,
    GOOD: 0,
    NEUTRAL: 0,
    TOTAL: 0,
  });
  const { t } = useTranslation();
  const { filters } = useMetricFiltersContext();
  const period = filters.quarter;
  const periodDates = DateUtil.quarterToDate(period);

  const getVotes = async () => {
    const votesResult =
      await new ThermometerSurveyMetricRepository().countGroupByResult({
        where: {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      });
    setVotes({
      BAD: Number(votesResult.BAD || 0),
      GOOD: Number(votesResult.GOOD || 0),
      NEUTRAL: Number(votesResult.NETRUAL || 0),
      TOTAL:
        Number(votesResult.BAD || 0) +
        Number(votesResult.GOOD || 0) +
        Number(votesResult.NETRUAL || 0),
    });
  };

  useEffect(() => {
    getVotes();
  }, [period]);

  return (
    <div>
      <h4>
        {t('entities.metric.responses.thermometerVotes', {
          votes: votes.TOTAL,
        })}
      </h4>
      <div className="flex flex-col md:flex-row gap-2 justify-evenly pt-3">
        <div
          className={`flex flex-col md:w-[350px] gap-2 items-center justify-between p-5 bg-success-100 rounded-xl`}
        >
          <img src={goodIcon} className="w-8 m-0" />
          <div className="flex flex-col items-center">
            <span className="text-md font-semibold text-gray-700 dark:text-foreground-700 text-center">
              {t('user.dashboard.surveys.thermometer.options.GOOD')}
            </span>
            <span className="text-default-400">
              {t('entities.metric.responses.votesCount', { votes: votes.GOOD })}
            </span>
          </div>
          <span className="text-5xl font-bold text-success">
            {Number(
              votes.TOTAL > 0 ? (votes.GOOD / votes.TOTAL) * 100 : 0,
            ).toFixed(0)}
            %
          </span>
        </div>
        <div
          className={`flex flex-col md:w-[350px] gap-2 items-center justify-between p-5 bg-warning-100 rounded-xl`}
        >
          <img src={neutralIcon} className="w-8 m-0" />
          <div className="flex flex-col items-center">
            <span className="text-md font-semibold text-gray-700 dark:text-foreground-700 text-center">
              {t('user.dashboard.surveys.thermometer.options.NEUTRAL')}
            </span>
            <span className="text-default-400">
              {t('entities.metric.responses.votesCount', {
                votes: votes.NEUTRAL,
              })}
            </span>
          </div>
          <span className="text-5xl font-bold text-warning">
            {Number(
              votes.TOTAL > 0 ? (votes.NEUTRAL / votes.TOTAL) * 100 : 0,
            ).toFixed(0)}
            %
          </span>
        </div>
        <div
          className={`flex flex-col md:w-[350px] gap-2 items-center justify-between p-5 bg-danger-100 rounded-xl`}
        >
          <img src={badIcon} className="w-8 m-0" />
          <div className="flex flex-col items-center">
            <span className="text-md font-semibold text-gray-700 dark:text-foreground-700 text-center">
              {t('user.dashboard.surveys.thermometer.options.BAD')}
            </span>
            <span className="text-default-400">
              {t('entities.metric.responses.votesCount', { votes: votes.BAD })}
            </span>
          </div>
          <span className="text-5xl font-bold text-danger">
            {Number(
              votes.TOTAL > 0 ? (votes.BAD / votes.TOTAL) * 100 : 0,
            ).toFixed(0)}
            %
          </span>
        </div>
      </div>
    </div>
  );
};

export default ThermometerVotesMetric;
