import IconProps from './common/icon.interface';

const EditIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30773C3.5 4.8026 3.675 4.37503 4.025 4.02503C4.375 3.67503 4.80257 3.50003 5.3077 3.50003H13.9635L12.4635 5.00001H5.3077C5.23077 5.00001 5.16024 5.03206 5.09612 5.09616C5.03202 5.16028 4.99997 5.2308 4.99997 5.30773V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9039C5.16024 18.968 5.23077 19 5.3077 19H18.6923C18.7692 19 18.8397 18.968 18.9038 18.9039C18.9679 18.8397 19 18.7692 19 18.6923V11.473L20.5 9.97308V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM9.5 13.5961V11.8154C9.5 11.5744 9.5468 11.3427 9.6404 11.1202C9.73398 10.8978 9.86283 10.7045 10.0269 10.5404L18.5596 2.00773C18.7147 1.8526 18.8853 1.73946 19.0711 1.66831C19.257 1.59716 19.4461 1.56158 19.6385 1.56158C19.8346 1.56158 20.023 1.59716 20.2038 1.66831C20.3846 1.73946 20.5493 1.84939 20.698 1.99811L21.9538 3.25003C22.0987 3.40515 22.2099 3.5763 22.2874 3.76348C22.365 3.95065 22.4038 4.14038 22.4038 4.33268C22.4038 4.525 22.3708 4.71154 22.3048 4.89231C22.2387 5.07306 22.1281 5.241 21.973 5.39613L13.4115 13.9577C13.2474 14.1218 13.0541 14.2532 12.8317 14.3519C12.6092 14.4506 12.3775 14.5 12.1365 14.5H10.4039C10.1462 14.5 9.93111 14.4138 9.75868 14.2413C9.58623 14.0689 9.5 13.8538 9.5 13.5961ZM11 13H12.2461L18.4789 6.76728L17.8558 6.14421L17.1885 5.50191L11 11.6904V13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;
