import React from 'react';
import logo from '@assets/img/giveit.svg';
import {
  Accordion,
  AccordionItem,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Navbar as NextUINavbar,
  User,
} from '@nextui-org/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@components/icons/home.icon';
import ObjectiveIcon from '@components/icons/objective.icon';
import LicenseIcon from '@components/icons/license.icon';
import AuthRepository from '@modules/userModule/auth/auth.repository';
import MoonIcon from '@components/icons/moon.icon';
import MetricsIcon from '@components/icons/metrics.icon';
import { useTheme } from 'next-themes';
import SunIcon from '@components/icons/sun.icon';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DocumentationIcon from '@components/icons/documentation.icon';

const UserMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const navbarAccount: {
    key: string;
    section: string;
    items: { label: string; onClick: () => void | Promise<void> }[];
  }[] = [];
  const user = useSelector(selectProfile);
  const navigate = useNavigate();

  const items = [
    {
      label: t('menu.particular.profile'),
      onClick: () => {
        navigate(`profile/${user?.id}`);
      },
    },
  ];

  if (
    user?.group.permissions.some(
      (permission) =>
        permission.entity === AllowedEntities.USER ||
        permission.entity === AllowedEntities.USER_GROUP ||
        permission.entity === AllowedEntities.TEAM ||
        (permission.entity === AllowedEntities.ENTERPRISE &&
          permission.methods === AllowedMethods.VIEW),
    )
  ) {
    items.push({
      label: t('menu.particular.configuration.enterprise'),
      onClick: () => {
        navigate('configuration');
      },
    });
  }
  if (
    user?.group.permissions.some(
      (permission) =>
        ((permission.entity === AllowedEntities.SURVEY ||
          permission.entity === AllowedEntities.RECOGNITION) &&
          permission.methods !== AllowedMethods.VIEW) ||
        (permission.entity === AllowedEntities.THERMOMETER &&
          permission.methods === AllowedMethods.MODIFY),
    )
  ) {
    items.push({
      label: t('menu.particular.configuration.surveys'),
      onClick: () => {
        navigate('configuration/surveys');
      },
    });
  }
  if (
    user?.group.permissions.some(
      (permission) => permission.entity === AllowedEntities.CHALLENGE,
    )
  )
    items.push({
      label: t('menu.particular.configuration.challenges'),
      onClick: () => {
        navigate('configuration/challenges');
      },
    });
  navbarAccount.push({
    key: 'configuration',
    section: t('menu.particular.configuration.configuration'),
    items,
  });

  items.push({
    label: t('menu.particular.configuration.notifications'),
    onClick: () => navigate('configuration/notifications'),
  });

  if (
    user?.group.permissions.some(
      (permission) => permission.entity === AllowedEntities.LEVEL,
    )
  ) {
    items.push({
      label: 'Niveles',
      onClick: () => navigate('configuration/levels'),
    });
  }

  navbarAccount.push({
    key: 'account',
    section: 'Cuenta',
    items: [
      { label: 'Nivel', onClick: () => navigate(`level/view`) },
      { label: t('menu.exit'), onClick: () => new AuthRepository().signout() },
    ],
  });

  return (
    <NextUINavbar
      isBordered
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
      maxWidth="full"
      className="bg-white dark:bg-background"
    >
      <NavbarContent justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          className="lg:hidden"
        />
        <NavbarBrand className="hidden lg:block">
          <div
            className="w-fit cursor-pointer flex gap-1 items-center"
            onClick={() => navigate('/enterprise')}
          >
            <img className="w-7 m-0" src={logo} alt="logo" />
            <span className="text-primary font-bold text-xl font-paytoneOne dark:text-white">
              give it
            </span>
          </div>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden lg:flex gap-4" justify="center">
        <NavbarItem>
          <Button
            className={`flex items-center gap-1 ${
              window.location.pathname.split('/')[
                window.location.pathname.split('/').length - 1
              ] !== 'enterprise' && 'text-gray-400'
            }`}
            color={
              window.location.pathname.split('/')[
                window.location.pathname.split('/').length - 1
              ] === 'enterprise'
                ? 'primary'
                : undefined
            }
            variant={
              window.location.pathname.split('/')[
                window.location.pathname.split('/').length - 1
              ] === 'enterprise'
                ? 'flat'
                : 'light'
            }
            onClick={() => navigate('/enterprise')}
          >
            <HomeIcon />
            {t('menu.particular.home')}
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Button
            className={`flex items-center gap-1 ${
              !window.location.pathname.split('/').includes('license') &&
              'text-gray-400'
            }`}
            color={
              window.location.pathname.split('/').includes('license')
                ? 'primary'
                : undefined
            }
            variant={
              window.location.pathname.split('/').includes('license')
                ? 'flat'
                : 'light'
            }
            onClick={() => navigate('license')}
          >
            <LicenseIcon className="w-6 h-6" />
            {t('menu.particular.license')}
          </Button>
        </NavbarItem>
        {user?.group.permissions.some(
          (permission: { entity: AllowedEntities; methods: AllowedMethods }) =>
            permission.entity === AllowedEntities.OBJECTIVE &&
            permission.methods === AllowedMethods.LIST,
        ) && (
          <NavbarItem>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className={`flex items-center gap-1 ${
                    !window.location.pathname
                      .split('/')
                      .includes('objective') && 'text-gray-400'
                  }`}
                  color={
                    window.location.pathname.split('/').includes('objective')
                      ? 'primary'
                      : undefined
                  }
                  variant={
                    window.location.pathname.split('/').includes('objective')
                      ? 'flat'
                      : 'light'
                  }
                >
                  <ObjectiveIcon />
                  {t('menu.particular.objective.title')}
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate('objective?p=mine')}>
                  {t('menu.particular.objective.mine')}
                </DropdownItem>
                <DropdownItem onClick={() => navigate('objective?p=current')}>
                  {t('menu.particular.objective.current')}
                </DropdownItem>
                <DropdownItem onClick={() => navigate('objective')}>
                  {t('menu.particular.objective.all')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        )}
        <NavbarItem>
          <Button
            className={`flex items-center gap-1 ${
              !window.location.pathname.split('/').includes('documents') &&
              'text-gray-400'
            }`}
            color={
              window.location.pathname.split('/').includes('documents')
                ? 'primary'
                : undefined
            }
            variant={
              window.location.pathname.split('/').includes('documents')
                ? 'flat'
                : 'light'
            }
            onClick={() => navigate('documents/browse')}
          >
            <DocumentationIcon />
            {t('menu.particular.documentation')}
          </Button>
        </NavbarItem>
        {user?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.METRIC &&
            permission.methods === AllowedMethods.VIEW,
        ) && (
          <NavbarItem>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className={`flex items-center gap-1 ${
                    !window.location.pathname.split('/').includes('metric') &&
                    'text-gray-400'
                  }`}
                  color={
                    window.location.pathname.split('/').includes('metric')
                      ? 'primary'
                      : undefined
                  }
                  variant={
                    window.location.pathname.split('/').includes('metric')
                      ? 'flat'
                      : 'light'
                  }
                >
                  <MetricsIcon />
                  {t('menu.particular.metrics.title')}
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate('metric?tab=survey')}>
                  {t('menu.particular.metrics.survey')}
                </DropdownItem>
                <DropdownItem onClick={() => navigate('metric?tab=objective')}>
                  {t('menu.particular.metrics.objective')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate('metric?tab=recognition')}
                >
                  {t('menu.particular.metrics.recognition')}
                </DropdownItem>
                <DropdownItem onClick={() => navigate('metric?tab=history')}>
                  {t('menu.particular.metrics.history')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        )}
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem
          className="hidden lg:flex text-gray-400 cursor-pointer"
          onClick={() => {
            setTheme(theme === 'light' ? 'dark' : 'light');
          }}
        >
          {theme === 'light' ? <MoonIcon /> : <SunIcon />}
        </NavbarItem>
        <NavbarItem className="block lg:hidden">
          <NavbarBrand
            className="flex gap-1 items-center cursor-pointer"
            onClick={() => navigate('/enterprise')}
          >
            <img className="w-7 m-0" src={logo} alt="logo" />
            <span className="text-primary font-bold text-xl font-paytoneOne dark:text-white">
              give it
            </span>
          </NavbarBrand>
        </NavbarItem>
        <NavbarItem className="hidden lg:flex items-center gap-5">
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light">
                <User
                  name={`${user?.firstName} ${user?.lastName}`}
                  description={`${user?.enterprise.enterpriseName}`}
                  avatarProps={{
                    src:
                      user?.avatar &&
                      `https://giveit-system-assets.s3.amazonaws.com/${user?.avatar}`,
                    color: 'primary',
                  }}
                />
              </Button>
            </DropdownTrigger>
            <DropdownMenu items={navbarAccount}>
              {(sectionItem: {
                key: string;
                section: string;
                items: { label: string; onClick: () => void | Promise<void> }[];
              }) => {
                return (
                  <DropdownSection title={sectionItem.section}>
                    {sectionItem.items.map(
                      (item: {
                        label: string;
                        onClick: () => void | Promise<void>;
                      }) => (
                        <DropdownItem key={item.label} onClick={item.onClick}>
                          {item.label}
                        </DropdownItem>
                      ),
                    )}
                  </DropdownSection>
                );
              }}
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className="flex gap-5">
        <NavbarMenuItem className="flex justify-center">
          <User
            name={`${user?.firstName} ${user?.lastName}`}
            description={`${user?.enterprise.enterpriseName}`}
            avatarProps={{
              src:
                user?.avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${user?.avatar}`,
            }}
          />
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            className={`flex items-center gap-1 ${
              window.location.pathname.split('/')[
                window.location.pathname.split('/').length - 1
              ] !== 'enterprise' && 'text-gray-400'
            }`}
            color={
              window.location.pathname.split('/')[
                window.location.pathname.split('/').length - 1
              ] === 'enterprise'
                ? 'primary'
                : undefined
            }
            variant={
              window.location.pathname.split('/')[
                window.location.pathname.split('/').length - 1
              ] === 'enterprise'
                ? 'flat'
                : 'light'
            }
            onClick={() => {
              navigate('/enterprise');
              setIsMenuOpen(false);
            }}
          >
            <HomeIcon />
            {t('menu.particular.home')}
          </Button>
        </NavbarMenuItem>

        <NavbarMenuItem>
          <Button
            className={`flex items-center gap-1 ${
              !window.location.pathname.split('/').includes('license') &&
              'text-gray-400'
            }`}
            color={
              window.location.pathname.split('/').includes('license')
                ? 'primary'
                : undefined
            }
            variant={
              window.location.pathname.split('/').includes('license')
                ? 'flat'
                : 'light'
            }
            onClick={() => {
              navigate('license');
              setIsMenuOpen(false);
            }}
          >
            <LicenseIcon className="w-6 h-6" />
            {t('menu.particular.license')}
          </Button>
        </NavbarMenuItem>

        {user?.group.permissions.some(
          (permission: { entity: AllowedEntities; methods: AllowedMethods }) =>
            permission.entity === AllowedEntities.OBJECTIVE &&
            permission.methods === AllowedMethods.LIST,
        ) && (
          <NavbarMenuItem>
            <Accordion>
              <AccordionItem
                title="Objetivos"
                classNames={{
                  title: `flex items-center text-small ${!window.location.pathname.split('/').includes('objective') ? 'text-gray-400' : 'text-primary'}`,
                  trigger: `px-2 rounded-medium h-10  ${!window.location.pathname.split('/').includes('objective') ? 'text-gray-400' : 'text-primary bg-primary/20'}`,
                }}
                startContent={<ObjectiveIcon />}
              >
                <div className="flex flex-col gap-2 items-start pl-5">
                  <Button
                    onPress={() => {
                      navigate('objective?p=mine');
                      setIsMenuOpen(false);
                    }}
                    variant="light"
                    className="text-gray-400"
                  >
                    Mis objetivos
                  </Button>
                  <Button
                    onPress={() => {
                      navigate('objective?p=current');
                      setIsMenuOpen(false);
                    }}
                    variant="light"
                    className="text-gray-400"
                  >
                    Objetivos vigentes
                  </Button>
                  <Button
                    onPress={() => {
                      navigate('objective');
                      setIsMenuOpen(false);
                    }}
                    className="text-gray-400"
                    variant="light"
                  >
                    Todos los objetivos
                  </Button>
                </div>
              </AccordionItem>
            </Accordion>
          </NavbarMenuItem>
        )}
        <NavbarMenuItem>
          <Button
            className={`flex items-center gap-1 ${
              !window.location.pathname.split('/').includes('documents') &&
              'text-gray-400'
            }`}
            color={
              window.location.pathname.split('/').includes('documents')
                ? 'primary'
                : undefined
            }
            variant={
              window.location.pathname.split('/').includes('documents')
                ? 'flat'
                : 'light'
            }
            onClick={() => {
              navigate('documents/browse');
              setIsMenuOpen(false);
            }}
          >
            <DocumentationIcon />
            {t('menu.particular.documentation')}
          </Button>
        </NavbarMenuItem>
        {user?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.METRIC &&
            permission.methods === AllowedMethods.VIEW,
        ) && (
          <NavbarMenuItem>
            <Accordion>
              <AccordionItem
                title={t('menu.particular.metrics.title')}
                classNames={{
                  title: `flex items-center text-small ${!window.location.pathname.split('/').includes('metric') ? 'text-gray-400' : 'text-primary'}`,
                  trigger: `px-2 rounded-medium h-10  ${!window.location.pathname.split('/').includes('metric') ? 'text-gray-400' : 'text-primary bg-primary/20'}`,
                }}
                startContent={<MetricsIcon />}
              >
                <div className="flex flex-col gap-2 items-start pl-5">
                  <Button
                    onPress={() => {
                      navigate('metric?tab=survey');
                      setIsMenuOpen(false);
                    }}
                    variant="light"
                    className="text-gray-400"
                  >
                    {t('menu.particular.metrics.survey')}
                  </Button>
                  <Button
                    onPress={() => {
                      navigate('metric?tab=objective');
                      setIsMenuOpen(false);
                    }}
                    variant="light"
                    className="text-gray-400"
                  >
                    {t('menu.particular.metrics.objective')}
                  </Button>
                  <Button
                    onPress={() => {
                      navigate('metric?tab=recognition');
                      setIsMenuOpen(false);
                    }}
                    className="text-gray-400"
                    variant="light"
                  >
                    {t('menu.particular.metrics.recognition')}{' '}
                  </Button>
                  <Button
                    onPress={() => {
                      navigate('metric?tab=history');
                      setIsMenuOpen(false);
                    }}
                    className="text-gray-400"
                    variant="light"
                  >
                    {t('menu.particular.metrics.history')}{' '}
                  </Button>
                </div>
              </AccordionItem>
            </Accordion>
          </NavbarMenuItem>
        )}
        <NavbarMenuItem>
          <Button
            onClick={() => {
              navigate('configuration');
              setIsMenuOpen(false);
            }}
            fullWidth
            className={`flex items-center justify-start gap-1 text-gray-400`}
            variant="light"
          >
            {t('menu.particular.configuration.enterprise')}
          </Button>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            onClick={() => {
              navigate('configuration/surveys');
              setIsMenuOpen(false);
            }}
            fullWidth
            className={`flex items-center justify-start gap-1 text-gray-400`}
            variant="light"
          >
            {t('menu.particular.configuration.surveys')}
          </Button>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            onClick={() => {
              navigate('configuration/challenges');
              setIsMenuOpen(false);
            }}
            fullWidth
            className={`flex items-center justify-start gap-1 text-gray-400`}
            variant="light"
          >
            {t('menu.particular.configuration.challenges')}
          </Button>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            onClick={() => {
              navigate('configuration/notifications');
              setIsMenuOpen(false);
            }}
            fullWidth
            className={`flex items-center justify-start gap-1 text-gray-400`}
            variant="light"
          >
            Notificaciones
          </Button>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            onClick={() => navigate(`level/view`)}
            fullWidth
            className={`flex items-center justify-start gap-1 text-gray-400`}
            variant="light"
          >
            Nivel
          </Button>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            onClick={() => new AuthRepository().signout()}
            fullWidth
            className={`flex items-center justify-start gap-1 text-gray-400`}
            variant="light"
          >
            {t('menu.exit')}
          </Button>
        </NavbarMenuItem>
      </NavbarMenu>
    </NextUINavbar>
  );
};

export default UserMenu;
