import React from 'react';
import logo from '@assets/img/giveit.svg';

const RocketsIndicator = ({ amount }: { amount: number }) => {
  return (
    <div className="rounded-full w-14 bg-primary relative text-end text-white text-sm px-2 py-1">
      <span className="bg-white rounded-full p-1 absolute inset-0 w-fit h-fit border-1 border-primary top-1/2 -translate-y-1/2 -left-1">
        <img src={logo} alt="rocket" className="w-3 m-0" />
      </span>
      {amount}
    </div>
  );
};

export default RocketsIndicator;
