import IconProps from './common/icon.interface';

const PlusIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.50002 8.49999H4.16669C4.02502 8.49999 3.90627 8.45205 3.81044 8.35619C3.7146 8.26031 3.66669 8.14151 3.66669 7.99979C3.66669 7.85805 3.7146 7.73933 3.81044 7.6436C3.90627 7.54788 4.02502 7.50002 4.16669 7.50002H7.50002V4.16669C7.50002 4.02502 7.54795 3.90627 7.64382 3.81044C7.7397 3.7146 7.8585 3.66669 8.00022 3.66669C8.14195 3.66669 8.26068 3.7146 8.3564 3.81044C8.45213 3.90627 8.49999 4.02502 8.49999 4.16669V7.50002H11.8333C11.975 7.50002 12.0937 7.54795 12.1896 7.64382C12.2854 7.7397 12.3333 7.8585 12.3333 8.00022C12.3333 8.14195 12.2854 8.26068 12.1896 8.3564C12.0937 8.45213 11.975 8.49999 11.8333 8.49999H8.49999V11.8333C8.49999 11.975 8.45205 12.0937 8.35619 12.1896C8.26031 12.2854 8.14151 12.3333 7.99979 12.3333C7.85805 12.3333 7.73933 12.2854 7.6436 12.1896C7.54788 12.0937 7.50002 11.975 7.50002 11.8333V8.49999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusIcon;
