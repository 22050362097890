import React, { useEffect } from 'react';
import { Select, SelectItem } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import DateUtil from '@utils/date';
import { useMetricFiltersContext } from '../context/metricFilters.context';
import { QuartersType } from '@lib/type/quarters.type';

const PeriodFilter = () => {
  const { t } = useTranslation();
  const { filters, setFilters } = useMetricFiltersContext();

  useEffect(() => {
    const quarter: QuartersType = DateUtil.dateToQuarter(new Date());
    setFilters((prevFilters) => ({ ...prevFilters, quarter }));
  }, []);

  return (
    <div>
      <Select
        className="min-w-[130px]"
        label={t('entities.metric.filter.period.label')}
        selectedKeys={[filters.quarter]}
        onSelectionChange={(keys) =>
          setFilters((prevFilters) => ({
            ...prevFilters,
            quarter: new Set(keys).entries().next().value[0],
          }))
        }
        size="sm"
      >
        <SelectItem key={'Q1'}>Q1</SelectItem>
        <SelectItem key={'Q2'}>Q2</SelectItem>
        <SelectItem key={'Q3'}>Q3</SelectItem>
        <SelectItem key={'Q4'}>Q4</SelectItem>
      </Select>
    </div>
  );
};

export default PeriodFilter;
