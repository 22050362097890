import React from 'react';
import { Card, CardBody, Skeleton } from '@nextui-org/react';

interface CardMetricProps {
  helpTitle: string;
  title: string;
  result: number;
  isLoading: boolean;
  className?: string;
}

const CardMetric = (props: CardMetricProps) => {
  const percentColor = (
    percent: number,
  ): { class: string; percent: string } => {
    if (isNaN(percent))
      return {
        class: 'text-gray-400',
        percent: `0%`,
      };

    if (percent >= 75)
      return {
        class: 'text-success',
        percent: `${percent.toFixed(2)}%`,
      };

    if (percent >= 30)
      return {
        class: 'text-warning',
        percent: `${percent.toFixed(2)}%`,
      };

    return {
      class: 'text-danger',
      percent: `${percent.toFixed(2)}%`,
    };
  };

  const calculatedPercents = percentColor(props.result);

  return (
    <Card className={`${props.className}`}>
      <CardBody>
        <span className="text-default-400 text-sm">{props.helpTitle}</span>
        <span className="font-bold text-lg">{props.title}</span>
        <Skeleton isLoaded={!props.isLoading}>
          <span className={`text-xl ${calculatedPercents.class}`}>
            {calculatedPercents.percent}
          </span>
        </Skeleton>
      </CardBody>
    </Card>
  );
};

export default CardMetric;
