import React from 'react';
import { useEffect, useState } from 'react';
import CardMetric from '../../components/cardMetric';
import { DeepPartial } from '@lib/common/deepPartial';
import { useTranslation } from 'react-i18next';
import ThermometerSurveyMetricRepository from '../thermometerSurveyMetric.repository';
import ThermometerVoteDTO from '../dto/thermometerVote.dto';

interface ThermometerSurveyMetricProps {
  options?: DeepPartial<ThermometerVoteDTO>;
  className?: string;
}

const ThermometerSurveyMetric = (props: ThermometerSurveyMetricProps) => {
  const { t } = useTranslation();
  const [percent, setPercent] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  const voteToPercent = (vote: 'GOOD' | 'NETRUAL' | 'BAD') => {
    const percentResults = {
      GOOD: 100,
      NETRUAL: 50,
      BAD: 0,
    };

    return percentResults[vote];
  };

  const getThermometerMetricPercentResponse = async () => {
    setLoading(true);
    const thermometerSurveysResult =
      await new ThermometerSurveyMetricRepository().find({
        where: props.options,
      });

    const arrSumThermometerPercents = thermometerSurveysResult.results.map(
      (thermometerSurveys) => {
        return voteToPercent(thermometerSurveys.vote);
      },
    );

    const sumThermometerPercents = arrSumThermometerPercents.reduce(
      (a, b) => a + b,
      0,
    );

    setPercent(sumThermometerPercents / arrSumThermometerPercents.length);
    setLoading(false);
  };

  useEffect(() => {
    getThermometerMetricPercentResponse();
  }, []);

  useEffect(() => {
    getThermometerMetricPercentResponse();
  }, [props.options]);

  return (
    <CardMetric
      helpTitle={t('entities.metric.thermometerSurvey.helpTitle')}
      title={t('entities.metric.thermometerSurvey.title')}
      result={percent}
      isLoading={isLoading}
      className={`${props.className}`}
    />
  );
};

export default ThermometerSurveyMetric;
