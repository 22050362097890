enum AllowedEntities {
  ENTERPRISE = 'ENTERPRISE',
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
  TEAM = 'TEAM',
  POST = 'POST',
  POST_COMMENT = 'POST_COMMENT',
  POST_CATEGORY = 'POST_CATEGORY',
  CHALLENGE = 'CHALLENGE',
  CHALLENGE_ANSWER = 'CHALLENGE_ANSWER',
  CHALLENGE_ANSWER_COMMENT = 'CHALLENGE_ANSWER_COMMENT',
  CHALLENGE_ANSWER_CATEGORY = 'CHALLENGE_ANSWER_CATEGORY',
  OBJECTIVE = 'OBJECTIVE',
  KEY_RESULT = 'KEY_RESULT',
  INITIATIVE = 'INITIATIVE',
  METRIC = 'METRIC',
  SURVEY = 'SURVEY',
  RECOGNITION = 'RECOGNITION',
  THERMOMETER = 'THERMOMETER',
  LICENSE_TYPE = 'LICENSE_TYPE',
  LEVEL = 'LEVEL',
  DOCUMENTS = 'DOCUMENTS',
}

export default AllowedEntities;
