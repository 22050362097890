import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ObjectiveUserDTO from './dto/objectiveUser.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';

export default class ObjectiveUserRepository {
  private url: string = '/api/objectiveUser';

  async find(
    options?: FindOptionsDTO<ObjectiveUserDTO>,
  ): Promise<PaginatedDTO<ObjectiveUserDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const objectiveResponse = await axios.get<PaginatedDTO<ObjectiveUserDTO>>(
      `${this.url}${stringOption}`,
    );
    return objectiveResponse.data;
  }
}
