import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ObjectiveDTO from './dto/objective.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import CreateObjectiveDTO from './dto/createObjective.dto';
import DateUtil from '@utils/date';
import UpdateObjectiveDTO from './dto/updateObjective.dto';

export default class ObjectiveRepository {
  private url: string = '/api/objective';

  async find(
    options?: FindOptionsDTO<ObjectiveDTO>,
  ): Promise<PaginatedDTO<ObjectiveDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const objectiveResponse = await axios.get<PaginatedDTO<ObjectiveDTO>>(
      `${this.url}${stringOption}`,
    );
    objectiveResponse.data.results.forEach((objective) => {
      objective.periodFrom = DateUtil.stringToDate(
        String(objective.periodFrom),
      );
      objective.periodTo = DateUtil.stringToDate(String(objective.periodTo));
    });
    return objectiveResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<ObjectiveDTO>,
  ): Promise<ObjectiveDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const objectiveResponse = await axios.get<ObjectiveDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    objectiveResponse.data.periodFrom = DateUtil.stringToDate(
      String(objectiveResponse.data.periodFrom),
    );
    objectiveResponse.data.periodTo = DateUtil.stringToDate(
      String(objectiveResponse.data.periodTo),
    );
    return objectiveResponse.data;
  }

  async save(objective: CreateObjectiveDTO) {
    const createdObjectiveResponse = await axios.post<ObjectiveDTO>(
      `${this.url}`,
      objective,
    );
    return createdObjectiveResponse.data;
  }

  async update(id: string, changes: UpdateObjectiveDTO) {
    const updatedUserResponse = await axios.put<ObjectiveDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedUserResponse.data;
  }

  async delete(id: string) {
    const deletedObjectiveResponse = await axios.delete<ObjectiveDTO>(
      `${this.url}/${id}`,
    );
    return deletedObjectiveResponse.data;
  }
}
