import IconProps from './common/icon.interface';

const TagIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.04808 15.5384L8.175 19.0057C8.13782 19.1711 8.05834 19.3012 7.93656 19.3961C7.81476 19.491 7.66604 19.5384 7.4904 19.5384C7.25707 19.5384 7.06606 19.4455 6.91736 19.2596C6.76864 19.0737 6.72633 18.8692 6.79043 18.6461L7.56735 15.5384H4.71735C4.46735 15.5384 4.2664 15.4429 4.11448 15.2519C3.96256 15.0609 3.91865 14.8455 3.98275 14.6057C4.02634 14.4301 4.11575 14.2916 4.251 14.1903C4.38625 14.0891 4.5417 14.0384 4.71735 14.0384H7.94235L8.96158 9.9615H6.11158C5.86158 9.9615 5.66062 9.86599 5.50871 9.67497C5.35679 9.48396 5.31288 9.26858 5.37698 9.02885C5.42056 8.8532 5.50998 8.71474 5.64523 8.61347C5.78048 8.51219 5.93593 8.46155 6.11158 8.46155H9.33658L10.2097 4.99422C10.2468 4.82884 10.3263 4.69871 10.4481 4.60385C10.5699 4.50898 10.7186 4.46155 10.8943 4.46155C11.1276 4.46155 11.3186 4.55449 11.4673 4.74037C11.616 4.92627 11.6583 5.13076 11.5942 5.35382L10.8173 8.46155H14.9519L15.825 4.99422C15.8622 4.82884 15.9417 4.69871 16.0635 4.60385C16.1853 4.50898 16.334 4.46155 16.5096 4.46155C16.7429 4.46155 16.934 4.55449 17.0827 4.74037C17.2314 4.92627 17.2737 5.13076 17.2096 5.35382L16.4327 8.46155H19.2827C19.5327 8.46155 19.7336 8.55706 19.8855 8.74807C20.0374 8.93909 20.0814 9.15447 20.0173 9.39422C19.9737 9.56986 19.8843 9.70831 19.749 9.8096C19.6138 9.91086 19.4583 9.9615 19.2827 9.9615H16.0577L15.0384 14.0384H17.8884C18.1384 14.0384 18.3394 14.134 18.4913 14.325C18.6432 14.516 18.6871 14.7314 18.623 14.9711C18.5794 15.1467 18.49 15.2852 18.3548 15.3865C18.2195 15.4878 18.0641 15.5384 17.8884 15.5384H14.6634L13.7904 19.0057C13.7532 19.1711 13.6737 19.3012 13.5519 19.3961C13.4301 19.491 13.2814 19.5384 13.1058 19.5384C12.8724 19.5384 12.6814 19.4455 12.5327 19.2596C12.384 19.0737 12.3417 18.8692 12.4058 18.6461L13.1827 15.5384H9.04808ZM9.42308 14.0384H13.5577L14.5769 9.9615H10.4423L9.42308 14.0384Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TagIcon;
