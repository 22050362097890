import React from 'react';
import { useEffect, useState } from 'react';
import CommentDTO from '../dto/comment.dto';
import CommentRepository from '../comment.repository';
import { Card, CardBody, Pagination, Skeleton } from '@nextui-org/react';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import CreateComment from './createComment';
import ObjectiveComment from './objectiveComment';

interface CommentListProps {
  objective?: string;
  keyResult?: string;
  handleSubmit: (content: string) => Promise<void>;
}

const CommentList = (props: CommentListProps) => {
  const [comments, setComments] = useState<CommentDTO[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isCommentListLoading, setCommentListLoading] = useState(false);

  const getComments = async () => {
    setCommentListLoading(true);
    const where: any = {};
    if (props.objective) where['objective'] = { id: props.objective as string };
    if (props.keyResult) where['keyResult'] = { id: props.keyResult as string };
    const commentResponse = await new CommentRepository().findRoot({
      where: where,
      relations: ['user'],
      order: {
        createdDate: 'DESC',
      },
      take: page * PAGINATION_TAKE,
      skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
    });
    setTotal(commentResponse.total);
    setComments(commentResponse.results);
    setCommentListLoading(false);
  };

  useEffect(() => {
    getComments();
  }, [page]);

  const CommentLoading = () => {
    return (
      <div className="flex flex-col gap-3 py-5">
        <Card>
          <CardBody>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2">
                <div>
                  <Skeleton className="rounded-full w-[40px] h-[40px]" />
                </div>
                <div className="flex flex-col gap-2">
                  <Skeleton className="rounded-full w-44 h-4" />
                  <Skeleton className="rounded-full w-52 h-3" />
                </div>
              </div>
              <div className="px-5">
                <Skeleton className="rounded-full w-36 h-3" />
              </div>
            </div>
            <div className="px-5 pt-5 flex flex-col gap-3">
              <Skeleton className="rounded-full w-full h-5" />
              <Skeleton className="rounded-full w-[75%] h-5" />
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2">
                <div>
                  <Skeleton className="rounded-full w-[40px] h-[40px]" />
                </div>
                <div className="flex flex-col gap-2">
                  <Skeleton className="rounded-full w-44 h-4" />
                  <Skeleton className="rounded-full w-52 h-3" />
                </div>
              </div>
              <div className="px-5">
                <Skeleton className="rounded-full w-36 h-3" />
              </div>
            </div>
            <div className="px-5 pt-5 flex flex-col gap-3">
              <Skeleton className="rounded-full w-full h-5" />
              <Skeleton className="rounded-full w-[75%] h-5" />
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2">
                <div>
                  <Skeleton className="rounded-full w-[40px] h-[40px]" />
                </div>
                <div className="flex flex-col gap-2">
                  <Skeleton className="rounded-full w-44 h-4" />
                  <Skeleton className="rounded-full w-52 h-3" />
                </div>
              </div>
              <div className="px-5">
                <Skeleton className="rounded-full w-36 h-3" />
              </div>
            </div>
            <div className="px-5 pt-5 flex flex-col gap-3">
              <Skeleton className="rounded-full w-full h-5" />
              <Skeleton className="rounded-full w-[75%] h-5" />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <div>
      <Card className="shadow-none overflow-visible">
        <CardBody className="overflow-visible">
          <CreateComment
            refresh={getComments}
            keyResult={props.keyResult}
            objective={props.objective}
          />
        </CardBody>
      </Card>
      {isCommentListLoading ? (
        <CommentLoading />
      ) : (
        <div className="flex flex-col gap-3 py-5">
          {comments.map((comment) => (
            <ObjectiveComment
              comment={comment}
              refresh={getComments}
              key={comment.id}
            />
          ))}
        </div>
      )}
      <div className="flex justify-center">
        <Pagination
          color="primary"
          showControls={page > 0}
          page={page}
          total={Math.ceil(total / PAGINATION_TAKE)}
          onChange={(selectedPage) => setPage(selectedPage)}
        />
      </div>
    </div>
  );
};

export default CommentList;
