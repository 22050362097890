import RecognitionVoteRepository from '@modules/voteModule/recognition/recognitionVote/recognitionVote.repository';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@nextui-org/react';
import ReactEChart from 'echarts-for-react';

interface StrengthRadarMetricProps {
  userId: string;
}

const StrengthRadar = (props: StrengthRadarMetricProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [recognitions, setRecognitions] = useState<{ [key: string]: number }>(
    {},
  );
  const [totalCount, setTotalCount] = useState<number>(0);

  const getRecognitions = async () => {
    return await new RecognitionVoteRepository().find({
      take: 999,
      where: {
        to: { externalUser: props.userId },
      },
      relations: ['strength', 'to'],
    });
  };

  const calculateRecognitions = async () => {
    setLoading(true);
    const recognitions = await getRecognitions();

    const votes: { [key: string]: number } = {};
    let totalRocketsRecongitions = 0;

    recognitions.results.map((recognition) => {
      votes[recognition.strength.strength]
        ? (votes[recognition.strength.strength] += recognition.rockets)
        : (votes[recognition.strength.strength] = recognition.rockets);
      totalRocketsRecongitions += recognition.rockets;
    });

    setRecognitions(votes);
    setTotalCount(totalRocketsRecongitions);
    setLoading(false);
  };

  useEffect(() => {
    calculateRecognitions();
  }, []);

  useEffect(() => {
    calculateRecognitions();
  }, [props.userId]);

  if (isLoading)
    return (
      <Skeleton isLoaded={!isLoading}>
        <div className="w-8 h-8"></div>
      </Skeleton>
    );

  const indicators = Object.keys(recognitions).map((key) => {
    return {
      name: key,
      max: totalCount,
    };
  });
  const values = Object.keys(recognitions).map((key) => recognitions[key]);

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col gap-2">
        <span className="font-bold text-lg text-center lg:text-start">
          {t(`entities.metric.strengthRadar.title`)}
        </span>
        <span className="text-gray-600 text-sm text-center lg:text-start">
          {t(`entities.metric.strengthRadar.descriptionIndividual`)}
        </span>
      </div>
      {indicators.length > 0 && values.length > 0 ? (
        <div className="md:grid grid-cols-2">
          <div className="py-5">
            <ReactEChart
              option={{
                radar: {
                  indicator: indicators,
                },
                series: [
                  {
                    name: 'Budget vs spending',
                    type: 'radar',
                    data: [
                      {
                        value: values,
                        name: 'Strength',
                      },
                    ],
                  },
                ],
              }}
            />
          </div>
          <div className="md:flex md:flex-col md:gap-3 justify-center">
            {Object.keys(recognitions).map((key) => (
              <div
                key={key}
                className="flex justify-between bg-primary-100 px-2 py-1 rounded-lg md:w-[320px]"
              >
                <span>{key}</span>
                <span className="text-primary">{recognitions[key]}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>{t('entities.metric.objective.participation.values.NO_DATA')}</p>
      )}
    </div>
  );
};

export default StrengthRadar;
