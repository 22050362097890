import i18next from 'i18next';
import * as Yup from 'yup';

const StrengthFormValidation = () => {
  return Yup.object().shape({
    strength: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
  });
};

export default StrengthFormValidation;
