import i18next from 'i18next';
import * as Yup from 'yup';
import Approvers from '../enum/approvers.enum';

const LicenseTypeFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18next.t('validations.required')),
    days: Yup.number().required(i18next.t('validations.required')),
    anticipationDays: Yup.number().required(i18next.t('validations.required')),
    approver: Yup.mixed<Approvers>()
      .oneOf(Object.values(Approvers))
      .required(i18next.t('validations.required')),
    renewalMonth: Yup.number().required(i18next.t('validations.required')),
    accumulationYears: Yup.number().required(i18next.t('validations.required')),
  });
};

export default LicenseTypeFormValidation;
