import { Card, CardBody, Skeleton } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserDTO from '../dto/user.dto';
import FindOptions from 'lib/paginated/findOptions.dto';
import UserRepository from '../user.repository';

interface UserDetailProps {
  id: string;
}

const UserDetail = (props: UserDetailProps) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<UserDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getUser = async (options?: FindOptions<UserDTO>) => {
    setLoading(true);
    const userRequest = await new UserRepository().findOne(props.id, {
      ...options,
      relations: Object.assign([], options?.relations, ['team']),
    });
    setUser(userRequest);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Card className="my-5">
      <CardBody className="flex gap-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.firstName')}:
              </strong>{' '}
              {user?.firstName || '-'}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.lastName')}:
              </strong>{' '}
              {user?.lastName || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.email')}:
              </strong>{' '}
              {user?.email || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.team')}:
              </strong>{' '}
              {user?.team?.name || '-'}
            </p>
          </Skeleton>
        </div>
      </CardBody>
    </Card>
  );
};

export default UserDetail;
