import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import PulseCategoryDTO from './dto/pulseCategory.dto';
import CreatePulseCategoryDTO from './dto/createPulseCategory.dto';

export default class PulseCategoryRepository {
  private url: string = '/api/pulseCategory';

  async find(
    options?: FindOptionsDTO<PulseCategoryDTO>,
  ): Promise<PaginatedDTO<PulseCategoryDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const commentResponse = await axios.get<PaginatedDTO<PulseCategoryDTO>>(
      `${this.url}${stringOption}`,
    );
    return commentResponse.data;
  }

  async save(comment: CreatePulseCategoryDTO) {
    const createdCommentResponse = await axios.post<PulseCategoryDTO>(
      `${this.url}`,
      comment,
    );
    return createdCommentResponse.data;
  }
}
