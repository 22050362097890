import React from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { useEffect, useState } from 'react';
import ObjectiveDTO from '../dto/objective.dto';
import ObjectiveRepository from '../objective.repository';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import {
  Card,
  CardBody,
  CardFooter,
  Chip,
  CircularProgress,
  Pagination,
  Skeleton,
  User,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseye,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import ObjectiveStatusLabel from './objectiveStatusLabel';
import ObjectivePeriodLabel from './objectivePeriodLabel';
import KeyResultList from '@modules/objectiveModule/keyResult/components/keyResultList';
import ObjectiveFilter from './objectiveFilter';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DateUtil from '@utils/date';

const ObjectiveList = () => {
  const [params] = useSearchParams();
  const profile = useSelector(selectProfile);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<FindOptionsDTO<ObjectiveDTO>>({});
  const [objectivesResult, setObjectivesResult] = useState<
    PaginatedDTO<ObjectiveDTO>
  >({
    results: [],
    total: 0,
  });

  const getObjectives = async (options?: FindOptionsDTO<ObjectiveDTO>) => {
    setLoading(true);
    const objectiveResult = await new ObjectiveRepository().find({
      ...options,
      relations: Object.assign([], options?.relations, [
        'manager',
        'categories',
      ]),
    });
    setObjectivesResult(objectiveResult);
    setLoading(false);
  };

  const handlerPagination = (page: number) => {
    setPage(page);
    setOptions((prev) => {
      return {
        ...prev,
        take: page * PAGINATION_TAKE,
        skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
      };
    });
  };

  useEffect(() => {
    const p = params.get('p');

    if (p === 'mine')
      setOptions({
        where: {
          manager: { externalUser: profile?.id },
        },
      });
    if (p === 'current') {
      const quarter = DateUtil.dateToQuarter(new Date());
      const { from, to } = DateUtil.quarterToDate(quarter);
      setOptions({
        where: {
          periodFrom: `<=${DateUtil.dateForInput(from)}`,
          periodTo: `>=${DateUtil.dateForInput(to)}`,
        },
      });
    }
    if (!p) {
      setOptions({});
    }
  }, [params]);

  useEffect(() => {
    getObjectives({
      take: page * PAGINATION_TAKE,
      skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
    });
  }, []);

  useEffect(() => {
    getObjectives(options);
  }, [options]);

  const LoadingTemplate = () => {
    return (
      <div className="flex flex-col gap-10">
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
      </div>
    );
  };

  const EmptyList = () => {
    return (
      <div className="pt-5">
        <p className="text-center">{t('entities.objective.table.empty')}</p>
      </div>
    );
  };

  const Objective = ({ item }: { item: ObjectiveDTO }) => {
    const navigate = useNavigate();
    const [isKeyResultListOpen, setKeyResultListOpen] = useState(false);
    return (
      <div>
        <Card className="border-l-3 border-l-primary shadow-none bg-content4">
          <CardBody className="flex-row md:max-lg:justify-around justify-between flex-wrap lg:flex-nowrap gap-5 gap-x-2  pb-0">
            <div className="flex flex-col gap-2 w-full sm:w-1/4">
              <span>
                <FontAwesomeIcon icon={faBullseye} className="text-primary" />
                <b className="text-foreground-500 text-sm">
                  {' '}
                  {t('entities.objective.fields.name')}:{' '}
                </b>
              </span>
              {profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.OBJECTIVE &&
                  permission.methods === AllowedMethods.VIEW,
              ) ? (
                <Link
                  to={`view/${item.id}`}
                  className={`no-underline text-foreground ${!isKeyResultListOpen ? 'sm:line-clamp-2 truncate whitespace-normal' : ''}`}
                >
                  {item.name}
                </Link>
              ) : (
                <div
                  className={`no-underline text-foreground ${!isKeyResultListOpen ? 'sm:line-clamp-2 truncate whitespace-normal' : ''}`}
                >
                  {item.name}
                </div>
              )}
            </div>
            <div className="w-full gap-5 items-start sm:gap-0 sm:w-1/5 flex sm:flex-col sm:items-center">
              <span>
                <b className="text-foreground-500 text-sm">
                  {' '}
                  {t('entities.objective.fields.categories')}{' '}
                </b>
              </span>
              <div className="flex flex-wrap justify-center gap-2 pt-1 h-full items-center">
                {item.categories.length === 0 && ' - '}
                {item.categories.map((category) => (
                  <Chip key={category.id} size="sm" color="primary">
                    {category.name}
                  </Chip>
                ))}
              </div>
            </div>
            <div
              className={`${isKeyResultListOpen ? 'flex' : 'hidden'} sm:flex flex-col gap-2 items-center sm:w-1/5`}
            >
              <span>
                <b className="text-foreground-500 text-sm">
                  {t('entities.objective.fields.manager')}
                </b>
              </span>
              <div className="h-full flex items-center max-w-full">
                <User
                  classNames={{
                    name: 'w-full text-foreground truncate hidden sm:inline',
                    description: 'w-full truncate hidden sm:inline',
                    base: 'max-w-full justify-start',
                    wrapper: 'max-w-full truncate cursor-pointer',
                  }}
                  onClick={() =>
                    navigate(`/enterprise/profile/${item.manager.externalUser}`)
                  }
                  name={item.manager.fullName}
                  description={item.manager.email}
                  avatarProps={{
                    src:
                      item.manager.avatar &&
                      `https://giveit-system-assets.s3.amazonaws.com/${item.manager.avatar}`,
                    className: 'flex-shrink-0 cursor-pointer',
                    color:
                      item.manager.externalUser === profile?.id
                        ? 'primary'
                        : 'secondary',
                    isBordered: item.manager.externalUser === profile?.id,
                  }}
                />
              </div>
            </div>
            <div className="hidden sm:flex flex-col gap-2 items-center">
              <span>
                <b className="text-foreground-500 text-sm">
                  {t('entities.objective.fields.period')}
                </b>
              </span>
              <div className="h-full flex items-center">
                <ObjectivePeriodLabel
                  periodFrom={item.periodFrom}
                  periodTo={item.periodTo}
                />
              </div>
            </div>
            <div
              className={`${isKeyResultListOpen ? 'flex' : 'hidden'} sm:flex flex-col gap-2 items-center`}
            >
              <span>
                <b className="text-foreground-500 text-sm">
                  {t('entities.objective.fields.progress')}
                </b>
              </span>
              <div className="h-full flex items-center">
                <CircularProgress
                  value={item.progress}
                  size="lg"
                  showValueLabel={true}
                  color="success"
                />
              </div>
            </div>
            <div
              className={`${isKeyResultListOpen ? 'flex' : 'hidden'} sm:flex flex-col gap-2 items-center`}
            >
              <span>
                <b className="text-foreground-500 text-sm">
                  {t('entities.objective.fields.status')}
                </b>
              </span>
              <div className="h-full flex items-center">
                <ObjectiveStatusLabel status={item.status} />
              </div>
            </div>
          </CardBody>
          {profile?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.KEY_RESULT &&
              permission.methods === AllowedMethods.LIST,
          ) && (
            <CardFooter className="flex justify-center">
              <FontAwesomeIcon
                icon={isKeyResultListOpen ? faChevronUp : faChevronDown}
                className="text-foreground-500 cursor-pointer"
                onClick={() => setKeyResultListOpen(!isKeyResultListOpen)}
              />
            </CardFooter>
          )}
        </Card>
        {isKeyResultListOpen && (
          <div className="ml-5">
            <KeyResultList
              options={{ where: { objective: { id: item.id } } }}
              paginable={false}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <ObjectiveFilter
        options={options}
        onChange={(newOptions) => {
          setOptions({
            ...options,
            ...newOptions,
            where: {
              ...newOptions.where,
            },
          });
        }}
      />
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <div>
          {objectivesResult.total === 0 ? (
            <EmptyList />
          ) : (
            <div className="py-5 flex flex-col space-y-5">
              {objectivesResult.results.map((objective) => (
                <Objective key={objective.id} item={objective} />
              ))}
              <div className="flex justify-center">
                <Pagination
                  showControls
                  color="primary"
                  page={page}
                  total={Math.ceil(objectivesResult.total / PAGINATION_TAKE)}
                  onChange={(page) => handlerPagination(page)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ObjectiveList;
