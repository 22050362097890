import IconProps from './common/icon.interface';

const CommentIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.03845 17.5L4.03655 19.5019C3.75193 19.7865 3.42469 19.8509 3.05483 19.6951C2.68494 19.5393 2.5 19.2589 2.5 18.8538V4.3077C2.5 3.80257 2.675 3.375 3.025 3.025C3.375 2.675 3.80257 2.5 4.3077 2.5H19.6923C20.1974 2.5 20.625 2.675 20.975 3.025C21.325 3.375 21.5 3.80257 21.5 4.3077V15.6923C21.5 16.1974 21.325 16.625 20.975 16.975C20.625 17.325 20.1974 17.5 19.6923 17.5H6.03845ZM5.4 16H19.6923C19.7692 16 19.8397 15.9679 19.9038 15.9038C19.9679 15.8397 20 15.7692 20 15.6923V4.3077C20 4.23077 19.9679 4.16024 19.9038 4.09613C19.8397 4.03203 19.7692 3.99998 19.6923 3.99998H4.3077C4.23077 3.99998 4.16024 4.03203 4.09613 4.09613C4.03202 4.16024 3.99998 4.23077 3.99998 4.3077V17.3846L5.4 16Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CommentIcon;
