import { RouteObject } from 'react-router-dom';
import PulseSurveyAddPage from './pulseSurveyAdd.page';
import PulseSurveyEditPage from './pulseSurveyEdit.page';
import PulseSurveyViewPage from './pulseSurveyView.page';

const pulseSurveyRoutes: Array<RouteObject> = [
  {
    children: [
      { path: 'add', element: <PulseSurveyAddPage /> },
      { path: 'view/:id', element: <PulseSurveyViewPage /> },
      { path: 'edit/:id', element: <PulseSurveyEditPage /> },
    ],
  },
];

export default pulseSurveyRoutes;
