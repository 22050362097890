import IconProps from './common/icon.interface';

const ChevronIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 14.6615C11.8795 14.6615 11.7673 14.6423 11.6635 14.6038C11.5597 14.5653 11.4609 14.4993 11.3674 14.4057L6.87313 9.91151C6.73466 9.77306 6.66383 9.59902 6.66063 9.38941C6.65741 9.17979 6.72824 9.00255 6.87313 8.85768C7.01799 8.71281 7.19363 8.64038 7.40003 8.64038C7.60643 8.64038 7.78206 8.71281 7.92693 8.85768L12 12.9308L16.0731 8.85768C16.2116 8.71923 16.3856 8.6484 16.5952 8.64518C16.8048 8.64198 16.9821 8.71281 17.1269 8.85768C17.2718 9.00255 17.3443 9.17819 17.3443 9.38461C17.3443 9.59101 17.2718 9.76664 17.1269 9.91151L12.6327 14.4057C12.5391 14.4993 12.4404 14.5653 12.3366 14.6038C12.2327 14.6423 12.1205 14.6615 12 14.6615Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronIcon;
