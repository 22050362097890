import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InitiativeDTO from '../dto/initiative.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import InitiativeRepository from '../initiative.repository';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  User,
} from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faWrench } from '@fortawesome/free-solid-svg-icons';
import InitiativeStatusChange from './initiativeStatusChange';
import InitiativeStatus from '../enum/initiativeStatus.enum';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface InitiativeDetailProps {
  id: string;
}

const InitiativeDetail = (props: InitiativeDetailProps) => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [initiative, setInitiative] = useState<InitiativeDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const getInitiative = async (options?: FindOptionsDTO<InitiativeDTO>) => {
    setLoading(true);
    const keyResultRequest = await new InitiativeRepository().findOne(
      props.id,
      {
        ...options,
        relations: Object.assign(['manager'], options?.relations),
      },
    );
    setInitiative(keyResultRequest);
    setLoading(false);
  };

  useEffect(() => {
    getInitiative();
  }, []);

  const Loading = () => {
    return (
      <div className="py-5 flex flex-col gap-5">
        <div>
          <Skeleton className="h-6 w-64"></Skeleton>
        </div>
        <div>
          <Skeleton className="h-6 w-64"></Skeleton>
        </div>
        <div>
          <Skeleton className="h-6 w-32"></Skeleton>
        </div>
      </div>
    );
  };

  const onChangeStatus = async (id: string, status: InitiativeStatus) => {
    await new InitiativeRepository().update(id, { status });
    getInitiative();
  };

  const handlerDelete = async () => {
    await new InitiativeRepository().delete(props.id);
    navigate(-1);
  };

  return isLoading || !initiative ? (
    <Loading />
  ) : (
    <div>
      <div className="py-3 flex flex-col gap-8">
        <div className="flex gap-5 items-center">
          <div>
            <span className="flex flex-col gap-3 font-bold text-lg text-foreground">
              <span className="text-sm font-bold text-foreground-500">
                {t('entities.initiative.fields.name')}
              </span>
              <span className="text-foreground">{initiative.name}</span>
            </span>
          </div>
          <div className="flex gap-3">
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.INITIATIVE &&
                permission.methods === AllowedMethods.MODIFY,
            ) && (
              <Button
                color="warning"
                size="sm"
                variant="flat"
                onClick={() =>
                  navigate(`/enterprise/initiative/edit/${props.id}`)
                }
              >
                <FontAwesomeIcon icon={faWrench} />
              </Button>
            )}
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.INITIATIVE &&
                permission.methods === AllowedMethods.DELETE,
            ) && (
              <Button
                color="danger"
                size="sm"
                variant="flat"
                onClick={() => setModalOpen(!isModalOpen)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-bold text-foreground-500">
            {t('entities.initiative.fields.description')}
          </span>
          <span className="text-foreground">{initiative.description}</span>
        </div>
        <div className="flex">
          {initiative.manager && (
            <User
              name={initiative.manager.fullName}
              description={initiative.manager.email}
              avatarProps={{
                src:
                  initiative.manager?.avatar &&
                  `https://giveit-system-assets.s3.amazonaws.com/${initiative.manager?.avatar}`,
              }}
            />
          )}
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-sm font-bold text-foreground-500">
            {t('entities.initiative.fields.status')}
          </span>
          <div className="h-full flex items-center">
            <InitiativeStatusChange
              status={initiative.status}
              onChange={(status) => onChangeStatus(props.id, status)}
              disabled={
                !profile?.group.permissions.some(
                  (permission) =>
                    permission.entity === AllowedEntities.INITIATIVE &&
                    permission.methods === AllowedMethods.MODIFY,
                )
              }
            />
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)}>
        <ModalContent>
          <ModalHeader>
            {t(`entities.initiative.table.deleteTitle`)}
          </ModalHeader>
          <ModalBody>
            {t(`entities.initiative.table.delete`, { name: initiative.name })}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModalOpen(!isModalOpen)}>
              {t(`entities.initiative.table.back`)}
            </Button>
            <Button
              color="danger"
              onClick={async () => {
                await handlerDelete();
              }}
            >
              {t(`entities.initiative.table.deleteButton`)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default InitiativeDetail;
