import { RouteObject } from 'react-router-dom';
import ChallengeViewPage from './view/challengeView.page';
import ChallengeAnswerPage from './answer/challengeAnswer.page';

const challengeRoutes: Array<RouteObject> = [
  {
    children: [
      { path: 'view/:id', element: <ChallengeViewPage /> },
      { path: 'view/:id/post/:ansId', element: <ChallengeAnswerPage /> },
    ],
  },
];

export default challengeRoutes;
