import UserRepository from '@modules/userModule/user/user.repository';
import React from 'react';
import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const RecognitionAverage = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [average, setAverage] = useState<number>(0);

  const getRecognitions = async () => {
    setLoading(true);

    const users = await new UserRepository().find({
      take: 999,
    });

    let rockets = 0;
    users.results.map((user) => {
      rockets += user.rockets;
    });

    setAverage(rockets / users.total);

    setLoading(false);
  };

  useEffect(() => {
    getRecognitions();
  }, []);

  return (
    <Card className={'min-w-[240px] md:w-1/3 shadow-md'}>
      <CardBody>
        <span className="text-default-400 text-sm">
          {t('entities.metric.recognition.rockets.average.description')}
        </span>
        <span className="font-bold text-lg">
          {t('entities.metric.recognition.rockets.average.title')}
        </span>
        <Skeleton isLoaded={!isLoading}>
          <span className={`text-xl text-primary`}>
            {t('entities.metric.recognition.rockets.average.result', {
              count: isNaN(average) ? 0 : Number(average.toFixed(2)),
            })}
          </span>
        </Skeleton>
      </CardBody>
    </Card>
  );
};

export default RecognitionAverage;
