import { RouteObject } from 'react-router-dom';
import Profile from './profile';

const profileRoutes: Array<RouteObject> = [
  {
    children: [{ path: ':id', element: <Profile /> }],
  },
];

export default profileRoutes;
