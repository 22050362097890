import {
  faEye,
  faMagnifyingGlass,
  faTrash,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import {
  Badge,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Pagination,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  Table as TableNext,
  TableRow,
  getKeyValue,
} from '@nextui-org/react';
import DateUtil from '@utils/date';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TableProps<T extends { id: string }> {
  handlerRequest: (
    options?: FindOptionsDTO<T>,
  ) => Promise<{ results: T[]; total: number }>;
  handlerSearcher: (
    textSearched: string,
    options?: FindOptionsDTO<T>,
  ) => FindOptionsDTO<T>;
  headers: Array<keyof T>;
  options?: FindOptionsDTO<T>;
  configurations: {
    translationLabel: string;
    add: {
      handler: () => void;
      isAllowed: boolean;
    };
    view: {
      handler: (id: string) => void;
      isAllowed: boolean;
    };
    edit: {
      handler: (id: string) => void;
      isAllowed: boolean;
    };
    delete: {
      field: keyof T;
      isAllowed: boolean;
      handler: (id: string) => Promise<void>;
    };
  };
}

const Table = <T extends { id: string }>(props: TableProps<T>) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [statusRequest, setStatusRequest] = useState<{
    results: T[];
    total: number;
    isLoading: boolean;
  }>({ results: [], total: 0, isLoading: true });
  const [options, setOptions] = useState<FindOptionsDTO<T>>(
    props.options || {},
  );
  const [selectedEntity, setSelectedEntity] = useState<null | T>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const getData = async (options?: FindOptionsDTO<T>) => {
    setStatusRequest((prev) => {
      return { ...prev, isLoading: true };
    });
    const response = await props.handlerRequest(options);
    setStatusRequest({ ...response, isLoading: false });
  };

  const handlerSearcher = (value: string) => {
    setOptions((prev) => {
      return props.handlerSearcher(value, prev);
    });
  };

  const handlerPagination = (page: number) => {
    setPage(page);
    setOptions((prev) => {
      return {
        ...prev,
        take: page * PAGINATION_TAKE,
        skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
      };
    });
  };

  const handlerDelete = (selectedEntity: null | T) => {
    setSelectedEntity(selectedEntity);
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    getData({
      take: page * PAGINATION_TAKE,
      skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
    });
  }, []);

  useEffect(() => {
    getData(options);
  }, [options]);

  const getValue = (obj: any, key: any) => {
    const value = getKeyValue(obj, key);
    if (value instanceof Date) return DateUtil.dateToPeriod(value);

    return value;
  };

  return (
    <div className="py-5">
      <div className="flex flex-col-reverse sm:flex-row gap-5 items-center">
        <Input
          label={t(
            `entities.${props.configurations.translationLabel}.table.find`,
          )}
          size="sm"
          startContent={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          onValueChange={(e) => handlerSearcher(e)}
          classNames={{
            inputWrapper:
              'text-foreground bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
          }}
        />
        {props.configurations.add.isAllowed && (
          <Button color="primary" onClick={props.configurations.add.handler}>
            {t(`entities.${props.configurations.translationLabel}.table.add`)}
          </Button>
        )}
      </div>
      <TableNext
        className="pt-3"
        classNames={{ wrapper: 'bg-content4' }}
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              classNames={{ wrapper: 'p-0 shadow-none' }}
              isCompact
              showControls
              showShadow
              color="primary"
              page={page}
              total={Math.ceil(statusRequest.total / PAGINATION_TAKE)}
              onChange={(page) => handlerPagination(page)}
            />
          </div>
        }
      >
        <TableHeader>
          {[...props.headers, 'actions'].map((header) => (
            <TableColumn key={header.toString()} className="text-center">
              {t(
                `entities.${
                  props.configurations.translationLabel
                }.fields.${header.toString()}`,
              )}
            </TableColumn>
          ))}
        </TableHeader>
        <TableBody
          emptyContent={t(
            `entities.${props.configurations.translationLabel}.table.empty`,
          )}
          isLoading={statusRequest.isLoading}
          items={statusRequest.results}
        >
          {(row) => (
            <TableRow key={row.id}>
              {(columnKey) => {
                if (columnKey === 'actions')
                  return (
                    <TableCell className="flex justify-center gap-3">
                      {props.configurations.view.isAllowed && (
                        <Button
                          color="primary"
                          variant="flat"
                          size="sm"
                          onClick={() =>
                            props.configurations.view.handler(row.id)
                          }
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      )}
                      {props.configurations.edit.isAllowed && (
                        <Button
                          color="warning"
                          variant="flat"
                          size="sm"
                          onClick={() =>
                            props.configurations.edit.handler(row.id)
                          }
                        >
                          <FontAwesomeIcon icon={faWrench} />
                        </Button>
                      )}
                      {props.configurations.delete.isAllowed && (
                        <Button
                          color="danger"
                          variant="flat"
                          size="sm"
                          onClick={() => handlerDelete(row)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      )}
                    </TableCell>
                  );

                if (columnKey === 'isActive' || columnKey === 'finished')
                  return (
                    <TableCell className="">
                      <div className="flex items-center justify-center">
                        {(getValue(row, columnKey) as boolean) ? (
                          <Badge
                            className="border-none"
                            content=""
                            color="success"
                          >
                            <></>
                          </Badge>
                        ) : (
                          <Badge
                            className="border-none"
                            content=""
                            color="danger"
                          >
                            <></>
                          </Badge>
                        )}
                      </div>
                    </TableCell>
                  );

                return (
                  <TableCell className="text-foreground text-center">
                    {getValue(row, columnKey)}
                  </TableCell>
                );
              }}
            </TableRow>
          )}
        </TableBody>
      </TableNext>
      <Modal isOpen={isModalOpen} onClose={() => handlerDelete(null)}>
        <ModalContent>
          <ModalHeader>
            {t(
              `entities.${props.configurations.translationLabel}.table.deleteTitle`,
            )}
          </ModalHeader>
          <ModalBody>
            {selectedEntity &&
              t(
                `entities.${props.configurations.translationLabel}.table.delete`,
                { name: selectedEntity[props.configurations.delete.field] },
              )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => handlerDelete(null)}>
              {t(
                `entities.${props.configurations.translationLabel}.table.back`,
              )}
            </Button>
            <Button
              color="danger"
              onClick={async () => {
                await props.configurations.delete.handler(
                  selectedEntity?.id || '',
                );
                getData(options);
                handlerDelete(null);
              }}
            >
              {t(
                `entities.${props.configurations.translationLabel}.table.deleteButton`,
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Table;
