import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Divider, User } from '@nextui-org/react';
import LicenseRequestDTO from '../dto/licenseRequest.dto';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
//import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
//import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import LicenseRequestRepository from '../licenseRequest.repository';
import { useTranslation } from 'react-i18next';
import DateUtil from '@utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTrash } from '@fortawesome/free-solid-svg-icons';
import LicenseStatus from '../enum/licenseStatus.enum';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

const LicenseRequestList = () => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();

  const isLicenseAdmin = profile?.group.permissions.some(
    (permission) =>
      permission.entity === AllowedEntities.LICENSE_TYPE &&
      permission.methods === AllowedMethods.ADMIN,
  );
  const isLeader = profile?.teamLeader?.id;

  const [licenseRequests, setLicenseRequests] = useState<{
    [key: string]: LicenseRequestDTO[];
  }>({});

  const organizedLicenseByDate = (
    licenseList: LicenseRequestDTO[],
  ): {
    [key: string]: LicenseRequestDTO[];
  } => {
    const organizedList: { [key: string]: LicenseRequestDTO[] } = {};

    licenseList.map((license) => {
      const licenseDate = new Date(license.createdDate);

      organizedList[
        `${(licenseDate.getMonth() + 1).toString().padStart(2, '0')}-${licenseDate.getDate().toString().padStart(2, '0')}-${licenseDate.getFullYear()}`
      ]
        ? organizedList[
            `${(licenseDate.getMonth() + 1).toString().padStart(2, '0')}-${licenseDate.getDate().toString().padStart(2, '0')}-${licenseDate.getFullYear()}`
          ].push(license)
        : (organizedList[
            `${(licenseDate.getMonth() + 1).toString().padStart(2, '0')}-${licenseDate.getDate().toString().padStart(2, '0')}-${licenseDate.getFullYear()}`
          ] = [license]);
    });

    return organizedList;
  };

  const getLicenseRequest = async () => {
    const where: any = {
      status: 'PENDING',
    };

    if (!isLicenseAdmin) where['user'] = { externalUser: profile?.id };

    if (!isLicenseAdmin && isLeader)
      where['user'] = [
        { externalUser: profile?.id },
        { externalTeam: isLeader },
      ];

    const response = await new LicenseRequestRepository().find({
      where,
      relations: ['licenseType', 'user'],
      order: {
        createdDate: 'DESC',
      },
    });

    setLicenseRequests(organizedLicenseByDate(response.results));
  };

  useEffect(() => {
    getLicenseRequest();
  }, []);

  const [isSubmitting, setSubmitting] = useState(false);

  const handleRejectLicense = async (id: string) => {
    setSubmitting(true);
    await new LicenseRequestRepository().update(id, {
      status: LicenseStatus.REJECTED,
    });
    setTimeout(() => {}, 200);
    getLicenseRequest();
    setSubmitting(false);
  };

  const handleApproveLicense = async (id: string) => {
    setSubmitting(true);
    await new LicenseRequestRepository().update(id, {
      status: LicenseStatus.APPROVED,
    });
    setTimeout(() => {}, 200);
    getLicenseRequest();
    setSubmitting(false);
  };

  const handleDeleteLicense = async (id: string) => {
    setSubmitting(true);
    await new LicenseRequestRepository().delete(id);
    setTimeout(() => {}, 200);
    getLicenseRequest();
    setSubmitting(false);
  };

  const getScreen = () => {
    if (Object.keys(licenseRequests).length === 0)
      return <div className="text-center">{t('user.license.list.empty')}</div>;

    return Object.keys(licenseRequests).map((dateKey, index) => (
      <div key={dateKey}>
        <div className={`flex items-center gap-3 ${index > 0 && 'pt-7'}`}>
          <span className="text-[#94A3B8] font-bold">
            {DateUtil.dateDaysDiff(new Date(dateKey), new Date()) > 0
              ? t('user.license.list.dates.ago', {
                  days: DateUtil.dateDaysDiff(new Date(dateKey), new Date()),
                })
              : t('user.license.list.dates.today')}
          </span>
          <Divider className="w-[90%] m-0" />
        </div>
        <div className="pt-7 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7">
          {licenseRequests[dateKey].map((license) => {
            return (
              <Card key={license.id}>
                <CardBody className="p-5">
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between">
                      <div className="py-1 px-2 rounded-full border bg-[#F8FBFF] w-fit dark:border-[#1E293B] dark:text-[#1E293B] dark:bg-[#1e1e1e]">
                        <span className="text-[#475569] text-sm">
                          {t(
                            `entities.license.values.status.${license.status}`,
                          )}
                        </span>
                      </div>
                      {isLicenseAdmin && (
                        <Button
                          color="danger"
                          isLoading={isSubmitting}
                          onClick={() => handleDeleteLicense(license.id)}
                          className="min-w-4 text-[12px]"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      )}
                    </div>
                    <div>
                      <span className="font-bold">
                        {license.licenseType.name} (
                        {DateUtil.dateDaysDiff(
                          new Date(license.startDate),
                          new Date(license.endDate),
                        )}{' '}
                        {DateUtil.dateDaysDiff(
                          new Date(license.startDate),
                          new Date(license.endDate),
                        ) > 1
                          ? t('entities.license.values.days')
                          : t('entities.license.values.day')}
                        )
                      </span>
                    </div>
                    <div className="text-sm text-[#94A3B8] flex items-center gap-2">
                      <FontAwesomeIcon icon={faCalendar} />
                      <span>
                        {DateUtil.dateToPeriod(new Date(license.startDate))} -{' '}
                        {DateUtil.dateToPeriod(new Date(license.endDate))}
                      </span>
                    </div>
                    <div className="flex items-end pt-5">
                      <User
                        name={
                          <span className="font-bold">
                            {license.user.fullName}
                          </span>
                        }
                        avatarProps={{
                          src:
                            license.user.avatar &&
                            `https://giveit-system-assets.s3.amazonaws.com/${license.user.avatar}`,
                          color:
                            license.user.externalUser === profile?.id
                              ? 'primary'
                              : 'secondary',
                        }}
                      />
                    </div>
                    {(license.user.externalUser !== profile?.id ||
                      isLicenseAdmin) && (
                      <div className="grid grid-cols-2 gap-5 mt-5">
                        <Button
                          isLoading={isSubmitting}
                          color="danger"
                          onClick={() => handleRejectLicense(license.id)}
                        >
                          Rechazar
                        </Button>
                        <Button
                          isLoading={isSubmitting}
                          color="success"
                          className="text-white"
                          onClick={() => handleApproveLicense(license.id)}
                        >
                          Aprobar
                        </Button>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </div>
      </div>
    ));
  };

  return <div>{getScreen()}</div>;
};

export default LicenseRequestList;
