import React from 'react';
import configuracionIcon from '@assets/icons/Configuracion.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const CreateLicenseAnounce = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-white dark:bg-black rounded-2xl p-7 flex flex-col items-center gap-7">
      <img
        src={configuracionIcon}
        alt="configuracionIcon"
        className="w-9 m-0"
      />
      <span className="text-xl font-semibold">
        {t('user.license.view.admin.card.title')}
      </span>
      <p className="text-[#94A3B8] w-[80%] text-center">
        {t('user.license.view.admin.card.description')}
      </p>
      <Button
        color="primary"
        className="border border-[#CCE3FD]"
        onClick={() => navigate('/enterprise/license/licenseType/create')}
      >
        <div className="flex items-center gap-3 p-3">
          <FontAwesomeIcon icon={faPlus} />
          {t('user.license.view.admin.card.button')}
        </div>
      </Button>
    </div>
  );
};

export default CreateLicenseAnounce;
