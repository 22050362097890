import axios, { AxiosError } from 'axios';
import SigninDTO from './dto/signin.dto';
import UnauthorizedException from './exceptions/unauthorized.exception';
import ProfileDTO from './dto/profile.dto';
import SignupDTO from './dto/signup.dto';
import envConfig from '@config/env.config';
import UserSignupDTO from './dto/userSignup.dto';
import PaymentRequiredException from './exceptions/paymentRequired.exception';

export default class AuthRepository {
  private url: string = '/api/auth';

  async getProfile() {
    try {
      const profileResponse = await axios.get<ProfileDTO>(
        `${this.url}/profile`,
      );
      return profileResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async signin(auth: SigninDTO) {
    try {
      if (auth.rememberMe) this.saveCredentials(auth);

      const signinResponse = await axios.post<{ token: string }>(
        `${this.url}/signin`,
        auth,
      );
      this.setToken(signinResponse.data.token);
      return signinResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
        if (err.response?.status === 402) throw new PaymentRequiredException();
      }

      throw err;
    }
  }

  async signinGoogle() {
    window.location.assign(
      `${process.env.REACT_APP_BACKEND_URL || envConfig.BACKEND_URL}${this.url}/signin/google`,
    );
  }

  async validateContactSignup(contact: UserSignupDTO) {
    try {
      const signupResponse = await axios.post(
        `${this.url}/signup/contact`,
        contact,
      );
      return signupResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) throw err.response?.data.errors;
      throw err;
    }
  }

  async signup(enterprise: SignupDTO) {
    try {
      const signupResponse = await axios.post<{ token: string }>(
        `${this.url}/signup`,
        enterprise,
      );
      this.setToken(signupResponse.data.token);
      return signupResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) throw err.response?.data.errors;

      throw err;
    }
  }

  async signout() {
    this.setToken('');
    window.location.assign(`/auth/signin`);
  }

  saveCredentials(credentials: SigninDTO) {
    localStorage.setItem('credentials', JSON.stringify(credentials));
  }

  getCredentials(): SigninDTO | null {
    const credentials = localStorage.getItem('credentials');
    if (credentials) return JSON.parse(credentials);

    return null;
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }
}
