import i18next from 'i18next';
import * as Yup from 'yup';

const signupContactValidation = () => {
  return Yup.object().shape({
    email: Yup.string()
      .required(i18next.t('validations.required'))
      .email(i18next.t('validations.email')),
    password: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    firstName: Yup.string().required(i18next.t('validations.required')),
    lastName: Yup.string().required(i18next.t('validations.required')),
    phone: Yup.string().required(i18next.t('validations.required')),
    repeatPassword: Yup.string()
      .required(i18next.t('validations.required'))
      .oneOf(
        [Yup.ref('repeatPassword')],
        i18next.t('validations.repeatPassword'),
      ),
    country: Yup.string().required(i18next.t('validations.required')),
  });
};

export default signupContactValidation;
