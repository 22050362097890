import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationsSelection from '@modules/notificationModule/components/notificationsSelection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotificationsConfigurationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
        {t('entities.notifications.title')}
      </h3>
      <NotificationsSelection />
    </div>
  );
};

export default NotificationsConfigurationPage;
