import React from 'react';
import UserFileRepository, {
  UserEventDTO,
} from '@modules/userModule/userFile/userFile.repository';
import { Card, CardBody } from '@nextui-org/card';
import {
  User,
  Spinner,
  CardHeader,
  Button,
  CardFooter,
} from '@nextui-org/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateUtil from '@utils/date';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import ChevronIcon from '@components/icons/chevron.icon';
import { PAGINATION_TAKE } from '@lib/paginated/constants';

const MobileEventsDashboardCard = () => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [nextEvents, setNextEvents] = useState<UserEventDTO[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getNextEvents = async (startDate: string, endDate: string) => {
    try {
      const nextEventsResponse = await new UserFileRepository().getNextEvents(
        startDate,
        endDate,
      );
      setNextEvents(nextEventsResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
      const startDate = new Date(Date.now() - timezoneOffset);
      const startDateString = startDate.toISOString().substring(0, 10);

      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 4);
      const endDateString = endDate.toISOString().substring(0, 10);

      getNextEvents(startDateString, endDateString);
    }
  }, [isOpen]);

  return (
    <Card className="shadow-none bg-content4 lg:hidden">
      <CardHeader className="justify-center pb-0">
        <span className="text-lg dark:text-primary-600">
          {t('user.events.title')}
        </span>
      </CardHeader>
      {isOpen && (
        <CardBody className="flex flex-col gap-2">
          {isLoading ? (
            <Spinner />
          ) : (
            nextEvents.map((user, index) => {
              if (index < PAGINATION_TAKE)
                return (
                  <Card className="shadow-sm" key={user.id}>
                    <CardBody className="flex flex-row justify-between items-center p-1">
                      <User
                        key={index}
                        name={user.firstName + ' ' + user.lastName}
                        description={
                          <>
                            {DateUtil.isToday(new Date(user.eventDate)) ? (
                              <span>
                                {user.hireDate
                                  ? t('user.events.isAnniversaryToday')
                                  : t('user.events.isBirthdayToday')}
                              </span>
                            ) : (
                              <span>
                                {new Date(user.eventDate).toLocaleDateString(
                                  'es-es',
                                  {
                                    month: 'long',
                                    day: 'numeric',
                                  },
                                )}{' '}
                                {user.hireDate
                                  ? t('user.events.isAniversay')
                                  : t('user.events.isBirthdate')}
                              </span>
                            )}
                          </>
                        }
                        avatarProps={{
                          className: 'flex-shrink-0',
                          src: user.avatar
                            ? `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`
                            : undefined,
                          color:
                            user.id === profile?.id ? 'primary' : 'secondary',
                          isBordered: user.id === profile?.id,
                        }}
                      />
                    </CardBody>
                  </Card>
                );
            })
          )}
        </CardBody>
      )}
      <CardFooter className="justify-center pt-0">
        <Button
          variant="light"
          onPress={() => setIsOpen(!isOpen)}
          color="primary"
        >
          <ChevronIcon
            className={`transition-all ${isOpen ? 'rotate-180' : ''}`}
          />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default MobileEventsDashboardCard;
