import IconProps from './common/icon.interface';

const DocumentationIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.30773 21.5C4.80901 21.5 4.38306 21.3233 4.02986 20.9701C3.67664 20.6169 3.50003 20.191 3.50003 19.6923V8.4942C3.20645 8.34292 2.96639 8.12785 2.77986 7.849C2.59331 7.57015 2.50003 7.24868 2.50003 6.88458V4.3077C2.50003 3.80898 2.67664 3.38302 3.02986 3.02982C3.38306 2.67661 3.80901 2.5 4.30773 2.5H19.6923C20.191 2.5 20.617 2.67661 20.9702 3.02982C21.3234 3.38302 21.5 3.80898 21.5 4.3077V6.88458C21.5 7.24868 21.4067 7.57015 21.2202 7.849C21.0336 8.12785 20.7936 8.34292 20.5 8.4942V19.6923C20.5 20.191 20.3234 20.6169 19.9702 20.9701C19.617 21.3233 19.191 21.5 18.6923 21.5H5.30773ZM5.00001 8.69225V19.6442C5.00001 19.7467 5.03686 19.8317 5.11058 19.899C5.18431 19.9663 5.27567 20 5.38466 20H18.6923C18.782 20 18.8558 19.9711 18.9135 19.9134C18.9712 19.8557 19 19.782 19 19.6923V8.69225H5.00001ZM4.30773 7.1923H19.6923C19.782 7.1923 19.8558 7.16345 19.9135 7.10575C19.9712 7.04805 20 6.97433 20 6.88458V4.3077C20 4.21795 19.9712 4.14423 19.9135 4.08653C19.8558 4.02883 19.782 3.99998 19.6923 3.99998H4.30773C4.21798 3.99998 4.14426 4.02883 4.08656 4.08653C4.02886 4.14423 4.00001 4.21795 4.00001 4.3077V6.88458C4.00001 6.97433 4.02886 7.04805 4.08656 7.10575C4.14426 7.16345 4.21798 7.1923 4.30773 7.1923ZM9.91346 13.4326H14.0962C14.3026 13.4326 14.4728 13.364 14.6067 13.2269C14.7407 13.0897 14.8077 12.9179 14.8077 12.7115C14.8077 12.5051 14.7407 12.3349 14.6067 12.2009C14.4728 12.067 14.3026 12 14.0962 12H9.90386C9.69744 12 9.52725 12.067 9.39328 12.2009C9.25931 12.3349 9.19233 12.5051 9.19233 12.7115C9.19233 12.9179 9.26091 13.0897 9.39808 13.2269C9.53526 13.364 9.70706 13.4326 9.91346 13.4326Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DocumentationIcon;
