import dayjs, { ManipulateType } from 'dayjs';
import IsSameOrAfter from 'dayjs/plugin/isSameOrAfter';

export interface QuarterDate {
  from: Date;
  to: Date;
}

export default class DateUtil {
  static dateToPeriod(date: Date): string {
    return dayjs(date).format('DD MMM YYYY');
  }

  static dateToFullDate(date: Date): string {
    return dayjs(date).format('DD MMMM YYYY HH:mm');
  }

  static dateForInput(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD');
  }

  static timeForInput(date: Date): string {
    return dayjs(date).format('HH:mm');
  }

  static stringToDate(dateString: string): Date {
    if (!dateString) return dayjs().toDate();
    return dayjs(dateString).toDate();
  }

  static stringToHours(date: Date, hours: string): Date {
    const timeComponents = hours.split(':');
    if (parseInt(timeComponents[0]) < 24)
      date.setHours(parseInt(timeComponents[0]));
    if (parseInt(timeComponents[1]) < 60)
      date.setMinutes(parseInt(timeComponents[1]));
    return date;
  }

  static dateDaysDiff(dateFrom: Date, dateTo: Date): number {
    const diferenciaEnMilisegundos = dateTo.getTime() - dateFrom.getTime();
    const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
    return Math.abs(Math.round(diferenciaEnDias));
  }

  static dateDaysDiffNotAbs(dateFrom: Date, dateTo: Date): number {
    const diferenciaEnMilisegundos = dateTo.getTime() - dateFrom.getTime();
    const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
    return Math.round(diferenciaEnDias);
  }

  static isToday(date: Date): boolean {
    const today = new Date();

    if (
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      return true;
    }
    return false;
  }

  static dateSortFunction(dateA: Date, dateB: Date): number {
    if (dayjs(dateA).isBefore(dateB)) return -1;
    return 1;
  }

  static quarterToDate(quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4'): QuarterDate {
    const date = dayjs().startOf('year');

    const quarters = {
      Q1: {
        from: date.toDate(),
        to: date.add(2, 'month').endOf('month').toDate(),
      },
      Q2: {
        from: date.add(3, 'month').toDate(),
        to: date.add(5, 'month').endOf('month').toDate(),
      },
      Q3: {
        from: date.add(6, 'month').toDate(),
        to: date.add(8, 'month').endOf('month').toDate(),
      },
      Q4: {
        from: date.add(9, 'month').toDate(),
        to: date.add(11, 'month').endOf('month').toDate(),
      },
    };

    return quarters[quarter];
  }

  static dateToQuarter(date: Date): 'Q1' | 'Q2' | 'Q3' | 'Q4' {
    if (date.getMonth() >= 0 && date.getMonth() <= 2) return 'Q1';

    if (date.getMonth() >= 3 && date.getMonth() <= 5) return 'Q2';

    if (date.getMonth() >= 6 && date.getMonth() <= 8) return 'Q3';

    return 'Q4';
  }

  static getFutureDate(amount: number, unit: ManipulateType): Date {
    const currentDate = dayjs();
    return currentDate.add(amount, unit).toDate();
  }

  static isFutureDate(date: Date, futureDate: Date): boolean {
    dayjs.extend(IsSameOrAfter);
    return dayjs(futureDate).isSameOrAfter(date);
  }

  static checkValidDate(value: any): boolean {
    return !isNaN(new Date(value).getTime());
  }
}
