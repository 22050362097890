import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ThermometerConfigDTO from './dto/thermometerConfig.dto';
import axios from 'axios';
import UpdateThermometerConfigDTO from './dto/updateThermometerConfig.dto';

export default class ThermometerConfigRepository {
  private url: string = '/api/thermometer/config';

  async findOne(
    options?: FindOptionsDTO<ThermometerConfigDTO>,
  ): Promise<ThermometerConfigDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<ThermometerConfigDTO>(
      `${this.url}${stringOption}`,
    );
    return userResponse.data;
  }

  async update(id: string, changes: UpdateThermometerConfigDTO) {
    const updatedUserResponse = await axios.put<ThermometerConfigDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedUserResponse.data;
  }
}
