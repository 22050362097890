import { Input, InputProps } from '@nextui-org/react';

const CustomInput = (props: InputProps) => {
  return (
    <Input
      classNames={{
        inputWrapper:
          'border-1 border-default shadow-none bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
      }}
      {...props}
    />
  );
};

export default CustomInput;
