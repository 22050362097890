import { RouteObject } from 'react-router-dom';
import SingupOptions from './signupOptions';
import SignupContact from './signupContact';
import SignupEnterprise from './signupEnterprise';

const signupRoutes: Array<RouteObject> = [
  {
    path: 'options',
    element: <SingupOptions />,
  },
  {
    path: 'contact',
    element: <SignupContact />,
  },
  {
    path: 'enterprise',
    element: <SignupEnterprise />,
  },
];

export default signupRoutes;
