import i18next from 'i18next';
import * as Yup from 'yup';

const LicenseRequestFormValidation = () => {
  return Yup.object().shape({
    startDate: Yup.date().required(i18next.t('validations.required')),
    endDate: Yup.date().required(i18next.t('validations.required')),
    licenseType: Yup.object()
      .required(i18next.t('validations.required'))
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
    user: Yup.object()
      .required(i18next.t('validations.required'))
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
  });
};

export default LicenseRequestFormValidation;
