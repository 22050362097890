import React from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import InitiativeDTO from '../dto/initiative.dto';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import InitiativeRepository from '../initiative.repository';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import {
  Card,
  CardBody,
  Link,
  Pagination,
  Skeleton,
  User,
} from '@nextui-org/react';
import InitiativeStatusChange from './initiativeStatusChange';
import InitiativeStatus from '../enum/initiativeStatus.enum';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { useNavigate } from 'react-router-dom';

interface IInitiativeList {
  options?: FindOptionsDTO<InitiativeDTO>;
  keyResult: string;
}

const InitiativeList = (props: IInitiativeList) => {
  const profile = useSelector(selectProfile);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState<FindOptionsDTO<InitiativeDTO>>({});
  const [initiativesResult, setInitiativesResult] = useState<
    PaginatedDTO<InitiativeDTO>
  >({
    results: [],
    total: 0,
  });

  const getInitiatives = async (options?: FindOptionsDTO<InitiativeDTO>) => {
    setLoading(true);
    const initiativeResult = await new InitiativeRepository().find({
      ...options,
      relations: Object.assign(['manager'], options?.relations),
      where: {
        ...options?.where,
        keyResult: {
          id: props.keyResult,
        },
      },
    });
    setTotal(initiativeResult.total);
    setInitiativesResult(initiativeResult);
    setLoading(false);
  };

  const handlerPagination = (page: number) => {
    setPage(page);
    setOptions((prev) => {
      return {
        ...prev,
        take: page * PAGINATION_TAKE,
        skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
      };
    });
  };

  useEffect(() => {
    getInitiatives({
      take: page * PAGINATION_TAKE,
      skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
    });
  }, []);

  useEffect(() => {
    getInitiatives(options);
  }, [options]);

  const LoadingTemplate = () => {
    return (
      <div className="flex flex-col gap-10">
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
      </div>
    );
  };

  const EmptyList = () => {
    return (
      <div className="pt-5">
        <p className="text-center">{t('entities.initiative.table.empty')}</p>
      </div>
    );
  };

  const onChangeStatus = async (id: string, status: InitiativeStatus) => {
    await new InitiativeRepository().update(id, { status });
    getInitiatives(options);
  };

  return isLoading ? (
    <LoadingTemplate />
  ) : (
    <div>
      {initiativesResult.total === 0 ? (
        <EmptyList />
      ) : (
        <div>
          <div className="py-3 flex flex-col gap-3">
            {initiativesResult.results.map((initiative) => (
              <Card key={initiative.id} className="shadow-none">
                <CardBody className="justify-between gap-3 flex-row flex-wrap lg:flex-nowrap">
                  <div className="w-fit lg:w-1/3">
                    <div className="flex flex-col gap-3">
                      <span className="text-sm font-bold text-foreground-500">
                        {t('entities.initiative.fields.name')}
                      </span>
                      <div className="h-full flex items-center">
                        {profile?.group.permissions.some(
                          (permission) =>
                            permission.entity === AllowedEntities.INITIATIVE &&
                            permission.methods === AllowedMethods.VIEW,
                        ) ? (
                          <Link
                            href={`/enterprise/initiative/view/${initiative.id}`}
                            className="text-foreground cursor-pointer"
                          >
                            {initiative.name}
                          </Link>
                        ) : (
                          <div className="text-foreground">
                            {initiative.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-1/3 flex-col gap-3 items-start">
                    <span className="text-sm font-bold text-foreground-500">
                      {t('entities.initiative.fields.manager')}
                    </span>
                    {initiative.manager && (
                      <User
                        name={initiative.manager.fullName}
                        description={initiative.manager.email}
                        avatarProps={{
                          src:
                            initiative.manager?.avatar &&
                            `https://giveit-system-assets.s3.amazonaws.com/${initiative.manager?.avatar}`,
                        }}
                        onClick={() =>
                          navigate(
                            `/enterprise/profile/${initiative.manager.id}`,
                          )
                        }
                        className="cursor-pointer"
                        classNames={{
                          name: 'hidden lg:inline',
                          description: 'hidden lg:inline',
                        }}
                      />
                    )}
                  </div>
                  <div className="w-1/3">
                    <div className="flex flex-col gap-3">
                      <span className="text-sm font-bold text-foreground-500">
                        {t('entities.initiative.fields.status')}
                      </span>
                      <div className="h-full flex items-center">
                        <InitiativeStatusChange
                          status={initiative.status}
                          onChange={(status) =>
                            onChangeStatus(initiative.id, status)
                          }
                          disabled={
                            !profile?.group.permissions.some(
                              (permission) =>
                                permission.entity ===
                                  AllowedEntities.INITIATIVE &&
                                permission.methods === AllowedMethods.MODIFY,
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
          <div className="flex justify-center">
            <Pagination
              color="primary"
              showControls
              page={page}
              total={Math.ceil(total / PAGINATION_TAKE)}
              onChange={handlerPagination}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InitiativeList;
