import React from 'react';
import KeyResultStatus from '@modules/objectiveModule/keyResult/enum/keyResultStatus.enum';
import KeyResultRepository from '@modules/objectiveModule/keyResult/keyResult.repository';
import { Card, CardBody, Skeleton } from '@nextui-org/react';
import DateUtil from '@utils/date';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface KeyResultStatusesMetricProps {
  period: 'Q1' | 'Q2' | 'Q3' | 'Q4';
}

const KeyResultStatuses = (props: KeyResultStatusesMetricProps) => {
  const { t } = useTranslation();
  const periodDates = DateUtil.quarterToDate(props.period);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [statuses, setStatuses] = useState({
    ON_TIME: 0,
    LATE: 0,
    AT_RISK: 0,
  });

  const calculateStatuses = async () => {
    setLoading(true);
    const keyResults = await new KeyResultRepository().find({
      where: [
        {
          periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ],
    });

    const keyResultsStatuses = {
      ON_TIME: 0,
      LATE: 0,
      AT_RISK: 0,
    };

    keyResults.results.map((keyResult) => {
      if (keyResult.status === KeyResultStatus.ON_TIME)
        keyResultsStatuses.ON_TIME++;
      if (keyResult.status === KeyResultStatus.LATE) keyResultsStatuses.LATE++;
      if (keyResult.status === KeyResultStatus.AT_RISK)
        keyResultsStatuses.AT_RISK++;
    });
    setStatuses(keyResultsStatuses);
    setLoading(false);
  };

  useEffect(() => {
    calculateStatuses();
  }, [props.period]);

  return (
    <div className="flex gap-3 overflow-x-auto pb-5 px-5">
      <Card className={`min-w-[240px] md:w-1/3 shadow-md`}>
        <CardBody>
          <span className="text-default-400 text-sm">
            {t('entities.metric.objective.keyResulStatuses.description')}
          </span>
          <span className="font-bold text-lg">
            {t(
              'entities.metric.objective.keyResulStatuses.status.ON_TIME.label',
            )}
          </span>
          <Skeleton isLoaded={!isLoading}>
            <span className={`text-xl text-success`}>{statuses.ON_TIME}</span>
          </Skeleton>
        </CardBody>
      </Card>
      <Card className={`min-w-[240px] md:w-1/3 shadow-md`}>
        <CardBody>
          <span className="text-default-400 text-sm">
            {t('entities.metric.objective.keyResulStatuses.description')}
          </span>
          <span className="font-bold text-lg">
            {t('entities.metric.objective.keyResulStatuses.status.LATE.label')}
          </span>
          <Skeleton isLoaded={!isLoading}>
            <span className={`text-xl text-warning`}>{statuses.LATE}</span>
          </Skeleton>
        </CardBody>
      </Card>
      <Card className={`min-w-[240px] md:w-1/3 shadow-md`}>
        <CardBody>
          <span className="text-default-400 text-sm">
            {t('entities.metric.objective.keyResulStatuses.description')}
          </span>
          <span className="font-bold text-lg">
            {t(
              'entities.metric.objective.keyResulStatuses.status.AT_RISK.label',
            )}
          </span>
          <Skeleton isLoaded={!isLoading}>
            <span className={`text-xl text-danger`}>{statuses.AT_RISK}</span>
          </Skeleton>
        </CardBody>
      </Card>
    </div>
  );
};

export default KeyResultStatuses;
