import React, { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import CreateChallengeDTO from '../dto/createChallenge.dto';
import ChallengeValidation from '../validation/challenge.validation';
import { Button, Checkbox } from '@nextui-org/react';
import DateUtil from '@utils/date';
import CustomInput from '@components/input/input';
import CustomBlockNote from '@components/customBlockNote/customBlockNote.component';
import { CustomBlockNoteEditor } from '@components/customBlockNote/customSchema';
import FeedUserDTO from '@modules/feed/feedUser/dto/feedUser.dto';

interface ChallengeFormProps {
  initialValues?: CreateChallengeDTO;
  onSubmit: (values: CreateChallengeDTO) => void | Promise<void>;
  editor: CustomBlockNoteEditor;
  blocknoteError: boolean;
}

export const initialChallengeFormValues: CreateChallengeDTO = {
  title: '',
  assignment: '',
  dateFrom: new Date(),
  dateTo: new Date(),
  rocketsWinner: 10,
  rocketsParticipation: 1,
  amountOfWinners: 1,
  publishWinner: true,
};

const ChallengeForm = ({
  initialValues = initialChallengeFormValues,
  onSubmit,
  editor,
  blocknoteError,
}: ChallengeFormProps) => {
  const [taggedUsers] = useState<FeedUserDTO[]>([]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChallengeValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateChallengeDTO>) => (
        <Form className="space-y-2">
          <div>
            <label htmlFor="title">Título</label>
            <CustomInput
              id="title"
              name="title"
              placeholder="El título que identifica al desafío..."
              isInvalid={Boolean(errors.title)}
              errorMessage={errors.title}
              color={errors.title ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('title', e.target.value)}
              value={values.title}
            />
          </div>
          <div className="space-y-2 lg:flex lg:gap-5 lg:items-end">
            <div className="w-full">
              <label htmlFor="dateFrom">Desde</label>
              <CustomInput
                id="dateFrom"
                name="dateFrom"
                type="date"
                isInvalid={Boolean(errors.dateFrom)}
                errorMessage={String(errors.dateFrom || '')}
                color={errors.dateFrom ? 'danger' : 'default'}
                onChange={(e) => {
                  setFieldValue(
                    'dateFrom',
                    DateUtil.stringToDate(e.target.value),
                  );
                }}
                value={DateUtil.dateForInput(values.dateFrom)}
              />
            </div>
            <div className="w-full">
              <CustomInput
                type="hour"
                isInvalid={Boolean(errors.dateFrom)}
                errorMessage={String(errors.dateFrom || '')}
                color={errors.dateFrom ? 'danger' : 'default'}
                onChange={(e) => {
                  if (!e.target.value.includes(':')) return;
                  if (typeof values.dateFrom === 'string')
                    values.dateFrom = DateUtil.stringToDate(values.dateFrom);
                  const dateWithHours = DateUtil.stringToHours(
                    values.dateFrom,
                    e.target.value,
                  );
                  setFieldValue('dateFrom', dateWithHours);
                }}
                value={DateUtil.timeForInput(values.dateFrom)}
              />
            </div>
            <div className="w-full">
              <label htmlFor="dateTo">Hasta</label>
              <CustomInput
                id="dateTo"
                name="dateTo"
                type="date"
                isInvalid={Boolean(errors.dateTo)}
                errorMessage={String(errors.dateTo || '')}
                color={errors.dateTo ? 'danger' : 'default'}
                onChange={(e) =>
                  setFieldValue('dateTo', DateUtil.stringToDate(e.target.value))
                }
                value={DateUtil.dateForInput(values.dateTo)}
              />
            </div>
            <div className="w-full">
              <CustomInput
                type="hour"
                isInvalid={Boolean(errors.dateTo)}
                errorMessage={String(errors.dateTo || '')}
                color={errors.dateTo ? 'danger' : 'default'}
                onChange={(e) => {
                  if (!e.target.value.includes(':')) return;
                  if (typeof values.dateTo === 'string')
                    values.dateTo = DateUtil.stringToDate(values.dateTo);
                  const dateWithHours = DateUtil.stringToHours(
                    values.dateTo,
                    e.target.value,
                  );
                  setFieldValue('dateTo', dateWithHours);
                }}
                value={DateUtil.timeForInput(values.dateTo)}
              />
            </div>
          </div>
          <div className="lg:flex lg:gap-5">
            <div className="w-full">
              <label htmlFor="rocketsWinner">
                Recompensa por ganar (rockets)
              </label>
              <CustomInput
                id="rocketsWinner"
                name="rocketsWinner"
                type="number"
                isInvalid={Boolean(errors.rocketsWinner)}
                errorMessage={String(errors.rocketsWinner || '')}
                color={errors.rocketsWinner ? 'danger' : 'default'}
                onChange={(e) =>
                  setFieldValue('rocketsWinner', e.target.valueAsNumber)
                }
                value={String(values.rocketsWinner) || '10'}
              />
            </div>
            <div className="w-full">
              <label htmlFor="rocketsParticipation">
                Recompensa por participar (rockets)
              </label>
              <CustomInput
                id="rocketsParticipation"
                name="rocketsParticipation"
                type="number"
                isInvalid={Boolean(errors.rocketsParticipation)}
                errorMessage={String(errors.rocketsParticipation || '')}
                color={errors.rocketsParticipation ? 'danger' : 'default'}
                onChange={(e) =>
                  setFieldValue('rocketsParticipation', e.target.valueAsNumber)
                }
                value={String(values.rocketsParticipation) || '1'}
              />
            </div>
          </div>
          <div className="lg:flex lg:gap-5 lg:items-center">
            <div className="w-full">
              <label htmlFor="amountOfWinners">Cantidad de ganadores</label>
              <CustomInput
                id="amountOfWinners"
                name="amountOfWinners"
                type="number"
                isInvalid={Boolean(errors.amountOfWinners)}
                errorMessage={String(errors.amountOfWinners || '')}
                color={errors.amountOfWinners ? 'danger' : 'default'}
                onChange={(e) =>
                  setFieldValue('amountOfWinners', e.target.valueAsNumber)
                }
                value={String(values.amountOfWinners) || '1'}
              />
            </div>
            <div className="w-full flex justify-center">
              <Checkbox
                name="rocketsParticipation"
                onValueChange={(e) => setFieldValue('publishWinner', e)}
                isSelected={values.publishWinner}
                className="mt-5"
              >
                Publicar ganador al finalizar el desafío
              </Checkbox>
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="">Consigna</label>
            <CustomBlockNote
              editor={editor}
              error={blocknoteError}
              className="border-1 p-2 border-default rounded-xl w-full"
              taggedUsers={taggedUsers}
            />
          </div>
          <div className="w-full flex justify-center lg:justify-end">
            <Button
              className="w-fit"
              type="submit"
              color="primary"
              isLoading={isSubmitting}
            >
              Enviar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChallengeForm;
