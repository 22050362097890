import UserFileDTO from '@modules/userModule/userFile/dto/userFile.dto';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import FindOptions from 'lib/paginated/findOptions.dto';
import UserFileRepository from '@modules/userModule/userFile/userFile.repository';
import { Spinner } from '@nextui-org/react';
import UpdateUserFileDTO from '@modules/userModule/userFile/dto/updateUserFile.dto';
import CompareUtil from '@utils/compare';
import UserFileForm from '@modules/userModule/userFile/components/userForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const EditUserFile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [userResult, setUserResult] = useState<UserFileDTO | null>(null);

  const getUser = async (options?: FindOptions<UserFileDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const userResult = await new UserFileRepository().findOne(id as string, {
      ...options,
      relations: Object.assign(options?.relations || [], ['user']),
    });
    if (!userResult) navigate(-1);
    setUserResult(userResult);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  const handleSubmit = async (values: any) => {
    if (!userResult) return;
    await new UserFileRepository().update(userResult?.id as string, {
      ...(CompareUtil.compareObjects(userResult, values) as UpdateUserFileDTO),
    });
    navigate(-1);
  };

  return isLoading || !userResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.userFile.edit')}
      </h3>
      <UserFileForm onSubmit={handleSubmit} initialValues={userResult as any} />
    </div>
  );
};

export default EditUserFile;
