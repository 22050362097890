import React from 'react';
import ReactEChart from 'echarts-for-react';
import UserMetricRepository from '../user/userMetric.repository';
import { useEffect, useState } from 'react';
import ThermometerSurveyMetricRepository from '../thermometerSurvey/thermometerSurveyMetric.repository';
import PulseSurveyMetricRepository from '../pulseSurvey/pulseSurveyMetric.repository';
import DateUtil from '@utils/date';
import { useTranslation } from 'react-i18next';
import { Chip } from '@nextui-org/react';

interface ParticipationMetricProps {
  period: 'Q1' | 'Q2' | 'Q3' | 'Q4';
}

const ParticipationMetric = (props: ParticipationMetricProps) => {
  const { t } = useTranslation();
  const periodDates = DateUtil.quarterToDate(props.period);
  const [votes, setVotes] = useState({
    percent: 0,
    thermometer: 0,
    pulse: 0,
    total: 0,
  });
  const calculateParticipation = async () => {
    const [userCount, thermometerSurveyVotesCount, pulseSurveys] =
      await Promise.all([
        new UserMetricRepository().count(),
        new ThermometerSurveyMetricRepository().count({
          where: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        }),
        new PulseSurveyMetricRepository().voteResponseAverage({
          where: {
            dateFrom: `>=${DateUtil.dateForInput(periodDates.from)}`,
            dateTo: `<=${DateUtil.dateForInput(periodDates.to)}`,
          },
        }),
      ]);

    const averageResult =
      ((pulseSurveys.avg +
        thermometerSurveyVotesCount /
          DateUtil.dateDaysDiff(
            periodDates.from,
            periodDates.from <= new Date() && periodDates.to >= new Date()
              ? new Date()
              : periodDates.to,
          )) /
        2 /
        userCount) *
      100;
    setVotes({
      percent: averageResult,
      pulse: Math.ceil(pulseSurveys.sum),
      thermometer: thermometerSurveyVotesCount,
      total: Math.ceil(pulseSurveys.sum) + thermometerSurveyVotesCount,
    });
  };

  useEffect(() => {
    calculateParticipation();
  }, [props.period]);

  const percentColor = (
    percent: number,
  ): { class: string; color: string; percent: string } => {
    if (isNaN(percent) || percent === 0)
      return {
        class: 'text-default-500',
        color: '#9ca3af',
        percent: `0%`,
      };

    if (percent >= 70)
      return {
        class: 'text-success',
        color: '#17c964',
        percent: `${percent.toFixed(2)}%`,
      };

    if (percent >= 30)
      return {
        class: 'text-warning',
        color: '#f5a524',
        percent: `${percent.toFixed(2)}%`,
      };

    return {
      class: 'text-danger',
      color: '#f31260',
      percent: `${percent.toFixed(2)}%`,
    };
  };

  const calculatedPercents = percentColor(votes.percent);

  return (
    <div className="flex flex-col gap-5">
      <div>
        <div className="max-h-44">
          <ReactEChart
            option={{
              series: [
                {
                  type: 'gauge',
                  startAngle: 180,
                  endAngle: 0,
                  min: 0,
                  max: 100,
                  splitNumber: 10,
                  itemStyle: {
                    color: calculatedPercents.color,
                  },
                  progress: {
                    show: true,
                    roundCap: true,
                    width: 18,
                  },
                  pointer: {
                    icon: 'path://M3.92727 24.6734C3.51722 24.8194 3.06862 24.8776 2.60774 24.8275C0.989967 24.6517 -0.168597 23.2078 0.0200879 21.6026L1.57366 8.38497L2.13091 3.64376L2.50866 0.429838C2.53343 0.219356 2.66283 0.0860044 2.81949 0.0302167C3.08711 -0.0650834 3.4343 0.0658484 3.47514 0.424998L3.82602 3.51098L4.39251 8.49256L5.88001 21.5733C5.90279 21.7853 5.90474 22.0184 5.87872 22.2394C5.74382 23.3874 4.95659 24.3068 3.92727 24.6734ZM4.37202 21.4511C4.0988 20.6841 3.25635 20.2836 2.49036 20.5565C1.72437 20.8293 1.32484 21.6722 1.59809 22.4392C1.87129 23.2062 2.71376 23.6067 3.47975 23.3338C4.24574 23.061 4.64522 22.218 4.37202 21.4511Z',
                    length: '60%',
                    width: 16,
                    offsetCenter: [0, '10%'],
                  },
                  axisLine: {
                    roundCap: true,
                    lineStyle: {
                      width: 18,
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: false,
                  },
                  axisLabel: {
                    show: false,
                  },
                  title: {
                    show: false,
                  },
                  detail: {
                    show: false,
                  },
                  data: [
                    {
                      value: votes.percent,
                    },
                  ],
                },
              ],
            }}
          />
        </div>
        <div className="flex flex-col gap-3 items-center">
          <span className="text-default-400 text-sm">
            {t(`entities.metric.participation.period.${props.period}`)}
          </span>
          <span className="font-bold text-lg">
            {t(`entities.metric.participation.title`)}
          </span>
          <span className={`text-5xl font-bold ${calculatedPercents.class}`}>
            {calculatedPercents.percent}
          </span>
        </div>
      </div>
      <div className="flex justify-center md:justify-start md:items-center">
        <div className="flex flex-col gap-1 md:gap-3 w-full">
          <span className="font-bold lg:text-xl">
            {t('entities.metric.participation.surveyVotes.title')}
          </span>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between">
              <span className="lg:text-lg">
                {t('entities.metric.participation.surveyVotes.surveys.pulse')}
              </span>
              <Chip color="primary" variant="flat">
                {votes.pulse}
              </Chip>
            </div>
            <div className="flex justify-between">
              <span className="lg:text-lg">
                {t(
                  'entities.metric.participation.surveyVotes.surveys.thermometer',
                )}
              </span>
              <Chip color="primary" variant="flat">
                {votes.thermometer}
              </Chip>
            </div>
          </div>
          <div className="flex justify-between">
            <span className="font-bold lg:text-lg">
              {t('entities.metric.participation.surveyVotes.total')}
            </span>
            <span className="font-bold lg:text-lg pr-2">{votes.total}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationMetric;
