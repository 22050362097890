import React from 'react';
import { useTranslation } from 'react-i18next';
import KeyResultStatus from '../enum/keyResultStatus.enum';
import { Select, SelectItem } from '@nextui-org/react';

const KeyResultStatusChange = ({
  status,
  onChange,
  disabled,
}: {
  status: KeyResultStatus;
  onChange: (status: KeyResultStatus) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const styles: { [key in KeyResultStatus]: string } = {
    ON_TIME:
      'bg-success-200 text-success-800 border border-success-600 data-[hover=true]:bg-success-200',
    LATE: 'bg-warning-200 text-warning-800 border border-warning-600 data-[hover=true]:bg-warning-200',
    AT_RISK:
      'bg-danger-200 text-danger-800 border border-danger-600 data-[hover=true]:bg-danger-200',
  };
  return (
    <Select
      isDisabled={disabled}
      classNames={{
        trigger: `${styles[status as KeyResultStatus]}`,
        value: `w-32 text-sm px-2 py-1 rounded`,
      }}
      selectedKeys={new Set([status])}
      onSelectionChange={(keys) => {
        onChange(new Set(keys).values().next().value);
      }}
    >
      {Object.keys(KeyResultStatus).map((selectionStatus) => (
        <SelectItem
          className={`text-sm px-2 py-3 rounded ${styles[selectionStatus as KeyResultStatus]}`}
          key={selectionStatus}
          value={selectionStatus}
        >
          {t(`entities.keyResult.values.status.${selectionStatus}`)}
        </SelectItem>
      ))}
    </Select>
  );
};

export default KeyResultStatusChange;
