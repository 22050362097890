import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { Skeleton } from '@nextui-org/react';
import StrengthDTO from '../dto/strength.dto';
import StrengthRepository from '../strength.repository';

interface StrengthDetailProps {
  id: string;
}

const StrengthDetail = (props: StrengthDetailProps) => {
  const { t } = useTranslation();
  const [strength, setStrength] = useState<StrengthDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getStrength = async (options?: FindOptionsDTO<StrengthDTO>) => {
    setLoading(true);
    const strengthRequest = await new StrengthRepository().findOne(props.id, {
      ...options,
      relations: Object.assign([], options?.relations),
    });
    setStrength(strengthRequest);
    setLoading(false);
  };

  useEffect(() => {
    getStrength();
  }, []);

  const Loading = () => {
    return (
      <div className="py-5 flex flex-col gap-5">
        <div>
          <Skeleton className="h-6 w-64"></Skeleton>
        </div>
      </div>
    );
  };

  return isLoading || !strength ? (
    <Loading />
  ) : (
    <div className="py-5 flex flex-col gap-5">
      <div>
        <p>
          <strong className="text-foreground">
            {t('entities.strength.fields.strength')}:
          </strong>{' '}
          {strength?.strength || '-'}
        </p>
      </div>
    </div>
  );
};

export default StrengthDetail;
