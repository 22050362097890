import axios, { AxiosError } from 'axios';
import UnauthorizedException from '../auth/exceptions/unauthorized.exception';
import EnterpriseDTO from './dto/enterprise.dto';
import PaginatedDTO from 'lib/paginated/paginated.dto';
import FindOptionsDTO from 'lib/paginated/findOptions.dto';
import UserDTO from '../user/dto/user.dto';
import CreateEnterpriseDTO from './dto/createEnterprise.dto';
import CreateUserDTO from '../user/dto/createUser.dto';
import UserGroupDTO from '../userGroup/dto/userGroup.dto';
import CreateUserGroupDTO from '../userGroup/dto/createUserGroup';

export default class EnterpriseRepository {
  private url: string = '/api/enterprise';

  async find(
    options?: FindOptionsDTO<EnterpriseDTO>,
  ): Promise<PaginatedDTO<EnterpriseDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const enterpriseResponse = await axios.get<PaginatedDTO<EnterpriseDTO>>(
      `${this.url}${stringOption}`,
    );
    return enterpriseResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<EnterpriseDTO>,
  ): Promise<EnterpriseDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const enterpriseResponse = await axios.get<EnterpriseDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return enterpriseResponse.data;
  }

  /** OLD */

  async getEnterprises(
    options?: FindOptionsDTO<EnterpriseDTO>,
  ): Promise<PaginatedDTO<EnterpriseDTO>> {
    try {
      let stringOption = '';
      if (options)
        stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
      const enterpriseResponse = await axios.get<PaginatedDTO<EnterpriseDTO>>(
        `${this.url}${stringOption}`,
      );
      return enterpriseResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async getEnterprise(
    id: string,
    options?: FindOptionsDTO<EnterpriseDTO>,
  ): Promise<EnterpriseDTO> {
    try {
      let stringOption = '';
      if (options)
        stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
      const enterpriseResponse = await axios.get<EnterpriseDTO>(
        `${this.url}/${id}${stringOption}`,
      );
      return enterpriseResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async getUsers(
    id: string,
    options?: FindOptionsDTO<UserDTO>,
  ): Promise<PaginatedDTO<UserDTO>> {
    try {
      let stringOption = '';
      if (options)
        stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
      const userEnterpriseResponse = await axios.get<PaginatedDTO<UserDTO>>(
        `${this.url}/${id}/users${stringOption}`,
      );
      return userEnterpriseResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async create(enterprise: CreateEnterpriseDTO) {
    try {
      const userEnterpriseResponse = await axios.post<EnterpriseDTO>(
        `${this.url}`,
        enterprise,
      );
      return userEnterpriseResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async edit(id: string, enterprise: CreateEnterpriseDTO) {
    try {
      const userEnterpriseResponse = await axios.put<EnterpriseDTO>(
        `${this.url}/${id}`,
        enterprise,
      );
      return userEnterpriseResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async delete(id: string) {
    await axios.delete(`${this.url}/${id}`);
  }

  async createUser(user: CreateUserDTO) {
    try {
      const createdUserResponse = await axios.post<UserDTO>(
        `${this.url}/user`,
        user,
      );
      return createdUserResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async editUser(id: string, user: UserDTO) {
    try {
      const editedUserResponse = await axios.put<UserDTO>(
        `${this.url}/user/${id}`,
        user,
      );
      return editedUserResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async getUser(
    id: string,
    options?: FindOptionsDTO<UserDTO>,
  ): Promise<UserDTO> {
    try {
      let stringOption = '';
      if (options)
        stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
      const getUserResponse = await axios.get<UserDTO>(
        `${this.url}/user/${id}${stringOption}`,
      );
      return {
        ...getUserResponse.data,
      };
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async deleteUser(id: string) {
    try {
      const deletedUserResponse = await axios.delete<UserDTO>(
        `${this.url}/user/${id}`,
      );
      return deletedUserResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async getUserGroups(enterpriseId: string, options?: FindOptionsDTO<UserDTO>) {
    try {
      let stringOption = '';
      if (options)
        stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
      const userEnterpriseResponse = await axios.get<
        PaginatedDTO<UserGroupDTO>
      >(`${this.url}/${enterpriseId}/userGroup${stringOption}`);
      return userEnterpriseResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async getUserGroup(
    id: string,
    options?: FindOptionsDTO<UserGroupDTO>,
  ): Promise<UserGroupDTO> {
    try {
      let stringOption = '';
      if (options)
        stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
      const getUserGroupResponse = await axios.get<UserGroupDTO>(
        `${this.url}/userGroup/${id}${stringOption}`,
      );
      return {
        ...getUserGroupResponse.data,
      };
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async createUserGroup(user: CreateUserGroupDTO) {
    try {
      const createdUserGroupResponse = await axios.post<UserGroupDTO>(
        `${this.url}/userGroup`,
        user,
      );
      return createdUserGroupResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async editUserGroup(id: string, userGroup: CreateUserGroupDTO) {
    try {
      const editedUserResponse = await axios.put<UserGroupDTO>(
        `${this.url}/userGroup/${id}`,
        userGroup,
      );
      return editedUserResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }

  async deleteUserGroup(id: string) {
    try {
      const deletedUserResponse = await axios.delete<UserGroupDTO>(
        `${this.url}/userGroup/${id}`,
      );
      return deletedUserResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) throw new UnauthorizedException();
      }

      throw err;
    }
  }
}
