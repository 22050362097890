import { Avatar, Select, SelectItem, Selection } from '@nextui-org/react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DocumentPermissionType } from '../type/documentPermission.type';
import DocumentUserDTO from '@modules/documentModule/documentUser/dto/documentUser.dto';
import DocumentTeamDTO from '@modules/documentModule/documentUser/dto/documentTeam.dto';
import { useTranslation } from 'react-i18next';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';

interface DocumentAccessSelectorProps {
  item: DocumentUserDTO | DocumentTeamDTO;
  viewerItems: DocumentPermissionType;
  setViewerItems: Dispatch<SetStateAction<DocumentPermissionType>>;
  editorItems: DocumentPermissionType;
  setEditorItems: Dispatch<SetStateAction<DocumentPermissionType>>;
  selected: string;
}

const DocumentAccessUserSelector = ({
  item,
  viewerItems,
  setViewerItems,
  editorItems,
  setEditorItems,
  selected,
}: DocumentAccessSelectorProps) => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [permissionSelected, setPermissionSelected] = useState<Selection>(
    new Set([selected]),
  );

  useEffect(() => {
    if (permissionSelected === 'all') return;

    const addItemToPermission = (
      permissionItems: DocumentPermissionType,
      setPermissionItems: Dispatch<SetStateAction<DocumentPermissionType>>,
    ) => {
      if (permissionItems === 'all') return;
      if (!permissionItems.includes(item)) {
        setPermissionItems((prevItems) => [
          ...(prevItems as (DocumentUserDTO | DocumentTeamDTO)[]),
          item,
        ]);
      }
    };

    const removeItemFromPermission = (
      permissionItems: DocumentPermissionType,
      setPermissionItems: Dispatch<SetStateAction<DocumentPermissionType>>,
    ) => {
      if (permissionItems === 'all') return;
      const index = permissionItems.indexOf(item);
      if (index !== -1) {
        const newItems = [...permissionItems];
        newItems.splice(index, 1);
        setPermissionItems(newItems);
      }
    };

    if (permissionSelected.has('viewer')) {
      removeItemFromPermission(editorItems, setEditorItems);
      addItemToPermission(viewerItems, setViewerItems);
    }

    if (permissionSelected.has('editor')) {
      removeItemFromPermission(viewerItems, setViewerItems);
      addItemToPermission(editorItems, setEditorItems);
    }

    if (permissionSelected.has('none')) {
      removeItemFromPermission(viewerItems, setViewerItems);
      removeItemFromPermission(editorItems, setEditorItems);
    }
  }, [permissionSelected, viewerItems, editorItems, item]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        <Avatar
          src={
            'avatar' in item && item.avatar
              ? `https://giveit-system-assets.s3.amazonaws.com/${item.avatar}`
              : undefined
          }
          name={'name' in item ? item.name : item.fullName}
          isBordered={item.id === profile?.id}
          className="flex-shrink-0"
          color={item.id === profile?.id ? 'primary' : 'secondary'}
        />
        <span className="max-w-full text-center truncate">
          {'name' in item ? item.name : item.fullName}
        </span>
      </div>
      <Select
        selectedKeys={permissionSelected}
        onSelectionChange={setPermissionSelected}
        disallowEmptySelection={true}
        variant="bordered"
        className="w-1/3"
      >
        <SelectItem key="viewer" value={'viewer'}>
          {t('user.document.browser.item.share.viewer')}
        </SelectItem>
        <SelectItem key="editor" value={'editor'}>
          {t('user.document.browser.item.share.editor')}
        </SelectItem>
        <SelectItem key="none" value={'none'}>
          {t('user.document.browser.item.share.none')}
        </SelectItem>
      </Select>
    </div>
  );
};

export default DocumentAccessUserSelector;
