import i18next from 'i18next';
import * as Yup from 'yup';

const CreateRecognitionVoteFormValidation = () => {
  return Yup.object().shape({
    to: Yup.object()
      .required(i18next.t('validations.required'))
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
    because: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    rockets: Yup.number()
      .required(i18next.t('validations.required'))
      .min(1, i18next.t('validations.min', { min: 1 })),
    strength: Yup.object()
      .required(i18next.t('validations.required'))
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
  });
};

export default CreateRecognitionVoteFormValidation;
