import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChallengeList from '@modules/voteModule/challenges/configuration/components/challenge.list';
import { useNavigate } from 'react-router-dom';

const ChallengeConfigurationPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
        Desafíos
      </h3>
      <ChallengeList />
    </div>
  );
};

export default ChallengeConfigurationPage;
