import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import DocumentDTO from '../dto/document.dto';
import DocumentRepository from '@modules/documentModule/document.repository';
import { useNavigate } from 'react-router-dom';
import DateUtil from '@utils/date';
import { DocumentSortBy } from '../enum/documentSortBy.enum';

interface DocumentProviderProps {
  children: ReactNode;
}

interface DocumentContextType {
  documents: DocumentDTO[];
  getDocuments: () => Promise<void>;
  findDocumentById: (id: string) => DocumentDTO | undefined;
  createDocument: (title: string, parent?: DocumentDTO) => Promise<void>;
  sortBy: DocumentSortBy;
  setSortBy: Dispatch<SetStateAction<DocumentSortBy>>;
  isBrowserLoading: boolean;
  isBrowserOpen: boolean;
  setIsBrowserOpen: Dispatch<SetStateAction<boolean>>;
}

const DocumentContext = createContext<DocumentContextType | undefined>(
  undefined,
);

export const DocumentProvider = ({ children }: DocumentProviderProps) => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [isBrowserLoading, setIsBrowserLoading] = useState(true);
  const [isBrowserOpen, setIsBrowserOpen] = useState(true);
  const [documentRepository] = useState(new DocumentRepository());
  const [sortBy, setSortBy] = useState<DocumentSortBy>(DocumentSortBy.Access);

  const getDocuments = async () => {
    setIsBrowserLoading(true);
    const documentsTree = await documentRepository.getTree();
    setDocuments(documentsTree);
    setIsBrowserLoading(false);
  };

  const findDocumentById = (id: string) => {
    const stack = [...documents];
    while (stack.length > 0) {
      const doc = stack.pop();
      if (doc) {
        if (doc.id === id) return doc;
        if (doc.children) stack.push(...doc.children);
      }
    }
    return undefined;
  };

  const createDocument = async (title: string, parent?: DocumentDTO) => {
    const newDoc = await documentRepository.save({
      title,
      editorAll: false,
      viewerAll: false,
      parent,
    });
    await getDocuments();
    navigate(`/enterprise/documents/edit/${newDoc.id}`);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const sortDocuments = (docs: DocumentDTO[]) => {
    const sortedDocs = docs.map((doc) => {
      if (doc.children) doc.children = sortDocuments(doc.children);
      return doc;
    });

    switch (sortBy) {
      case DocumentSortBy.TitleAsc:
        sortedDocs.sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        );
        break;
      case DocumentSortBy.TitleDesc:
        sortedDocs.sort((a, b) =>
          b.title.toLowerCase().localeCompare(a.title.toLowerCase()),
        );
        break;
      case DocumentSortBy.ModifiedDateAsc:
        sortedDocs.sort((a, b) =>
          DateUtil.dateSortFunction(a.lastModifiedDate, b.lastModifiedDate),
        );
        break;
      case DocumentSortBy.ModifiedDateDesc:
        sortedDocs.sort((a, b) =>
          DateUtil.dateSortFunction(b.lastModifiedDate, a.lastModifiedDate),
        );
        break;
      default:
        break;
    }

    return sortedDocs;
  };

  useEffect(() => {
    if (sortBy === DocumentSortBy.Access) getDocuments();
    else if (documents.length) {
      const docs = sortDocuments(documents);
      setDocuments(docs);
    }
  }, [sortBy]);

  return (
    <DocumentContext.Provider
      value={{
        documents,
        getDocuments,
        findDocumentById,
        createDocument,
        sortBy,
        setSortBy,
        isBrowserLoading,
        isBrowserOpen,
        setIsBrowserOpen,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocumentContext = (): DocumentContextType => {
  const context = useContext(DocumentContext);
  if (!context)
    throw new Error(
      'useDocumentContext must be used within a DocumentProvider',
    );
  return context;
};
