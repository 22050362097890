import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Tab, Tabs } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileAnalityc from './private/analityc/profileAnalityc';
import ProfileConfig from './private/config/profileConfig';

const PrivateProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div>
      <div className="flex justify-between items-end">
        <h3 className="text-primary flex gap-5 items-center">
          {' '}
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />{' '}
          {t('user.profile.private.view')}
        </h3>
      </div>
      <Card className="shadow-none">
        <CardBody className="py-3 px-4">
          <Tabs>
            <Tab
              key={'analityc'}
              title={t('user.profile.private.tabs.analityc')}
            >
              <ProfileAnalityc userId={id as string} />
            </Tab>
            <Tab
              key={'personal'}
              title={t('user.profile.private.tabs.personal')}
            >
              <ProfileConfig userId={id as string} />
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </div>
  );
};

export default PrivateProfile;
