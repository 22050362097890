import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyResultForm from '@modules/objectiveModule/keyResult/components/keyResultForm';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addKeyResults,
  clear,
  deleteKeyResult,
  getObjective,
} from '@modules/objectiveModule/objective/objective.reducer';
import { useDispatch, useSelector } from 'react-redux';
import CreateKeyResultDTO from '@modules/objectiveModule/keyResult/dto/createKeyResult.dto';
import KeyResultAddedList from '@modules/objectiveModule/keyResult/components/keyResultAddedList';
import CreateObjectiveDTO from '@modules/objectiveModule/objective/dto/createObjective.dto';
import KeyResultRepository from '@modules/objectiveModule/keyResult/keyResult.repository';
import KeyResultDTO from '@modules/objectiveModule/keyResult/dto/keyResult.dto';

const KeyResultAddToObjective = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const objective = useSelector(getObjective) as CreateObjectiveDTO;

  const handleAddKeyResult = (values: CreateKeyResultDTO) => {
    dispatch(addKeyResults(values));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDeleteKeyResult = (index: number) => {
    dispatch(deleteKeyResult(index));
  };

  const handleSubmit = async (values: CreateKeyResultDTO) => {
    const keyResultsAddeds = new Array(...(objective?.keyResults || []));
    keyResultsAddeds.push(values);
    const keyResultsToAdd: Array<Promise<KeyResultDTO>> = [];
    keyResultsAddeds.map((keyResult) =>
      keyResultsToAdd.push(
        new KeyResultRepository().save({
          objective: {
            id: id as string,
          },
          ...keyResult,
          expectedValue: Number(keyResult.expectedValue),
          initialValue: Number(keyResult.initialValue),
        }),
      ),
    );
    await Promise.all(keyResultsToAdd);
    dispatch(clear());
    navigate(`/enterprise/objective/view/${id}`);
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.keyResult.add.title')}
      </h3>
      <p>{t('user.objective.add.subTitle')}</p>
      <KeyResultAddedList
        keyResults={objective?.keyResults || []}
        handleDelete={handleDeleteKeyResult}
      />
      <KeyResultForm
        onAddAnother={handleAddKeyResult}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
export default KeyResultAddToObjective;
