import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  Skeleton,
  useDisclosure,
} from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pulseIcon from '@assets/icons/pulseIcon.svg';
import PulseSurveyRepository from '../../pulseSurvey/pulseSurvey.repository';
import DateUtil from '@utils/date';
import infoIcon from '@assets/icons/infoicon.svg';
import goodIcon from '@assets/icons/goodicon.svg';
import neutralIcon from '@assets/icons/neutralIcon.svg';
import badIcon from '@assets/icons/bardicon.svg';
import ActivePulseSurveyDTO from '../../pulseSurvey/dto/activePulseSurvey.dto';
import PulseVoteType from '../enum/pulseVoteType.enum';
import PulseVoteRepository from '../pulseVote.repository';

interface VotedActivePulseSurveyDTO extends ActivePulseSurveyDTO {
  selected?: PulseVoteType | null;
}

interface PulseCardProps {
  open: boolean;
}

const PulseCard = ({ open }: PulseCardProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [daysDiff, setDaysDiff] = useState<number>(0);
  const [activesSurveys, setActivesSurveys] = useState<
    VotedActivePulseSurveyDTO[]
  >([]);

  const getActivesSurveys = async () => {
    setLoading(true);
    const activesSurveysData = await new PulseSurveyRepository().getActives();
    activesSurveysData.sort((a, b) => {
      const fechaA = a.dateTo.getTime();
      const fechaB = b.dateTo.getTime();

      if (fechaA < fechaB) {
        return -1;
      } else if (fechaA > fechaB) {
        return 1;
      } else {
        return 0;
      }
    });
    setDaysDiff(
      activesSurveysData.length > 0
        ? DateUtil.dateDaysDiff(
            activesSurveysData[0].dateFrom,
            activesSurveysData[0].dateTo,
          )
        : -1,
    );
    setActivesSurveys(activesSurveysData);
    if (activesSurveysData.length === 0) onClose();
    setLoading(false);
  };

  useEffect(() => {
    getActivesSurveys();
    if (open) onOpen();
  }, [open]);

  const handlerSelect = (index: number, value: PulseVoteType) => {
    const copy = Object.assign(
      [],
      activesSurveys,
    ) as VotedActivePulseSurveyDTO[];
    copy[index].selected = value;
    setActivesSurveys(copy);
  };

  const handlerSubmit = async (index: number) => {
    if (activesSurveys[index].selected) {
      await new PulseVoteRepository().vote({
        survey: { id: activesSurveys[index].id },
        vote: activesSurveys[index].selected as PulseVoteType,
      });
      getActivesSurveys();
    }
  };

  return (
    <>
      <Skeleton
        isLoaded={!isLoading}
        className="rounded-xl w-full h-full"
        classNames={{ content: 'h-full' }}
      >
        <Card className="shadow-none w-full h-full bg-content4">
          <CardBody
            className="cursor-pointer w-full flex-row gap-5 p-3 items-center"
            onClick={() => {
              daysDiff > -1 && onOpen();
            }}
          >
            <img src={pulseIcon} className="size-[90px] lg:size-16 m-0" />
            <div className="flex flex-col justify-between">
              <div className="flex gap-2 items-center">
                <div
                  className={`flex-shrink-0 rounded-full ${
                    daysDiff === -1 ? 'bg-success' : 'bg-warning'
                  } w-2 h-2`}
                ></div>
                <span className="text-default-500 text-sm">
                  {daysDiff === -1
                    ? t('user.dashboard.surveys.card.alreadyVote')
                    : daysDiff > 1
                      ? t('user.dashboard.surveys.card.dueDate', {
                          days: daysDiff,
                        })
                      : t('user.dashboard.surveys.card.dueDateDay', {
                          days: daysDiff,
                        })}
                </span>
              </div>
              <span className="font-bold">
                {t('user.dashboard.surveys.pulse.title')}
              </span>
              <span className="text-default-500 text-sm">
                {t('user.dashboard.surveys.pulse.description')}
              </span>
            </div>
          </CardBody>
        </Card>
      </Skeleton>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="5xl"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalBody className="py-5">
            <span className="text-default-400 uppercase">
              {t('user.dashboard.surveys.pulse.title')}
            </span>
            {activesSurveys.map((activeSurvey, index) => (
              <div key={activeSurvey.id}>
                <div className="flex flex-col gap-1">
                  <span className="text-xl font-bold">
                    {activeSurvey.question}
                  </span>
                  <span className="text-default-600">
                    {t('user.dashboard.surveys.pulse.selectOption')}
                  </span>
                </div>
                <div className="flex flex-col md:flex-row gap-2 md:gap-5 py-5">
                  <div
                    className={`flex md:w-1/3 flex-col gap-2 md:gap-5 items-center p-5 bg-success-100 rounded cursor-pointer ${
                      activeSurvey.selected === PulseVoteType.AGREE &&
                      'border border-success-600'
                    }`}
                    onClick={() => handlerSelect(index, PulseVoteType.AGREE)}
                  >
                    <img src={goodIcon} />
                    <p className="text-md md:text-lg font-semibold text-default-700 dark:text-foreground-700 text-center">
                      {t('user.dashboard.surveys.pulse.options.AGREE')}
                    </p>
                  </div>
                  <div
                    className={`flex md:w-1/3 flex-col gap-2 md:gap-5 items-center p-5 bg-warning-100 rounded cursor-pointer ${
                      activeSurvey.selected === PulseVoteType.NOT_QUITE &&
                      'border border-warning-600'
                    }`}
                    onClick={() =>
                      handlerSelect(index, PulseVoteType.NOT_QUITE)
                    }
                  >
                    <img src={neutralIcon} />
                    <p className="text-md md:text-lg font-semibold text-default-700 dark:text-foreground-700 text-center">
                      {t('user.dashboard.surveys.pulse.options.NOT_QUITE')}
                    </p>
                  </div>
                  <div
                    className={`flex md:w-1/3 flex-col gap-2 md:gap-5 items-center p-5 bg-danger-100 rounded cursor-pointer ${
                      activeSurvey.selected === PulseVoteType.NOT_AGREE &&
                      'border border-danger-600'
                    }`}
                    onClick={() =>
                      handlerSelect(index, PulseVoteType.NOT_AGREE)
                    }
                  >
                    <img src={badIcon} />
                    <p className="text-md md:text-lg font-semibold text-default-700 dark:text-foreground-700 text-center">
                      {t('user.dashboard.surveys.pulse.options.NOT_AGREE')}
                    </p>
                  </div>
                </div>
                <Button
                  variant="flat"
                  className="w-full"
                  color="primary"
                  isLoading={isLoading}
                  onClick={() => handlerSubmit(index)}
                >
                  {t('user.dashboard.surveys.pulse.send')}
                </Button>
              </div>
            ))}
            <div className="flex gap-5 items-start pt-3">
              <img src={infoIcon} />
              <div>
                <p className="font-bold text-default-700 text-lg">
                  {t('user.dashboard.surveys.pulse.why')}
                </p>
                <p className="text-default-500">
                  {t('user.dashboard.surveys.pulse.because')}
                </p>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PulseCard;
