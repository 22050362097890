import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { Chip } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import ObjectiveDTO from '../dto/objective.dto';
import DateUtil from '@utils/date';
import { DeepPartial } from '@lib/common/deepPartial';
import ObjectivePeriodFilter from './objectivePeriodFilter';
import ObjectiveStatus from '../enum/objectiveStatus.enum';
import ObjectiveStatusFilter from './objectiveStatusFilter';
import ObjectiveManagerFilter from './objectiveManagerFilter';
import ObjectiveCategoryFilter from './objectiveCategoryFilter';
import Input from '@components/input/input';

interface ObjectiveFilterProps {
  options: FindOptionsDTO<ObjectiveDTO>;
  onChange: (options: FindOptionsDTO<ObjectiveDTO>) => void;
}

const ObjectiveFilter = (props: ObjectiveFilterProps) => {
  const { t } = useTranslation();

  const handlerNameFilter = (text: string) => {
    if (text)
      props.onChange({
        ...props.options,
        where: {
          ...props.options.where,
          name: `lk=${text}`,
        },
      });
    else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, ...where } = props.options
        .where as DeepPartial<ObjectiveDTO>;
      props.onChange({
        ...props.options,
        where: {
          ...where,
        },
      });
    }
  };

  const handlerAddPeriod = () => {
    props.onChange({
      ...props.options,
      where: {
        ...props.options.where,
        periodFrom: `>=${DateUtil.dateForInput(new Date())}`,
        periodTo: `<=${DateUtil.dateForInput(new Date())}`,
      },
    });
  };

  const handlerModifyPeriod = (dateFrom: Date, dateTo: Date) => {
    props.onChange({
      ...props.options,
      where: {
        ...props.options.where,
        periodFrom: `<=${DateUtil.dateForInput(dateFrom)}`,
        periodTo: `>=${DateUtil.dateForInput(dateTo)}`,
      },
    });
  };

  const handlerDeletePeriod = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { periodFrom, periodTo, ...where } = props.options
      .where as DeepPartial<ObjectiveDTO>;
    props.onChange({
      ...props.options,
      where: {
        ...where,
      },
    });
  };

  const handlerAddStatus = () => {
    props.onChange({
      ...props.options,
      where: {
        ...props.options.where,
        status: ObjectiveStatus.ON_TIME,
      },
    });
  };

  const handlerModifyStatus = (status: ObjectiveStatus) => {
    props.onChange({
      ...props.options,
      where: {
        ...props.options.where,
        status,
      },
    });
  };

  const handlerDeleteStatus = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status, ...where } = props.options
      .where as DeepPartial<ObjectiveDTO>;
    props.onChange({
      ...props.options,
      where: {
        ...where,
      },
    });
  };

  const handlerAddManager = () => {
    props.onChange({
      ...props.options,
      where: {
        ...props.options.where,
        manager: {},
      },
    });
  };

  const handlerModifyManager = (manager: string) => {
    if (!manager)
      props.onChange({
        ...props.options,
        where: {
          ...props.options.where,
          manager: {},
        },
      });
    else
      props.onChange({
        ...props.options,
        where: {
          ...props.options.where,
          manager: {
            id: manager,
          },
        },
      });
  };

  const handlerDeleteManager = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { manager, ...where } = props.options
      .where as DeepPartial<ObjectiveDTO>;
    props.onChange({
      ...props.options,
      where: {
        ...where,
      },
    });
  };

  const handlerAddCategories = () => {
    props.onChange({
      ...props.options,
      where: {
        ...props.options.where,
        categories: [],
      },
    });
  };

  const handlerModifyCategories = (categories: { id: string }[]) => {
    if (categories.length === 0)
      props.onChange({
        ...props.options,
        where: {
          ...props.options.where,
          categories: [],
        },
      });
    else
      props.onChange({
        ...props.options,
        where: {
          ...props.options.where,
          categories: categories.map((category) => {
            return { id: category.id };
          }),
        },
      });
  };

  const handlerDeleteCategories = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { categories, ...where } = props.options
      .where as DeepPartial<ObjectiveDTO>;
    props.onChange({
      ...props.options,
      where: {
        ...where,
      },
    });
  };

  return (
    <div className="py-2">
      <Input
        label={t('entities.objective.table.find')}
        onChange={(event) => handlerNameFilter(event.target.value)}
      />
      <div className="pt-3 flex flex-wrap gap-3">
        <Chip
          onClick={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.periodFrom
              ? handlerDeletePeriod
              : handlerAddPeriod
          }
          className="cursor-pointer"
          color="primary"
          variant={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.periodFrom
              ? 'shadow'
              : 'flat'
          }
          startContent={
            <FontAwesomeIcon
              icon={
                (props.options.where as DeepPartial<ObjectiveDTO>)?.periodFrom
                  ? faTimes
                  : faPlusCircle
              }
            />
          }
        >
          {t('entities.objective.fields.period')}
        </Chip>
        <Chip
          onClick={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.status
              ? handlerDeleteStatus
              : handlerAddStatus
          }
          className="cursor-pointer"
          color="primary"
          variant={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.status
              ? 'shadow'
              : 'flat'
          }
          startContent={
            <FontAwesomeIcon
              icon={
                (props.options.where as DeepPartial<ObjectiveDTO>)?.status
                  ? faTimes
                  : faPlusCircle
              }
            />
          }
        >
          {t('entities.objective.fields.status')}
        </Chip>
        <Chip
          onClick={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.manager
              ? handlerDeleteManager
              : handlerAddManager
          }
          className="cursor-pointer"
          color="primary"
          variant={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.manager
              ? 'shadow'
              : 'flat'
          }
          startContent={
            <FontAwesomeIcon
              icon={
                (props.options.where as DeepPartial<ObjectiveDTO>)?.manager
                  ? faTimes
                  : faPlusCircle
              }
            />
          }
        >
          {t('entities.objective.fields.manager')}
        </Chip>
        <Chip
          onClick={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.categories
              ? handlerDeleteCategories
              : handlerAddCategories
          }
          className="cursor-pointer"
          color="primary"
          variant={
            (props.options.where as DeepPartial<ObjectiveDTO>)?.categories
              ? 'shadow'
              : 'flat'
          }
          startContent={
            <FontAwesomeIcon
              icon={
                (props.options.where as DeepPartial<ObjectiveDTO>)?.categories
                  ? faTimes
                  : faPlusCircle
              }
            />
          }
        >
          {t('entities.objective.fields.categories')}
        </Chip>
      </div>
      <div>
        {(props.options.where as DeepPartial<ObjectiveDTO>)?.periodFrom && (
          <ObjectivePeriodFilter onChange={handlerModifyPeriod} />
        )}
        {(props.options.where as DeepPartial<ObjectiveDTO>)?.status && (
          <ObjectiveStatusFilter onChange={handlerModifyStatus} />
        )}
        {(props.options.where as DeepPartial<ObjectiveDTO>)?.manager && (
          <ObjectiveManagerFilter onChange={handlerModifyManager} />
        )}
        {(props.options.where as DeepPartial<ObjectiveDTO>)?.categories && (
          <ObjectiveCategoryFilter onChange={handlerModifyCategories} />
        )}
      </div>
    </div>
  );
};

export default ObjectiveFilter;
