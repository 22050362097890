import IconProps from './common/icon.interface';

const PinIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.5 12.2115L17.2884 14V15.5H12.75V20.9999L12 21.7499L11.25 20.9999V15.5H6.71161V14L8.50003 12.2115V4.99998H7.50003V3.5H16.5V4.99998H15.5V12.2115ZM8.85001 14H15.15L14 12.85V4.99998H10V12.85L8.85001 14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PinIcon;
