import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import DocumentUserDTO from './dto/documentUser.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';

export default class DocumentUserRepository {
  private url: string = '/api/documentUser';

  async find(
    options?: FindOptionsDTO<DocumentUserDTO>,
  ): Promise<PaginatedDTO<DocumentUserDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const documentUserResponse = await axios.get<PaginatedDTO<DocumentUserDTO>>(
      `${this.url}${stringOption}`,
    );
    return documentUserResponse.data;
  }
}
