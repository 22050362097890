import Table from '@components/table/table';
import FindOptionsDTO from 'lib/paginated/findOptions.dto';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import UserFileDTO from '../dto/userFile.dto';
import UserFileRepository from '../userFile.repository';
import PaginatedDTO from '@lib/paginated/paginated.dto';

interface UserFileListProps {
  options?: FindOptionsDTO<UserFileDTO>;
  configurations?: {
    add: {
      isAllowed: boolean;
    };
    view: {
      isAllowed: boolean;
    };
    edit: {
      isAllowed: boolean;
    };
    delete: {
      isAllowed: boolean;
    };
  };
}

const UserFileList = ({
  options,
  configurations = {
    add: {
      isAllowed: true,
    },
    view: {
      isAllowed: true,
    },
    edit: {
      isAllowed: true,
    },
    delete: {
      isAllowed: true,
    },
  },
}: UserFileListProps) => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const getUsers = useCallback(
    async (
      option?: FindOptionsDTO<UserFileDTO>,
    ): Promise<
      PaginatedDTO<{ firstName: string; lastName: string } & UserFileDTO>
    > => {
      const userFiles = await new UserFileRepository().find({
        ...option,
        where: {
          ...option?.where,
          user: {
            deleteDate: '=null',
            firstName: '!=null',
            lastName: '!=null',
          },
        },
        relations: ['user'],
      });

      userFiles.results = userFiles.results.map((userFile) => {
        return {
          firstName: userFile.user.firstName,
          lastName: userFile.user.lastName,
          ...userFile,
        };
      });
      return userFiles as PaginatedDTO<
        { firstName: string; lastName: string } & UserFileDTO
      >;
    },
    [],
  );

  const handleSearcher = useCallback(
    (textSearched: string, option?: FindOptionsDTO<UserFileDTO>) => {
      return {
        ...option,
        where: [
          { user: { firstName: `lk=${textSearched}` } },
          { user: { lastName: `lk=${textSearched}` } },
        ],
      };
    },
    [],
  );

  const handleEditButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/userFile/edit/${id}`);
    },
    [navigate],
  );

  const handleViewButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/userFile/view/${id}`);
    },
    [navigate],
  );

  return (
    <Table<{ firstName: string; lastName: string } & UserFileDTO>
      headers={['firstName', 'lastName']}
      handlerRequest={(option) => getUsers({ ...options, ...option })}
      handlerSearcher={handleSearcher}
      options={options}
      configurations={{
        translationLabel: 'userFile',
        add: {
          handler: () => {},
          isAllowed: false,
        },
        edit: {
          handler: handleEditButton,
          isAllowed:
            configurations.edit.isAllowed &&
            Boolean(
              profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.USER &&
                  permission.methods === AllowedMethods.MODIFY,
              ),
            ),
        },
        view: {
          handler: handleViewButton,
          isAllowed:
            configurations.view.isAllowed &&
            Boolean(
              profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.USER &&
                  permission.methods === AllowedMethods.VIEW,
              ),
            ),
        },
        delete: {
          field: 'employeeFileNumber',
          isAllowed: false,
          handler: () => Promise.reject(),
        },
      }}
    />
  );
};

export default UserFileList;
