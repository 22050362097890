import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import DocumentDTO from '../dto/document.dto';
import PlusIcon from '@components/icons/plus.icon';
import OptionsIcon from '@components/icons/options.icon';
import ChevronIcon from '@components/icons/chevron.icon';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@components/icons/edit.icon';
import DeleteIcon from '@components/icons/delete.icon';
import ShareIcon from '@components/icons/share.icon';
import DocumentAccessModal from './documentAccessModal';
import DocumentNameModal from './documentNameModal';
import { useDocumentContext } from '../context/document.context';
import DocumentRepository from '@modules/documentModule/document.repository';
import { useTranslation } from 'react-i18next';

interface SingleDocumentBrowserProps {
  document: DocumentDTO;
  parent?: DocumentDTO;
  displayParent?: boolean;
}

const DocumentBrowserItem = ({
  document,
  parent,
  displayParent = false,
}: SingleDocumentBrowserProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [displayChildren, setDisplayChildren] = useState(false);
  const renameModal = useDisclosure();
  const nestedDocTitleModal = useDisclosure();
  const accessModal = useDisclosure();
  const deleteModal = useDisclosure();
  const { getDocuments } = useDocumentContext();
  const [documentRepository] = useState(new DocumentRepository());

  useEffect(() => {
    if (document.id === id) setDisplayChildren(true);
  }, [id]);

  const deleteDocument = async (id: string) => {
    await documentRepository.delete(id);
    getDocuments();
  };

  return (
    <>
      {displayParent && parent && <DocumentBrowserItem document={parent} />}
      <div
        className={`flex justify-between px-2 rounded-lg ${document.id === id ? 'bg-primary-100' : ''}`}
      >
        {document.onlyName ? (
          <p>{document.title}</p>
        ) : (
          <p
            className="cursor-pointer"
            onClick={() =>
              navigate(`/enterprise/documents/view/${document.id}`)
            }
          >
            {document.title}
          </p>
        )}
        <div className="flex items-center">
          {!document.onlyName && !document.onlyView && (
            <>
              <span
                className="cursor-pointer"
                onClick={nestedDocTitleModal.onOpen}
              >
                <PlusIcon className="w-6 h-6" />
              </span>
              <Dropdown>
                <DropdownTrigger>
                  <span className="cursor-pointer">
                    <OptionsIcon />
                  </span>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownSection showDivider>
                    <DropdownItem
                      onPress={() => renameModal.onOpen()}
                      startContent={<EditIcon />}
                    >
                      {t('user.document.browser.item.rename')}
                    </DropdownItem>
                    <DropdownItem
                      onPress={() => accessModal.onOpen()}
                      startContent={<ShareIcon className="w-6 h-6" />}
                    >
                      {t('user.document.browser.item.share.option')}
                    </DropdownItem>
                  </DropdownSection>
                  <DropdownSection>
                    <DropdownItem
                      onPress={() => deleteModal.onOpen()}
                      startContent={<DeleteIcon />}
                      className="text-danger"
                    >
                      {t('user.document.browser.item.delete.option')}
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </>
          )}
          {document.children?.length ? (
            <span
              className={`cursor-pointer ${displayChildren ? 'rotate-180' : ''}`}
              onClick={() => setDisplayChildren(!displayChildren)}
            >
              <ChevronIcon />
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* Children display */}
      <div className={`${displayChildren ? 'block' : 'hidden'} text-sm ml-5`}>
        {document.children?.length ? (
          document.children.map((child) => {
            if (child)
              return <DocumentBrowserItem document={child} key={child.id} />;
          })
        ) : (
          <></>
        )}
      </div>
      <DocumentNameModal
        isOpen={renameModal.isOpen}
        onOpenChange={renameModal.onOpenChange}
        document={document}
      />
      <DocumentNameModal
        isOpen={nestedDocTitleModal.isOpen}
        onOpenChange={nestedDocTitleModal.onOpenChange}
        parent={document}
      />
      <DocumentAccessModal
        isOpen={accessModal.isOpen}
        onOpenChange={accessModal.onOpenChange}
        document={document}
      />
      <Modal
        isOpen={deleteModal.isOpen}
        onOpenChange={deleteModal.onOpenChange}
        classNames={{
          base: 'bg-danger-100',
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="items-center gap-2">
                <span className="rounded-full bg-danger flex items-center justify-center aspect-square p-1">
                  <DeleteIcon className="text-white" />
                </span>{' '}
                {t('user.document.browser.item.delete.title')}
              </ModalHeader>
              <ModalBody>
                {t('user.document.browser.item.delete.text')}
              </ModalBody>
              <ModalFooter className="justify-center">
                <Button onPress={onClose} color="danger" variant="bordered">
                  {t('user.document.browser.item.delete.cancel')}
                </Button>
                <Button
                  onPress={() => {
                    deleteDocument(document.id);
                    onClose();
                  }}
                  color="danger"
                >
                  {t('user.document.browser.item.delete.confirm')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentBrowserItem;
