import {
  Button,
  Card,
  CardBody,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationType from '../enum/notificationTypes.enum';
import NotificationRepository from '../notification.repository';
import NotificationConfigurationsDTO from '../dto/notificationConfigurations.dto';

const NotificationsSelection = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [preferences, setPreferences] = useState<string[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const [notificationRepository] = useState(new NotificationRepository());

  const unsubscribeFromNotification = async (unsubscribe: string) => {
    setIsLoading(true);
    const configurations = (await notificationRepository.findMine()).map(
      (config) => {
        return config.notificationType;
      },
    );
    setPreferences(
      configurations.filter((notification) => notification !== unsubscribe),
    );
    await notificationRepository.save({
      notificationTypes: configurations,
    } as NotificationConfigurationsDTO);
    onOpen();
    setIsLoading(false);
  };

  const getNotificationsPreferences = async () => {
    setIsLoading(true);
    const configurations = await notificationRepository.findMine();
    setPreferences(
      configurations.map((config) => {
        return config.notificationType;
      }),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const unsubscribe = queryParams.get('unsubscribe');
    if (unsubscribe) unsubscribeFromNotification(unsubscribe);
    else getNotificationsPreferences();
  }, []);

  const savePreferences = async () => {
    setIsButtonLoading(true);
    await notificationRepository.save({
      notificationTypes: preferences,
    } as NotificationConfigurationsDTO);
    setIsButtonLoading(false);
    navigate(-1);
  };

  return (
    <>
      <Card className="shadow-none overflow-visible mb-5">
        <CardBody className="overflow-visible">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <CheckboxGroup
                value={preferences}
                onValueChange={setPreferences}
                label={t('entities.notifications.subtitle')}
              >
                <Checkbox value={NotificationType.WEEKLY_SUMMARY}>
                  {t('entities.notifications.notifications.weeklyUpdate')}
                </Checkbox>
                <Checkbox value={NotificationType.CHALLENGE_FINISHED}>
                  {t('entities.notifications.notifications.challengeResults')}
                </Checkbox>
                <Checkbox value={NotificationType.CHALLENGE_FINISHED_WINNER}>
                  {t('entities.notifications.notifications.challengeWinner')}
                </Checkbox>
                <Checkbox value={NotificationType.TAGGED_USER}>
                  {t('entities.notifications.notifications.taggedInPost')}
                </Checkbox>
                <Checkbox value={NotificationType.RECOGNITION_SUMMARY}>
                  {t(
                    'entities.notifications.notifications.recognitionReceived',
                  )}
                </Checkbox>
                <Checkbox value={NotificationType.NEW_LEVEL}>
                  {t('entities.notifications.notifications.levelUp')}
                </Checkbox>
                <Checkbox value={NotificationType.ATTACHED_POST}>
                  {t('entities.notifications.notifications.pinnedPost')}
                </Checkbox>
              </CheckboxGroup>
              <Button
                onPress={savePreferences}
                isLoading={isButtonLoading}
                color="primary"
                className="w-fit mt-5"
              >
                {t('entities.notifications.save')}
              </Button>
            </>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                {t('entities.notifications.modal.title')}
              </ModalHeader>
              <ModalBody>{t('entities.notifications.modal.text')}</ModalBody>
              <ModalFooter>
                <Button onPress={onClose} color="primary">
                  {t('entities.notifications.modal.continue')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default NotificationsSelection;
