import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ObjectiveUserDTO from '@modules/objectiveModule/objectiveUser/dto/objectiveUser.dto';
import ObjectiveUserRepository from '@modules/objectiveModule/objectiveUser/objectiveUser.repository';
import { Autocomplete, AutocompleteItem, User } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ObjectiveManagerFilterProps {
  onChange: (manager: string) => void;
}

const ObjectiveManagerFilter = (props: ObjectiveManagerFilterProps) => {
  const { t } = useTranslation();
  const [selectedManager, setSelectedManager] = useState<string>('');
  const [users, setUsers] = useState<ObjectiveUserDTO[]>([]);

  const getUsers = async (options?: FindOptionsDTO<ObjectiveUserDTO>) => {
    const users = await new ObjectiveUserRepository().find(options);
    setUsers(users.results);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="py-3">
      <Autocomplete
        items={users}
        label={t('entities.objective.fields.manager')}
        startContent={
          <FontAwesomeIcon icon={faSearch} className="text-default-400" />
        }
        onSelectionChange={(e) => {
          setSelectedManager((e || '').toString());
          props.onChange((e || '').toString());
        }}
        onInputChange={(e) => {
          getUsers({ where: { fullName: `lk=${e}` } });
        }}
        selectedKey={selectedManager}
        inputProps={{
          classNames: {
            inputWrapper:
              'border-1 border-default shadow-none bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
          },
        }}
      >
        {(item) => (
          <AutocompleteItem key={item.id} textValue={item.fullName}>
            <User
              name={item.fullName}
              description={item.email}
              avatarProps={{
                src:
                  item?.avatar &&
                  `https://giveit-system-assets.s3.amazonaws.com/${item?.avatar}`,
              }}
            />
          </AutocompleteItem>
        )}
      </Autocomplete>
    </div>
  );
};

export default ObjectiveManagerFilter;
