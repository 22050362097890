type AlertType = 'danger' | 'warning' | 'info';

interface AlertProps {
  type: AlertType;
  children: string;
}

const Alert = (props: AlertProps) => {
  const classNames: { [key in AlertType]: string } = {
    danger:
      'bg-danger-50 dark:bg-danger-700 border border-danger-500 text-danger-500 rounded px-3 py-2',
    warning:
      'bg-warning-50 dark:bg-warning-700 border border-warning-500 text-warning-500 rounded px-3 py-2',
    info: 'bg-info-50 dark:bg-info-700 border border-info-500 text-info-500 rounded px-3 py-2',
  };

  return (
    <div className={`text-sm ${classNames[props.type]}`}>{props.children}</div>
  );
};

export default Alert;
