import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import DateUtil from '@utils/date';
import PulseSurveyDTO from './dto/pulseSurvey.dto';
import CreatePulseSurveyDTO from './dto/createPulseSurvey.dto';
import UpdatePulseSurveyDTO from './dto/updatePulseSurvey.dto';
import ActivePulseSurveyDTO from './dto/activePulseSurvey.dto';

export default class PulseSurveyRepository {
  private url: string = '/api/pulseSurvey';

  async find(
    options?: FindOptionsDTO<PulseSurveyDTO>,
  ): Promise<PaginatedDTO<PulseSurveyDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const pulseResponse = await axios.get<PaginatedDTO<PulseSurveyDTO>>(
      `${this.url}${stringOption}`,
    );
    pulseResponse.data.results.forEach((pulseSurvey) => {
      pulseSurvey.dateFrom = DateUtil.stringToDate(
        String(pulseSurvey.dateFrom),
      );
      pulseSurvey.dateTo = DateUtil.stringToDate(String(pulseSurvey.dateTo));
      pulseSurvey.isActive =
        new Date() > pulseSurvey.dateFrom && new Date() < pulseSurvey.dateTo;
    });
    return pulseResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<PulseSurveyDTO>,
  ): Promise<PulseSurveyDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const pulseResponse = await axios.get<PulseSurveyDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    pulseResponse.data.dateFrom = DateUtil.stringToDate(
      String(pulseResponse.data.dateFrom),
    );
    pulseResponse.data.dateTo = DateUtil.stringToDate(
      String(pulseResponse.data.dateTo),
    );
    pulseResponse.data.isActive =
      new Date() > pulseResponse.data.dateFrom &&
      new Date() < pulseResponse.data.dateTo;
    return pulseResponse.data;
  }

  async save(pulseSurvey: CreatePulseSurveyDTO) {
    const createdPulseResponse = await axios.post<PulseSurveyDTO>(
      `${this.url}`,
      pulseSurvey,
    );
    return createdPulseResponse.data;
  }

  async update(id: string, changes: UpdatePulseSurveyDTO) {
    const updatedPulseResponse = await axios.put<PulseSurveyDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedPulseResponse.data;
  }

  async delete(id: string) {
    const deletedPulseResponse = await axios.delete<PulseSurveyDTO>(
      `${this.url}/${id}`,
    );
    return deletedPulseResponse.data;
  }

  async getActives() {
    const pulseResponse = await axios.get<ActivePulseSurveyDTO[]>(
      `${this.url}/actives`,
    );
    pulseResponse.data.forEach((pulseSurvey) => {
      pulseSurvey.dateFrom = DateUtil.stringToDate(
        String(pulseSurvey.dateFrom),
      );
      pulseSurvey.dateTo = DateUtil.stringToDate(String(pulseSurvey.dateTo));
    });
    return pulseResponse.data;
  }
}
