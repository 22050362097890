import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileAnalityc from './private/analityc/profileAnalityc';

const PublicProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div>
      <div className="flex justify-between items-end">
        <h3 className="text-primary flex gap-5 items-center">
          {' '}
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />{' '}
          {t('user.profile.private.view')}
        </h3>
      </div>
      <Card className="shadow-none">
        <CardBody className="py-3 px-4">
          <ProfileAnalityc userId={id as string} />
        </CardBody>
      </Card>
    </div>
  );
};

export default PublicProfile;
