import React from 'react';
import ReactEChart from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@nextui-org/react';
import ObjectiveRepository from '@modules/objectiveModule/objective/objective.repository';
import DateUtil from '@utils/date';
import { QuartersType } from '@lib/type/quarters.type';

interface ObjectiveProgressProps {
  period: QuartersType;
}

const ObjectiveProgress = (props: ObjectiveProgressProps) => {
  const { t } = useTranslation();
  const periodDates = DateUtil.quarterToDate(props.period);
  const [progress, setProgress] = useState(0);
  const [statuses, setStatuses] = useState({
    COMPLETED: 0,
    PENDING: 0,
    NOT_COMPLETED: 0,
  });

  const calculateProgress = async () => {
    const objectives = await new ObjectiveRepository().find({
      where: [
        {
          periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ],
    });

    let progressSum = 0;
    const objectiveStatuses = {
      COMPLETED: 0,
      PENDING: 0,
      NOT_COMPLETED: 0,
    };

    objectives.results.forEach((obj) => {
      progressSum += Number(obj.progress);

      if (obj.progress >= 100) objectiveStatuses.COMPLETED++;
      else if (obj.periodTo <= new Date()) objectiveStatuses.NOT_COMPLETED++;
      else objectiveStatuses.PENDING++;
    });

    progressSum = progressSum / objectives.results.length;
    setStatuses(objectiveStatuses);
    setProgress(progressSum);
  };

  useEffect(() => {
    calculateProgress();
  }, [props.period]);

  const percentColor = (
    percent: number,
  ): { class: string; color: string; percent: string } => {
    if (isNaN(percent) || percent === 0)
      return {
        class: 'text-gray-400',
        color: '#9ca3af',
        percent: `0%`,
      };

    if (percent >= 70)
      return {
        class: 'text-success',
        color: '#17c964',
        percent: `${percent.toFixed(2)}%`,
      };

    if (percent >= 30)
      return {
        class: 'text-warning',
        color: '#f5a524',
        percent: `${percent.toFixed(2)}%`,
      };

    return {
      class: 'text-danger',
      color: '#f31260',
      percent: `${percent.toFixed(2)}%`,
    };
  };

  const calculatedPercents = percentColor(progress);

  return (
    <div className="flex flex-col items-center gap-5">
      <div className="w-full">
        <div className="max-h-44">
          <ReactEChart
            option={{
              series: [
                {
                  type: 'gauge',
                  startAngle: 180,
                  endAngle: 0,
                  min: 0,
                  max: 100,
                  splitNumber: 10,
                  itemStyle: {
                    color: calculatedPercents.color,
                  },
                  progress: {
                    show: true,
                    roundCap: true,
                    width: 18,
                  },
                  pointer: {
                    icon: 'path://M3.92727 24.6734C3.51722 24.8194 3.06862 24.8776 2.60774 24.8275C0.989967 24.6517 -0.168597 23.2078 0.0200879 21.6026L1.57366 8.38497L2.13091 3.64376L2.50866 0.429838C2.53343 0.219356 2.66283 0.0860044 2.81949 0.0302167C3.08711 -0.0650834 3.4343 0.0658484 3.47514 0.424998L3.82602 3.51098L4.39251 8.49256L5.88001 21.5733C5.90279 21.7853 5.90474 22.0184 5.87872 22.2394C5.74382 23.3874 4.95659 24.3068 3.92727 24.6734ZM4.37202 21.4511C4.0988 20.6841 3.25635 20.2836 2.49036 20.5565C1.72437 20.8293 1.32484 21.6722 1.59809 22.4392C1.87129 23.2062 2.71376 23.6067 3.47975 23.3338C4.24574 23.061 4.64522 22.218 4.37202 21.4511Z',
                    length: '60%',
                    width: 16,
                    offsetCenter: [0, '10%'],
                  },
                  axisLine: {
                    roundCap: true,
                    lineStyle: {
                      width: 18,
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: false,
                  },
                  axisLabel: {
                    show: false,
                  },
                  title: {
                    show: false,
                  },
                  detail: {
                    show: false,
                  },
                  data: [
                    {
                      value: progress,
                    },
                  ],
                },
              ],
            }}
          />
        </div>
        <div className="flex flex-col gap-3 items-center">
          <span className="text-default-400 text-sm">
            {t(`entities.metric.participation.period.${props.period}`)}
          </span>
          <span className="font-bold text-lg">
            {t(`entities.metric.objective.objectiveProgress.title`)}
          </span>
          <span className={`text-5xl font-bold ${calculatedPercents.class}`}>
            {calculatedPercents.percent}
          </span>
        </div>
      </div>
      <div className="flex justify-center md:justify-start md:items-center w-full">
        <div className="flex flex-col gap-1 md:gap-3 w-full">
          <span className="font-bold lg:text-xl">
            {t('entities.metric.objective.objectiveProgress.detail.title')}
          </span>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center">
              <div className="flex gap-3 items-center">
                <div className="w-4 h-4 rounded-full bg-primary"></div>
                <span className="lg:text-lg">
                  {t(
                    'entities.metric.objective.objectiveProgress.detail.status.COMPLETED',
                  )}
                </span>
              </div>
              <Chip color="primary" variant="flat">
                {statuses.COMPLETED}
              </Chip>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-3 items-center">
                <div className="w-4 h-4 rounded-full bg-primary-200"></div>
                <span className="lg:text-lg">
                  {t(
                    'entities.metric.objective.objectiveProgress.detail.status.PENDING',
                  )}
                </span>
              </div>
              <Chip color="primary" variant="flat">
                {statuses.PENDING}
              </Chip>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-3 items-center">
                <div className="w-4 h-4 rounded-full bg-primary-50"></div>
                <span className="lg:text-lg">
                  {t(
                    'entities.metric.objective.objectiveProgress.detail.status.NOT_COMPLETED',
                  )}
                </span>
              </div>
              <Chip color="primary" variant="flat">
                {statuses.NOT_COMPLETED}
              </Chip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectiveProgress;
