import axios from 'axios';
import PostDTO from '../createPost/dto/post.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PostCategoryDTO from '../createPost/dto/postCategory.dto';
import PostInteractionDTO from './dto/postInteraction.dto';
import PostCommentDTO from './dto/postComment.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';

export default class PostsRepository {
  private url: string = '/api/post';

  //Post

  async getPosts(
    options?: FindOptionsDTO<PostDTO>,
  ): Promise<PaginatedDTO<PostDTO>> {
    if (options)
      options = {
        ...options,
        relations: [
          'user',
          'tagged',
          'categories',
          'comments',
          'interactions',
          'pinnedBy',
          'comments.user',
          'comments.interactions',
          'interactions.user',
        ],
      };
    else
      options = {
        relations: [
          'user',
          'tagged',
          'categories',
          'comments',
          'interactions',
          'pinnedBy',
          'comments.user',
          'comments.interactions',
          'interactions.user',
        ],
      };
    return (
      await axios.get(
        `${this.url}?options=${encodeURIComponent(JSON.stringify(options))}`,
      )
    ).data;
  }

  async getOne(
    id: string,
    options?: FindOptionsDTO<PostDTO>,
  ): Promise<PostDTO> {
    if (options)
      options = {
        ...options,
        relations: [
          'user',
          'tagged',
          'categories',
          'comments',
          'interactions',
          'pinnedBy',
          'comments.user',
          'comments.interactions',
          'interactions.user',
        ],
      };
    else
      options = {
        relations: [
          'user',
          'tagged',
          'categories',
          'comments',
          'interactions',
          'pinnedBy',
          'comments.user',
          'comments.interactions',
          'interactions.user',
        ],
      };
    let stringOption = '';
    stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const postResponse = await axios.get<PostDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return postResponse.data;
  }

  async savePost(post: Partial<PostDTO>): Promise<PostDTO> {
    return (await axios.post(`${this.url}`, post)).data;
  }

  async updatePost(post: Partial<PostDTO>, id: string): Promise<PostDTO> {
    return (await axios.put(`${this.url}/${id}`, post)).data;
  }

  async deletePost(id: string): Promise<PostDTO> {
    return (await axios.delete(`${this.url}/${id}`)).data;
  }

  //Category

  async getCategory(
    options?: FindOptionsDTO<PostCategoryDTO>,
  ): Promise<PostCategoryDTO[]> {
    return (
      await axios.get(
        `${this.url}Category?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveCategory(
    postCategory: Partial<PostCategoryDTO>,
  ): Promise<PostCategoryDTO> {
    return (await axios.post(`${this.url}Category`, postCategory)).data;
  }

  async deleteCategory(id: string): Promise<PostCategoryDTO> {
    return (await axios.delete(`${this.url}Category/${id}`)).data;
  }

  //Interaction

  async getInteraction(
    options?: FindOptionsDTO<PostInteractionDTO>,
  ): Promise<PostInteractionDTO[]> {
    if (options) options = { ...options, relations: ['user', 'post'] };
    else options = { relations: ['user', 'post'] };
    return (
      await axios.get(
        `${this.url}Interaction?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveInteraction(
    postInteraction: Partial<PostInteractionDTO>,
  ): Promise<PostInteractionDTO> {
    return (await axios.post(`${this.url}Interaction`, postInteraction)).data;
  }

  async deleteInteraction(id: string): Promise<PostInteractionDTO> {
    return (await axios.delete(`${this.url}Interaction/${id}`)).data;
  }

  //Comments

  async getComments(
    options?: FindOptionsDTO<PostCommentDTO>,
  ): Promise<PostCommentDTO[]> {
    if (options)
      options = {
        ...options,
        relations: [
          'user',
          'interactions',
          'parent',
          'children',
          'interactions.user',
        ],
      };
    else
      options = {
        relations: [
          'user',
          'interactions',
          'parent',
          'children',
          'interactions.user',
        ],
      };
    return (
      await axios.get(
        `${this.url}Comment/root?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveComment(post: Partial<PostCommentDTO>): Promise<PostCommentDTO> {
    return (await axios.post(`${this.url}Comment`, post)).data;
  }

  async updateComment(
    post: Partial<PostCommentDTO>,
    id: string,
  ): Promise<PostCommentDTO> {
    return (await axios.put(`${this.url}Comment/${id}`, post)).data;
  }

  async deleteComment(id: string): Promise<PostCommentDTO> {
    return (await axios.delete(`${this.url}Comment/${id}`)).data;
  }

  //Comment Interaction

  async getCommentInteraction(
    options?: FindOptionsDTO<PostInteractionDTO>,
  ): Promise<PostInteractionDTO[]> {
    if (options) options = { ...options, relations: ['user', 'comment'] };
    else options = { relations: ['user', 'comment'] };
    return (
      await axios.get(
        `${this.url}CommentInteraction?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveCommentInteraction(
    postInteraction: Partial<PostInteractionDTO>,
  ): Promise<PostInteractionDTO> {
    return (await axios.post(`${this.url}CommentInteraction`, postInteraction))
      .data;
  }

  async deleteCommentInteraction(id: string): Promise<PostInteractionDTO> {
    return (await axios.delete(`${this.url}CommentInteraction/${id}`)).data;
  }
}
