import { RouteObject } from 'react-router-dom';
import administrationRoutes from './administration/routes';
import userRoutes from './user/routes';

const privateRoutes: Array<RouteObject> = [
  {
    path: 'admin',
    children: administrationRoutes,
  },
  {
    path: 'enterprise',
    children: userRoutes,
  },
];

export default privateRoutes;
