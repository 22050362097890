import ArrowIcon from '@components/icons/arrow.icon';
import SidebarCloseIcon from '@components/icons/sidebarClose.icon';
import SidebarOpenIcon from '@components/icons/sidebarOpen.icon';
import DocumentBrowser from '@modules/documentModule/browser/components/documentBrowser';
import { useDocumentContext } from '@modules/documentModule/browser/context/document.context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

const DocumentBrowsePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isBrowserOpen, setIsBrowserOpen } = useDocumentContext();

  return (
    <div>
      <div className="flex justify-between items-end">
        <h3 className="text-primary flex gap-5 items-center mb-2.5">
          {' '}
          <span
            className="cursor-pointer bg-content4 rounded-xl p-1.5 w-9 h-9"
            onClick={() => navigate(-1)}
          >
            <ArrowIcon />
          </span>
          {t('user.document.title')}
        </h3>
        <span
          className="cursor-pointer bg-content4 rounded-xl p-1.5 w-9 h-9 mb-2.5 hidden lg:inline"
          onClick={() => setIsBrowserOpen(!isBrowserOpen)}
        >
          {isBrowserOpen ? (
            <SidebarCloseIcon className="size-6 text-foreground" />
          ) : (
            <SidebarOpenIcon className="size-6 text-foreground" />
          )}
        </span>
      </div>
      <div
        className={`flex flex-col lg:flex-row ${isBrowserOpen ? 'gap-5' : ''}`}
      >
        <div
          className={`transition-all ${isBrowserOpen ? 'lg:w-3/4' : 'lg:w-full'}`}
        >
          <Outlet />
        </div>
        <div
          className={`transition-all ${isBrowserOpen ? 'lg:w-1/4' : 'lg:w-0'}`}
        >
          <DocumentBrowser />
        </div>
      </div>
    </div>
  );
};

export default DocumentBrowsePage;
