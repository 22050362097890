import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import FindOptions from 'lib/paginated/findOptions.dto';
import CompareUtil from '@utils/compare';
import TeamDTO from '@modules/userModule/team/dto/team.dto';
import TeamRepository from '@modules/userModule/team/team.repository';
import TeamForm from '@modules/userModule/team/components/teamForm';
import UpdateTeamDTO from '@modules/userModule/team/dto/updateTeam.dto';

const TeamEdit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [teamResult, setTeamResult] = useState<TeamDTO | null>(null);

  const getTeam = async (options?: FindOptions<TeamDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const teamResult = await new TeamRepository().findOne(
      id as string,
      options,
    );
    if (!teamResult) navigate(-1);
    if (!teamResult.parent) teamResult.parent = null as any;
    setTeamResult(teamResult);
    setLoading(false);
  };

  useEffect(() => {
    getTeam({ relations: ['parent', 'leader'] });
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  const handleSubmit = async (values: UpdateTeamDTO) => {
    if (!teamResult) return;
    await new TeamRepository().update(teamResult?.id as string, {
      ...(CompareUtil.compareObjects(
        teamResult,
        values as any,
      ) as UpdateTeamDTO),
      parent: values.parent,
      leader: values.leader,
    });
    navigate(-1);
  };

  return isLoading || !teamResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.team.edit')}
      </h3>
      <TeamForm onSubmit={handleSubmit} initialValues={teamResult} />
    </div>
  );
};

export default TeamEdit;
