import Table from '@components/table/table';
import ChallengeDTO from '../dto/challenge.dto';
import { useCallback, useState } from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { useNavigate } from 'react-router-dom';
import ChallengesRepository from '../../challenges.repository';
import DateUtil from '@utils/date';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface ChallengeListProps {
  options?: FindOptionsDTO<ChallengeDTO>;
}

const ChallengeList = ({ options }: ChallengeListProps) => {
  const [challengesRepository] = useState(new ChallengesRepository());
  const navigate = useNavigate();
  const user = useSelector(selectProfile);

  const getChallenges = useCallback(
    async (option?: FindOptionsDTO<ChallengeDTO>) => {
      const results = await challengesRepository.get({
        ...option,
        order: { dateFrom: 'ASC', dateTo: 'ASC' },
      });
      const now = new Date();
      results.forEach((challenge) => {
        challenge.finished = challenge.processedDate ? true : false;
        const dateFrom = new Date(challenge.dateFrom);
        const dateTo = new Date(challenge.dateTo);
        challenge.dateFrom = DateUtil.stringToDate(String(challenge.dateFrom));
        challenge.dateTo = DateUtil.stringToDate(String(challenge.dateTo));
        if (dateFrom <= now && dateTo >= now && !challenge.finished)
          challenge.isActive = true;
      });
      return {
        results,
        total: results.length,
      };
    },
    [],
  );

  const handleSearcher = useCallback(
    (textSearched: string, option?: FindOptionsDTO<ChallengeDTO>) => {
      return {
        ...option,
        where: [{ title: `lk=${textSearched}` }],
      };
    },
    [],
  );

  const handleAddButton = useCallback(() => {
    navigate('/enterprise/configuration/challenges/add');
  }, [navigate]);

  const handleEditButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/challenges/edit/${id}`);
    },
    [navigate],
  );

  const handleViewButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/challenge/view/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback(async (id: string) => {
    await challengesRepository.delete(id);
  }, []);

  return (
    <Table<ChallengeDTO>
      headers={['title', 'dateFrom', 'dateTo', 'isActive', 'finished']}
      handlerRequest={(option) => getChallenges({ ...options, ...option })}
      handlerSearcher={handleSearcher}
      configurations={{
        translationLabel: 'challenge',
        add: {
          handler: handleAddButton,
          isAllowed: user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.CHALLENGE &&
              permission.methods === AllowedMethods.ADD,
          )
            ? true
            : false,
        },
        edit: {
          handler: handleEditButton,
          isAllowed: user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.CHALLENGE &&
              permission.methods === AllowedMethods.MODIFY,
          )
            ? true
            : false,
        },
        view: {
          handler: handleViewButton,
          isAllowed: user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.CHALLENGE &&
              permission.methods === AllowedMethods.VIEW,
          )
            ? true
            : false,
        },
        delete: {
          field: 'title',
          handler: handleDelete,
          isAllowed: user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.CHALLENGE &&
              permission.methods === AllowedMethods.DELETE,
          )
            ? true
            : false,
        },
      }}
    />
  );
};

export default ChallengeList;
