import React from 'react';
import { useCreateBlockNote } from '@blocknote/react';
import TagIcon from '@components/icons/tag.icon';
import TagInput from '@components/tagInput/tagInput.component';
import { Avatar } from '@nextui-org/avatar';
import { Button } from '@nextui-org/button';
import { Chip } from '@nextui-org/chip';
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/popover';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChallengeAnswerDTO from '../dto/challengeAnswer.dto';
import { Spinner } from '@nextui-org/spinner';
import ChallengeAnswerCategoryDTO from '../dto/challengeAnswerCategory.dto';
import ChallengesRepository from '../../challenges.repository';
import ChallengeDTO from '../../configuration/dto/challenge.dto';
import { BlockNoteEditor } from '@blocknote/core';
import {
  CustomPartialBlock,
  customSchema,
} from '@components/customBlockNote/customSchema';
import CustomBlockNote, {
  validateBlockNote,
} from '@components/customBlockNote/customBlockNote.component';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import FeedUserDTO from '@modules/feed/feedUser/dto/feedUser.dto';

interface CreateChallengeAnswerProps {
  challenge: ChallengeDTO;
  data?: ChallengeAnswerDTO;
  refresh?: () => void;
}

const CreateChallengeAnswer = ({
  challenge,
  data,
  refresh,
}: CreateChallengeAnswerProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<ChallengeAnswerCategoryDTO[]>([]);
  const [challengeRepository] = useState(new ChallengesRepository());
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [blocknoteError, setBlocknoteError] = useState(false);
  const [initialContent, setInitialContent] = useState<
    CustomPartialBlock[] | 'loading' | null | undefined
  >('loading');
  const [taggedUsers, setTaggedUsers] = useState<FeedUserDTO[]>([]);

  const editor = useMemo(() => {
    if (initialContent === 'loading') {
      return undefined;
    }
    if (initialContent === null) {
      return BlockNoteEditor.create({ schema: customSchema });
    }
    return BlockNoteEditor.create({ initialContent, schema: customSchema });
  }, [initialContent]);

  const functionalEditor = useCreateBlockNote({ schema: customSchema });

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (data) {
        setTags(data.categories);
        const blocks = await functionalEditor.tryParseHTMLToBlocks(
          data.content,
        );
        setTaggedUsers(data.tagged);
        setInitialContent(blocks);
      } else {
        setInitialContent(null);
      }
      setIsLoading(false);
    };

    loadData();
  }, []);

  if (editor) editor.onEditorContentChange(() => setBlocknoteError(false));

  const handleSubmit = async () => {
    setIsButtonLoading(true);
    if (!editor) return;
    if (validateBlockNote(editor)) {
      setBlocknoteError(true);
      setIsButtonLoading(false);
      return;
    }
    const content = await editor.blocksToHTMLLossy(editor.document);
    const cleanContent = content
      .replace(/(<)(.*?)(>)/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
    const challengeAnswer = {
      content,
      cleanContent,
      categories: tags,
      likes: [],
      comments: [],
      challenge,
      tagged: taggedUsers,
    };
    if (data)
      await challengeRepository.updateChallengeAnswer(challengeAnswer, data.id);
    else await challengeRepository.saveChallengeAnswer(challengeAnswer);
    clearInput();
    setIsButtonLoading(false);
    if (refresh) refresh();
  };

  const clearInput = () => {
    setTags([]);
    if (editor) editor.removeBlocks(editor.document);
  };

  const findOrSaveTag = async (name: string): Promise<string> => {
    const results = await challengeRepository.getCategory({
      where: { name },
      take: 1,
    });
    if (results[0] && results[0].id) return results[0].id;
    else {
      const resultsCreate = await challengeRepository.saveCategory({ name });
      if (resultsCreate.id) return resultsCreate.id;
      return '-1';
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="py-10 flex justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="space-y-5 py-5 px-5">
            <div className="flex flex-row gap-5">
              <Avatar
                src={
                  profile?.avatar &&
                  `https://giveit-system-assets.s3.amazonaws.com/${profile.avatar}`
                }
                showFallback
                isBordered
                color="primary"
                className="h-full hidden sm:flex aspect-square flex-shrink-0"
              />
              <div className="w-full">
                {editor && (
                  <CustomBlockNote
                    editor={editor}
                    error={blocknoteError}
                    taggedUsers={taggedUsers}
                  />
                )}
              </div>
            </div>
            {tags.length ? (
              <div className="space-x-2 space-y-2">
                <span>
                  {t('user.dashboard.feed.createPost.hashtag.hashtag')}:{' '}
                </span>
                {tags.map((tag) => (
                  <Chip
                    key={tag.id}
                    color="primary"
                    onClose={() => {
                      setTags(tags.filter((t) => t !== tag));
                    }}
                  >
                    #{tag.name}
                  </Chip>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="pb-5 px-5 flex items-center justify-between">
            <Popover>
              <PopoverTrigger>
                <Button
                  variant="light"
                  color="primary"
                  className="dark:text-primary-600"
                >
                  <TagIcon className="w-5" />
                  {t('user.dashboard.feed.createPost.hashtag.hashtag')}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                {(titleProps) => (
                  <div className="p-2 space-y-2">
                    <p {...titleProps}>
                      {t('user.dashboard.feed.createPost.hashtag.add')}
                    </p>
                    <TagInput
                      tags={tags}
                      setTags={setTags}
                      findOrSaveTag={findOrSaveTag}
                      repository={challengeRepository}
                    />
                  </div>
                )}
              </PopoverContent>
            </Popover>
            <Button
              color="primary"
              onPress={handleSubmit}
              isDisabled={isLoading}
              isLoading={isButtonLoading}
            >
              {data
                ? t('user.dashboard.feed.createPost.edit')
                : t('user.dashboard.feed.createPost.post')}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CreateChallengeAnswer;
