import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import DateUtil from '@utils/date';
import KeyResultDTO from './dto/keyResult.dto';
import CreateKeyResultDTO from './dto/createKeyResult.dto';
import UpdateKeyResultDTO from './dto/updateKeyResult.dto';

export default class KeyResultRepository {
  private url: string = '/api/keyResult';

  async find(
    options?: FindOptionsDTO<KeyResultDTO>,
  ): Promise<PaginatedDTO<KeyResultDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const keyResultResponse = await axios.get<PaginatedDTO<KeyResultDTO>>(
      `${this.url}${stringOption}`,
    );
    keyResultResponse.data.results.forEach((keyResult) => {
      keyResult.periodFrom = DateUtil.stringToDate(
        String(keyResult.periodFrom),
      );
      keyResult.periodTo = DateUtil.stringToDate(String(keyResult.periodTo));
    });
    return keyResultResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<KeyResultDTO>,
  ): Promise<KeyResultDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const keyResultResponse = await axios.get<KeyResultDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    keyResultResponse.data.periodFrom = DateUtil.stringToDate(
      String(keyResultResponse.data.periodFrom),
    );
    keyResultResponse.data.periodTo = DateUtil.stringToDate(
      String(keyResultResponse.data.periodTo),
    );
    return keyResultResponse.data;
  }

  async save(keyResult: CreateKeyResultDTO) {
    const createdKeyResultResponse = await axios.post<KeyResultDTO>(
      `${this.url}`,
      keyResult,
    );
    return createdKeyResultResponse.data;
  }

  async update(id: string, changes: UpdateKeyResultDTO) {
    const updatedKeyResultResponse = await axios.put<KeyResultDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedKeyResultResponse.data;
  }

  async delete(id: string) {
    const deletedKeyResultResponse = await axios.delete<KeyResultDTO>(
      `${this.url}/${id}`,
    );
    return deletedKeyResultResponse.data;
  }
}
