import { RouteObject } from 'react-router-dom';
import EnterpriseView from './enterprise/view/view';
import AddUser from './enterprise/user/add/addUser';
import ViewUser from './enterprise/user/view/viewUser';
import EditUser from './enterprise/user/edit/editUser';
import UserGroupView from './enterprise/userGroup/view/userGroupView';
import UserGroupAdd from './enterprise/userGroup/add/userGroupAdd';
import UserGroupEdit from './enterprise/userGroup/edit/userGroupEdit';
import TeamAdd from './enterprise/team/add/teamAdd';
import TeamEdit from './enterprise/team/edit/teamEdit';
import TeamView from './enterprise/team/view/teamView';
import surveyConfigurationRoutes from './surveys/route';
import challengeConfigurationRoutes from './challenges/route';
import ViewUserFile from './enterprise/userFile/view/viewUserFile';
import EditUserFile from './enterprise/userFile/edit/editUserFile';
import NotificationsConfigurationPage from './notifications/notificationsConfiguration.page';
import LevelsConfigurationPage from './levels/levelsConfiguration.page';

const configurationRoutes: Array<RouteObject> = [
  {
    children: [
      { path: '', element: <EnterpriseView /> },
      { path: 'user/add', element: <AddUser /> },
      { path: 'user/view/:id', element: <ViewUser /> },
      { path: 'user/edit/:id', element: <EditUser /> },
      { path: 'userFile/view/:id', element: <ViewUserFile /> },
      { path: 'userFile/edit/:id', element: <EditUserFile /> },
      { path: 'userGroup/add', element: <UserGroupAdd /> },
      { path: 'userGroup/view/:id', element: <UserGroupView /> },
      { path: 'userGroup/edit/:id', element: <UserGroupEdit /> },
      { path: 'team/add', element: <TeamAdd /> },
      { path: 'team/view/:id', element: <TeamView /> },
      { path: 'team/edit/:id', element: <TeamEdit /> },
      { path: 'surveys', children: surveyConfigurationRoutes },
      { path: 'challenges', children: challengeConfigurationRoutes },
      { path: 'notifications', element: <NotificationsConfigurationPage /> },
      { path: 'levels', element: <LevelsConfigurationPage /> },
    ],
  },
];

export default configurationRoutes;
