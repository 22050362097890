import i18next from 'i18next';
import * as Yup from 'yup';

const ChallengeValidation = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    dateFrom: Yup.date().required(i18next.t('validations.required')),
    dateTo: Yup.date().required(i18next.t('validations.required')),
    rocketsWinner: Yup.number()
      .typeError(i18next.t('validations.number'))
      .min(0, i18next.t('validations.min', { min: 0 }))
      .required(i18next.t('validations.required')),
    rocketsParticipation: Yup.number()
      .typeError(i18next.t('validations.number'))
      .min(0, i18next.t('validations.min', { min: 0 }))
      .required(i18next.t('validations.required')),
    amountOfWinners: Yup.number()
      .typeError(i18next.t('validations.number'))
      .min(0, i18next.t('validations.min', { min: 0 }))
      .required(i18next.t('validations.required')),
  });
};

export default ChallengeValidation;
