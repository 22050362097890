import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PulseSurveyDTO from '../dto/pulseSurvey.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PulseSurveyRepository from '../pulseSurvey.repository';
import { Chip, Skeleton } from '@nextui-org/react';
import DateUtil from '@utils/date';

interface PulseSurveyDetailProps {
  id: string;
}

const PulseSurveyDetail = (props: PulseSurveyDetailProps) => {
  const { t } = useTranslation();
  const [pulseSurvey, setPulseSurvey] = useState<PulseSurveyDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getPulseSurvey = async (options?: FindOptionsDTO<PulseSurveyDTO>) => {
    setLoading(true);
    const pulseSurveyRequest = await new PulseSurveyRepository().findOne(
      props.id,
      {
        ...options,
        relations: Object.assign([], options?.relations, ['categories']),
      },
    );
    setPulseSurvey(pulseSurveyRequest);
    setLoading(false);
  };

  useEffect(() => {
    getPulseSurvey();
  }, []);

  const Loading = () => {
    return (
      <div className="py-5 flex flex-col gap-5">
        <div>
          <Skeleton className="h-6 w-64"></Skeleton>
        </div>
        <div className="flex flex-col">
          <Skeleton className="w-64 h-5"></Skeleton>
          <Skeleton className="w-64 h-5"></Skeleton>
        </div>
        <Skeleton className="w-64 h-5"></Skeleton>
      </div>
    );
  };

  return isLoading || !pulseSurvey ? (
    <Loading />
  ) : (
    <div className="py-5 flex flex-col gap-5">
      <div>
        <p>
          <strong className="text-foreground">
            {t('entities.pulseSurvey.fields.question')}:
          </strong>{' '}
          {pulseSurvey?.question || '-'}
        </p>
      </div>
      <div className="flex gap-5">
        <p>
          <strong className="text-foreground">
            {t('entities.pulseSurvey.fields.dateFrom')}:
          </strong>{' '}
          {DateUtil.dateToPeriod(pulseSurvey?.dateFrom || new Date()) || '-'}
        </p>
        <p>
          <strong className="text-foreground">
            {t('entities.pulseSurvey.fields.dateTo')}:
          </strong>{' '}
          {DateUtil.dateToPeriod(pulseSurvey?.dateTo || new Date()) || '-'}
        </p>
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-bold text-foreground-500">
          {t('entities.pulseSurvey.fields.categories')}
        </span>
        <div className="flex gap-3 pt-1">
          {pulseSurvey?.categories.length === 0 && ' - '}
          {pulseSurvey?.categories.map((category) => (
            <Chip key={category.id} color="primary">
              {category.name}
            </Chip>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PulseSurveyDetail;
