import React from 'react';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import { Avatar } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import edificio from '@assets/icons/edificio.svg';
import InitiativeMetric from '../components/initiatives';
import StrengthRadar from '../components/strengthRadar';
import logo from '@assets/img/giveit.svg';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface ProfileAnalitycProps {
  userId: string;
}

const ProfileAnalityc = (props: ProfileAnalitycProps) => {
  const user = useSelector(selectProfile);
  const [profile, setProfile] = useState<UserDTO | null>(null);

  const getProfile = async () => {
    const returnedProfile = await new UserRepository().findOne(props.userId, {
      relations: ['userFile', 'team', 'enterprise'],
    });
    setProfile(returnedProfile);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="lg:grid lg:grid-cols-3 py-5">
      <div className="flex flex-col items-center gap-5">
        <Avatar
          name={`${profile?.firstName} ${profile?.lastName}`}
          src={
            profile?.avatar &&
            `https://giveit-system-assets.s3.amazonaws.com/${profile?.avatar}`
          }
          classNames={{
            base: 'w-32 h-32',
          }}
        />
        <div>
          <p className="text-lg text-center">
            {profile?.firstName} {profile?.lastName}
          </p>
          <div className="text-sm text-default-400 text-center">
            {profile?.userFile?.jobTitle || '-'}
          </div>
        </div>
        {profile?.team && (
          <div className="py-2 px-4 bg-primary-100 text-primary-800 rounded-[16px]">
            {profile.team.name}
          </div>
        )}
        <div className="flex flex-col justify-center text-primary gap-2 mt-2 lg:mt-5">
          <div className="flex items-center gap-3">
            <img src={logo} className="m-0" />
            <span className="font-bold">Rockets</span>
          </div>
          <span className="font-bold text-center text-2xl">
            {profile?.rockets}
          </span>
        </div>
      </div>
      <div className="col-span-2 flex flex-col gap-5 items-center lg:items-start mt-2">
        <div className="p-3 w-fit rounded-xl flex items-center gap-3 bg-primary-100">
          <img className="m-0" src={edificio} />
          {profile?.enterprise.enterpriseName}
        </div>

        {user?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.INITIATIVE &&
            permission.methods === AllowedMethods.VIEW,
        ) && <InitiativeMetric userId={profile?.id as string} />}
        {user?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.RECOGNITION &&
            permission.methods === AllowedMethods.VIEW,
        ) && <StrengthRadar userId={profile?.id as string} />}
      </div>
    </div>
  );
};

export default ProfileAnalityc;
