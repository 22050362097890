import AuthRepository from '@modules/userModule/auth/auth.repository';
import { Spinner } from '@nextui-org/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SigninGoogle = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const handlerRedirect = () => {
    if (params.get('t')) {
      new AuthRepository().setToken(params.get('t') as string);
    }

    return navigate('/');
  };

  useEffect(() => {
    handlerRedirect();
  }, []);

  return (
    <div className="flex justify-center items-center">
      <Spinner />
    </div>
  );
};

export default SigninGoogle;
