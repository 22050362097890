import axios from 'axios';
import ChallengeAnswerDTO from './vote/dto/challengeAnswer.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ChallengeAnswerCategoryDTO from './vote/dto/challengeAnswerCategory.dto';
import ChallengeAnswerInteractionDTO from './vote/dto/challengeAnswerInteraction.dto';
import ChallengeAnswerCommentDTO from './vote/dto/challengeAnswerComment.dto';
import ChallengeDTO from './configuration/dto/challenge.dto';
import ChallengeRankingDTO from './vote/dto/challengeRanking.dto';

export default class ChallengesRepository {
  private url: string = '/api/challenge';

  //Challenge

  async get(options?: FindOptionsDTO<ChallengeDTO>): Promise<ChallengeDTO[]> {
    return (
      await axios.get(
        `${this.url}?options=${encodeURIComponent(JSON.stringify(options))}`,
      )
    ).data.results;
  }

  async getOne(
    id: string,
    options?: FindOptionsDTO<ChallengeDTO>,
  ): Promise<ChallengeDTO> {
    return (
      await axios.get(
        `${this.url}/${id}?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data;
  }

  async save(challenge: Partial<ChallengeDTO>): Promise<ChallengeDTO> {
    return (await axios.post(`${this.url}`, challenge)).data;
  }

  async update(
    challenge: Partial<ChallengeDTO>,
    id: string,
  ): Promise<ChallengeDTO> {
    return (await axios.put(`${this.url}/${id}`, challenge)).data;
  }

  async delete(id: string): Promise<ChallengeDTO> {
    return (await axios.delete(`${this.url}/${id}`)).data;
  }

  //ChallengeAnswer

  async getChallengeAnswers(
    options?: FindOptionsDTO<ChallengeAnswerDTO>,
  ): Promise<ChallengeAnswerDTO[]> {
    options = {
      ...options,
      relations: [
        'user',
        'tagged',
        'categories',
        'comments',
        'interactions',
        'comments.user',
        'comments.interactions',
        'interactions.user',
      ],
    };
    return (
      await axios.get(
        `${this.url}Answer?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async getOneChallengeAnswer(
    id: string,
    options?: FindOptionsDTO<ChallengeAnswerDTO>,
  ): Promise<ChallengeAnswerDTO> {
    if (options)
      options = {
        ...options,
        relations: [
          'user',
          'tagged',
          'categories',
          'comments',
          'interactions',
          'comments.user',
          'comments.interactions',
          'interactions.user',
        ],
      };
    else
      options = {
        relations: [
          'user',
          'tagged',
          'categories',
          'comments',
          'interactions',
          'comments.user',
          'comments.interactions',
          'interactions.user',
        ],
      };
    let stringOption = '';
    stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const answerResponse = await axios.get<ChallengeAnswerDTO>(
      `${this.url}Answer/${id}${stringOption}`,
    );
    return answerResponse.data;
  }

  async getChallengeRanking(
    options?: FindOptionsDTO<ChallengeAnswerDTO>,
  ): Promise<ChallengeRankingDTO[]> {
    return (
      await axios.get(
        `${this.url}Answer/ranking?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data;
  }

  async saveChallengeAnswer(
    challengeAnswer: Partial<ChallengeAnswerDTO>,
  ): Promise<ChallengeAnswerDTO> {
    return (await axios.post(`${this.url}Answer`, challengeAnswer)).data;
  }

  async updateChallengeAnswer(
    challengeAnswer: Partial<ChallengeAnswerDTO>,
    id: string,
  ): Promise<ChallengeAnswerDTO> {
    return (await axios.put(`${this.url}Answer/${id}`, challengeAnswer)).data;
  }

  async deleteChallengeAnswer(id: string): Promise<ChallengeAnswerDTO> {
    return (await axios.delete(`${this.url}Answer/${id}`)).data;
  }

  //Category

  async getCategory(
    options?: FindOptionsDTO<ChallengeAnswerCategoryDTO>,
  ): Promise<ChallengeAnswerCategoryDTO[]> {
    return (
      await axios.get(
        `${this.url}AnswerCategory?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveCategory(
    challengeAnswerCategory: Partial<ChallengeAnswerCategoryDTO>,
  ): Promise<ChallengeAnswerCategoryDTO> {
    return (
      await axios.post(`${this.url}AnswerCategory`, challengeAnswerCategory)
    ).data;
  }

  //Interaction

  async getInteractions(
    options?: FindOptionsDTO<ChallengeAnswerInteractionDTO>,
  ): Promise<ChallengeAnswerInteractionDTO[]> {
    if (options)
      options = { ...options, relations: ['user', 'challengeAnswer'] };
    else options = { relations: ['user', 'challengeAnswer'] };
    return (
      await axios.get(
        `${this.url}AnswerInteraction?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveInteraction(
    challengeAnswerInteraction: Partial<ChallengeAnswerInteractionDTO>,
  ): Promise<ChallengeAnswerInteractionDTO> {
    return (
      await axios.post(
        `${this.url}AnswerInteraction`,
        challengeAnswerInteraction,
      )
    ).data;
  }

  async deleteInteraction(id: string): Promise<ChallengeAnswerInteractionDTO> {
    return (await axios.delete(`${this.url}AnswerInteraction/${id}`)).data;
  }

  //Comments

  async getComments(
    options?: FindOptionsDTO<ChallengeAnswerCommentDTO>,
  ): Promise<ChallengeAnswerCommentDTO[]> {
    if (options)
      options = {
        ...options,
        relations: [
          'user',
          'interactions',
          'parent',
          'children',
          'interactions.user',
        ],
      };
    else
      options = {
        relations: [
          'user',
          'interactions',
          'parent',
          'children',
          'interactions.user',
        ],
      };
    return (
      await axios.get(
        `${this.url}AnswerComment/root?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveComment(
    challengeAnswer: Partial<ChallengeAnswerCommentDTO>,
  ): Promise<ChallengeAnswerCommentDTO> {
    return (await axios.post(`${this.url}AnswerComment`, challengeAnswer)).data;
  }

  async updateComment(
    challengeAnswer: Partial<ChallengeAnswerCommentDTO>,
    id: string,
  ): Promise<ChallengeAnswerCommentDTO> {
    return (await axios.put(`${this.url}AnswerComment/${id}`, challengeAnswer))
      .data;
  }

  async deleteComment(id: string): Promise<ChallengeAnswerCommentDTO> {
    return (await axios.delete(`${this.url}AnswerComment/${id}`)).data;
  }

  //Comment Interaction

  async getCommentInteraction(
    options?: FindOptionsDTO<ChallengeAnswerInteractionDTO>,
  ): Promise<ChallengeAnswerInteractionDTO[]> {
    if (options) options = { ...options, relations: ['user', 'comment'] };
    else options = { relations: ['user', 'comment'] };
    return (
      await axios.get(
        `${this.url}AnswerCommentInteraction?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      )
    ).data.results;
  }

  async saveCommentInteraction(
    challengeAnswerInteraction: Partial<ChallengeAnswerInteractionDTO>,
  ): Promise<ChallengeAnswerInteractionDTO> {
    return (
      await axios.post(
        `${this.url}AnswerCommentInteraction`,
        challengeAnswerInteraction,
      )
    ).data;
  }

  async deleteCommentInteraction(
    id: string,
  ): Promise<ChallengeAnswerInteractionDTO> {
    return (await axios.delete(`${this.url}AnswerCommentInteraction/${id}`))
      .data;
  }
}
