import React from 'react';
import { useEffect, useState } from 'react';
import CardMetric from '../../components/cardMetric';
import PulseSurveyMetricRepository from '../pulseSurveyMetric.repository';
import { DeepPartial } from '@lib/common/deepPartial';
import PulseSurveyDTO from '../dto/pulseSurvey.dto';
import { useTranslation } from 'react-i18next';

interface PulseSurveyMetricProps {
  options?: DeepPartial<PulseSurveyDTO>;
  className?: string;
}

const PulseSurveyMetric = (props: PulseSurveyMetricProps) => {
  const { t } = useTranslation();
  const [percent, setPercent] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  const voteToPercent = (vote: 'AGREE' | 'NOT_QUITE' | 'NOT_AGREE') => {
    const percentResults = {
      AGREE: 100,
      NOT_QUITE: 50,
      NOT_AGREE: 0,
    };

    return percentResults[vote];
  };

  const getPulseMetricPercentResponse = async () => {
    setLoading(true);
    const pulseSurveysResult = await new PulseSurveyMetricRepository().find({
      where: props.options,
      relations: ['votes'],
    });

    const arrSumPulsePercents = pulseSurveysResult.results.map(
      (pulseSurveys) => {
        const votesPercent = pulseSurveys.votes.map((vote) => {
          return voteToPercent(vote.vote);
        });

        const sumVotes = votesPercent.reduce((a, b) => a + b, 0);

        return sumVotes / pulseSurveys.votes.length;
      },
    );

    const sumPulsePercents = arrSumPulsePercents.reduce((a, b) => a + b, 0);

    setPercent(sumPulsePercents / arrSumPulsePercents.length);
    setLoading(false);
  };

  useEffect(() => {
    getPulseMetricPercentResponse();
  }, []);

  useEffect(() => {
    getPulseMetricPercentResponse();
  }, [props.options]);

  return (
    <CardMetric
      helpTitle={t('entities.metric.pulseSurvey.helpTitle')}
      title={t('entities.metric.pulseSurvey.title')}
      result={percent}
      isLoading={isLoading}
      className={`${props.className}`}
    />
  );
};

export default PulseSurveyMetric;
