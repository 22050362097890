import Input from '@components/input/input';
import { Button } from '@nextui-org/react';
import DateUtil from '@utils/date';
import { useState } from 'react';

interface ObjectivePeriodFilterProps {
  periodFrom?: Date;
  periodTo?: Date;
  onChange: (periodFrom: Date, periodTo: Date) => void;
}

const ObjectivePeriodFilter = (props: ObjectivePeriodFilterProps) => {
  const [selectedPeriodFrom, setSelectedPeriodFrom] = useState<Date>(
    props.periodFrom || new Date(),
  );
  const [selectedPeriodTo, setSelectedPeriodTo] = useState<Date>(
    props.periodTo || new Date(),
  );

  return (
    <div className="flex flex-wrap items-center justify-center sm:justify-start gap-2 py-3">
      <div className="flex gap-2">
        <Button
          size="sm"
          color="primary"
          variant={
            DateUtil.dateToQuarter(selectedPeriodFrom) === 'Q1' ||
            DateUtil.dateToQuarter(selectedPeriodTo) === 'Q1'
              ? 'solid'
              : 'flat'
          }
          onClick={() => {
            const periodFrom = new Date();
            periodFrom.setDate(1);
            periodFrom.setMonth(0);
            const periodTo = new Date();
            periodTo.setMonth(3);
            periodTo.setDate(1);
            periodTo.setDate(0);
            setSelectedPeriodFrom(periodFrom);
            setSelectedPeriodTo(periodTo);
            props.onChange(periodFrom, periodTo);
          }}
        >
          Q1
        </Button>
        <Button
          size="sm"
          color="primary"
          variant={
            DateUtil.dateToQuarter(selectedPeriodFrom) === 'Q2' ||
            DateUtil.dateToQuarter(selectedPeriodTo) === 'Q2'
              ? 'solid'
              : 'flat'
          }
          onClick={() => {
            const periodFrom = new Date();
            periodFrom.setDate(1);
            periodFrom.setMonth(3);
            const periodTo = new Date();
            periodTo.setMonth(6);
            periodTo.setDate(1);
            periodTo.setDate(0);
            setSelectedPeriodFrom(periodFrom);
            setSelectedPeriodTo(periodTo);
            props.onChange(periodFrom, periodTo);
          }}
        >
          Q2
        </Button>
        <Button
          size="sm"
          color="primary"
          variant={
            DateUtil.dateToQuarter(selectedPeriodFrom) === 'Q3' ||
            DateUtil.dateToQuarter(selectedPeriodTo) === 'Q3'
              ? 'solid'
              : 'flat'
          }
          onClick={() => {
            const periodFrom = new Date();
            periodFrom.setDate(1);
            periodFrom.setMonth(6);
            const periodTo = new Date();
            periodTo.setMonth(9);
            periodTo.setDate(1);
            periodTo.setDate(0);
            setSelectedPeriodFrom(periodFrom);
            setSelectedPeriodTo(periodTo);
            props.onChange(periodFrom, periodTo);
          }}
        >
          Q3
        </Button>
        <Button
          size="sm"
          color="primary"
          variant={
            DateUtil.dateToQuarter(selectedPeriodFrom) === 'Q4' ||
            DateUtil.dateToQuarter(selectedPeriodTo) === 'Q4'
              ? 'solid'
              : 'flat'
          }
          onClick={() => {
            const periodFrom = new Date();
            periodFrom.setDate(1);
            periodFrom.setMonth(9);
            const periodTo = new Date();
            periodTo.setMonth(12);
            periodTo.setDate(1);
            periodTo.setDate(0);
            setSelectedPeriodFrom(periodFrom);
            setSelectedPeriodTo(periodTo);
            props.onChange(periodFrom, periodTo);
          }}
        >
          Q4
        </Button>
      </div>
      <div className="flex gap-2">
        <Input
          size="sm"
          type="date"
          onChange={(event) => {
            setSelectedPeriodFrom(DateUtil.stringToDate(event.target.value));
            props.onChange(
              selectedPeriodFrom,
              DateUtil.stringToDate(event.target.value),
            );
          }}
          value={DateUtil.dateForInput(selectedPeriodFrom)}
        />
        <Input
          size="sm"
          type="date"
          onChange={(event) => {
            props.onChange(
              DateUtil.stringToDate(event.target.value),
              selectedPeriodTo,
            );
            setSelectedPeriodTo(DateUtil.stringToDate(event.target.value));
          }}
          value={DateUtil.dateForInput(selectedPeriodTo)}
        />
      </div>
    </div>
  );
};

export default ObjectivePeriodFilter;
