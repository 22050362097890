import React from 'react';
import TeamRepository from '@modules/userModule/team/team.repository';
import ReactEChart from 'echarts-for-react';
import { useEffect, useState } from 'react';
import PulseSurveyMetricRepository from '../pulseSurvey/pulseSurveyMetric.repository';
import PulseCategoryDTO from '@modules/voteModule/pulse/pulseCategory/dto/pulseCategory.dto';
import DateUtil from '@utils/date';

interface PulseHeatMapProps {
  period: 'Q1' | 'Q2' | 'Q3' | 'Q4';
}

const PulseHeatMap = (props: PulseHeatMapProps) => {
  const periodDates = DateUtil.quarterToDate(props.period);
  const [headData, setHeadData] = useState<{
    teams: string[];
    category: string[];
    data: [number, number, number | string][];
  }>({
    teams: [],
    category: [],
    data: [],
  });

  const calculateVotePercent = (vote: 'AGREE' | 'NOT_QUITE' | 'NOT_AGREE') => {
    if (vote === 'AGREE') return 100;

    if (vote === 'NOT_QUITE') return 50;

    return 0;
  };

  const calculate = async () => {
    const teams = await new TeamRepository().find();

    const pulseSurveys = await new PulseSurveyMetricRepository().find({
      relations: ['votes', 'votes.user', 'votes.user', 'categories'],
      where: {
        dateFrom: `>=${DateUtil.dateForInput(periodDates.from)}`,
        dateTo: `<=${DateUtil.dateForInput(periodDates.to)}`,
      },
    });
    const categories: PulseCategoryDTO[] = pulseSurveys.results
      .map((pulseSurvey) =>
        pulseSurvey.categories.map((category) => category).flat(),
      )
      .flat();
    const teamNames = teams.results.map((team) => team.name);
    const categoryNames = categories.map((category) => category.name);

    const voteData: [number, number, number | string][] = [];
    let teamPosition = 0;

    teams.results.map((team) => {
      let categoryPosition = 0;
      categories.map((category) => {
        let voteSum = 0;
        let totalVotes = 0;
        pulseSurveys.results.map((pulseSurvey) => {
          pulseSurvey.votes
            .filter(
              (vote) =>
                vote.user.externalTeam === team.id &&
                pulseSurvey.categories.some(
                  (pulseCategory) => pulseCategory.id === category.id,
                ),
            )
            .map((matchedVotes) => {
              voteSum += calculateVotePercent(matchedVotes.vote);
              totalVotes++;
            });
        });
        voteData.push([
          categoryPosition,
          teamPosition,
          voteSum === 0 ? '-' : voteSum / totalVotes,
        ]);
        categoryPosition++;
      });
      teamPosition++;
    });

    setHeadData({
      teams: teamNames,
      category: categoryNames,
      data: voteData,
    });
  };

  useEffect(() => {
    calculate();
  }, [props.period]);

  return (
    <div className="py-5">
      <p className="text-lg font-bold">Mapa de calor - Equipos</p>
      <p className="text-default-400 text-sm">
        Este informe recolecta datos a través de las encuestas realizadas a los
        colaboradores
      </p>
      <ReactEChart
        option={{
          tooltip: {
            position: 'top',
          },
          grid: {
            height: '90%',
            top: '0%',
          },
          xAxis: {
            type: 'category',
            data: headData.category,
            splitArea: {
              show: true,
            },
          },
          yAxis: {
            type: 'category',
            data: headData.teams,
            splitArea: {
              show: true,
            },
          },
          visualMap: {
            min: 0,
            max: 100,
            show: false,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '15%',
            target: {
              inRange: {
                color: ['#FFC0C4', '#FFEEC1', '#C5F2D9'],
              },
            },
          },
          series: [
            {
              name: 'Categoria (Porcentaje)',
              type: 'heatmap',
              data: headData.data,
              label: {
                show: true,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }}
      />
    </div>
  );
};

export default PulseHeatMap;
