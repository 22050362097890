import i18next from 'i18next';
import * as Yup from 'yup';

const TeamFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18next.t('validations.required')),
    parent: Yup.object()
      .nullable()
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
  });
};

export default TeamFormValidation;
