import NotificationConfigurationDTO from './dto/notificationConfiguration.dto';
import NotificationConfigurationsDTO from './dto/notificationConfigurations.dto';
import axios from 'axios';

export default class NotificationRepository {
  private url: string = '/api/notificationConfiguration';

  async findMine(): Promise<NotificationConfigurationDTO[]> {
    const notificationConfigurationResponse = await axios.get<
      NotificationConfigurationDTO[]
    >(`${this.url}`);
    return notificationConfigurationResponse.data;
  }

  async save(configurations: NotificationConfigurationsDTO) {
    await axios.put<NotificationConfigurationsDTO>(
      `${this.url}`,
      configurations,
    );
  }
}
