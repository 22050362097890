import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import CreateUserDTO from '../dto/createUser.dto';
import UserFormValidation from '../validation/userForm.validation';
import {
  Button,
  Input,
  Autocomplete,
  AutocompleteItem,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import UserGroupRepository from '@modules/userModule/userGroup/userGroup.repository';
import { useEffect, useState } from 'react';
import UserGroupDTO from '@modules/userModule/userGroup/dto/userGroup.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import TeamDTO from '@modules/userModule/team/dto/team.dto';
import TeamRepository from '@modules/userModule/team/team.repository';

interface UserFormProps {
  initialValues?: CreateUserDTO;
  onSubmit: (
    values: CreateUserDTO,
    helpers: FormikHelpers<CreateUserDTO>,
  ) => void | Promise<void>;
}

export const initialUserFormValues: CreateUserDTO = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  group: { id: '' },
  team: null,
};

const UserForm = ({
  initialValues = initialUserFormValues,
  onSubmit,
}: UserFormProps) => {
  const { t } = useTranslation();
  const [userGroupList, setUserGroupList] = useState<UserGroupDTO[]>([]);
  const [teamList, setTeamList] = useState<TeamDTO[]>([]);

  const getUserGroups = async (options?: FindOptionsDTO<UserGroupDTO>) => {
    const userGroups = await new UserGroupRepository().find(options);
    setUserGroupList(userGroups.results);
  };
  const getTeams = async (options?: FindOptionsDTO<TeamDTO>) => {
    const teams = await new TeamRepository().find(options);
    setTeamList(teams.results);
  };

  useEffect(() => {
    getUserGroups();
    getTeams();
  }, []);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateUserDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="email"
              label={t('entities.user.fields.email')}
              isInvalid={Boolean(errors.email)}
              errorMessage={errors.email}
              color={errors.email ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('email', e.target.value)}
              value={values.email}
            />
            <Input
              name="password"
              label={t('entities.user.fields.password')}
              isInvalid={Boolean(errors.password)}
              errorMessage={errors.password}
              color={errors.password ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('password', e.target.value)}
              value={values.password}
              type="password"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="firstName"
              label={t('entities.user.fields.firstName')}
              isInvalid={Boolean(errors.firstName)}
              errorMessage={errors.firstName}
              color={errors.firstName ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('firstName', e.target.value)}
              value={values.firstName}
            />
            <Input
              name="lastName"
              label={t('entities.user.fields.lastName')}
              isInvalid={Boolean(errors.lastName)}
              errorMessage={errors.lastName}
              color={errors.lastName ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('lastName', e.target.value)}
              value={values.lastName}
            />
          </div>
          <div>
            <Autocomplete
              label={t('entities.user.fields.userGroup')}
              items={userGroupList}
              onInputChange={(e) => {
                getUserGroups({ where: { name: `lk=${e}` } });
              }}
              onSelectionChange={(e) => setFieldValue('group.id', e)}
              selectedKey={values.group.id}
              isInvalid={Boolean(errors.group?.id)}
              errorMessage={errors.group?.id}
              color={errors.group?.id ? 'danger' : 'default'}
            >
              {(item) => (
                <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>
              )}
            </Autocomplete>
          </div>
          <div>
            <Autocomplete
              label={t('entities.user.fields.team')}
              items={teamList}
              onInputChange={(e) => {
                getTeams({ where: { name: `lk=${e}` } });
              }}
              onSelectionChange={(e) =>
                setFieldValue('team', e ? { id: e } : null)
              }
              selectedKey={values.team?.id || null}
              isInvalid={Boolean(errors.team)}
              errorMessage={errors.team}
              color={errors.team ? 'danger' : 'default'}
            >
              {(item) => (
                <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>
              )}
            </Autocomplete>
          </div>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
