import React from 'react';
import Table from '@components/table/table';
import UserGroupDTO from '../dto/userGroup.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import UserGroupRepository from '../userGroup.repository';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface UserGroupProps {
  options?: FindOptionsDTO<UserGroupDTO>;
}

const UserGroupList = (props: UserGroupProps) => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const getUserGroups = useCallback(
    async (options?: FindOptionsDTO<UserGroupDTO>) => {
      return await new UserGroupRepository().find({
        ...options,
        order: { name: 'ASC' },
      });
    },
    [],
  );

  const handleSearcher = useCallback(
    (textSearched: string, options?: FindOptionsDTO<UserGroupDTO>) => {
      return {
        ...options,
        where: { name: `lk=${textSearched}` },
      };
    },
    [],
  );

  const handleAddButton = useCallback(() => {
    navigate('/enterprise/configuration/userGroup/add');
  }, [navigate]);

  const handleEditButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/userGroup/edit/${id}`);
    },
    [navigate],
  );

  const handleViewButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/userGroup/view/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback(async (id: string) => {
    await new UserGroupRepository().delete(id);
  }, []);

  return (
    <Table<UserGroupDTO>
      headers={['name']}
      handlerRequest={getUserGroups}
      handlerSearcher={handleSearcher}
      options={props.options}
      configurations={{
        translationLabel: 'userGroup',
        add: {
          handler: handleAddButton,
          isAllowed: Boolean(
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.USER_GROUP &&
                permission.methods === AllowedMethods.ADD,
            ),
          ),
        },
        edit: {
          handler: handleEditButton,
          isAllowed: Boolean(
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.USER_GROUP &&
                permission.methods === AllowedMethods.MODIFY,
            ),
          ),
        },
        view: {
          handler: handleViewButton,
          isAllowed: Boolean(
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.USER_GROUP &&
                permission.methods === AllowedMethods.VIEW,
            ),
          ),
        },
        delete: {
          field: 'name',
          isAllowed: Boolean(
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.USER_GROUP &&
                permission.methods === AllowedMethods.DELETE,
            ),
          ),
          handler: handleDelete,
        },
      }}
    />
  );
};

export default UserGroupList;
