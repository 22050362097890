import { Button, Card, CardBody } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import LicenseTypeDTO from '../dto/licenseType.dto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import LicenseTypeRepository from '../licenseType.repository';

const LicenseItem = ({
  licenseType,
  onDelete,
  isSubmitting,
}: {
  licenseType: LicenseTypeDTO;
  onDelete: () => void;
  isSubmitting: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardBody className="p-5">
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <div className="bg-primary rounded-full w-[28px] h-[28px] flex items-center justify-center">
              <FontAwesomeIcon
                className="text-white text-[12px]"
                icon={faCalendar}
              />
            </div>
            <span className="font-bold">
              {licenseType.name} (
              {t('entities.licenseType.values.days', {
                days: licenseType.days,
              })}
              )
            </span>
          </div>
          <Button
            color="danger"
            onClick={onDelete}
            className="min-w-4 text-[12px]"
            isLoading={isSubmitting}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
        <div>
          <span className="text-[#475569] text-sm">
            {t('entities.licenseType.values.renew', {
              month: t(
                `entities.licenseType.values.renewalMonth.${licenseType.renewalMonth}`,
              ),
            })}
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

const LicenseTypeList = () => {
  const [licenseTypeList, setLicenseTypeList] = useState<LicenseTypeDTO[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const getLicenseTypes = async () => {
    const licenseTypes = await new LicenseTypeRepository().find();
    setLicenseTypeList(Object.assign([], licenseTypes.results));
  };

  useEffect(() => {
    getLicenseTypes();
  }, []);

  const handlerDeleteLicenseType = async (id: string) => {
    setSubmitting(true);
    await new LicenseTypeRepository().delete(id);
    setTimeout(() => {}, 200);
    getLicenseTypes();
    setSubmitting(false);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {licenseTypeList.map((licenseType) => (
        <LicenseItem
          key={licenseType.id}
          licenseType={licenseType}
          onDelete={() => handlerDeleteLicenseType(licenseType.id)}
          isSubmitting={isSubmitting}
        />
      ))}
    </div>
  );
};

export default LicenseTypeList;
