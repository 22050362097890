import RankingIcon from '@components/icons/ranking.icon';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
  useDisclosure,
} from '@nextui-org/react';
import React from 'react';
import gift from '@assets/gif/gift.gif';
import { LevelDTO } from '../../dto/level.dto';
import { useTranslation } from 'react-i18next';

interface RewardsModalProps {
  levels: LevelDTO[];
}

const RewardsModal = ({ levels }: RewardsModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  return (
    <>
      <Button
        color="primary"
        variant="flat"
        startContent={<RankingIcon />}
        className="mt-6 "
        onPress={onOpen}
      >
        {t('user.level.rewardsModal.button')}{' '}
        <span className="hidden md:inline">
          {t('user.level.rewardsModal.perLevel')}
        </span>
      </Button>
      <Modal
        size="4xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="gap-5">
                <img src={gift} alt="Gift" className="m-0 size-10" />
                <div>
                  <p className="text-xl">
                    {t('user.level.rewardsModal.title')}
                  </p>
                  <p className="font-normal">
                    {t('user.level.rewardsModal.description')}
                  </p>
                </div>
              </ModalHeader>
              <ModalBody>
                <Table
                  classNames={{
                    wrapper: 'shadow-none',
                    thead: '[&>tr]:first:border-b-0',
                    tr: 'border-b-2 border-divider',
                    th: 'text-primary bg-primary-100 font-normal text-md [&>tr]:first:border-b-0',
                  }}
                >
                  <TableHeader>
                    <TableColumn key={'number'}>
                      {t('user.level.rewardsModal.table.level')}
                    </TableColumn>
                    <TableColumn key={'rockets'}>
                      {t('user.level.rewardsModal.table.objective')}
                    </TableColumn>
                    <TableColumn key={'reward'}>
                      {t('user.level.rewardsModal.table.rewards')}
                    </TableColumn>
                  </TableHeader>
                  <TableBody items={levels}>
                    {(item) => (
                      <TableRow key={item.number}>
                        {(columnKey) => (
                          <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose} className="w-full">
                  {t('user.level.rewardsModal.close')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default RewardsModal;
