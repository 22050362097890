import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import InitiativeDTO from '@modules/objectiveModule/initiative/dto/initiative.dto';
import UpdateInitiativeDTO from '@modules/objectiveModule/initiative/dto/updateInitiative.dto';
import InitiativeRepository from '@modules/objectiveModule/initiative/initiative.repository';
import InitiativeForm from '@modules/objectiveModule/initiative/initiativeForm';
import { Spinner } from '@nextui-org/react';
import CompareUtil from '@utils/compare';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const InitiativeEditPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [initiative, setInitiative] = useState<InitiativeDTO | null>(null);

  const handleSubmit = async (values: UpdateInitiativeDTO) => {
    if (!initiative) return;
    await new InitiativeRepository().update(initiative?.id as string, {
      ...(CompareUtil.compareObjects(
        initiative,
        values,
      ) as UpdateInitiativeDTO),
      manager: values.manager,
    });
    navigate(-1);
  };

  const getInitiative = async (options?: FindOptionsDTO<InitiativeDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const initiativeResult = await new InitiativeRepository().findOne(
      id as string,
      {
        ...options,
        relations: Object.assign(options?.relations || []),
      },
    );
    if (!initiativeResult) navigate(-1);
    setInitiative(initiativeResult);
    setLoading(false);
  };

  useEffect(() => {
    getInitiative();
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  return isLoading || !initiative ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.initiative.edit')}
      </h3>
      <InitiativeForm onSubmit={handleSubmit} initialValues={initiative} />
    </div>
  );
};

export default InitiativeEditPage;
