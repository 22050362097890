import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import ProfileDTO from './dto/profile.dto';
import { RootState } from 'store';
import SignupDTO from './dto/signup.dto';
import SignupType from './enum/signupType.enum';
import UserSignupDTO from './dto/userSignup.dto';

export interface AuthState {
  profile: ProfileDTO | null;
  signup: SignupDTO;
}

const initialState: AuthState = {
  profile: null,
  signup: {
    enterpriseName: '',
    taxId: '',
    taxAddress: '',
    activity: '',
    employeeCount: 1,
    type: SignupType.PARTICULAR,
    contact: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
    },
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfile: (state: AuthState, action: PayloadAction<ProfileDTO>) => {
      state.profile = action.payload;
    },
    setSignupContact: (
      state: AuthState,
      action: PayloadAction<UserSignupDTO>,
    ) => {
      state.signup.contact = action.payload;
    },
    setSignupEnterprise: (
      state: AuthState,
      action: PayloadAction<SignupDTO>,
    ) => {
      state.signup = action.payload;
    },
    clearSignup: (state: AuthState) => {
      state.signup = initialState.signup;
    },
  },
});

export const {
  setProfile,
  setSignupContact,
  setSignupEnterprise,
  clearSignup,
} = authSlice.actions;

export const selectProfile = (state: RootState) => state.authReducer.profile;
export const getSignupContact = (state: RootState) =>
  state.authReducer.signup.contact;
export const getSignupEnterprise = (state: RootState) =>
  state.authReducer.signup;

export default authSlice.reducer;
