import { QuartersType } from '@lib/type/quarters.type';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface MetricFiltersProviderProps {
  children: ReactNode;
}

interface FilterType {
  quarter: QuartersType;
}

interface MetricsFiltersContextType {
  filters: FilterType;
  setFilters: Dispatch<SetStateAction<FilterType>>;
}

const MetricFiltersContext = createContext<
  MetricsFiltersContextType | undefined
>(undefined);

export const MetricFiltersProvider = ({
  children,
}: MetricFiltersProviderProps) => {
  const [filters, setFilters] = useState<FilterType>({ quarter: 'Q1' });

  return (
    <MetricFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </MetricFiltersContext.Provider>
  );
};

export const useMetricFiltersContext = (): MetricsFiltersContextType => {
  const context = useContext(MetricFiltersContext);
  if (!context)
    throw new Error(
      'useMetricFiltersContext must be used within a MetricFiltersProvider',
    );
  return context;
};
