import React from 'react';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import {
  Avatar,
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from '@nextui-org/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import PersonalData from '../components/personalData';
import Alert from '@components/alert/alert.component';

interface ProfileConfigProps {
  userId: string;
}

const ProfileConfig = (props: ProfileConfigProps) => {
  const [profile, setProfile] = useState<UserDTO | null>(null);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isUploading, setUploading] = useState<boolean>(false);
  const { t } = useTranslation();

  const getProfile = async () => {
    const returnedProfile = await new UserRepository().findMe({
      relations: ['userFile', 'team', 'enterprise', 'group'],
    });
    setProfile(returnedProfile);
  };

  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const [file, setFile] = useState<File | null>(null);
  const [fileTooLarge, setFileTooLarge] = useState<boolean>(false);
  
  const handleFileChange = (file: File | null) => {
    if (file && file.size <= 1024000) {
      setFileTooLarge(false);
    } else {
      file = null;
      setFileTooLarge(true);
    }
    setFile(file);
  };

  const uploadImage = async () => {
    if (file) {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      await axios.post(`/api/user/avatar/${props.userId}`, formData);
      setUploading(false);
      onOpenChange();
      setProfile(null);
      getProfile();
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return profile === null ? (
    <div className="flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <div className="py-5">
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <Badge
            content={
              <div className="cursor-pointer" onClick={onOpen}>
                <FontAwesomeIcon
                  className="text-[8px]"
                  icon={faArrowUpRightFromSquare}
                />
              </div>
            }
            color="primary"
            placement="bottom-right"
          >
            <Avatar
              name={`${profile?.firstName} ${profile?.lastName}`}
              size="lg"
              src={
                profile?.avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${profile?.avatar}`
              }
            />
          </Badge>
          <div>
            <div className="text-sm">{`${profile?.firstName} ${profile?.lastName}`}</div>
            <div className="text-xs text-gray-500">
              {profile?.userFile.jobTitle}
            </div>
          </div>
        </div>
        <div className="py-2 px-4 bg-primary-100 text-primary-800 rounded-[16px]">
          {profile?.team?.name}
        </div>
      </div>
      <PersonalData profile={profile} />
      <Modal
        isOpen={isOpen}
        onOpenChange={() => {
          setFile(null);
          onOpenChange();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>{t('user.profile.private.upload.load')}</ModalHeader>
              <ModalBody>
                <FileUploader
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                >
                  <div className="border-1 border-primary border-dashed rounded-lg px-3 py-2 cursor-pointer">
                    {file
                      ? t('user.profile.private.upload.success', {
                          name: file?.name,
                        })
                      : t('user.profile.private.upload.waiting')}
                  </div>
                </FileUploader>
                {fileTooLarge && (
                  <Alert type="danger">
                    {t('user.profile.private.upload.fileTooLarge')}
                  </Alert>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  {t('user.profile.private.upload.cancel')}
                </Button>
                <Button
                  color="primary"
                  isDisabled={!file}
                  isLoading={isUploading}
                  onClick={uploadImage}
                >
                  {t('user.profile.private.upload.upload')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProfileConfig;
