const SunIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.5769C11.4949 21.5769 11.0593 21.4019 10.6933 21.0519C10.3273 20.7019 10.1282 20.2743 10.0962 19.7692H13.9039C13.8718 20.2743 13.6727 20.7019 13.3067 21.0519C12.9407 21.4019 12.5051 21.5769 12 21.5769ZM9.00001 18.3846C8.78719 18.3846 8.60899 18.3128 8.46541 18.1692C8.32182 18.0256 8.25003 17.8474 8.25003 17.6346C8.25003 17.4218 8.32182 17.2436 8.46541 17.1C8.60899 16.9564 8.78719 16.8846 9.00001 16.8846H15C15.2128 16.8846 15.391 16.9564 15.5346 17.1C15.6782 17.2436 15.75 17.4218 15.75 17.6346C15.75 17.8474 15.6782 18.0256 15.5346 18.1692C15.391 18.3128 15.2128 18.3846 15 18.3846H9.00001ZM8.40386 15.5C7.35644 14.8487 6.52728 13.9977 5.91638 12.9471C5.30548 11.8964 5.00003 10.7474 5.00003 9.49998C5.00003 7.55128 5.67951 5.89743 7.03848 4.53845C8.39746 3.17948 10.0513 2.5 12 2.5C13.9487 2.5 15.6025 3.17948 16.9615 4.53845C18.3205 5.89743 19 7.55128 19 9.49998C19 10.7474 18.6945 11.8964 18.0836 12.9471C17.4727 13.9977 16.6436 14.8487 15.5962 15.5H8.40386ZM8.85001 14H15.15C15.9 13.4666 16.4792 12.8083 16.8875 12.025C17.2958 11.2416 17.5 10.4 17.5 9.49998C17.5 7.96664 16.9667 6.66664 15.9 5.59998C14.8333 4.53331 13.5333 3.99998 12 3.99998C10.4667 3.99998 9.16667 4.53331 8.10001 5.59998C7.03334 6.66664 6.50001 7.96664 6.50001 9.49998C6.50001 10.4 6.70417 11.2416 7.11251 12.025C7.52084 12.8083 8.10001 13.4666 8.85001 14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SunIcon;
