import IconProps from './common/icon.interface';

const LockIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.00002 14.6667C1.63335 14.6667 1.31946 14.5361 1.05835 14.275C0.797243 14.0139 0.666687 13.7 0.666687 13.3334V6.66669C0.666687 6.30002 0.797243 5.98613 1.05835 5.72502C1.31946 5.46391 1.63335 5.33335 2.00002 5.33335H2.66669V4.00002C2.66669 3.0778 2.99169 2.29169 3.64169 1.64169C4.29169 0.991687 5.0778 0.666687 6.00002 0.666687C6.92224 0.666687 7.70835 0.991687 8.35835 1.64169C9.00835 2.29169 9.33335 3.0778 9.33335 4.00002V5.33335H10C10.3667 5.33335 10.6806 5.46391 10.9417 5.72502C11.2028 5.98613 11.3334 6.30002 11.3334 6.66669V13.3334C11.3334 13.7 11.2028 14.0139 10.9417 14.275C10.6806 14.5361 10.3667 14.6667 10 14.6667H2.00002ZM2.00002 13.3334H10V6.66669H2.00002V13.3334ZM6.00002 11.3334C6.36669 11.3334 6.68058 11.2028 6.94169 10.9417C7.2028 10.6806 7.33335 10.3667 7.33335 10C7.33335 9.63335 7.2028 9.31947 6.94169 9.05835C6.68058 8.79724 6.36669 8.66669 6.00002 8.66669C5.63335 8.66669 5.31947 8.79724 5.05835 9.05835C4.79724 9.31947 4.66669 9.63335 4.66669 10C4.66669 10.3667 4.79724 10.6806 5.05835 10.9417C5.31947 11.2028 5.63335 11.3334 6.00002 11.3334ZM4.00002 5.33335H8.00002V4.00002C8.00002 3.44446 7.80558 2.97224 7.41669 2.58335C7.0278 2.19446 6.55558 2.00002 6.00002 2.00002C5.44447 2.00002 4.97224 2.19446 4.58335 2.58335C4.19446 2.97224 4.00002 3.44446 4.00002 4.00002V5.33335Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LockIcon;
