import PlusIcon from '@components/icons/plus.icon';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Select,
  SelectItem,
  Spinner,
  useDisclosure,
} from '@nextui-org/react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import DocumentBrowserItem from './documentBrowserItem';
import { useLocation } from 'react-router-dom';
import DocumentNameModal from './documentNameModal';
import { useDocumentContext } from '../context/document.context';
import { useTranslation } from 'react-i18next';
import { DocumentSortBy } from '../enum/documentSortBy.enum';

const DocumentBrowser = () => {
  const { t } = useTranslation();
  const { documents, isBrowserLoading, isBrowserOpen, sortBy, setSortBy } =
    useDocumentContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const nameModal = useDisclosure();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {(!isMobile || location.pathname.includes('browse')) && (
        <>
          <Card className="shadow-none lg:min-h-96">
            <CardHeader className="px-3 flex-col">
              <div className="w-full flex justify-between items-center border-b-2 pb-2">
                <span className="font-bold text-lg">
                  {t('user.document.browser.title')}
                </span>
                <Button isIconOnly color="primary" onPress={nameModal.onOpen}>
                  <PlusIcon className="h-6 w-6" />
                </Button>
              </div>
              <Select
                label="Ordenar por"
                size="sm"
                className="mt-2"
                selectedKeys={[sortBy]}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setSortBy(e.target.value as DocumentSortBy)
                }
              >
                <SelectItem key={DocumentSortBy.Access}>Acceso</SelectItem>
                <SelectItem key={DocumentSortBy.TitleAsc}>
                  Título (Asc)
                </SelectItem>
                <SelectItem key={DocumentSortBy.TitleDesc}>
                  Título (Desc)
                </SelectItem>
                <SelectItem key={DocumentSortBy.ModifiedDateDesc}>
                  Última modificación (Asc)
                </SelectItem>
                <SelectItem key={DocumentSortBy.ModifiedDateAsc}>
                  Última modificación (Desc)
                </SelectItem>
              </Select>
            </CardHeader>
            <CardBody
              className={`pt-0 transition-all ${isBrowserOpen ? 'flex' : 'hidden'}`}
            >
              {isBrowserLoading ? (
                <Spinner className="mt-5" />
              ) : (
                documents.map((doc) => {
                  if (!doc.parent)
                    return <DocumentBrowserItem key={doc.id} document={doc} />;
                })
              )}
            </CardBody>
          </Card>
          <DocumentNameModal
            isOpen={nameModal.isOpen}
            onOpenChange={nameModal.onOpenChange}
          />
        </>
      )}
    </>
  );
};

export default DocumentBrowser;
