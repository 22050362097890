import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faWrench } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Chip,
  CircularProgress,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Tab,
  Tabs,
  User,
} from '@nextui-org/react';
import CommentRepository from '@modules/objectiveModule/comment/comment.repository';
import CommentList from '@modules/objectiveModule/comment/components/commentList';
import { useNavigate } from 'react-router-dom';
import KeyResultDTO from '../dto/keyResult.dto';
import KeyResultRepository from '../keyResult.repository';
import KeyResultPeriodLabel from './keyResultPeriodLabel';
import KeyResultMeasurementType from '../enum/keyResultMeasurementType.enum';
import KeyResultStatusChange from './keyResultStatusChange';
import KeyResultStatus from '../enum/keyResultStatus.enum';
import InitiativeList from '@modules/objectiveModule/initiative/components/initiativeList';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';

interface KeyResultDetailProps {
  id: string;
}

const KeyResultDetail = (props: KeyResultDetailProps) => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [keyResultValues, setKeyResultValues] = useState(0);
  const [keyResult, setKeyResult] = useState<KeyResultDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const getKeyResult = async (options?: FindOptionsDTO<KeyResultDTO>) => {
    setLoading(true);
    const keyResultRequest = await new KeyResultRepository().findOne(props.id, {
      ...options,
      relations: Object.assign([], options?.relations, [
        'manager',
        'colaborators',
        'notified',
        'categories',
      ]),
    });
    setKeyResult(keyResultRequest);
    setKeyResultValues(keyResultRequest?.initialValue || 0);
    setLoading(false);
  };

  useEffect(() => {
    getKeyResult();
  }, []);

  const onEditKeyResultValue = async (
    value: number,
    options?: FindOptionsDTO<KeyResultDTO>,
  ) => {
    await new KeyResultRepository().update(props.id, {
      initialValue: Number(value),
      expectedValue: Number(keyResult?.expectedValue),
    });
    const keyResultRequest = await new KeyResultRepository().findOne(props.id, {
      ...options,
      relations: Object.assign([], options?.relations, [
        'manager',
        'colaborators',
        'notified',
        'categories',
      ]),
    });
    setKeyResult(keyResultRequest);
  };

  const onEditKeyResultStatus = async (
    value: KeyResultStatus,
    options?: FindOptionsDTO<KeyResultDTO>,
  ) => {
    await new KeyResultRepository().update(props.id, { status: value });
    const keyResultRequest = await new KeyResultRepository().findOne(props.id, {
      ...options,
      relations: Object.assign([], options?.relations, [
        'manager',
        'colaborators',
        'notified',
        'categories',
      ]),
    });
    setKeyResult(keyResultRequest);
  };

  const Loading = () => {
    return (
      <div className="py-5 flex flex-col gap-5">
        <div>
          <Skeleton className="h-6 w-64"></Skeleton>
        </div>
        <div className="flex flex-col">
          <Skeleton className="w-64 h-5"></Skeleton>
          <Skeleton className="w-64 h-5"></Skeleton>
        </div>
        <div className="flex flex-wrap gap-8">
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
          <div className="flex flex-col gap-3">
            <Skeleton className="w-64 h-5"></Skeleton>
            <Skeleton className="w-64 h-5"></Skeleton>
          </div>
        </div>
      </div>
    );
  };

  const handleCommentSubmit = async (content: string) => {
    await new CommentRepository().save({
      content: content,
      keyResult: { id: props.id },
      parent: null,
    });
  };

  const handlerDelete = async () => {
    await new KeyResultRepository().delete(props.id);
    navigate(-1);
  };

  return isLoading || !keyResult ? (
    <Loading />
  ) : (
    <div>
      <div className="py-3 flex flex-col gap-8">
        <div className="flex gap-5">
          <div>
            <span className="flex gap-3 items-center font-bold text-lg text-foreground">
              <span className="bg-primary text-primary-foreground font-bold p-3 rounded">
                KR
              </span>
              {keyResult.name}
            </span>
          </div>
          <div className="flex gap-3">
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.KEY_RESULT &&
                permission.methods === AllowedMethods.MODIFY,
            ) && (
              <Button
                color="warning"
                size="sm"
                variant="flat"
                onClick={() =>
                  navigate(`/enterprise/keyResult/edit/${props.id}`)
                }
              >
                <FontAwesomeIcon icon={faWrench} />
              </Button>
            )}
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.KEY_RESULT &&
                permission.methods === AllowedMethods.DELETE,
            ) && (
              <Button
                color="danger"
                size="sm"
                variant="flat"
                onClick={() => setModalOpen(!isModalOpen)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-bold text-foreground-500">
            {t('entities.keyResult.fields.description')}
          </span>
          <span className="text-foreground">{keyResult.description}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-bold text-foreground-500">
            {t('entities.keyResult.fields.categories')}
          </span>
          <div className="flex gap-3 pt-1">
            {keyResult.categories.length === 0 && ' - '}
            {keyResult.categories.map((category) => (
              <Chip key={category.id} color="primary">
                {category.name}
              </Chip>
            ))}
          </div>
        </div>
        <div className="flex flex-wrap gap-16">
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.keyResult.fields.period')}
            </span>
            <div className="h-full flex items-center">
              <KeyResultPeriodLabel
                periodFrom={keyResult.periodFrom as Date}
                periodTo={keyResult.periodTo as Date}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.keyResult.fields.manager')}
            </span>
            <div className="h-full flex items-center">
              <User
                classNames={{ name: 'text-foreground' }}
                name={keyResult.manager.fullName}
                description={keyResult.manager.email}
                avatarProps={{
                  src:
                    keyResult.manager.avatar &&
                    `https://giveit-system-assets.s3.amazonaws.com/${keyResult.manager.avatar}`,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.keyResult.fields.status')}
            </span>
            <div className="h-full flex items-center">
              <KeyResultStatusChange
                status={keyResult.status}
                onChange={onEditKeyResultStatus}
                disabled={
                  !profile?.group.permissions.some(
                    (permission) =>
                      permission.entity === AllowedEntities.KEY_RESULT &&
                      permission.methods === AllowedMethods.MODIFY,
                  )
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.keyResult.fields.progress')}
            </span>
            <div className="h-full flex items-center">
              <CircularProgress
                classNames={{ svg: 'w-16 h-16' }}
                value={keyResult.progress}
                showValueLabel={true}
                color="success"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-16">
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t(`entities.keyResult.fields.measurementType`)}
            </span>
            <div className="h-full flex items-center">
              {t(
                `entities.keyResult.values.measurementType.${keyResult.measurementType}`,
              )}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.keyResult.fields.initialValue')}
            </span>
            <div className="h-full flex items-center">
              {keyResult.measurementType ===
                KeyResultMeasurementType.CURRENCY && '$'}{' '}
              {keyResult.measurementType ===
                KeyResultMeasurementType.PERCENTAGE && '%'}
              <Input
                type="number"
                onChange={(event) => {
                  setKeyResultValues(Number(event.target.value));
                  onEditKeyResultValue(Number(event.target.value));
                }}
                value={String(keyResultValues)}
                isDisabled={
                  !profile?.group.permissions.some(
                    (permission) =>
                      permission.entity === AllowedEntities.KEY_RESULT &&
                      permission.methods === AllowedMethods.MODIFY,
                  )
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-bold text-foreground-500">
              {t('entities.keyResult.fields.expectedValue')}
            </span>
            <div className="h-full flex items-center">
              {keyResult.expectedValue}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-sm font-bold text-foreground-500">
            {t('entities.keyResult.fields.colaborators')}
          </span>
          <div className="h-full flex items-center gap-3">
            {keyResult.colaborators.length > 0
              ? keyResult.colaborators.map((colaborator) => (
                  <User
                    key={colaborator.id}
                    classNames={{ name: 'text-foreground' }}
                    name={colaborator.fullName}
                    description={colaborator.email}
                    avatarProps={{
                      src:
                        colaborator.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${colaborator.avatar}`,
                    }}
                  />
                ))
              : '-'}
          </div>
        </div>
        <Tabs>
          {profile?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.INITIATIVE &&
              permission.methods === AllowedMethods.LIST,
          ) && (
            <Tab title={t('user.keyResult.tabs.initiatives')}>
              {profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.INITIATIVE &&
                  permission.methods === AllowedMethods.ADD,
              ) && (
                <div className="flex justify-end">
                  <Button
                    color="primary"
                    onClick={() =>
                      navigate(`/enterprise/initiative/add/${props.id}`)
                    }
                  >
                    {t('user.initiative.add.title')}
                  </Button>
                </div>
              )}
              <InitiativeList keyResult={props.id} />
            </Tab>
          )}
          <Tab title={t('user.keyResult.tabs.comments')}>
            <CommentList
              keyResult={props.id}
              handleSubmit={handleCommentSubmit}
            />
          </Tab>
        </Tabs>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)}>
        <ModalContent>
          <ModalHeader>{t(`entities.keyResult.table.deleteTitle`)}</ModalHeader>
          <ModalBody>
            {t(`entities.keyResult.table.delete`, { name: keyResult.name })}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModalOpen(!isModalOpen)}>
              {t(`entities.keyResult.table.back`)}
            </Button>
            <Button
              color="danger"
              onClick={async () => {
                await handlerDelete();
              }}
            >
              {t(`entities.keyResult.table.deleteButton`)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default KeyResultDetail;
