import { RouteObject } from 'react-router-dom';
import DocumentBrowsePage from './browse/documentsBrowse.page';
import OnBoardingMessage from '@modules/documentModule/browser/components/onBoardingMessage';
import DocumentViewer from '@modules/documentModule/browser/components/documentViewer';
import DocumentEditor from '@modules/documentModule/browser/components/documentEditor';
import ErrorMessage from '@modules/documentModule/browser/components/errorMessage';
import { DocumentProvider } from '@modules/documentModule/browser/context/document.context';

const documentRoutes: Array<RouteObject> = [
  {
    path: '',
    element: (
      <DocumentProvider>
        <DocumentBrowsePage />
      </DocumentProvider>
    ),
    children: [
      { path: 'browse', element: <OnBoardingMessage /> },
      { path: 'view/:id', element: <DocumentViewer /> },
      { path: 'edit', element: <DocumentEditor /> },
      { path: 'edit/:id', element: <DocumentEditor /> },
      { path: 'error', element: <ErrorMessage /> },
    ],
  },
];

export default documentRoutes;
