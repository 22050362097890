import FilterIcon from '@components/icons/filter.icon';
import TagInput from '@components/tagInput/tagInput.component';
import {
  Accordion,
  AccordionItem,
  Button,
  Checkbox,
  Chip,
  Divider,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
} from '@nextui-org/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import PostCategoryDTO from '../../createPost/dto/postCategory.dto';
import FeedFiltersDTO from '../dto/feedFilters.dto';
import SearchIcon from '@components/icons/search.icon';
import { useTranslation } from 'react-i18next';

interface FeedFiltersProps {
  filters: FeedFiltersDTO;
  setFilters: Dispatch<SetStateAction<FeedFiltersDTO>>;
  findOrSaveTag: (name: string) => Promise<string>;
}

const FeedFilters = ({
  filters,
  setFilters,
  findOrSaveTag,
}: FeedFiltersProps) => {
  const { t } = useTranslation();
  const { sortBy, tags, showPinned } = filters;
  const [tagList, setTagList] = useState<PostCategoryDTO[]>([]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    setFilters({ ...filters, tags: tagList });
  }, [tagList]);

  return (
    <div className="flex items-center gap-2">
      <Input
        className="w-full m-0"
        placeholder={t('user.dashboard.feed.filters.searchPlaceholder')}
        onChange={(event) =>
          setFilters({ ...filters, search: event.target.value })
        }
        classNames={{
          innerWrapper: 'pl-5',
          inputWrapper:
            'shadow-none bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
        }}
        startContent={
          <SearchIcon className="mr-5 text-foreground hidden md:block" />
        }
        endContent={
          <Popover
            showArrow
            classNames={{
              trigger: 'w-1/4',
              content: 'max-w-96 min-w-60 pt-2',
            }}
            placement="bottom"
          >
            <PopoverTrigger>
              <Button
                variant="light"
                color="primary"
                className="dark:text-primary-600"
              >
                <FilterIcon /> {t('user.dashboard.feed.filters.filters')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
              {(titleProps) => (
                <div className="w-full p-2">
                  <div className="text-base" {...titleProps}>
                    {t('user.dashboard.feed.filters.filterPosts')}
                  </div>
                  <Accordion selectionMode="multiple">
                    <AccordionItem
                      title={t('user.dashboard.feed.filters.sortBy.title')}
                      subtitle={
                        sortBy === 'recent'
                          ? t('user.dashboard.feed.filters.sortBy.recent')
                          : t('user.dashboard.feed.filters.sortBy.relevant')
                      }
                      classNames={{
                        title: 'text-sm',
                        subtitle: 'text-xs text-primary',
                      }}
                    >
                      <RadioGroup
                        value={sortBy}
                        onValueChange={(value) => {
                          setFilters({ ...filters, sortBy: value });
                        }}
                      >
                        <Radio
                          value={'recent'}
                          classNames={{ label: 'text-sm' }}
                        >
                          {t('user.dashboard.feed.filters.sortBy.recent')}
                        </Radio>
                        <Radio
                          value={'relevant'}
                          classNames={{ label: 'text-sm' }}
                        >
                          {t('user.dashboard.feed.filters.sortBy.relevant')}
                        </Radio>
                      </RadioGroup>
                    </AccordionItem>
                    <AccordionItem
                      title={t('user.dashboard.feed.filters.tags.title')}
                      subtitle={t('user.dashboard.feed.filters.tags.selected', {
                        amount: tags.length,
                      })}
                      classNames={{
                        title: 'font-normal text-sm',
                        subtitle: `${
                          tags.length > 0 && 'text-primary'
                        } text-xs`,
                      }}
                    >
                      <div className="space-x-2 space-y-2 mb-2">
                        {tags.map((tag) => (
                          <Chip
                            onClose={() => {
                              setTagList(tagList.filter((t) => t !== tag));
                            }}
                            color="primary"
                            key={tag.name}
                          >
                            {tag.name}
                          </Chip>
                        ))}
                      </div>
                      <TagInput
                        tags={tagList}
                        setTags={setTagList}
                        findOrSaveTag={findOrSaveTag}
                      />
                    </AccordionItem>
                  </Accordion>
                  <div className="px-2">
                    <Divider />
                    <Checkbox
                      isSelected={showPinned}
                      onValueChange={(value) =>
                        setFilters({ ...filters, showPinned: value })
                      }
                      classNames={{ base: 'my-4', label: 'text-sm' }}
                    >
                      {t('user.dashboard.feed.filters.showPinned')}
                    </Checkbox>
                  </div>
                  <Button
                    color="primary"
                    variant="flat"
                    className="w-full"
                    onPress={() =>
                      setFilters({
                        sortBy: 'recent',
                        tags: [],
                        showPinned: true,
                        search: '',
                      })
                    }
                  >
                    {t('user.dashboard.feed.filters.removeFilters')}
                  </Button>
                </div>
              )}
            </PopoverContent>
          </Popover>
        }
      />
    </div>
  );
};

export default FeedFilters;
