import { BlockNoteEditor } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import { validateBlockNote } from '@components/customBlockNote/customBlockNote.component';
import {
  CustomPartialBlock,
  customSchema,
} from '@components/customBlockNote/customSchema';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChallengesRepository from '@modules/voteModule/challenges/challenges.repository';
import ChallengeForm from '@modules/voteModule/challenges/configuration/components/challenge.form';
import ChallengeDTO from '@modules/voteModule/challenges/configuration/dto/challenge.dto';
import CreateChallengeDTO from '@modules/voteModule/challenges/configuration/dto/createChallenge.dto';
import { Card, CardBody, Spinner } from '@nextui-org/react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChallengeEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [blocknoteError, setBlocknoteError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [challengeResult, setChallengeResult] = useState<ChallengeDTO>();
  const [challengesRepository] = useState(new ChallengesRepository());
  const [initialContent, setInitialContent] = useState<
    CustomPartialBlock[] | 'loading' | null | undefined
  >('loading');

  const editor = useMemo(() => {
    if (initialContent === 'loading') {
      return undefined;
    }
    if (initialContent === null) {
      return BlockNoteEditor.create({ schema: customSchema });
    }
    return BlockNoteEditor.create({ initialContent, schema: customSchema });
  }, [initialContent]);

  const functionalEditor = useCreateBlockNote({ schema: customSchema });

  const getChallenge = async () => {
    setLoading(true);
    if (!id) navigate(-1);
    const challengeResult = await challengesRepository.get({
      where: { id },
      take: 1,
    });
    if (!challengeResult) navigate(-1);
    setChallengeResult(challengeResult[0]);
    const blocks = await functionalEditor.tryParseHTMLToBlocks(
      challengeResult[0].assignment,
    );
    setInitialContent(blocks);
    setLoading(false);
  };

  useEffect(() => {
    getChallenge();
  }, []);

  if (editor) editor.onEditorContentChange(() => setBlocknoteError(false));

  const handleSubmitForm = async (values: CreateChallengeDTO) => {
    if (!editor) return;
    if (validateBlockNote(editor)) return setBlocknoteError(true);
    const assignment = await editor.blocksToHTMLLossy(editor.document);
    const challenge = { ...values, assignment };
    if (id) await challengesRepository.update(challenge, id);
    navigate(-1);
  };

  return (
    <div>
      {' '}
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        Editar desafío
      </h3>
      {loading || !challengeResult || !editor ? (
        <div className="h-80 w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <Card className="shadow-none overflow-visible mb-5">
          <CardBody className="overflow-visible">
            <ChallengeForm
              onSubmit={handleSubmitForm}
              editor={editor}
              blocknoteError={blocknoteError}
              initialValues={challengeResult}
            />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default ChallengeEditPage;
