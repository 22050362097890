import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateInitiativeDTO from '@modules/objectiveModule/initiative/dto/createInitiative.dto';
import InitiativeRepository from '@modules/objectiveModule/initiative/initiative.repository';
import InitiativeForm from '@modules/objectiveModule/initiative/initiativeForm';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const InitiativeAddPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const handleSubmit = async (values: CreateInitiativeDTO) => {
    await new InitiativeRepository().save({
      ...values,
      keyResult: {
        id: id as string,
      },
    });
    navigate(-1);
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.initiative.add.title')}
      </h3>
      <InitiativeForm onSubmit={handleSubmit} />
    </div>
  );
};

export default InitiativeAddPage;
