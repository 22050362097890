import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import CategoryDTO from './dto/category.dto';
import CreateCategoryDTO from './dto/createCategory.dto';

export default class CategoryRepository {
  private url: string = '/api/category';

  async find(
    options?: FindOptionsDTO<CategoryDTO>,
  ): Promise<PaginatedDTO<CategoryDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const commentResponse = await axios.get<PaginatedDTO<CategoryDTO>>(
      `${this.url}${stringOption}`,
    );
    return commentResponse.data;
  }

  async save(comment: CreateCategoryDTO) {
    const createdCommentResponse = await axios.post<CategoryDTO>(
      `${this.url}`,
      comment,
    );
    return createdCommentResponse.data;
  }
}
