export default class CompareUtil {
  static compareObjects<T extends Record<string, any>>(
    obj1: T,
    obj2: T,
  ): Partial<T> {
    const diff: any = {};

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const nestedDiff = this.compareObjects(obj1[key], obj2[key]);
          if (Object.keys(nestedDiff).length > 0) {
            diff[key] = nestedDiff;
          }
        } else if (obj1[key] !== obj2[key]) {
          diff[key] = obj2[key];
        }
      }
    }

    return diff;
  }
}
