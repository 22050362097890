import axios from 'axios';
import CreateRecognitionVoteDTO from './dto/createRecognitionVote.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import RecognitionVoteDTO from './dto/recognitionVote.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';

export default class RecognitionVoteRepository {
  private url: string = '/api/recognitionVote';

  async vote(entity: CreateRecognitionVoteDTO) {
    return await axios.post(`${this.url}/vote`, entity);
  }

  async getAvailableRockets(): Promise<number> {
    const availableRocketsResponse = await axios.get<number>(
      `${this.url}/availableRockets`,
    );
    return availableRocketsResponse.data;
  }

  async find(
    options?: FindOptionsDTO<RecognitionVoteDTO>,
  ): Promise<PaginatedDTO<RecognitionVoteDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const recognitionResponse = await axios.get<
      PaginatedDTO<RecognitionVoteDTO>
    >(`${this.url}${stringOption}`);
    return recognitionResponse.data;
  }
}
