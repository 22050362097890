import React from 'react';
import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserGroupDTO from '../dto/userGroup.dto';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import UserGroupRepository from '../userGroup.repository';

interface UserGroupDetailProps {
  id: string;
  options?: FindOptionsDTO<UserDTO>;
}

const UserGroupDetail = (props: UserGroupDetailProps) => {
  const { t } = useTranslation();
  const [userGroupResult, setUserGroupResult] = useState<UserGroupDTO | null>(
    null,
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  const getUserGroup = async (options?: FindOptionsDTO<UserDTO>) => {
    setLoading(true);
    const userGroupResultData = await new UserGroupRepository().findOne(
      props.id as string,
      options,
    );
    setUserGroupResult(userGroupResultData);
    setLoading(false);
  };

  useEffect(() => {
    getUserGroup({
      ...props.options,
      relations: Object.assign(['permissions'], props.options?.relations),
    });
  }, [props.options]);

  return (
    <Card className="my-5">
      <CardBody>
        <div className="grid grid-cols-1">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.userGroup.fields.name')}
              </strong>{' '}
              {userGroupResult?.name}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1">
          <p>
            <strong className="text-foreground">
              {t('entities.userGroup.fields.permissions')}
            </strong>
          </p>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <ul>
              {userGroupResult?.permissions.map((permission) => (
                <li key={`${permission.entity}-${permission.methods}`}>
                  <strong className="text-foreground">
                    {t(
                      `entities.userGroup.values.permissions.entities.${permission.entity}`,
                    )}
                    :
                  </strong>{' '}
                  {t(
                    `entities.userGroup.values.permissions.methods.${permission.methods}`,
                  )}
                </li>
              ))}
            </ul>
          </Skeleton>
        </div>
      </CardBody>
    </Card>
  );
};

export default UserGroupDetail;
