import IconProps from './common/icon.interface';

const InfoIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.00022 11.1667C8.14195 11.1667 8.26068 11.1187 8.3564 11.0229C8.45213 10.9271 8.49999 10.8083 8.49999 10.6667V7.83332C8.49999 7.69167 8.45205 7.57292 8.35619 7.47709C8.26031 7.38125 8.14151 7.33334 7.99979 7.33334C7.85805 7.33334 7.73933 7.38125 7.6436 7.47709C7.54788 7.57292 7.50002 7.69167 7.50002 7.83332V10.6667C7.50002 10.8083 7.54795 10.9271 7.64382 11.0229C7.7397 11.1187 7.8585 11.1667 8.00022 11.1667ZM8 6.19232C8.15257 6.19232 8.28045 6.14072 8.38365 6.03752C8.48685 5.93432 8.53845 5.80644 8.53845 5.65387C8.53845 5.50132 8.48685 5.37343 8.38365 5.27022C8.28045 5.16702 8.15257 5.11542 8 5.11542C7.84744 5.11542 7.71955 5.16702 7.61635 5.27022C7.51315 5.37343 7.46155 5.50132 7.46155 5.65387C7.46155 5.80644 7.51315 5.93432 7.61635 6.03752C7.71955 6.14072 7.84744 6.19232 8 6.19232ZM8.00112 14.3333C7.12516 14.3333 6.30181 14.1671 5.53105 13.8347C4.76029 13.5022 4.08983 13.051 3.51969 12.4812C2.94953 11.9113 2.49816 11.2411 2.16557 10.4707C1.83298 9.70026 1.66669 8.87708 1.66669 8.00112C1.66669 7.12516 1.83291 6.30181 2.16535 5.53105C2.4978 4.76029 2.94896 4.08983 3.51885 3.51969C4.08875 2.94953 4.75891 2.49816 5.52934 2.16557C6.29975 1.83298 7.12293 1.66669 7.99889 1.66669C8.87484 1.66669 9.6982 1.83291 10.469 2.16535C11.2397 2.4978 11.9102 2.94896 12.4803 3.51885C13.0505 4.08875 13.5018 4.75892 13.8344 5.52934C14.167 6.29975 14.3333 7.12293 14.3333 7.99889C14.3333 8.87484 14.1671 9.6982 13.8347 10.469C13.5022 11.2397 13.051 11.9102 12.4812 12.4803C11.9113 13.0505 11.2411 13.5018 10.4707 13.8344C9.70026 14.167 8.87708 14.3333 8.00112 14.3333ZM8 13.3333C9.48889 13.3333 10.75 12.8167 11.7833 11.7833C12.8167 10.75 13.3333 9.48889 13.3333 8C13.3333 6.51112 12.8167 5.25 11.7833 4.21667C10.75 3.18334 9.48889 2.66667 8 2.66667C6.51112 2.66667 5.25 3.18334 4.21667 4.21667C3.18334 5.25 2.66667 6.51112 2.66667 8C2.66667 9.48889 3.18334 10.75 4.21667 11.7833C5.25 12.8167 6.51112 13.3333 8 13.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InfoIcon;
