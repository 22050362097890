import React from 'react';
import { Card, CardBody, Tab, Tabs } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import LicenseRequestList from '@modules/licenseModule/licenseRequest/components/licenseRequestList.component';
import LicenseRequestHistory from '@modules/licenseModule/licenseRequest/components/licenseRequestHistory.component';

const LicenseListPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs>
        <Tab title={t('user.license.view.tabs.requests')}>
          <Card>
            <CardBody className="p-7 flex flex-col gap-7">
              <div className="flex flex-col gap-2">
                <span className="font-semibold text-xl">
                  {t('user.license.list.title')}
                </span>
                <span className="text-[#94A3B8] text-sm">
                  {t('user.license.list.subtitle')}
                </span>
              </div>
              <LicenseRequestList />
            </CardBody>
          </Card>
        </Tab>
        <Tab title={t('user.license.view.tabs.history')}>
          <Card>
            <CardBody className="p-5 flex flex-col gap-7 overflow-x-auto">
              <div className="flex flex-col gap-2">
                <span className="font-semibold text-xl">
                  {t('user.license.history.title')}
                </span>
                <span className="text-[#94A3B8] text-sm">
                  {t('user.license.history.subtitle')}
                </span>
              </div>
              <LicenseRequestHistory />
            </CardBody>
          </Card>
        </Tab>
      </Tabs>
    </div>
  );
};

export default LicenseListPage;
