import React, { useEffect, useState } from 'react';
import { faArrowLeft, faGear, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LicenseTypeRepository from '@modules/licenseModule/licenseType/licenseType.repository';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import CreateLicenseAnounce from '@modules/licenseModule/licenseType/components/createLicenseAnounce.component';
import CreateLicenseRequestAnounce from '@modules/licenseModule/licenseRequest/components/createLicenseRequestAnounce.component';
import LicenseRequestRepository from '@modules/licenseModule/licenseRequest/licenseRequest.repository';
import LicenseListPage from './license.list';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  DateRangePicker,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Select,
  SelectItem,
  Spinner,
  useDisclosure,
  User,
} from '@nextui-org/react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import LicenseTypeDTO from '@modules/licenseModule/licenseType/dto/licenseType.dto';
import { Form, Formik, FormikProps } from 'formik';
import LicenseRequestFormValidation from '@modules/licenseModule/licenseRequest/validation/licenseRequestValidation';
import CreateLicenseRequestDTO from '@modules/licenseModule/licenseRequest/dto/createLicenseRequest.dto';
import LicenseUserDTO from '@modules/licenseModule/licenseUser/dto/licenseUser.dto';
import LicenseUserRepository from '@modules/licenseModule/licenseUser/licenseUser.repository';
import DateUtil from '@utils/date';

const LicenseAdminHome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createModal = useDisclosure();

  const [existLicenseTypes, setExistLicenseType] = useState<boolean>(false);
  const [existLicense, setExistLicense] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const profile = useSelector(selectProfile);
  const canCreateLicenseTypes = profile?.group.permissions.some(
    (permission) =>
      permission.entity === AllowedEntities.LICENSE_TYPE &&
      permission.methods === AllowedMethods.ADD,
  );
  const isLeader = profile?.teamLeader?.id;
  const isLicenseAdmin = profile?.group.permissions.some(
    (permission) =>
      permission.entity === AllowedEntities.LICENSE_TYPE &&
      permission.methods === AllowedMethods.ADMIN,
  );

  const getExistLicenseType = async () => {
    const result = await new LicenseTypeRepository().find({
      take: 1,
    });

    setExistLicenseType(result.total > 0);
  };

  const getExistLicense = async () => {
    const result = await new LicenseRequestRepository().find({
      take: 1,
      where: {
        user: {
          externalUser: profile?.id,
        },
      },
    });

    setExistLicense(result.total > 0);
    setLoading(false);
  };

  const [licenseTypes, setLicenseTypes] = useState<LicenseTypeDTO[]>([]);

  const getLicenseTypes = async (options?: FindOptionsDTO<LicenseTypeDTO>) => {
    const licenseTypeResult = await new LicenseTypeRepository().find(options);
    setLicenseTypes(licenseTypeResult.results);
  };

  const [licenseUsers, setLicenseUsers] = useState<LicenseUserDTO[]>([]);

  const getLicenseUser = async (options?: FindOptionsDTO<LicenseUserDTO>) => {
    const licenseUserResult = await new LicenseUserRepository().find(options);
    setLicenseUsers(licenseUserResult.results);
  };

  const [myself, setMyself] = useState<LicenseUserDTO | null>(null);

  const getMyself = async () => {
    const licenseUserResult = await new LicenseUserRepository().find({
      take: 1,
      where: {
        externalUser: profile?.id,
      },
    });
    setMyself(licenseUserResult.results[0]);
  };

  const handlerLicenseTypeSearcher = async (text: string) => {
    const licenseTypeResult = await new LicenseTypeRepository().find({
      where: {
        name: `lk=${text}`,
      },
    });
    setLicenseTypes(licenseTypeResult.results);
  };

  useEffect(() => {
    if (canCreateLicenseTypes) getExistLicenseType();
    getExistLicense();
    getLicenseTypes();
    getLicenseUser();
    getMyself();
  }, []);

  const getScreen = () => {
    if (isLoading)
      return (
        <div className="h-screen flex items-center justify-center">
          <Spinner />
        </div>
      );

    if (!existLicenseTypes && canCreateLicenseTypes)
      return <CreateLicenseAnounce />;

    if (!canCreateLicenseTypes && !isLeader && !existLicense)
      return (
        <CreateLicenseRequestAnounce
          onClickAnnouncement={() => createModal.onOpenChange}
        />
      );

    return <LicenseListPage />;
  };

  const saveLicenseRequest = async (entity: CreateLicenseRequestDTO) => {
    try {
      if (DateUtil.dateDaysDiff(entity.startDate, entity.endDate) < 1)
        throw new Error('La cantidad de días debe ser más de uno.');

      if (!entity.licenseType?.id)
        throw new Error('Debe seleccionar un tipo de licencia');

      const licenseType = await new LicenseTypeRepository().findOne(
        entity.licenseType?.id,
      );

      const startDateFind = new Date();
      startDateFind.setFullYear(
        startDateFind.getFullYear() - licenseType.accumulationYears,
      );

      const finedPreviousLicenses = await new LicenseRequestRepository().find({
        where: {
          licenseType: { id: entity.licenseType.id },
          endDate: `>=${DateUtil.dateForInput(startDateFind)}`,
          user: { externalUser: entity.user?.id },
        },
      });

      let availableDays = licenseType.days;

      for (const prevLicense of finedPreviousLicenses.results) {
        availableDays =
          availableDays -
          DateUtil.dateDaysDiff(
            new Date(prevLicense.startDate),
            new Date(prevLicense.endDate),
          );
      }

      if (DateUtil.dateDaysDiff(entity.startDate, entity.endDate) <= 0)
        throw new Error(`La cantidad de días debe ser mayor a 0.`);

      if (
        DateUtil.dateDaysDiff(entity.startDate, entity.endDate) > availableDays
      )
        throw new Error(
          `La cantidad de días debe ser menor o igual a ${availableDays}.`,
        );

      await new LicenseRequestRepository().save(entity);
      window.location.reload();
    } catch (err) {
      if (err instanceof Error) setCreatingError(err.message);
    }
  };

  const [creatingError, setCreatingError] = useState<string | null>(null);

  return (
    <div className="flex flex-col gap-7">
      <div className="flex items-center justify-between">
        <h3 className="flex items-center gap-4">
          <div className="w-12 h-12 rounded-2xl flex items-center justify-center bg-white dark:bg-black drop-shadow-md">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="cursor-pointer"
              onClick={() => navigate(-1)}
            />
          </div>
          <span className="text-3xl">{t('user.license.view.title')}</span>
        </h3>
        <div className="mt-5 flex items-center gap-3">
          {canCreateLicenseTypes && (
            <Button
              color="primary"
              variant="flat"
              onClick={() => navigate('/enterprise/license/licenseType')}
            >
              <FontAwesomeIcon icon={faGear} />
              <span className="hidden lg:inline">
                {t('user.license.list.buttons.licenseType')}
              </span>
            </Button>
          )}
          <Button color="primary" onClick={createModal.onOpen}>
            <FontAwesomeIcon icon={faPlus} />
            <span className="hidden lg:inline">
              {t('user.license.list.buttons.license')}
            </span>
          </Button>
        </div>
      </div>
      {getScreen()}
      <Modal
        isOpen={createModal.isOpen}
        onOpenChange={createModal.onOpenChange}
        size="5xl"
      >
        <ModalContent>
          <ModalHeader>
            <div>{t('user.license.new.title')}</div>
          </ModalHeader>
          <ModalBody className="grid grid-cols-1 gap-6">
            <Formik
              initialValues={
                {
                  user: myself,
                  licenseType: null,
                  startDate: new Date(),
                  endDate: new Date(),
                } as CreateLicenseRequestDTO
              }
              validationSchema={LicenseRequestFormValidation}
              onSubmit={saveLicenseRequest}
            >
              {({
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }: FormikProps<CreateLicenseRequestDTO>) => (
                <Form className="grid grid-cols-1 gap-6">
                  <Select
                    label={t('entities.license.fields.user')}
                    labelPlacement="outside"
                    isDisabled={!isLicenseAdmin}
                    selectedKeys={new Set(values.user && [values.user.id])}
                    onSelectionChange={(keys) => {
                      setFieldValue('user', {
                        id: new Set(keys).values().next().value,
                      });
                    }}
                    isInvalid={Boolean(errors.user)}
                    errorMessage={String(errors.user || '')}
                    color={errors.user ? 'danger' : 'default'}
                  >
                    {licenseUsers.map((user) => (
                      <SelectItem
                        key={user.id}
                        value={user.id}
                        textValue={`${user.fullName}`}
                      >
                        <User
                          name={`${user.fullName}`}
                          avatarProps={{
                            src: `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`,
                          }}
                        />
                      </SelectItem>
                    ))}
                  </Select>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <Autocomplete
                      items={licenseTypes}
                      label={t('entities.license.fields.licenseType')}
                      labelPlacement="outside"
                      onInputChange={(e) => handlerLicenseTypeSearcher(e)}
                      isInvalid={Boolean(errors.licenseType)}
                      errorMessage={String(errors.licenseType || '')}
                      color={errors.licenseType ? 'danger' : 'default'}
                    >
                      {(item) => (
                        <AutocompleteItem
                          key={item.id}
                          onClick={() => setFieldValue('licenseType', item)}
                        >
                          {item.name}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                    <DateRangePicker
                      label={`${t('entities.license.fields.startDate')} - ${t('entities.license.fields.endDate')}`}
                      labelPlacement="outside"
                      classNames={{
                        calendar: 'w-[300px]',
                        calendarContent: 'w-[300px]',
                      }}
                      onChange={(val) => {
                        setFieldValue(
                          'startDate',
                          val.start.toDate(
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          ),
                        );
                        setFieldValue(
                          'endDate',
                          val.end.toDate(
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          ),
                        );
                      }}
                    ></DateRangePicker>
                    {creatingError && (
                      <div className="w-full px-2 py-1 rounded-lg bg-danger-50 border border-danger-200 text-danger-800">
                        {creatingError}
                      </div>
                    )}
                  </div>
                  <Button
                    isLoading={isSubmitting}
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    {t('user.license.new.button')}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default LicenseAdminHome;
