import IconProps from './common/icon.interface';

const StarIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.74006 10.1036L6.33333 8.54994L8.92661 10.1241L8.24742 7.18024L10.532 5.21769L7.52706 4.95193L6.33333 2.17165L5.1396 4.93149L2.1347 5.19725L4.41925 7.18024L3.74006 10.1036ZM6.33333 9.99195L3.13053 11.9104C3.01127 11.9796 2.89175 12.0087 2.77196 11.9977C2.65216 11.9867 2.54371 11.9466 2.44661 11.8774C2.34952 11.8082 2.27459 11.7178 2.22182 11.6062C2.16903 11.4945 2.16059 11.3711 2.19648 11.2358L3.04664 7.6237L0.219068 5.19253C0.113526 5.10132 0.0462446 4.9957 0.0172246 4.87568C-0.0117953 4.75563 -0.00414576 4.639 0.0401732 4.52579C0.0845059 4.41256 0.148364 4.32004 0.231746 4.24823C0.315129 4.17642 0.429117 4.12951 0.573709 4.1075L4.30529 3.78356L5.7539 0.372681C5.80667 0.245838 5.88662 0.152009 5.99374 0.0911973C6.10087 0.0303991 6.21407 0 6.33333 0C6.4526 0 6.5658 0.0303991 6.67293 0.0911973C6.78005 0.152009 6.85999 0.245838 6.91277 0.372681L8.36138 3.78356L12.093 4.1075C12.2375 4.12951 12.3515 4.17642 12.4349 4.24823C12.5183 4.32004 12.5822 4.41256 12.6265 4.52579C12.6708 4.639 12.6785 4.75563 12.6494 4.87568C12.6204 4.9957 12.5531 5.10132 12.4476 5.19253L9.62002 7.6237L10.4702 11.2358C10.5061 11.3711 10.4976 11.4945 10.4448 11.6062C10.3921 11.7178 10.3171 11.8082 10.2201 11.8774C10.123 11.9466 10.0145 11.9867 9.8947 11.9977C9.77492 12.0087 9.6554 11.9796 9.53613 11.9104L6.33333 9.99195Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StarIcon;
