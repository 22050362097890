import i18next from 'i18next';
import * as Yup from 'yup';

const UserFileFormValidation = () => {
  return Yup.object().shape({
    taxId: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    hireDate: Yup.date().required(i18next.t('validations.required')),
    birthDate: Yup.date().required(i18next.t('validations.required')),
    jobTitle: Yup.string().required(i18next.t('validations.required')),
    address: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    phone: Yup.string().required(i18next.t('validations.required')),
    inTime: Yup.string().required(i18next.t('validations.required')),
    outTime: Yup.string().required(i18next.t('validations.required')),
    bankAccountNumber: Yup.string().required(i18next.t('validations.required')),
    employeeFileNumber: Yup.string().required(
      i18next.t('validations.required'),
    ),
    emergencyPhone: Yup.string().required(i18next.t('validations.required')),
    emergencyName: Yup.string().required(i18next.t('validations.required')),
    secondaryEmergencyPhone: Yup.string().required(
      i18next.t('validations.required'),
    ),
    secondaryEmergencyName: Yup.string().required(
      i18next.t('validations.required'),
    ),
    maritalStatus: Yup.string().required(i18next.t('validations.required')),
  });
};

export default UserFileFormValidation;
