import { Button, Tooltip } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { LevelDTO } from '../dto/level.dto';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import LevelRepository from '../level.repository';
import { useNavigate } from 'react-router-dom';
import RankingIcon from '@components/icons/ranking.icon';
import { useTranslation } from 'react-i18next';

const LevelDashboardTooltip = () => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [level, setLevel] = useState<LevelDTO>();

  const getLevel = async () => {
    const levelResult = (
      await new LevelRepository().find({
        where: { users: { id: profile?.id } },
      })
    ).results[0];
    setLevel(levelResult);
  };

  useEffect(() => {
    getLevel();
  }, []);

  return (
    <Tooltip
      content={
        <div className="text-xs flex flex-col items-center gap-2 p-2">
          <p>
            {t('user.level.dashboardTooltip.currentLevel', {
              number: level?.number,
            })}
          </p>
          {level?.reward ? (
            <p>
              {t('user.level.dashboardTooltip.reward')}
              {level?.reward}
            </p>
          ) : (
            <p>{t('user.level.dashboardTooltip.noReward')}</p>
          )}
          <Button
            size="sm"
            color="warning"
            onPress={() => navigate('/enterprise/level/view')}
          >
            {t('user.level.dashboardTooltip.goToLevel')}
          </Button>
        </div>
      }
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      placement="bottom"
      className="bg-warning-100"
    >
      <Button
        variant="flat"
        color="warning"
        onPress={() => setIsOpen(!isOpen)}
        startContent={<RankingIcon />}
        className="w-full lg:w-fit"
      >
        {t('user.level.level', { number: level?.number })}
      </Button>
    </Tooltip>
  );
};

export default LevelDashboardTooltip;
