import React, { Key, useEffect, useState } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObjectiveMetric from '@modules/metricModule/objectiveMetric/components/objectiveMetric';
import RecognitionMetric from '@modules/metricModule/recognitionMetric/components/recognitionMetric';
import SurveyMetric from '@modules/metricModule/surveyMetric/components/surveyMetric';
import { Card, CardBody, Tab, Tabs } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MetricFiltersProvider } from '@modules/metricModule/context/metricFilters.context';
import PeriodFilter from '@modules/metricModule/components/periodFilter';
import HistoryMetric from '@modules/metricModule/history/components/historyMetric';

const MetricPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedTab, setSelectedTab] =
    useState<Exclude<Key, bigint>>('survey');

  useEffect(() => {
    if (queryParams.get('tab'))
      setSelectedTab(queryParams.get('tab') as string);
  }, [queryParams]);

  return (
    <div className="pb-10">
      <div className="flex justify-between items-end">
        <h3 className="text-primary flex gap-5 items-center mb-0">
          {' '}
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />{' '}
          {t('user.metric.view')}
        </h3>
      </div>
      <MetricFiltersProvider>
        <Card className="my-5 shadow-none">
          <CardBody className="lg:flex-row justify-between lg:items-center gap-2">
            <Tabs
              classNames={{
                tabList: 'overflow-x-scroll',
              }}
              selectedKey={selectedTab}
              onSelectionChange={(tab) => {
                setSelectedTab(tab);
                navigate(`/enterprise/metric?tab=${tab}`);
              }}
              color="primary"
            >
              <Tab title={t('user.metric.tabs.survey')} key="survey" />
              <Tab title={t('user.metric.tabs.objective')} key="objective" />
              <Tab
                title={t('user.metric.tabs.recognition')}
                key="recognition"
              />
              <Tab title={t('user.metric.tabs.history')} key="history" />
            </Tabs>
            <PeriodFilter />
          </CardBody>
        </Card>
        {selectedTab === 'survey' && <SurveyMetric />}
        {selectedTab === 'objective' && <ObjectiveMetric />}
        {selectedTab === 'recognition' && <RecognitionMetric />}
        {selectedTab === 'history' && <HistoryMetric />}
      </MetricFiltersProvider>
    </div>
  );
};

export default MetricPage;
