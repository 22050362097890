import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import KeyResultForm from '@modules/objectiveModule/keyResult/components/keyResultForm';
import KeyResultDTO from '@modules/objectiveModule/keyResult/dto/keyResult.dto';
import UpdateKeyResultDTO from '@modules/objectiveModule/keyResult/dto/updateKeyResult.dto';
import KeyResultRepository from '@modules/objectiveModule/keyResult/keyResult.repository';
import { Spinner } from '@nextui-org/react';
import CompareUtil from '@utils/compare';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const KeyResultEditPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [keyResult, setKeyResult] = useState<KeyResultDTO | null>(null);

  const handleSubmit = async (values: UpdateKeyResultDTO) => {
    if (!keyResult) return;
    await new KeyResultRepository().update(keyResult?.id as string, {
      ...(CompareUtil.compareObjects(keyResult, values) as UpdateKeyResultDTO),
      colaborators:
        values.colaborators?.length && values.colaborators?.length > 0
          ? values.colaborators
          : [],
      categories:
        values.categories?.length && values.categories?.length > 0
          ? values.categories
          : [],
      notified:
        values.notified?.length && values.notified?.length > 0
          ? values.notified
          : [],
    });
    navigate(-1);
  };

  const getKeyResult = async (options?: FindOptionsDTO<KeyResultDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const keyResultResult = await new KeyResultRepository().findOne(
      id as string,
      {
        ...options,
        relations: Object.assign(options?.relations || [], [
          'manager',
          'colaborators',
          'notified',
          'categories',
        ]),
      },
    );
    if (!keyResultResult) navigate(-1);
    setKeyResult(keyResultResult);
    setLoading(false);
  };

  useEffect(() => {
    getKeyResult();
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  return isLoading || !keyResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.keyResult.edit')}
      </h3>
      <KeyResultForm onSubmit={handleSubmit} initialValues={keyResult} />
    </div>
  );
};

export default KeyResultEditPage;
