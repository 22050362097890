import React from 'react';
import Feed from '@modules/feed/posts/components/feed';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LevelDashboardTooltip from '@modules/userModule/level/dashboard/levelDashboardTooltip';
import MyTasks from '@modules/objectiveModule/initiative/components/myTasks';
import EventsDashboardCard from '@modules/eventModule/components/dashboardCard';

const Dashboard = () => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);

  return (
    <div className="grid grid-cols-4 gap-5">
      <div className="col-span-4 flex justify-between items-start flex-col gap-5 lg:flex-row lg:items-end">
        <div className="flex flex-col">
          <span className="uppercase text-default-400 ">
            {t('user.dashboard.userWelcome', {
              fullname: `${profile?.firstName}`,
            })}
          </span>
          <span className="pt-3 text-3xl text-foreground">
            {t('user.dashboard.welcome')}
          </span>
        </div>
        <LevelDashboardTooltip />
      </div>
      <div className="col-span-4 lg:col-span-3">
        <Feed />
      </div>
      <div className="hidden lg:flex flex-col gap-5">
        {window.innerWidth > 1024 && (
          <>
            <EventsDashboardCard />
            <MyTasks />
          </>
        )}
      </div>
    </div>
  );
};
export default Dashboard;
