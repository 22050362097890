import React from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import InitiativeDTO from '../dto/initiative.dto';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InitiativeRepository from '../initiative.repository';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Pagination,
  Skeleton,
  Spinner,
  useDisclosure,
} from '@nextui-org/react';
import InitiativeStatusChange from './initiativeStatusChange';
import InitiativeStatus from '../enum/initiativeStatus.enum';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { Link } from 'react-router-dom';

const MyTasks = () => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [initiatives, setInitiatives] = useState<InitiativeDTO[]>([]);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [totalInitiatives, setTotalInitiatives] = useState<number>(0);
  const [page, setPage] = useState(1);

  const getInitiatives = async (options?: FindOptionsDTO<InitiativeDTO>) => {
    setIsLoading(true);
    const initiativeResult = await new InitiativeRepository().find({
      ...options,
      relations: Object.assign(['manager'], options?.relations),
      where: {
        ...options?.where,
        manager: {
          externalUser: profile?.id,
        },
        status: '!=COMPLETED',
      },
      order: { lastModifiedDate: 'DESC' },
    });
    setInitiatives(initiativeResult.results);
    if (!totalInitiatives) setTotalInitiatives(initiativeResult.total);
    setIsLoading(false);
  };

  useEffect(() => {
    getInitiatives({ take: 3 });
  }, []);

  useEffect(() => {
    if (isOpen)
      getInitiatives({
        take: page * PAGINATION_TAKE,
        skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
      });
  }, [isOpen, page]);

  const onChangeStatus = async (
    id: string,
    status: InitiativeStatus,
    isModal: boolean,
  ) => {
    await new InitiativeRepository().update(id, { status });
    getInitiatives(isModal ? {} : { take: 3 });
  };

  const LoadingTemplate = () => {
    return (
      <div className="flex flex-col gap-2">
        <Skeleton className="rounded-xl">
          <div className="h-14"></div>
        </Skeleton>
        <Skeleton className="rounded-xl">
          <div className="h-14"></div>
        </Skeleton>
        <Skeleton className="rounded-xl">
          <div className="h-14"></div>
        </Skeleton>
      </div>
    );
  };

  const EmptyList = () => {
    return (
      <div className="pt-2">
        <p className="text-center text-[14px]">
          {t('entities.initiative.table.empty')}
        </p>
      </div>
    );
  };

  return (
    <>
      <Card className="shadow-none bg-content4">
        <CardHeader className="flex-col items-start">
          <p className="font-bold">
            {t('entities.initiative.dashboard.title')}
          </p>
          <p className="text-sm text-foreground-400">
            {t('entities.initiative.dashboard.subtitle')}
          </p>
        </CardHeader>
        <CardBody className="p-2 gap-2">
          {isLoading ? (
            <LoadingTemplate />
          ) : initiatives.length ? (
            initiatives.map((initiative, index) => {
              if (index < 3)
                return (
                  <Card key={index} className="shadow-sm">
                    <CardBody className="flex-row gap-2 p-2 items-center">
                      {profile?.group.permissions.some(
                        (permission) =>
                          permission.entity === AllowedEntities.INITIATIVE &&
                          permission.methods === AllowedMethods.VIEW,
                      ) ? (
                        <Link
                          to={`/enterprise/initiative/view/${initiative.id}`}
                          className="text-sm no-underline w-1/2 text-foreground cursor-pointer line-clamp-2 truncate whitespace-normal"
                        >
                          {initiative.name}
                        </Link>
                      ) : (
                        <div className="text-sm no-underline w-1/2 text-foreground cursor-pointer line-clamp-2 truncate whitespace-normal">
                          {initiative.name}
                        </div>
                      )}
                      <div className="w-1/2">
                        <InitiativeStatusChange
                          status={initiative.status}
                          onChange={(status) =>
                            onChangeStatus(initiative.id, status, false)
                          }
                          disabled={
                            !profile?.group.permissions.some(
                              (permission) =>
                                permission.entity ===
                                  AllowedEntities.INITIATIVE &&
                                permission.methods === AllowedMethods.MODIFY,
                            )
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                );
            })
          ) : (
            <EmptyList />
          )}
        </CardBody>
        <CardFooter className="justify-center">
          <Button
            variant="light"
            color="primary"
            onPress={onOpen}
            className="h-full w-full py-2 px-4 rounded"
            size="sm"
          >
            {t('entities.initiative.dashboard.seeMore')}
          </Button>
        </CardFooter>
      </Card>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="xl"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalHeader>
            <p className="font-bold">
              {t('entities.initiative.dashboard.modalTitle')}
            </p>
          </ModalHeader>
          <ModalBody className="mb-5 items-center">
            {isLoading ? (
              <Spinner />
            ) : initiatives.length ? (
              initiatives.map((initiative, index) => (
                <Card key={index} className="shadow-sm w-full">
                  <CardBody className="flex-row gap-2 p-2 items-center">
                    {profile?.group.permissions.some(
                      (permission) =>
                        permission.entity === AllowedEntities.INITIATIVE &&
                        permission.methods === AllowedMethods.VIEW,
                    ) ? (
                      <Link
                        to={`/enterprise/initiative/view/${initiative.id}`}
                        className="text-sm no-underline w-2/3 text-foreground cursor-pointer"
                      >
                        {initiative.name}
                      </Link>
                    ) : (
                      <div className="text-sm no-underline w-2/3 text-foreground cursor-pointer">
                        {initiative.name}
                      </div>
                    )}
                    <div className="w-1/3">
                      <InitiativeStatusChange
                        status={initiative.status}
                        onChange={(status) =>
                          onChangeStatus(initiative.id, status, true)
                        }
                        disabled={
                          !profile?.group.permissions.some(
                            (permission) =>
                              permission.entity ===
                                AllowedEntities.INITIATIVE &&
                              permission.methods === AllowedMethods.MODIFY,
                          )
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              ))
            ) : (
              <EmptyList />
            )}
            <Pagination
              total={Math.ceil(totalInitiatives / PAGINATION_TAKE)}
              page={page}
              onChange={setPage}
              showControls
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyTasks;
