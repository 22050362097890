import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObjectiveList from '@modules/objectiveModule/objective/components/objectiveList';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

const ObjectiveListPage = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-end">
        <h3 className="text-primary flex gap-5 items-center">
          {' '}
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />{' '}
          {t('user.objective.list')}
        </h3>
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.OBJECTIVE &&
            permission.methods === AllowedMethods.VIEW,
        ) && (
          <Button color="primary" onClick={() => navigate('add')}>
            {t('entities.objective.table.add')}
          </Button>
        )}
      </div>
      <ObjectiveList />
    </div>
  );
};

export default ObjectiveListPage;
