import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PulseSurveyForm from '@modules/voteModule/pulse/pulseSurvey/components/pulseSurvey.form';
import CreatePulseSurveyDTO from '@modules/voteModule/pulse/pulseSurvey/dto/createPulseSurvey.dto';
import PulseSurveyRepository from '@modules/voteModule/pulse/pulseSurvey/pulseSurvey.repository';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PulseSurveyAddPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmitForm = async (values: CreatePulseSurveyDTO) => {
    await new PulseSurveyRepository().save(values);
    navigate(-1);
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.surveys.pulse.add')}
      </h3>
      <PulseSurveyForm onSubmit={handleSubmitForm} />
    </div>
  );
};

export default PulseSurveyAddPage;
