import ArrowIcon from '@components/icons/arrow.icon';
import PlusIcon from '@components/icons/plus.icon';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import GeneralLevelConfigurationModal from '@modules/userModule/level/configuration/components/generalLevelConfigurationModal';
import LevelsConfigurationList from '@modules/userModule/level/configuration/components/levelsConfigurationList';
import { LevelDTO } from '@modules/userModule/level/dto/level.dto';
import LevelRepository from '@modules/userModule/level/level.repository';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { Button, Card, CardBody, CardHeader } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LevelsConfigurationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [levels, setLevels] = useState<LevelDTO[]>([]);
  const [levelRepository] = useState(new LevelRepository());
  const user = useSelector(selectProfile);

  const getLevels = async () => {
    const levelsResponse = (
      await levelRepository.find({ order: { number: 'ASC' } })
    ).results;
    setLevels(levelsResponse);
  };

  useEffect(() => {
    getLevels();
  }, []);

  const addLevel = async () => {
    const lastLevel = levels[levels.length - 1];
    await levelRepository.save({
      number: lastLevel.number + 1,
      rockets: lastLevel.rockets + 10,
    });
    getLevels();
  };

  return (
    <div className="pb-5">
      <div className="flex justify-between items-end">
        <h3 className="text-primary flex gap-5 items-center">
          {' '}
          <span
            className="cursor-pointer bg-content4 rounded-xl p-1.5 w-9 h-9"
            onClick={() => navigate(-1)}
          >
            <ArrowIcon />
          </span>
          {t('entities.level.title')}
        </h3>
      </div>
      <Card className="shadow-none p-5">
        <CardHeader className="justify-between pb-0 gap-5">
          <div className="flex flex-col">
            <p className="text-lg font-bold">{t('entities.level.subtitle')}</p>
            <p>{t('entities.level.description')}</p>
          </div>
          {user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.LEVEL &&
              permission.methods === AllowedMethods.DELETE,
          ) && (
            <GeneralLevelConfigurationModal
              originalLevels={levels}
              levelRepository={levelRepository}
              refresh={getLevels}
            />
          )}
        </CardHeader>
        <CardBody className="pt-0">
          <LevelsConfigurationList levels={levels} refresh={getLevels} />
          {user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.LEVEL &&
              permission.methods === AllowedMethods.ADD,
          ) && (
            <div className="flex items-center justify-center gap-5">
              <Button
                isIconOnly
                color="primary"
                variant="flat"
                onClick={addLevel}
              >
                <PlusIcon />
              </Button>
              <span className="text-sm text-primary">
                {t('entities.level.addLevel')}
              </span>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default LevelsConfigurationPage;
