import React, { RefObject, useEffect, useRef, useState } from 'react';
import RecognitionCard from '../recognition/recognitionVote/components/recognitionCard';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import PulseCard from '../pulse/pulseVote/components/pulseCard.component';
import ThermometerCard from '../thermometerVote/components/thermometerCard';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useLocation } from 'react-router-dom';
import { Button } from '@nextui-org/react';
import ChevronIcon from '@components/icons/chevron.icon';

interface DashboardVotesProps {
  getPosts: () => void;
}

const dashboardVotes = ({ getPosts }: DashboardVotesProps) => {
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [modalOpen, setModalOpen] = useState<string | null>(null);
  const recognitionRef = useRef<HTMLDivElement>(null);
  const thermometerRef = useRef<HTMLDivElement>(null);
  const surveyRef = useRef<HTMLDivElement>(null);
  const [refArray, setRefArray] = useState<RefObject<HTMLDivElement>[]>([]);
  const [activeCard, setActiveCard] = useState(0);

  const openModal = () => {
    const modal = queryParams.get('open');
    if (modal) {
      setModalOpen(modal);
    }
  };

  useEffect(() => {
    openModal();
  }, []);

  const createRefArray = () => {
    const refs = [];
    if (recognitionRef) refs.push(recognitionRef);
    if (thermometerRef) refs.push(thermometerRef);
    if (surveyRef) refs.push(surveyRef);
    setRefArray(refs);
  };

  useEffect(() => {
    createRefArray();
  }, [recognitionRef, thermometerRef, surveyRef]);

  return (
    <div>
      <div className="w-full flex gap-2">
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.RECOGNITION &&
            permission.methods === AllowedMethods.VIEW,
        ) && (
          <div
            ref={recognitionRef}
            className={`w-full ${refArray[activeCard] === recognitionRef ? 'block' : 'hidden'} lg:block lg:w-1/3`}
          >
            <RecognitionCard
              onVote={() => getPosts()}
              open={modalOpen === 'recognition'}
            />
          </div>
        )}
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.THERMOMETER &&
            permission.methods === AllowedMethods.VIEW,
        ) && (
          <div
            ref={thermometerRef}
            className={`w-full ${refArray[activeCard] === thermometerRef ? 'block' : 'hidden'} lg:block lg:w-1/3`}
          >
            <ThermometerCard open={modalOpen === 'thermometer'} />
          </div>
        )}
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.SURVEY &&
            permission.methods === AllowedMethods.VIEW,
        ) && (
          <div
            ref={surveyRef}
            className={`w-full ${refArray[activeCard] === surveyRef ? 'block' : 'hidden'} lg:block lg:w-1/3`}
          >
            <PulseCard open={modalOpen === 'pulseSurvey'} />
          </div>
        )}
      </div>
      {refArray.length ? (
        <div className="flex justify-center gap-2 mt-2 lg:hidden">
          <Button
            onPress={() => {
              if (activeCard > 0) setActiveCard(activeCard - 1);
            }}
            isIconOnly
            size="sm"
            variant="light"
          >
            <ChevronIcon className="rotate-90" />
          </Button>
          <Button
            onPress={() => {
              if (activeCard < refArray.length - 1)
                setActiveCard(activeCard + 1);
            }}
            isIconOnly
            size="sm"
            variant="light"
          >
            <ChevronIcon className="rotate-[270deg]" />
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default dashboardVotes;
