import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import CategoryRepository from '@modules/objectiveModule/category/category.repository';
import CategoryDTO from '@modules/objectiveModule/category/dto/category.dto';
import { Autocomplete, AutocompleteItem, Chip } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ObjectiveCategoryFilterProps {
  onChange: (categories: { id: string }[]) => void;
}

const ObjectiveCategoryFilter = (props: ObjectiveCategoryFilterProps) => {
  const [selectCategories, setSelectCategories] = useState<
    { id: string; name: string }[]
  >([]);
  const [categories, setCategories] = useState<CategoryDTO[]>([]);
  const { t } = useTranslation();

  const getCategories = async (options?: FindOptionsDTO<CategoryDTO>) => {
    const categoriesResult = await new CategoryRepository().find(options);
    setCategories(categoriesResult.results);
  };

  const handlerSearcher = async (text: string) => {
    const categoriesResult = await new CategoryRepository().find({
      where: {
        name: `lk=${text}`,
      },
    });
    const categories = categoriesResult.results;
    if (
      !categories.some((category) => category.name.includes(text)) &&
      text.length > 3
    ) {
      categories.push({ name: text } as CategoryDTO);
    }
    setCategories(categories);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="py-3">
      <Autocomplete
        items={categories}
        label={t('entities.objective.fields.categories')}
        startContent={
          <FontAwesomeIcon icon={faSearch} className="text-default-400" />
        }
        onInputChange={(e) => handlerSearcher(e)}
        inputProps={{
          classNames: {
            inputWrapper:
              'border-1 border-default shadow-none bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
          },
        }}
      >
        {(item) => (
          <AutocompleteItem
            key={item.id || item.name}
            onClick={async () => {
              if (
                !selectCategories.some((category) => category.id === item.id)
              ) {
                setSelectCategories((prev) => {
                  prev.push({ id: item.id, name: item.name });
                  props.onChange(prev);
                  return prev;
                });
              }
            }}
          >
            {item.name}
          </AutocompleteItem>
        )}
      </Autocomplete>
      <div className="flex gap-3 pt-3">
        {selectCategories.map((category, index) => {
          return (
            <Chip
              key={category.id}
              color="primary"
              onClose={() => {
                setSelectCategories((prev) => {
                  prev.splice(index, 1);
                  props.onChange(prev);
                  return prev;
                });
              }}
            >
              {category.name}
            </Chip>
          );
        })}
      </div>
    </div>
  );
};

export default ObjectiveCategoryFilter;
