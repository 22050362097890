import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import StrengthForm from '@modules/voteModule/recognition/strength/components/strength.form';
import StrengthDTO from '@modules/voteModule/recognition/strength/dto/strength.dto';
import UpdateStrengthDTO from '@modules/voteModule/recognition/strength/dto/updateStrength.dto';
import StrengthRepository from '@modules/voteModule/recognition/strength/strength.repository';
import { Spinner } from '@nextui-org/react';
import CompareUtil from '@utils/compare';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const StrengthEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [strengthResult, setStrengthResult] = useState<StrengthDTO | null>(
    null,
  );

  const handleSubmit = async (values: UpdateStrengthDTO) => {
    if (!strengthResult) return;
    await new StrengthRepository().update(strengthResult?.id as string, {
      ...(CompareUtil.compareObjects(
        strengthResult,
        values,
      ) as UpdateStrengthDTO),
    });
    navigate(-1);
  };

  const getStrength = async (options?: FindOptionsDTO<StrengthDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const strengthResult = await new StrengthRepository().findOne(
      id as string,
      {
        ...options,
        relations: Object.assign(options?.relations || []),
      },
    );
    if (!strengthResult) navigate(-1);
    setStrengthResult(strengthResult);
    setLoading(false);
  };

  useEffect(() => {
    getStrength();
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  return isLoading || !strengthResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.surveys.strength.edit')}
      </h3>
      <StrengthForm onSubmit={handleSubmit} initialValues={strengthResult} />
    </div>
  );
};

export default StrengthEditPage;
