import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@nextui-org/react';
import ReactEChart from 'echarts-for-react';
import RecognitionVoteDTO from '@modules/voteModule/recognition/recognitionVote/dto/recognitionVote.dto';

interface StrengthRadarMetricProps {
  recognitions: RecognitionVoteDTO[];
}

const StrengthRadar = ({ recognitions }: StrengthRadarMetricProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [recognitionsForRadar, setRecognitionsForRadar] = useState<{
    [key: string]: number;
  }>({});
  const [totalCount, setTotalCount] = useState<number>(0);

  const calculateRecognitions = async () => {
    setLoading(true);

    const votes: { [key: string]: number } = {};
    let totalRocketsRecognitions = 0;

    recognitions.map((recognition) => {
      votes[recognition.strength.strength]
        ? (votes[recognition.strength.strength] += recognition.rockets)
        : (votes[recognition.strength.strength] = recognition.rockets);
      totalRocketsRecognitions += recognition.rockets;
    });

    setRecognitionsForRadar(votes);
    setTotalCount(totalRocketsRecognitions);
    setLoading(false);
  };

  useEffect(() => {
    calculateRecognitions();
  }, [recognitions]);

  if (isLoading)
    return (
      <Skeleton isLoaded={!isLoading}>
        <div className="w-8 h-8"></div>
      </Skeleton>
    );

  const indicators = Object.keys(recognitionsForRadar).map((key) => {
    return {
      name: key,
      max: totalCount,
    };
  });
  const values = Object.keys(recognitionsForRadar).map(
    (key) => recognitionsForRadar[key],
  );

  return (
    <div className="">
      <div className="flex flex-col gap-2">
        <span className="font-bold text-lg">
          {t(`entities.metric.strengthRadar.title`)}
        </span>
        <span className="text-default-400 text-sm">
          {t(`entities.metric.strengthRadar.description`)}
        </span>
      </div>
      {indicators.length > 0 && values.length > 0 ? (
        <div className="md:grid grid-cols-2">
          <div className="py-5">
            <ReactEChart
              option={{
                radar: {
                  indicator: indicators,
                },
                series: [
                  {
                    name: 'Budget vs spending',
                    type: 'radar',
                    data: [
                      {
                        value: values,
                        name: 'Strength',
                      },
                    ],
                  },
                ],
              }}
            />
          </div>
          <div className="flex flex-col gap-2 justify-center">
            {Object.keys(recognitionsForRadar).map((key, index) => (
              <div
                key={key}
                className={`flex justify-between items-center px-2 py-1 rounded-lg md:w-[320px] ${index < 3 ? 'bg-primary-50' : ''}`}
              >
                <span>{key}</span>
                <span
                  className={`text-sm ${index < 3 ? 'text-primary' : 'text-default-400'}`}
                >
                  {recognitionsForRadar[key]} rocket
                  {recognitionsForRadar[key] > 1 && 's'}
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>{t('entities.metric.objective.participation.values.NO_DATA')}</p>
      )}
    </div>
  );
};

export default StrengthRadar;
