import React from 'react';
import { useTranslation } from 'react-i18next';
import ObjectiveProgress from './objectivesProgress';
import KeyResultStatuses from './keyResultStatuses';
import InitiativeMetric from './initiativeStatus';
import NextDeadlines from './nextdeadlines';
import { useMetricFiltersContext } from '@modules/metricModule/context/metricFilters.context';
import { Card, CardBody, CardHeader } from '@nextui-org/react';

const ObjectiveMetric = () => {
  const { t } = useTranslation();
  const { filters } = useMetricFiltersContext();
  const period = filters.quarter;

  return (
    <div className="flex flex-col lg:flex-row gap-5">
      <Card className="shadow-none w-full lg:w-1/4 h-fit pb-5">
        <CardBody>
          <ObjectiveProgress period={period} />
        </CardBody>
      </Card>
      <Card className="shadow-none w-full lg:w-3/4">
        <CardHeader className="text-2xl">
          {t('user.metric.tabs.objective')}
        </CardHeader>
        <CardBody>
          <KeyResultStatuses period={period} />
          <NextDeadlines period={period} />
          <div className="pt-8">
            <InitiativeMetric period={period} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ObjectiveMetric;
