import consultant from '@assets/img/consultor 1.svg';
import particular from '@assets/img/particular.svg';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SingupOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="container mx-auto h-screen flex flex-col gap-8 items-center justify-center">
      <div className="flex flex-col items-center gap-3">
        <span className="text-xs font-bold">{t('signup.welcome')}</span>
        <span className="text-foreground text-2xl font-bold">
          {t('signup.optionTitle')}
        </span>
        <span className="text-gray-500">{t('signup.optionSubtitle')}</span>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col items-center gap-2 p-10">
          <img className="w-16 m-0" src={consultant} alt="consultant" />
          <h3 className="text-foreground m-0">
            {t('signup.consultant.title')}
          </h3>
          <p className="text-gray-500 text-center text-sm">
            {t('signup.consultant.text')}
          </p>
          <Button
            onClick={() => {
              navigate('/auth/signup/contact?t=CONSULTANT');
            }}
            isDisabled={true}
            className="w-full"
            color="primary"
          >
            {t('signup.consultant.select')}
          </Button>
        </div>
        <div className="flex flex-col items-center gap-2 p-10">
          <img className="w-16 m-0" src={particular} alt="consultant" />
          <h3 className="text-foreground m-0">
            {t('signup.enterprise.title')}
          </h3>
          <p className="text-gray-500 text-center text-sm">
            {t('signup.enterprise.text')}
          </p>
          <Button
            onClick={() => {
              navigate('/auth/signup/contact?t=PARTICULAR');
            }}
            className="w-full"
            color="primary"
          >
            {t('signup.enterprise.select')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SingupOptions;
