import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  Skeleton,
  useDisclosure,
} from '@nextui-org/react';
import thermometerIcon from '@assets/icons/thermometerIcon.svg';
import goodIcon from '@assets/icons/goodicon.svg';
import neutralIcon from '@assets/icons/neutralIcon.svg';
import badIcon from '@assets/icons/bardicon.svg';
import infoIcon from '@assets/icons/infoicon.svg';
import { useEffect, useState } from 'react';
import ThermometerType from '../enum/thermometerType';
import ThermometerRepository from '../thermometer.repository';
import DateUtil from '@utils/date';
import ThermometerConfigRepository from '@modules/voteModule/thermometerConfig/thermometerConfig.repository';
import ThermometerTemporality from '@modules/voteModule/thermometerConfig/enum/thermometerTemporality.enum';
import { useTranslation } from 'react-i18next';

interface ThermometerCardProps {
  open: boolean;
}

const ThermometerCard = ({ open }: ThermometerCardProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [selected, setSelected] = useState<null | ThermometerType>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [daysDiff, setDaysDiff] = useState<number>(0);
  const { t } = useTranslation();

  const handlerSelection = (pulseType: ThermometerType) => {
    if (pulseType === selected) return setSelected(null);
    setSelected(pulseType);
  };

  const submitSelection = async () => {
    if (selected) {
      onClose();
      await new ThermometerRepository().vote(selected);
      calculateLastVote();
    }
  };

  const calculateLastVote = async () => {
    setLoading(true);
    const lastVote = await new ThermometerRepository().getLastVote();
    const config = await new ThermometerConfigRepository().findOne();
    if (config.temporality === ThermometerTemporality.DAILY) {
      if (
        !lastVote ||
        DateUtil.dateDaysDiff(new Date(lastVote.createdDate), new Date()) >= 1
      ) {
        setDaysDiff(1);
      } else setDaysDiff(-1);
    }

    if (config.temporality === ThermometerTemporality.WEEKLY) {
      if (
        !lastVote ||
        DateUtil.dateDaysDiff(new Date(lastVote.createdDate), new Date()) >= 7
      ) {
        setDaysDiff(7);
      } else setDaysDiff(-1);
    }

    if (config.temporality === ThermometerTemporality.MONTHLY) {
      if (
        !lastVote ||
        DateUtil.dateDaysDiff(new Date(lastVote.createdDate), new Date()) >= 7
      ) {
        setDaysDiff(7);
      } else setDaysDiff(-1);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) onOpen();
  }, [open]);

  useEffect(() => {
    calculateLastVote();
  }, []);

  return (
    <>
      <Skeleton
        isLoaded={!isLoading}
        className="rounded-xl w-full h-full"
        classNames={{ content: 'h-full' }}
      >
        <Card className="shadow-none bg-content4 w-full h-full">
          <CardBody
            className="cursor-pointer w-full flex-row gap-5 p-3 items-center"
            onClick={() => {
              daysDiff > -1 && onOpen();
            }}
          >
            <img src={thermometerIcon} className="size-[90px] lg:size-16 m-0" />
            <div className="flex flex-col justify-between">
              <div className="flex gap-2 items-center">
                <div
                  className={`flex-shrink-0 rounded-full ${
                    daysDiff === -1 ? 'bg-success' : 'bg-warning'
                  } w-2 h-2`}
                ></div>
                <span className="text-default-500 text-sm">
                  {daysDiff === -1
                    ? t('user.dashboard.surveys.card.alreadyVote')
                    : daysDiff > 1
                      ? t('user.dashboard.surveys.card.dueDate', {
                          days: daysDiff,
                        })
                      : t('user.dashboard.surveys.card.dueDateDay', {
                          days: daysDiff,
                        })}
                </span>
              </div>
              <span className="font-bold">
                {t('user.dashboard.surveys.thermometer.title')}
              </span>
              <span className="text-default-500 text-sm">
                {t('user.dashboard.surveys.thermometer.description')}
              </span>
            </div>
          </CardBody>
        </Card>
      </Skeleton>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="5xl">
        <ModalContent>
          <ModalBody className="py-5 overflow-y-auto">
            <div className="flex flex-col gap-1">
              <span className="text-default-400 uppercase">
                {t('user.dashboard.surveys.thermometer.title')}
              </span>
              <span className="text-xl font-bold">
                {t('user.dashboard.surveys.thermometer.anotherDescription')}
              </span>
              <span className="text-default-600">
                {t('user.dashboard.surveys.thermometer.selectOption')}
              </span>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-5 py-5">
              <div
                className={`flex md:w-1/3 flex-col gap-2 md:gap-5 items-center p-5 bg-success-100 rounded cursor-pointer ${
                  selected === ThermometerType.GOOD &&
                  'border border-success-600'
                }`}
                onClick={() => handlerSelection(ThermometerType.GOOD)}
              >
                <img src={goodIcon} />
                <p className="text-md md:text-lg font-semibold text-default-700 dark:text-foreground-700 text-center">
                  {t('user.dashboard.surveys.thermometer.options.GOOD')}
                </p>
              </div>
              <div
                className={`flex md:w-1/3 flex-col gap-2 md:gap-5 items-center p-5 bg-warning-100 rounded cursor-pointer ${
                  selected === ThermometerType.NEUTRAL &&
                  'border border-warning-600'
                }`}
                onClick={() => handlerSelection(ThermometerType.NEUTRAL)}
              >
                <img src={neutralIcon} />
                <p className="text-md md:text-lg font-semibold text-default-700 dark:text-foreground-700 text-center">
                  {t('user.dashboard.surveys.thermometer.options.NEUTRAL')}
                </p>
              </div>
              <div
                className={`flex md:w-1/3 flex-col gap-2 md:gap-5 items-center p-5 bg-danger-100 rounded cursor-pointer ${
                  selected === ThermometerType.BAD && 'border border-danger-600'
                }`}
                onClick={() => handlerSelection(ThermometerType.BAD)}
              >
                <img src={badIcon} />
                <p className="text-md md:text-lg font-semibold text-default-700 dark:text-foreground-700 text-center">
                  {t('user.dashboard.surveys.thermometer.options.BAD')}
                </p>
              </div>
            </div>
            <Button
              variant="flat"
              color="primary"
              onClick={submitSelection}
              isDisabled={!selected}
            >
              {t('user.dashboard.surveys.thermometer.send')}
            </Button>
            <div className="flex gap-5 items-start pt-3">
              <img src={infoIcon} />
              <div>
                <p className="font-bold text-default-700 text-lg">
                  {t('user.dashboard.surveys.thermometer.why')}
                </p>
                <p className="text-default-500">
                  {t('user.dashboard.surveys.thermometer.because')}
                </p>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ThermometerCard;
