import { RouteObject } from 'react-router-dom';
import KeyResultViewPage from './view/keyResultView.page';
import KeyResultEditPage from './edit/keyResultEdit.page';

const keyResultRoutes: Array<RouteObject> = [
  {
    children: [
      { path: 'view/:id', element: <KeyResultViewPage /> },
      { path: 'edit/:id', element: <KeyResultEditPage /> },
    ],
  },
];

export default keyResultRoutes;
