import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PulseSurveyList from '@modules/voteModule/pulse/pulseSurvey/components/pulseSurvey.list';
import StrengthList from '@modules/voteModule/recognition/strength/components/strength.list';
import ThermometerConfigForm from '@modules/voteModule/thermometerConfig/components/thermometerConfigForm.component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

const SurveyConfigurationPage = () => {
  const user = useSelector(selectProfile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const surveyOptions = {
    add: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.SURVEY &&
          permission.methods === AllowedMethods.ADD,
      ),
    },
    view: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.SURVEY &&
          permission.methods === AllowedMethods.LIST,
      ),
    },
    edit: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.SURVEY &&
          permission.methods === AllowedMethods.MODIFY,
      ),
    },
    delete: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.SURVEY &&
          permission.methods === AllowedMethods.DELETE,
      ),
    },
  };
  const strengthsOptions = {
    add: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.RECOGNITION &&
          permission.methods === AllowedMethods.ADD,
      ),
    },
    view: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.RECOGNITION &&
          permission.methods === AllowedMethods.LIST,
      ),
    },
    edit: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.RECOGNITION &&
          permission.methods === AllowedMethods.MODIFY,
      ),
    },
    delete: {
      isAllowed: user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.RECOGNITION &&
          permission.methods === AllowedMethods.DELETE,
      ),
    },
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.surveys.title')}
      </h3>
      {user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.THERMOMETER &&
          permission.methods === AllowedMethods.MODIFY,
      ) && <ThermometerConfigForm />}
      {user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.SURVEY &&
          permission.methods !== AllowedMethods.VIEW,
      ) && (
        <>
          <h4>{t('user.configuration.surveys.pulse.title')}</h4>
          <PulseSurveyList configurations={surveyOptions} />
        </>
      )}
      {user?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.RECOGNITION &&
          permission.methods !== AllowedMethods.VIEW,
      ) && (
        <>
          <h4>{t('user.configuration.surveys.recognition.title')}</h4>
          <StrengthList configurations={strengthsOptions} />
        </>
      )}
    </div>
  );
};

export default SurveyConfigurationPage;
