const MoonIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0288 20.5C9.65963 20.5 7.65066 19.6756 6.00195 18.0269C4.35323 16.3782 3.52887 14.3692 3.52887 12C3.52887 10.027 4.13944 8.27314 5.36057 6.73854C6.5817 5.20392 8.20829 4.21481 10.2403 3.77121C10.4506 3.71865 10.6358 3.72377 10.7961 3.78659C10.9563 3.84942 11.0865 3.94301 11.1865 4.06736C11.2865 4.19171 11.3454 4.34267 11.3634 4.52024C11.3813 4.6978 11.3397 4.87505 11.2384 5.05196C11.032 5.4276 10.8788 5.81784 10.7788 6.22269C10.6788 6.62752 10.6288 7.0533 10.6288 7.50001C10.6288 9.13891 11.2024 10.532 12.3496 11.6792C13.4969 12.8264 14.8899 13.4 16.5288 13.4C17.0199 13.4 17.4891 13.3379 17.9365 13.2135C18.384 13.0892 18.7756 12.9449 19.1115 12.7808C19.2756 12.709 19.4359 12.6837 19.5923 12.7049C19.7487 12.726 19.8822 12.7783 19.9927 12.8616C20.1155 12.9449 20.2067 13.0587 20.2663 13.203C20.3259 13.3472 20.3326 13.5167 20.2865 13.7116C19.9313 15.6782 18.9746 17.3013 17.4163 18.5808C15.858 19.8602 14.0622 20.5 12.0288 20.5ZM12.0288 19C13.4955 19 14.8122 18.5958 15.9788 17.7875C17.1455 16.9792 17.9955 15.925 18.5288 14.625C18.1955 14.7083 17.8622 14.775 17.5288 14.825C17.1955 14.875 16.8622 14.9 16.5288 14.9C14.4788 14.9 12.733 14.1792 11.2913 12.7375C9.84968 11.2958 9.12884 9.55001 9.12884 7.50001C9.12884 7.16668 9.15384 6.83335 9.20385 6.50001C9.25384 6.16668 9.32051 5.83335 9.40384 5.50001C8.10384 6.03335 7.04968 6.88335 6.24135 8.05001C5.43301 9.21668 5.02884 10.5333 5.02884 12C5.02884 13.9333 5.71218 15.5833 7.07884 16.95C8.44551 18.3167 10.0955 19 12.0288 19Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoonIcon;
