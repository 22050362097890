import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Input } from '@nextui-org/react';
import CreateStrengthDTO from '../dto/createStrength.dto';
import StrengthFormValidation from '../validation/strength.validation';

interface StrengthFormProps {
  initialValues?: CreateStrengthDTO;
  onSubmit: (values: CreateStrengthDTO) => void | Promise<void>;
}

export const initialPulseSurveyFormValues: CreateStrengthDTO = {
  strength: '',
};

const StrengthForm = ({
  initialValues = initialPulseSurveyFormValues,
  onSubmit,
}: StrengthFormProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={StrengthFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateStrengthDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 gap-5">
            <Input
              name="strength"
              label={t('entities.strength.fields.strength')}
              isInvalid={Boolean(errors.strength)}
              errorMessage={errors.strength}
              color={errors.strength ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('strength', e.target.value)}
              value={values.strength}
            />
          </div>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default StrengthForm;
