import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentDTO from '../dto/document.dto';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Tooltip,
} from '@nextui-org/react';
import EditIcon from '@components/icons/edit.icon';
import DocumentBrowserItem from './documentBrowserItem';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import { useDocumentContext } from '../context/document.context';
import DocumentUserRepository from '@modules/documentModule/documentUser/documentUser.repository';
import InfoIcon from '@components/icons/info.icon';
import DateUtil from '@utils/date';
import { useTranslation } from 'react-i18next';
import FullscreenIcon from '@components/icons/fullscreen.icon';
import FullscreenExitIcon from '@components/icons/fullscreenExit.icon';

const DocumentViewer = () => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { id } = useParams();
  const { findDocumentById, isBrowserLoading } = useDocumentContext();
  const viewerRef = useRef<HTMLDivElement>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [doc, setDoc] = useState<DocumentDTO>();
  const [documentUserRepository] = useState(new DocumentUserRepository());
  const [creator, setCreator] = useState('');
  const [editor, setEditor] = useState('');
  const [infoOpen, setInfoOpen] = useState(false);

  const getCreatorAndEditor = async (doc: DocumentDTO) => {
    const creatorName = (
      await documentUserRepository.find({
        where: {
          email: doc.createdBy,
          externalEnterprise: profile?.enterprise.id,
        },
      })
    ).results[0].fullName;
    setCreator(creatorName);
    if (doc.lastModifiedBy === doc.createdBy) return setEditor(creatorName);
    const editorName = (
      await documentUserRepository.find({
        where: {
          email: doc.lastModifiedBy,
          externalEnterprise: profile?.enterprise.id,
        },
      })
    ).results[0].fullName;
    setEditor(editorName);
  };

  const getDocument = async (id: string) => {
    const doc = findDocumentById(id);
    if (doc) {
      setDoc(doc);
      getCreatorAndEditor(doc);
    } else navigate('/enterprise/documents/error');
  };

  useEffect(() => {
    if (id && !isBrowserLoading) getDocument(id);
  }, [id, isBrowserLoading]);

  const enterFullscreen = () => {
    if (viewerRef.current && viewerRef.current.requestFullscreen) {
      viewerRef.current?.requestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const handleFullscreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <div className="space-y-5 lg:space-y-0">
      {!doc ? (
        <div className="h-80 flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Card className="shadow-none lg:hidden">
            <CardBody>
              <DocumentBrowserItem
                document={doc}
                displayParent={true}
                parent={doc.parent && doc.parent}
              />
            </CardBody>
          </Card>
          <div ref={viewerRef} className="overflow-y-auto">
            <Card
              className={`shadow-none overflow-x-auto mb-5 ${isFullScreen ? 'min-h-screen overflow-y-auto' : ''}`}
            >
              <CardHeader className="justify-end gap-x-2">
                {!isFullScreen ? (
                  <Button onPress={enterFullscreen} isIconOnly variant="light">
                    <FullscreenIcon className="text-foreground" />
                  </Button>
                ) : (
                  <Button onPress={exitFullscreen} isIconOnly variant="light">
                    <FullscreenExitIcon className="text-foreground" />
                  </Button>
                )}
                <Tooltip
                  content={
                    <div className="text-xs">
                      <p>
                        {t('user.document.documentInfo.creation', {
                          userName: creator,
                          date: DateUtil.dateToFullDate(doc.createdDate),
                        })}
                      </p>
                      <p>
                        {t('user.document.documentInfo.lastEdition', {
                          userName: editor,
                          date: DateUtil.dateToFullDate(doc.lastModifiedDate),
                        })}
                      </p>
                    </div>
                  }
                  isOpen={infoOpen}
                  onOpenChange={(open) => setInfoOpen(open)}
                >
                  <Button
                    isIconOnly
                    variant="light"
                    size="sm"
                    color="primary"
                    onPress={() => setInfoOpen(!infoOpen)}
                  >
                    <InfoIcon className="text-primary" />
                  </Button>
                </Tooltip>
                {(doc.editorAll ||
                  doc.editors.find((editor) => editor.id === profile?.id) ||
                  doc.editorTeams?.find(
                    (editor) => editor.id === profile?.team?.id,
                  )) && (
                  <Button
                    onPress={() => navigate(`/enterprise/documents/edit/${id}`)}
                    color="primary"
                    startContent={<EditIcon className="w-5 h-5" />}
                  >
                    {t('user.document.edit')}
                  </Button>
                )}
              </CardHeader>
              <CardBody className="overflow-visible w-full space-y-2">
                {doc.content ? (
                  <div
                    className="text-default-600"
                    dangerouslySetInnerHTML={{ __html: doc.content }}
                  ></div>
                ) : (
                  <div className="h-80"></div>
                )}
              </CardBody>
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentViewer;
