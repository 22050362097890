import i18next from 'i18next';
import * as Yup from 'yup';

const PulseSurveyFormValidation = () => {
  return Yup.object().shape({
    question: Yup.string()
      .required(i18next.t('validations.required'))
      .min(3, i18next.t('validations.min', { min: 3 })),
    dateFrom: Yup.date().required(i18next.t('validations.required')),
    dateTo: Yup.date().required(i18next.t('validations.required')),
  });
};

export default PulseSurveyFormValidation;
