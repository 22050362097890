import React from 'react';
import UserFileRepository, {
  UserEventDTO,
} from '@modules/userModule/userFile/userFile.repository';
import { Card, CardBody, CardFooter } from '@nextui-org/card';
import {
  Button,
  User,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  CardHeader,
  Skeleton,
} from '@nextui-org/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import imgEvent from '@assets/img/papel-picado.svg';
import DateUtil from '@utils/date';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const EventsDashboardCard = () => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [nextEvents, setNextEvents] = useState<UserEventDTO[]>([]);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);

  const openModal = () => {
    const modal = queryParams.get('open');
    if (modal === 'events') onOpen();
  };

  useEffect(() => {
    openModal();
  }, [queryParams]);

  const getNextEvents = async (startDate: string, endDate: string) => {
    setIsLoading(true);
    try {
      const nextEventsResponse = await new UserFileRepository().getNextEvents(
        startDate,
        endDate,
      );
      setNextEvents(nextEventsResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const startDate = new Date(Date.now() - timezoneOffset);
    const startDateString = startDate.toISOString().substring(0, 10);

    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 4);
    const endDateString = endDate.toISOString().substring(0, 10);

    getNextEvents(startDateString, endDateString);
  }, []);

  const LoadingTemplate = () => {
    return (
      <div className="flex flex-col gap-2">
        <Skeleton className="rounded-xl">
          <div className="h-14"></div>
        </Skeleton>
        <Skeleton className="rounded-xl">
          <div className="h-14"></div>
        </Skeleton>
        <Skeleton className="rounded-xl">
          <div className="h-14"></div>
        </Skeleton>
      </div>
    );
  };

  return (
    <Card className="shadow-none bg-content4">
      <CardHeader>
        <span className="py-0 dark:text-primary-600 font-bold">
          {t('user.events.title')}
        </span>
      </CardHeader>
      <CardBody className="relative overflow-visible space-y-2 p-2">
        {isLoading ? (
          <LoadingTemplate />
        ) : (
          nextEvents.map((user, index) => {
            if (index < 3)
              return (
                <Card className="shadow-sm" key={user.id}>
                  <CardBody className="flex flex-row justify-between items-center p-1">
                    <User
                      key={index}
                      name={user.firstName + ' ' + user.lastName}
                      description={
                        <>
                          {DateUtil.isToday(new Date(user.eventDate)) ? (
                            <span>
                              {user.hireDate
                                ? t('user.events.isAnniversaryToday')
                                : t('user.events.isBirthdayToday')}
                            </span>
                          ) : (
                            <span>
                              {new Date(user.eventDate).toLocaleDateString(
                                'es-es',
                                {
                                  month: 'long',
                                  day: 'numeric',
                                  timeZone: 'UTC',
                                },
                              )}{' '}
                              {user.hireDate
                                ? t('user.events.isAniversay')
                                : t('user.events.isBirthdate')}
                            </span>
                          )}
                        </>
                      }
                      avatarProps={{
                        className: 'flex-shrink-0',
                        src: user.avatar
                          ? `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`
                          : undefined,
                        color:
                          user.id === profile?.id ? 'primary' : 'secondary',
                        isBordered: user.id === profile?.id,
                      }}
                    />
                  </CardBody>
                </Card>
              );
          })
        )}
      </CardBody>
      <CardFooter>
        <Button
          className="h-full w-full py-2 px-4 rounded"
          size="sm"
          color="primary"
          variant="light"
          onPress={onOpen}
        >
          {t('user.events.viewAll')}
        </Button>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="3xl">
          <ModalContent>
            {() => (
              <div>
                <ModalHeader className="flex flex-col gap-1 items-center">
                  <img src={imgEvent} alt="Icono de Eventos" />
                  <h1 className="text-2xl">{t('user.events.todayEvents')}</h1>
                  <span className="text-sm text-gray-400">
                    {t('user.events.today')},
                    {' ' +
                      new Date().toLocaleDateString('es-es', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'UTC',
                      })}
                  </span>
                </ModalHeader>
                <ModalBody>
                  {nextEvents.filter(
                    (user) =>
                      user.hireDate &&
                      DateUtil.isToday(new Date(user.hireDate)),
                  ).length > 0 ? (
                    <div>
                      <span className="text-sm font-gray-600">
                        {t('user.events.aniversaries')}
                      </span>
                      <div className="flex flex-wrap gap-2">
                        {nextEvents
                          .filter(
                            (user) =>
                              user.hireDate &&
                              DateUtil.isToday(new Date(user.hireDate)),
                          )
                          .map((user, index) => (
                            <Card className="shadow-sm" key={user.id}>
                              <CardBody className="flex flex-row justify-between items-center p-1">
                                <User
                                  key={index}
                                  name={user.firstName + ' ' + user.lastName}
                                  description={t('user.events.todayAniversary')}
                                  avatarProps={{
                                    className: 'flex-shrink-0',
                                    src: user.avatar
                                      ? `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`
                                      : undefined,
                                    color:
                                      user.id === profile?.id
                                        ? 'primary'
                                        : 'secondary',
                                    isBordered: user.id === profile?.id,
                                  }}
                                />
                              </CardBody>
                            </Card>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <span className="text-sm font-gray-600">
                      {t('user.events.noAnniversaryToday')}
                    </span>
                  )}
                  {nextEvents.filter(
                    (user) =>
                      user.birthDate &&
                      DateUtil.isToday(new Date(user.birthDate)),
                  ).length > 0 ? (
                    <div>
                      <span className="text-sm font-gray-600">
                        {t('user.events.birthDates')}
                      </span>
                      <div className="flex flex-wrap gap-2">
                        {nextEvents
                          .filter(
                            (user) =>
                              user.birthDate &&
                              DateUtil.isToday(new Date(user.birthDate)),
                          )
                          .map((user, index) => (
                            <Card className="shadow-sm" key={user.id}>
                              <CardBody className="flex flex-row justify-between items-center p-1">
                                <User
                                  key={index}
                                  name={user.firstName + ' ' + user.lastName}
                                  description={t('user.events.todayBirthday')}
                                  avatarProps={{
                                    className: 'flex-shrink-0',
                                    src: user.avatar
                                      ? `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`
                                      : undefined,
                                    color:
                                      user.id === profile?.id
                                        ? 'primary'
                                        : 'secondary',
                                    isBordered: user.id === profile?.id,
                                  }}
                                />
                              </CardBody>
                            </Card>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <span className="text-sm font-gray-600">
                      {t('user.events.noBirthdayToday')}
                    </span>
                  )}
                  <div className="">
                    <span className="text-sm font-gray-600 w-full">
                      {t('user.events.nextEvents')}
                    </span>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {nextEvents
                      .filter(
                        (user, index) =>
                          !DateUtil.isToday(new Date(user.eventDate)) &&
                          index < 8,
                      )
                      .map((user, index) => (
                        <Card className="shadow-sm w-1/4" key={user.id}>
                          <CardBody className="flex flex-row justify-between items-center p-1 w-full">
                            <User
                              key={index}
                              name={user.firstName + ' ' + user.lastName}
                              description={`${new Date(
                                user.eventDate,
                              ).toLocaleDateString('es-es', {
                                month: 'long',
                                day: 'numeric',
                                timeZone: 'UTC',
                              })} ${
                                user.hireDate
                                  ? t('user.events.isAniversay')
                                  : t('user.events.isBirthdate')
                              }`}
                              avatarProps={{
                                className: 'flex-shrink-0',
                                src: user.avatar
                                  ? `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`
                                  : undefined,
                                color:
                                  user.id === profile?.id
                                    ? 'primary'
                                    : 'secondary',
                                isBordered: user.id === profile?.id,
                              }}
                            />
                          </CardBody>
                        </Card>
                      ))}
                  </div>
                </ModalBody>
              </div>
            )}
          </ModalContent>
        </Modal>
      </CardFooter>
    </Card>
  );
};

export default EventsDashboardCard;
