import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateUserDTO from '@modules/userModule/user/dto/createUser.dto';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserForm from '@modules/userModule/user/components/userForm';
import UserRepository from '@modules/userModule/user/user.repository';
import { FormikHelpers } from 'formik';
import { AxiosError } from 'axios';

const AddUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmitForm = async (
    values: CreateUserDTO,
    helpers: FormikHelpers<CreateUserDTO>,
  ) => {
    try {
      await new UserRepository().save({
        ...values,
      });
      navigate(-1);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const response = error.response?.data.errors;
        const errors: any = {};
        Object.keys(response).forEach((errKey) => {
          errors[errKey] = response[errKey].join(' ');
        });
        helpers.setErrors(errors);
      }
    }
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.users.add')}
      </h3>
      <UserForm onSubmit={handleSubmitForm} />
    </div>
  );
};

export default AddUser;
