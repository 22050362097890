import React from 'react';
import { useCreateBlockNote } from '@blocknote/react';
import { validateBlockNote } from '@components/customBlockNote/customBlockNote.component';
import { customSchema } from '@components/customBlockNote/customSchema';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChallengesRepository from '@modules/voteModule/challenges/challenges.repository';
import ChallengeForm from '@modules/voteModule/challenges/configuration/components/challenge.form';
import CreateChallengeDTO from '@modules/voteModule/challenges/configuration/dto/createChallenge.dto';
import { Card, CardBody } from '@nextui-org/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ChallengeAddPage = () => {
  const navigate = useNavigate();
  const [blocknoteError, setBlocknoteError] = useState(false);
  const [challengesRepository] = useState(new ChallengesRepository());
  const editor = useCreateBlockNote({ schema: customSchema });

  editor.onEditorContentChange(() => setBlocknoteError(false));

  const handleSubmitForm = async (values: CreateChallengeDTO) => {
    if (validateBlockNote(editor)) return setBlocknoteError(true);
    const assignment = await editor.blocksToHTMLLossy(editor.document);
    const challenge = { ...values, assignment };
    await challengesRepository.save(challenge);
    navigate(-1);
  };

  return (
    <div>
      {' '}
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        Crear desafío
      </h3>
      <Card className="shadow-none overflow-visible mb-5">
        <CardBody className="overflow-visible">
          <ChallengeForm
            onSubmit={handleSubmitForm}
            editor={editor}
            blocknoteError={blocknoteError}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default ChallengeAddPage;
