import React from 'react';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody } from '@nextui-org/react';
import LicenseTypeList from '@modules/licenseModule/licenseType/components/licenseTypeList.component';

const LicenseTypeListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-7">
      <div className="flex justify-between items-center">
        <h3 className="flex items-center gap-4">
          <div className="w-12 h-12 rounded-2xl flex items-center justify-center bg-white dark:bg-black drop-shadow-md">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="cursor-pointer"
              onClick={() => navigate(-1)}
            />
          </div>
          <span className="text-3xl">{t('user.license.view.title')}</span>
        </h3>
        <Button
          color="primary"
          className="mt-5"
          onClick={() => navigate('/enterprise/license/licenseType/create')}
        >
          <div className="bg-primary rounded-lg p-2">
            <FontAwesomeIcon icon={faPlus} />
          </div>
          {t('user.licenseType.new.button')}
        </Button>
      </div>
      <Card>
        <CardBody className="p-7 flex flex-col gap-7">
          <div className="flex flex-col gap-2">
            <span className="font-semibold text-xl">
              {t('user.licenseType.list.title')}
            </span>
            <span className="text-[#94A3B8] text-sm">
              {t('user.licenseType.list.subtitle')}
            </span>
          </div>
          <LicenseTypeList />
        </CardBody>
      </Card>
    </div>
  );
};

export default LicenseTypeListPage;
