import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import axios from 'axios';
import UserDTO from './dto/user.dto';

export default class UserMetricRepository {
  private url: string = '/api/user';

  async count(options?: FindOptionsDTO<UserDTO>): Promise<number> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userCountResponse = await axios.get<number>(
      `${this.url}/count${stringOption}`,
    );
    return userCountResponse.data;
  }
}
