import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import UserGroupDetail from '@modules/userModule/userGroup/components/userGroupDetail';
import UserList from '@modules/userModule/user/components/userList';

const UserGroupView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.userGroups.detail')}
      </h3>
      <UserGroupDetail id={id as string} />
      <div className="pt-5">
        <span className="text-foreground text-lg">
          {t('user.configuration.enterprise.userGroups.users')}
        </span>
        <UserList
          options={{ where: { group: { id } } }}
          configurations={{
            add: { isAllowed: true },
            view: { isAllowed: true },
            edit: { isAllowed: true },
            delete: { isAllowed: false },
          }}
        />
      </div>
    </div>
  );
};
export default UserGroupView;
