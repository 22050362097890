import { RouteObject } from 'react-router-dom';
import RecognitionAddPage from './recognitionAdd.page';
import RecognitionEditPage from './recognitionEdit.page';
import RecognitionViewPage from './recognitionView.page';

const recognitionRoutes: Array<RouteObject> = [
  {
    children: [
      { path: 'strength/add', element: <RecognitionAddPage /> },
      { path: 'strength/view/:id', element: <RecognitionViewPage /> },
      { path: 'strength/edit/:id', element: <RecognitionEditPage /> },
    ],
  },
];

export default recognitionRoutes;
