import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObjectiveForm from '@modules/objectiveModule/objective/components/objectiveForm';
import CreateObjectiveDTO from '@modules/objectiveModule/objective/dto/createObjective.dto';
import ObjectiveDTO from '@modules/objectiveModule/objective/dto/objective.dto';
import {
  clear,
  getObjective,
  setObjective,
} from '@modules/objectiveModule/objective/objective.reducer';
import { Slider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ObjectiveAddPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const objective = useSelector(getObjective);

  const handleSubmit = async (values: CreateObjectiveDTO) => {
    dispatch(setObjective(values));
    navigate('/enterprise/objective/keyResult/add');
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => {
            dispatch(clear());
            navigate(-1);
          }}
        />{' '}
        {t('user.objective.add.title')}
      </h3>
      <p>{t('user.objective.add.subTitle')}</p>
      <div className="flex items-center justify-center">
        <Slider
          color="primary"
          step={3}
          value={0}
          marks={[
            {
              value: 0,
              label: t('user.objective.steps.objective'),
            },
            {
              value: 50,
              label: t('user.objective.steps.keyResults'),
            },
            {
              value: 100,
              label: t('user.objective.steps.review'),
            },
          ]}
          className="max-w-md"
        />
      </div>
      <ObjectiveForm
        onSubmit={handleSubmit}
        initialValues={(objective as ObjectiveDTO) || undefined}
      />
    </div>
  );
};
export default ObjectiveAddPage;
