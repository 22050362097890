import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyResultForm from '@modules/objectiveModule/keyResult/components/keyResultForm';
import { Slider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  addKeyResults,
  clear,
  deleteKeyResult,
  getObjective,
} from '@modules/objectiveModule/objective/objective.reducer';
import { useDispatch, useSelector } from 'react-redux';
import CreateKeyResultDTO from '@modules/objectiveModule/keyResult/dto/createKeyResult.dto';
import KeyResultAddedList from '@modules/objectiveModule/keyResult/components/keyResultAddedList';
import ObjectiveRepository from '@modules/objectiveModule/objective/objective.repository';
import CreateObjectiveDTO from '@modules/objectiveModule/objective/dto/createObjective.dto';

const KeyResultAddPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const objective = useSelector(getObjective) as CreateObjectiveDTO;

  const handleAddKeyResult = (values: CreateKeyResultDTO) => {
    values.initialValue = Number(values.initialValue);
    values.expectedValue = Number(values.expectedValue);
    dispatch(addKeyResults(values));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDeleteKeyResult = (index: number) => {
    dispatch(deleteKeyResult(index));
  };

  const handleSubmit = async (values: CreateKeyResultDTO) => {
    const keyResultsAddeds = new Array(...objective.keyResults);
    values.initialValue = Number(values.initialValue);
    values.expectedValue = Number(values.expectedValue);
    keyResultsAddeds.push(values);
    await new ObjectiveRepository().save({
      ...objective,
      keyResults: keyResultsAddeds,
    });
    dispatch(clear());
    navigate('/enterprise/objective');
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.keyResult.add.title')}
      </h3>
      <p>{t('user.objective.add.subTitle')}</p>
      <div className="flex items-center justify-center">
        <Slider
          color="primary"
          step={3}
          value={50}
          marks={[
            {
              value: 0,
              label: t('user.objective.steps.objective'),
            },
            {
              value: 50,
              label: t('user.objective.steps.keyResults'),
            },
            {
              value: 100,
              label: t('user.objective.steps.review'),
            },
          ]}
          className="max-w-md"
        />
      </div>
      <KeyResultAddedList
        keyResults={objective?.keyResults || []}
        handleDelete={handleDeleteKeyResult}
      />
      <KeyResultForm
        onAddAnother={handleAddKeyResult}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
export default KeyResultAddPage;
