import React from 'react';
import UserRepository from '@modules/userModule/user/user.repository';
import RecognitionVoteDTO from '@modules/voteModule/recognition/recognitionVote/dto/recognitionVote.dto';
import { Card, CardBody, Skeleton } from '@nextui-org/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ParticipationMetric {
  recognitions: RecognitionVoteDTO[];
  dates: { from: Date; to: Date };
  className?: string;
}

const ParticipationMetric = ({
  recognitions,
  dates,
  className,
}: ParticipationMetric) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);

  const [participation, setParticipation] = useState<
    'HIGH' | 'MEDIUM' | 'LOW' | 'NO_DATA'
  >('NO_DATA');

  const getUsers = async () => {
    return await new UserRepository().find({
      take: 999,
    });
  };

  const calculateParitipation = async () => {
    setLoading(true);
    const users = await getUsers();

    const fin = dayjs(dates.to);
    const meses = fin.diff(
      dates.from < new Date() ? dates.from : new Date(),
      'month',
    );

    const totalRocketsToUsed = users.total * (meses * 5);

    let totalRocketsRecongitions = 0;

    recognitions.map((recognition) => {
      totalRocketsRecongitions += recognition.rockets;
    });

    if (totalRocketsRecongitions === 0) {
      setParticipation('NO_DATA');
    } else {
      const participation =
        (totalRocketsRecongitions / totalRocketsToUsed) * 100;
      setParticipation(
        participation > 75 ? 'HIGH' : participation > 45 ? 'MEDIUM' : 'LOW',
      );
    }

    setLoading(false);
  };

  const textColor = (
    participationStatus: 'HIGH' | 'MEDIUM' | 'LOW' | 'NO_DATA',
  ) => {
    const colors = {
      HIGH: 'text-success',
      MEDIUM: 'text-warning',
      LOW: 'text-danger',
      NO_DATA: 'text-default',
    };

    return colors[participationStatus];
  };

  useEffect(() => {
    calculateParitipation();
  }, [recognitions]);

  return (
    <Card className={`${className}`}>
      <CardBody>
        <span className="text-default-400 text-sm">
          {t('entities.metric.objective.participation.helpTitle')}
        </span>
        <span className="font-bold text-lg">
          {t('entities.metric.objective.participation.title')}
        </span>
        <Skeleton isLoaded={!isLoading}>
          <span className={`text-xl ${textColor(participation)}`}>
            {t(
              `entities.metric.objective.participation.values.${participation}`,
            )}
          </span>
        </Skeleton>
      </CardBody>
    </Card>
  );
};

export default ParticipationMetric;
