import { Form, Formik } from 'formik';
import UpdateThermometerConfigDTO from '../dto/updateThermometerConfig.dto';
import ThermometerTemporality from '../enum/thermometerTemporality.enum';
import ThermometerConfigValidation from '../validation/thermometerConfig.validation';
import { Button, Select, SelectItem, Skeleton } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ThermometerConfigDTO from '../dto/thermometerConfig.dto';
import ThermometerConfigRepository from '../thermometerConfig.repository';

export const initialThermometerConfigFormValues: UpdateThermometerConfigDTO = {
  temporality: ThermometerTemporality.DAILY,
};

const ThermometerConfigForm = () => {
  const { t } = useTranslation();
  const [thermometerConfig, setThermometerConfig] = useState<
    ThermometerConfigDTO | undefined
  >(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getThermometerConfig = async () => {
    setLoading(true);
    const thermometerConfigReq =
      await new ThermometerConfigRepository().findOne();
    if (thermometerConfigReq) setThermometerConfig(thermometerConfigReq);
    setLoading(false);
  };

  const handlerThermometerConfigSubmit = async (
    values: UpdateThermometerConfigDTO,
  ) => {
    if (thermometerConfig) {
      const thermometerConfigReq =
        await new ThermometerConfigRepository().update(
          thermometerConfig.id,
          values,
        );
      setThermometerConfig(thermometerConfigReq);
    }
  };

  useEffect(() => {
    getThermometerConfig();
  }, []);

  const loading = () => {
    return (
      <Skeleton isLoaded={false}>
        <p></p>
      </Skeleton>
    );
  };

  return (
    <div>
      <h4>{t('user.configuration.surveys.thermometer.title')}</h4>
      {isLoading || !thermometerConfig ? (
        loading()
      ) : (
        <div>
          <Formik
            initialValues={thermometerConfig}
            validationSchema={ThermometerConfigValidation}
            onSubmit={handlerThermometerConfigSubmit}
          >
            {({ setFieldValue, isSubmitting, values }) => (
              <Form className="py-5 flex flex-col gap-5">
                <Select
                  label={t('entities.thermometerConfig.fields.temporality')}
                  selectedKeys={new Set([values.temporality])}
                  onSelectionChange={(keys: any) => {
                    setFieldValue(
                      'temporality',
                      new Set(keys).values().next().value,
                    );
                  }}
                >
                  {Object.keys(ThermometerTemporality).map((status) => (
                    <SelectItem key={status} value={status}>
                      {t(
                        `entities.thermometerConfig.values.temporality.${status}`,
                      )}
                    </SelectItem>
                  ))}
                </Select>
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    color="primary"
                    isDisabled={
                      isSubmitting ||
                      thermometerConfig.temporality === values.temporality
                    }
                  >
                    {t('save')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default ThermometerConfigForm;
