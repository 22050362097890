import React from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Table from '@components/table/table';
import StrengthDTO from '../dto/strength.dto';
import StrengthRepository from '../strength.repository';

interface StrengthListProps {
  options?: FindOptionsDTO<StrengthDTO>;
  configurations?: {
    add: {
      isAllowed: boolean | undefined;
    };
    view: {
      isAllowed: boolean | undefined;
    };
    edit: {
      isAllowed: boolean | undefined;
    };
    delete: {
      isAllowed: boolean | undefined;
    };
  };
}

const StrengthList = ({ options, configurations }: StrengthListProps) => {
  const navigate = useNavigate();

  const getStrengths = useCallback(
    async (option?: FindOptionsDTO<StrengthDTO>) => {
      return await new StrengthRepository().find(option);
    },
    [],
  );

  const handleSearcher = useCallback(
    (textSearched: string, option?: FindOptionsDTO<StrengthDTO>) => {
      return {
        ...option,
        where: [{ strength: `lk=${textSearched}` }],
      };
    },
    [],
  );

  const handleAddButton = useCallback(() => {
    navigate('/enterprise/configuration/surveys/recognition/strength/add');
  }, [navigate]);

  const handleEditButton = useCallback(
    (id: string) => {
      navigate(
        `/enterprise/configuration/surveys/recognition/strength/edit/${id}`,
      );
    },
    [navigate],
  );

  const handleViewButton = useCallback(
    (id: string) => {
      navigate(
        `/enterprise/configuration/surveys/recognition/strength/view/${id}`,
      );
    },
    [navigate],
  );

  const handleDelete = useCallback(async (id: string) => {
    await new StrengthRepository().delete(id);
  }, []);

  return (
    <Table<StrengthDTO>
      headers={['strength']}
      handlerRequest={(option) => getStrengths({ ...options, ...option })}
      handlerSearcher={handleSearcher}
      options={options}
      configurations={{
        translationLabel: 'strength',
        add: {
          handler: handleAddButton,
          isAllowed: configurations?.add.isAllowed ? true : false,
        },
        edit: {
          handler: handleEditButton,
          isAllowed: configurations?.edit.isAllowed ? true : false,
        },
        view: {
          handler: handleViewButton,
          isAllowed: configurations?.view.isAllowed ? true : false,
        },
        delete: {
          field: 'strength',
          handler: handleDelete,
          isAllowed: configurations?.delete.isAllowed ? true : false,
        },
      }}
    />
  );
};

export default StrengthList;
