import FindOptionsDTO from 'lib/paginated/findOptions.dto';
import PaginatedDTO from 'lib/paginated/paginated.dto';
import axios from 'axios';
import { LevelDTO } from './dto/level.dto';
import { EditLevelDTO } from './dto/editLevel.dto';

export default class LevelRepository {
  private url: string = '/api/level';

  async find(
    options?: FindOptionsDTO<LevelDTO>,
  ): Promise<PaginatedDTO<LevelDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const levelResponse = await axios.get<PaginatedDTO<LevelDTO>>(
      `${this.url}${stringOption}`,
    );
    return levelResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<LevelDTO>,
  ): Promise<LevelDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const levelResponse = await axios.get<LevelDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return levelResponse.data;
  }

  async save(level: Partial<LevelDTO>) {
    const createdLevelResponse = await axios.post<LevelDTO>(
      `${this.url}`,
      level,
    );
    return createdLevelResponse.data;
  }

  async update(id: string, changes: EditLevelDTO) {
    const updatedLevelResponse = await axios.put<LevelDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedLevelResponse.data;
  }

  async delete(id: string) {
    const deletedLevelResponse = await axios.delete<LevelDTO>(
      `${this.url}/${id}`,
    );
    return deletedLevelResponse.data;
  }

  async findTendency() {
    const levelResponse = await axios.get(`${this.url}/tendency`);
    return levelResponse.data;
  }

  async findRanking() {
    const levelResponse = await axios.get(`${this.url}/ranking`);
    return levelResponse.data;
  }

  async findRankingMonthly() {
    const levelResponse = await axios.get(`${this.url}/ranking/monthly`);
    return levelResponse.data;
  }

  async findRankingWeekley() {
    const levelResponse = await axios.get(`${this.url}/ranking/weekley`);
    return levelResponse.data;
  }
}
