import React, { useEffect, useMemo, useState } from 'react';
import DocumentDTO from '../dto/document.dto';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from '@nextui-org/react';
import CustomBlockNote from '@components/customBlockNote/customBlockNote.component';
import { BlockNoteEditor } from '@blocknote/core';
import {
  CustomPartialBlock,
  customSchema,
} from '@components/customBlockNote/customSchema';
import { useCreateBlockNote } from '@blocknote/react';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentBrowserItem from './documentBrowserItem';
import DocumentRepository from '@modules/documentModule/document.repository';
import { useDocumentContext } from '../context/document.context';
import { useTranslation } from 'react-i18next';

const DocumentEditor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { getDocuments, findDocumentById, isBrowserLoading } =
    useDocumentContext();
  const [document, setDocument] = useState<DocumentDTO>();
  const [initialContent, setInitialContent] = useState<
    CustomPartialBlock[] | 'loading' | null | undefined
  >('loading');
  const [isSaving, setIsSaving] = useState(false);
  const discardModal = useDisclosure();
  const [documentRepository] = useState(new DocumentRepository());

  const editor = useMemo(() => {
    if (initialContent === 'loading') {
      return undefined;
    }
    if (initialContent === null) {
      return BlockNoteEditor.create({ schema: customSchema });
    }
    return BlockNoteEditor.create({
      initialContent,
      schema: customSchema,
    });
  }, [initialContent]);

  const functionalEditor = useCreateBlockNote({ schema: customSchema });

  const loadDocument = async (id: string) => {
    const docToEdit = findDocumentById(id);
    if (docToEdit) {
      setDocument(docToEdit);
      if (docToEdit.content) {
        setInitialContent(
          await functionalEditor.tryParseHTMLToBlocks(docToEdit.content),
        );
      } else {
        setInitialContent(null);
      }
    } else navigate('/enterprise/documents/error');
  };

  useEffect(() => {
    if (id && !isBrowserLoading) loadDocument(id);
  }, [id, isBrowserLoading]);

  const saveDocument = async () => {
    setIsSaving(true);
    if (!editor) return;
    const content = await editor.blocksToHTMLLossy(editor.document);
    const doc = {
      ...document,
      content,
    };
    if (document) {
      await documentRepository.update(doc, document.id);
      await getDocuments();
      setIsSaving(false);
      navigate(`/enterprise/documents/view/${document.id}`);
    }
    setIsSaving(false);
  };

  return (
    <div className="space-y-5 lg:space-y-0">
      {initialContent === 'loading' || !document ? (
        <div className="h-80 flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Card className="shadow-none lg:hidden">
            <CardBody>
              <DocumentBrowserItem
                document={document}
                displayParent={true}
                parent={document.parent && document.parent}
              />
            </CardBody>
          </Card>
          <Card className="shadow-none overflow-visible min-h-80 mb-5">
            <CardBody className="px-0.5 lg:px-3 overflow-visible w-full space-y-2">
              <div className="flex justify-center lg:justify-end gap-2">
                <Button onPress={discardModal.onOpen} className="w-fit">
                  {t('user.document.cancel')}
                </Button>
                <Button
                  onPress={saveDocument}
                  color="primary"
                  isLoading={isSaving}
                  className="w-fit"
                >
                  {t('user.document.save')}
                </Button>
              </div>
              {editor && <CustomBlockNote editor={editor} />}
            </CardBody>
          </Card>

          <Modal
            isOpen={discardModal.isOpen}
            onOpenChange={discardModal.onOpenChange}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader>
                    {t('user.document.discardChanges.title')}
                  </ModalHeader>
                  <ModalBody>
                    {t('user.document.discardChanges.text', {
                      title: document?.title,
                    })}
                  </ModalBody>
                  <ModalFooter className="justify-center gap-2">
                    <Button variant="bordered" onPress={onClose}>
                      {t('user.document.discardChanges.cancel')}
                    </Button>
                    <Button
                      onPress={() =>
                        navigate(`/enterprise/documents/view/${id}`)
                      }
                    >
                      {t('user.document.discardChanges.confirm')}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </>
      )}
    </div>
  );
};

export default DocumentEditor;
