import { useState } from 'react';
import ObjectiveStatus from '../enum/objectiveStatus.enum';
import { Select, SelectItem } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

interface ObjectiveStatusFilterProps {
  onChange: (status: ObjectiveStatus) => void;
}

const ObjectiveStatusFilter = (props: ObjectiveStatusFilterProps) => {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<ObjectiveStatus>(
    ObjectiveStatus.ON_TIME,
  );

  const styles: { [key in ObjectiveStatus]: string } = {
    ON_TIME:
      'bg-success-200 text-success-800 border border-success-600 data-[hover=true]:bg-success-200',
    LATE: 'bg-warning-200 text-warning-800 border border-warning-600 data-[hover=true]:bg-warning-200',
    AT_RISK:
      'bg-danger-200 text-danger-800 border border-danger-600 data-[hover=true]:bg-danger-200',
  };

  return (
    <div className="py-3">
      <Select
        classNames={{
          trigger: `${styles[selectedStatus]}`,
          value: `text-sm px-2 py-3 rounded`,
        }}
        selectedKeys={new Set([selectedStatus])}
        onSelectionChange={(keys) => {
          setSelectedStatus(new Set(keys).values().next().value);
          props.onChange(new Set(keys).values().next().value);
        }}
      >
        {Object.keys(ObjectiveStatus).map((selectionStatus) => (
          <SelectItem
            className={`text-sm px-2 py-3 rounded ${styles[selectionStatus as ObjectiveStatus]}`}
            key={selectionStatus}
            value={selectionStatus}
          >
            {t(`entities.objective.values.status.${selectionStatus}`)}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

export default ObjectiveStatusFilter;
