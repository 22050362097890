import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PostDTO from '@modules/feed/createPost/dto/post.dto';
import Post from '@modules/feed/posts/components/post';
import PostsRepository from '@modules/feed/posts/posts.repository';
import { Spinner } from '@nextui-org/react';
import ErrorPage from '@pages/error/errorPage';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PostPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState<PostDTO | null>(null);
  const navigate = useNavigate();

  const getPost = async () => {
    setIsLoading(true);
    if (!id) return;
    try {
      const postData = await new PostsRepository().getOne(id);
      setPost(postData);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getPost();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {post ? (
            <div>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="cursor-pointer text-xl mb-5"
                onClick={() => navigate('/enterprise')}
              />
              <Post data={post}></Post>
            </div>
          ) : (
            <ErrorPage />
          )}
        </>
      )}
    </>
  );
};

export default PostPage;
