import React from 'react';
import {
  BasicTextStyleButton,
  BlockTypeSelect,
  ColorStyleButton,
  CreateLinkButton,
  FormattingToolbar,
  FormattingToolbarController,
  ImageCaptionButton,
  ReplaceImageButton,
} from '@blocknote/react';

const CustomToolbar = () => {
  return (
    <FormattingToolbarController
      formattingToolbar={() => (
        <FormattingToolbar>
          <BlockTypeSelect key={'blockTypeSelect'} />
          <ImageCaptionButton key={'imageCaptionButton'} />
          <ReplaceImageButton key={'replaceImageButton'} />
          <BasicTextStyleButton
            basicTextStyle={'bold'}
            key={'boldStyleButton'}
          />
          <BasicTextStyleButton
            basicTextStyle={'italic'}
            key={'italicStyleButton'}
          />
          <BasicTextStyleButton
            basicTextStyle={'underline'}
            key={'underlineStyleButton'}
          />
          <BasicTextStyleButton
            basicTextStyle={'strike'}
            key={'strikeStyleButton'}
          />
          <ColorStyleButton key={'colorStyleButton'} />
          <CreateLinkButton key={'createLinkButton'} />
        </FormattingToolbar>
      )}
    />
  );
};

export default CustomToolbar;
