import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateTeamDTO from '../dto/createTeam.dto';
import { Form, Formik, FormikProps } from 'formik';
import TeamFormValidation from '../validation/team.validation';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  User,
} from '@nextui-org/react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import TeamDTO from '../dto/team.dto';
import TeamRepository from '../team.repository';
import { useEffect, useState } from 'react';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface TeamFormProps {
  initialValues?: CreateTeamDTO;
  onSubmit: (values: CreateTeamDTO) => void | Promise<void>;
}

export const initialTeamFormValues: CreateTeamDTO = {
  name: '',
  parent: null,
  leader: null,
};

const TeamForm = ({
  initialValues = initialTeamFormValues,
  onSubmit,
}: TeamFormProps) => {
  const { t } = useTranslation();
  const [teamList, setTeamList] = useState<TeamDTO[]>([]);
  const [users, setUsers] = useState<UserDTO[]>([]);

  const getTeams = async (options?: FindOptionsDTO<TeamDTO>) => {
    const teamss = await new TeamRepository().find(options);
    setTeamList(teamss.results);
  };

  const getUsers = async (options?: FindOptionsDTO<UserDTO>) => {
    const users = await new UserRepository().find(options);
    setUsers(users.results);
  };

  useEffect(() => {
    getUsers();
    getTeams();
  }, []);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TeamFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateTeamDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 gap-5">
            <Input
              name="name"
              label={t('entities.team.fields.name')}
              isInvalid={Boolean(errors.name)}
              errorMessage={errors.name}
              color={errors.name ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('name', e.target.value)}
              value={values.name}
            />
          </div>
          <div>
            <Autocomplete
              items={users}
              label={t('entities.team.fields.leader')}
              startContent={<FontAwesomeIcon icon={faSearch} />}
              onFocus={() => {
                getUsers();
              }}
              onInputChange={(e) => {
                getUsers({
                  where: [{ firstName: `lk=${e}` }, { lastName: `lk=${e}` }],
                });
              }}
              onSelectionChange={(e) =>
                setFieldValue('leader', e ? { id: e } : null)
              }
              selectedKey={values.leader?.id || null}
              isInvalid={Boolean(errors.leader)}
              errorMessage={errors.leader}
              color={errors.leader ? 'danger' : 'default'}
            >
              {(item) => (
                <AutocompleteItem
                  key={item.id}
                  textValue={`${item.firstName} ${item.lastName}`}
                >
                  <User
                    name={`${item.firstName} ${item.lastName}`}
                    description={item.email}
                    avatarProps={{
                      src:
                        item?.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${item?.avatar}`,
                    }}
                  />
                </AutocompleteItem>
              )}
            </Autocomplete>
          </div>
          <div>
            <Autocomplete
              label={t('entities.team.fields.parent')}
              items={teamList}
              onInputChange={(e) => {
                getTeams({ where: { name: `lk=${e}` } });
              }}
              onSelectionChange={(e) => {
                setFieldValue('parent', e ? { id: e } : null);
              }}
              selectedKey={values.parent?.id || null}
              isInvalid={Boolean(errors.parent)}
              errorMessage={errors.parent}
              color={errors.parent ? 'danger' : 'default'}
            >
              {(item) => (
                <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>
              )}
            </Autocomplete>
          </div>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TeamForm;
