import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import DateUtil from '@utils/date';
import axios from 'axios';
import ThermometerVoteDTO from './dto/thermometerVote.dto';

export default class ThermometerSurveyMetricRepository {
  private url: string = '/api/thermometerVote';

  async find(
    options?: FindOptionsDTO<ThermometerVoteDTO>,
  ): Promise<PaginatedDTO<ThermometerVoteDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const thermometerResponse = await axios.get<
      PaginatedDTO<ThermometerVoteDTO>
    >(`${this.url}${stringOption}`);
    thermometerResponse.data.results.forEach((thermometerVote) => {
      thermometerVote.createdDate = DateUtil.stringToDate(
        String(thermometerVote.createdDate),
      );
    });
    return thermometerResponse.data;
  }

  async count(options?: FindOptionsDTO<ThermometerVoteDTO>): Promise<number> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userCountResponse = await axios.get<number>(
      `${this.url}/count${stringOption}`,
    );
    return userCountResponse.data;
  }

  async countGroupByResult(
    options?: FindOptionsDTO<ThermometerVoteDTO>,
  ): Promise<{
    BAD: number;
    GOOD: number;
    NETRUAL: number;
  }> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userCountResponse = await axios.get<{
      BAD: number;
      GOOD: number;
      NETRUAL: number;
    }>(`${this.url}/countGroupByResult${stringOption}`);
    return userCountResponse.data;
  }
}
