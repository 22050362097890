import React from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PulseSurveyDTO from '../dto/pulseSurvey.dto';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import PulseSurveyRepository from '../pulseSurvey.repository';
import Table from '@components/table/table';

interface PulseSurveyListProps {
  options?: FindOptionsDTO<PulseSurveyDTO>;
  configurations?: {
    add: {
      isAllowed: boolean | undefined;
    };
    view: {
      isAllowed: boolean | undefined;
    };
    edit: {
      isAllowed: boolean | undefined;
    };
    delete: {
      isAllowed: boolean | undefined;
    };
  };
}

const PulseSurveyList = ({ options, configurations }: PulseSurveyListProps) => {
  const navigate = useNavigate();

  const getPulseSurveys = useCallback(
    async (option?: FindOptionsDTO<PulseSurveyDTO>) => {
      return await new PulseSurveyRepository().find(option);
    },
    [],
  );

  const handleSearcher = useCallback(
    (textSearched: string, option?: FindOptionsDTO<PulseSurveyDTO>) => {
      return {
        ...option,
        where: [{ question: `lk=${textSearched}` }],
      };
    },
    [],
  );

  const handleAddButton = useCallback(() => {
    navigate('/enterprise/configuration/surveys/pulseSurvey/add');
  }, [navigate]);

  const handleEditButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/surveys/pulseSurvey/edit/${id}`);
    },
    [navigate],
  );

  const handleViewButton = useCallback(
    (id: string) => {
      navigate(`/enterprise/configuration/surveys/pulseSurvey/view/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback(async (id: string) => {
    await new PulseSurveyRepository().delete(id);
  }, []);

  return (
    <Table<PulseSurveyDTO>
      headers={['question', 'dateFrom', 'dateTo', 'isActive']}
      handlerRequest={(option) => getPulseSurveys({ ...options, ...option })}
      handlerSearcher={handleSearcher}
      options={options}
      configurations={{
        translationLabel: 'pulseSurvey',
        add: {
          handler: handleAddButton,
          isAllowed: configurations?.add.isAllowed ? true : false,
        },
        edit: {
          handler: handleEditButton,
          isAllowed: configurations?.edit.isAllowed ? true : false,
        },
        view: {
          handler: handleViewButton,
          isAllowed: configurations?.view.isAllowed ? true : false,
        },
        delete: {
          field: 'question',
          handler: handleDelete,
          isAllowed: configurations?.delete.isAllowed ? true : false,
        },
      }}
    />
  );
};

export default PulseSurveyList;
