import React from 'react';
import { useTranslation } from 'react-i18next';
import CreatePulseSurveyDTO from '../dto/createPulseSurvey.dto';
import { useEffect, useState } from 'react';
import PulseCategoryDTO from '../../pulseCategory/dto/pulseCategory.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PulseCategoryRepository from '../../pulseCategory/pulseCategory.repository';
import { Form, Formik, FormikProps } from 'formik';
import PulseSurveyFormValidation from '../validation/pulseSurvey.validation';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Chip,
  Input,
} from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CreatePulseCategoryDTO from '../../pulseCategory/dto/createPulseCategory.dto';
import DateUtil from '../../../../../utils/date';

interface CreatePulseSurveyForm extends CreatePulseSurveyDTO {
  categories: PulseCategoryDTO[];
}

interface PulseSurveyFormProps {
  initialValues?: CreatePulseSurveyForm;
  onSubmit: (values: CreatePulseSurveyForm) => void | Promise<void>;
}

export const initialPulseSurveyFormValues: CreatePulseSurveyForm = {
  question: '',
  dateFrom: new Date(),
  dateTo: new Date(),
  categories: [],
};

const PulseSurveyForm = ({
  initialValues = initialPulseSurveyFormValues,
  onSubmit,
}: PulseSurveyFormProps) => {
  const { t } = useTranslation();
  const [categoryList, setCategoryList] = useState<PulseCategoryDTO[]>([]);

  const getCategories = async (options?: FindOptionsDTO<PulseCategoryDTO>) => {
    const categories = await new PulseCategoryRepository().find(options);
    setCategoryList(categories.results);
  };

  const handlerSearcher = async (text: string) => {
    const categoriesResult = await new PulseCategoryRepository().find({
      where: {
        name: `lk=${text}`,
      },
    });
    const categories = categoriesResult.results;
    if (
      !categories.some((category) => category.name.includes(text)) &&
      text.length > 3
    ) {
      categories.push({ name: '+ ' + text } as PulseCategoryDTO);
    }
    setCategoryList(categories);
  };

  const saveCategory = async (newCategory: CreatePulseCategoryDTO) => {
    const categoryCreated = await new PulseCategoryRepository().save(
      newCategory,
    );
    return categoryCreated;
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PulseSurveyFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreatePulseSurveyForm>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 gap-5">
            <Input
              name="question"
              label={t('entities.pulseSurvey.fields.question')}
              isInvalid={Boolean(errors.question)}
              errorMessage={errors.question}
              color={errors.question ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('question', e.target.value)}
              value={values.question}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="dateFrom"
              label={t('entities.pulseSurvey.fields.dateFrom')}
              type="date"
              isInvalid={Boolean(errors.dateFrom)}
              errorMessage={String(errors.dateFrom || '')}
              color={errors.dateFrom ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('dateFrom', e.target.value)}
              value={
                DateUtil.dateForInput(values.dateFrom) || new Date().toString()
              }
            />
            <Input
              name="dateTo"
              label={t('entities.pulseSurvey.fields.dateTo')}
              type="date"
              isInvalid={Boolean(errors.dateTo)}
              errorMessage={String(errors.dateTo || '')}
              color={errors.dateTo ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('dateTo', e.target.value)}
              value={
                DateUtil.dateForInput(values.dateTo) || new Date().toString()
              }
            />
          </div>
          <div>
            <Autocomplete
              items={categoryList}
              label={t('entities.pulseSurvey.fields.categories')}
              startContent={<FontAwesomeIcon icon={faSearch} />}
              onInputChange={(e) => handlerSearcher(e)}
            >
              {(item) => (
                <AutocompleteItem
                  key={item.id || item.name}
                  onClick={async () => {
                    if (!item.id) {
                      item.name = item.name.substring(2);
                      item = await saveCategory(item);
                    }

                    if (
                      !values.categories.some(
                        (category) => category.id === item.id,
                      )
                    ) {
                      values.categories.push({ ...item });
                      setFieldValue('categories', values.categories);
                    }
                  }}
                  textValue={item.name}
                >
                  {/*item.id ? (
                    item.name
                  ) : (
                    <div className="flex gap-5 items-center">
                      <FontAwesomeIcon icon={faPlus} color="primary" />
                    </div>
                  )*/}
                  {item.name}
                </AutocompleteItem>
              )}
            </Autocomplete>
            <div className="flex gap-3 pt-3">
              {values.categories.map((category, index) => {
                return (
                  <Chip
                    key={category.id}
                    color="primary"
                    onClose={() => {
                      values.categories.splice(index, 1);
                      setFieldValue('categories', values.categories);
                    }}
                  >
                    {category.name}
                  </Chip>
                );
              })}
            </div>
          </div>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default PulseSurveyForm;
