import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import FeedUserDTO from './dto/feedUser.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';

export default class FeedUserRepository {
  private url: string = '/api/feedUser';

  async find(
    options?: FindOptionsDTO<FeedUserDTO>,
  ): Promise<PaginatedDTO<FeedUserDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const objectiveResponse = await axios.get<PaginatedDTO<FeedUserDTO>>(
      `${this.url}${stringOption}`,
    );
    return objectiveResponse.data;
  }
}
