import FindOptionsDTO from '@lib/paginated/findOptions.dto';

import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import LicenseUserDTO from './dto/licenseUser.dto';

export default class LicenseUserRepository {
  private url: string = '/api/licenseUser';

  async find(
    options?: FindOptionsDTO<LicenseUserDTO>,
  ): Promise<PaginatedDTO<LicenseUserDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const objectiveResponse = await axios.get<PaginatedDTO<LicenseUserDTO>>(
      `${this.url}${stringOption}`,
    );
    return objectiveResponse.data;
  }
}
