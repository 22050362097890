import DateUtil from '@utils/date';

const ObjectivePeriodLabel = ({
  periodFrom,
  periodTo,
}: {
  periodFrom: Date;
  periodTo: Date;
}) => {
  return (
    <span className="text-sm bg-primary text-primary-foreground px-2 py-3 rounded">
      {DateUtil.dateToPeriod(periodFrom)} - {DateUtil.dateToPeriod(periodTo)}
    </span>
  );
};

export default ObjectivePeriodLabel;
