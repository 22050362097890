import React from 'react';
import Navbar from '@components/navbar/navbar.componen';
import { RouteObject } from 'react-router-dom';
import EnterpriseType from '@modules/userModule/enterprise/enum/enterpriseType.enum';
import configurationRoutes from './configuration/router';
import objectiveRoutes from './objective/router';
import keyResultRoutes from './keyResult/router';
import initiativeRoutes from './initiative/routes';
import metricRoutes from './metric/routes';
import challengeRoutes from './challenge/router';
import profileRoutes from './profile/routes';
import documentRoutes from './document/router';
import dashboardRoutes from './dashboard/router';
import licenseRoutes from './license/routes';
import levelRoutes from './level/routes';

const userRoutes: Array<RouteObject> = [
  {
    path: '',
    element: <Navbar enterpriseType={EnterpriseType.PARTICULAR} />,
    children: [
      { path: '', children: dashboardRoutes },
      { path: 'configuration', children: configurationRoutes },
      { path: 'objective', children: objectiveRoutes },
      { path: 'keyResult', children: keyResultRoutes },
      { path: 'initiative', children: initiativeRoutes },
      { path: 'documents', children: documentRoutes },
      { path: 'metric', children: metricRoutes },
      { path: 'challenge', children: challengeRoutes },
      { path: 'profile', children: profileRoutes },
      { path: 'license', children: licenseRoutes },
      { path: 'level', children: levelRoutes },
    ],
  },
];

export default userRoutes;
