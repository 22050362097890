import StarIcon from '@components/icons/star.icon';
import {
  Card,
  CardBody,
  Image,
  Progress,
  Skeleton,
  Tooltip,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import levels from '@assets/gif/levels.gif';
import rewards from '@assets/gif/rewards.gif';
import { LevelDTO } from '../../dto/level.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import { useTranslation } from 'react-i18next';

interface LevelProfileProps {
  currentLevel?: LevelDTO;
  nextLevel?: LevelDTO;
}

const LevelProfile = ({ currentLevel, nextLevel }: LevelProfileProps) => {
  const { t } = useTranslation();
  const [userRepository] = useState(new UserRepository());
  const [user, setUser] = useState<UserDTO>();
  const [percentage, setPercentage] = useState<number>(0);

  const getUser = async (currentLevel: LevelDTO, nextLevel: LevelDTO) => {
    const userResult = await userRepository.findMe({ relations: ['userFile'] });
    setUser(userResult);
    const rocketsNeed = nextLevel.rockets - currentLevel.rockets;
    const rocketsSoFar = userResult.rockets - currentLevel.rockets;
    const percentageCalculation = (rocketsSoFar * 100) / rocketsNeed;
    setPercentage(percentageCalculation < 100 ? percentageCalculation : 100);
  };

  useEffect(() => {
    if (currentLevel && nextLevel) getUser(currentLevel, nextLevel);
  }, [currentLevel, nextLevel]);

  return (
    <Card className="lg:w-1/4 shadow-none lg:h-screen lg:max-h-[650px]">
      <CardBody className="justify-center gap-5">
        <div>
          <div className="bg-purple-100 dark:bg-purple-700 rounded-xl w-1/3 aspect-square p-1 relative">
            <Image
              src={
                user?.avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`
              }
              alt="User avatar"
              className="m-0 w-full h-full object-cover rounded-lg"
              classNames={{ wrapper: 'h-full' }}
            />
            <Tooltip
              content={
                <div className="text-xs max-w-40">
                  {t('user.level.levelProfile.currentLevel')}
                </div>
              }
              showArrow={true}
              placement="right"
            >
              <span className="absolute w-fit h-fit p-1 text-xs text-white bg-purple-400 rounded-full border-4 border-purple-100 dark:border-purple-700 left-1/2 -translate-x-1/2 text-nowrap top-[75%]  z-20">
                {t('user.level.level', { number: currentLevel?.number })}
              </span>
            </Tooltip>
          </div>
          <div className="flex flex-col pt-5 gap-2">
            <p className="text-lg font-bold">
              {user?.firstName} {user?.lastName}
            </p>
            <p>{user?.userFile?.jobTitle}</p>
          </div>
        </div>
        {currentLevel && nextLevel ? (
          <Card className="mt-2 shadow-none bg-primary-50 border-b-4 border-primary-200 relative overflow-visible">
            <img
              src={levels}
              alt="Levels"
              className="w-12 m-0 absolute -top-6"
            />
            <CardBody className="gap-2 pt-7">
              {nextLevel.id != currentLevel.id ? (
                <p className="text-primary font-bold">
                  {t('user.level.levelProfile.objective.title', {
                    number: nextLevel.number,
                  })}
                </p>
              ) : (
                <p className="text-primary font-bold">
                  {t('user.level.levelProfile.objective.maxLevel')}
                </p>
              )}
              <p>
                {t('user.level.levelProfile.objective.description', {
                  number: nextLevel.rockets,
                })}
              </p>
              <div className="flex items-center gap-2">
                <Progress
                  aria-label="progress"
                  value={percentage}
                  color="primary"
                  size="sm"
                />
                <span className="text-sm">{percentage.toFixed(0)}%</span>
              </div>
              <p className="text-sm text-default-400">
                <span className="text-primary text-2xl font-black">
                  {user?.rockets}
                </span>
                /{nextLevel.rockets} rockets
              </p>
            </CardBody>
          </Card>
        ) : (
          <Skeleton className="w-full h-52 rounded-xl mt-2" />
        )}
        {currentLevel && nextLevel ? (
          <Card className="mt-3 shadow-none bg-warning-50 border-b-4 border-warning-200 relative overflow-visible">
            <img
              src={rewards}
              alt="Rewards"
              className="w-12 m-0 absolute -top-6"
            />
            <CardBody className="pt-7">
              <p className="text-warning-700 font-bold">
                {t('user.level.levelProfile.reward')}
              </p>
              <div className="flex items-center gap-2">
                <span className="text-purple-400 bg-white border-2 border-purple-400 dark:bg-purple-400 dark:text-white dark:border-purple-200 rounded-full w-fit h-fit p-1">
                  <StarIcon />
                </span>
                <span className="text-sm">
                  {currentLevel.reward ? currentLevel.reward : '-'}
                </span>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Skeleton className="w-full h-52 rounded-xl mt-3" />
        )}
      </CardBody>
    </Card>
  );
};

export default LevelProfile;
