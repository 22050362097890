import IconProps from './common/icon.interface';

const CheckIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.55001 15.5154L18.1885 6.87692C18.3372 6.72821 18.5138 6.65224 18.7183 6.64902C18.9228 6.64582 19.1026 6.72179 19.2577 6.87692C19.4128 7.03204 19.4904 7.21024 19.4904 7.41152C19.4904 7.61279 19.4128 7.79099 19.2577 7.94612L10.1827 17.0211C10.0019 17.2019 9.79102 17.2922 9.55001 17.2922C9.30899 17.2922 9.0981 17.2019 8.91733 17.0211L4.74233 12.8461C4.59361 12.6974 4.52021 12.5208 4.52213 12.3163C4.52406 12.1118 4.60259 11.932 4.75771 11.7769C4.91284 11.6218 5.09104 11.5442 5.29231 11.5442C5.49359 11.5442 5.67179 11.6218 5.82691 11.7769L9.55001 15.5154Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckIcon;
