import { RouteObject } from 'react-router-dom';
import ChallengeConfigurationPage from './challengeConfiguration.page';
import ChallengeAddPage from './challengeAdd.page';
import ChallengeEditPage from './challengeEdit.page';

const challengeConfigurationRoutes: Array<RouteObject> = [
  {
    children: [
      { path: '', element: <ChallengeConfigurationPage /> },
      { path: 'add', element: <ChallengeAddPage /> },
      { path: 'edit/:id', element: <ChallengeEditPage /> },
    ],
  },
];

export default challengeConfigurationRoutes;
