import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TeamForm from '@modules/userModule/team/components/teamForm';
import CreateTeamDTO from '@modules/userModule/team/dto/createTeam.dto';
import TeamRepository from '@modules/userModule/team/team.repository';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const TeamAdd = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmitForm = async (values: CreateTeamDTO) => {
    await new TeamRepository().save(values);
    navigate(-1);
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.team.add')}
      </h3>
      <TeamForm onSubmit={handleSubmitForm} />
    </div>
  );
};

export default TeamAdd;
