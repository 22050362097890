import React from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyResultDTO from '../dto/keyResult.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import KeyResultRepository from '../keyResult.repository';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import {
  Avatar,
  Card,
  CardBody,
  Chip,
  CircularProgress,
  Pagination,
  Skeleton,
  Tooltip,
} from '@nextui-org/react';
import ObjectivePeriodLabel from '@modules/objectiveModule/objective/components/objectivePeriodLabel';
import KeyResultStatusChange from './keyResultStatusChange';
import KeyResultStatus from '../enum/keyResultStatus.enum';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { Link, useNavigate } from 'react-router-dom';

interface IKeyResultList {
  options?: FindOptionsDTO<KeyResultDTO>;
  paginable?: boolean;
  refresh?: () => Promise<void>;
}

const KeyResultList = (props: IKeyResultList = { paginable: true }) => {
  const profile = useSelector(selectProfile);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<FindOptionsDTO<KeyResultDTO>>({});
  const [keyResultsResult, setKeyResultsResult] = useState<
    PaginatedDTO<KeyResultDTO>
  >({
    results: [],
    total: 0,
  });

  const getKeyResults = async (options?: FindOptionsDTO<KeyResultDTO>) => {
    setLoading(true);
    const keyResultResult = await new KeyResultRepository().find({
      ...options,
      ...props.options,
      relations: Object.assign([], options?.relations, [
        'manager',
        'colaborators',
        'categories',
      ]),
      where: {
        ...options?.where,
        ...props?.options?.where,
      },
    });
    setKeyResultsResult(keyResultResult);
    setLoading(false);
  };

  const handlerPagination = (page: number) => {
    setPage(page);
    setOptions((prev) => {
      return {
        ...prev,
        take: page * PAGINATION_TAKE,
        skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
      };
    });
  };

  useEffect(() => {
    getKeyResults(
      props.paginable
        ? {
            take: page * PAGINATION_TAKE,
            skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
          }
        : {},
    );
  }, []);

  useEffect(() => {
    getKeyResults(options);
  }, [options]);

  const LoadingTemplate = () => {
    return (
      <div className="flex flex-col gap-10">
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
      </div>
    );
  };

  const EmptyList = () => {
    return (
      <div className="pt-5">
        <p className="text-center">{t('entities.keyResult.table.empty')}</p>
      </div>
    );
  };

  const onEditKeyResultStatus = async (value: KeyResultStatus, id: string) => {
    await new KeyResultRepository().update(id, { status: value });
    getKeyResults(
      props.paginable
        ? {
            take: page * PAGINATION_TAKE,
            skip: page * PAGINATION_TAKE - PAGINATION_TAKE,
          }
        : {},
    );
    if (props.refresh) props.refresh();
  };

  const KeyResult = ({ item }: { item: KeyResultDTO }) => {
    const navigate = useNavigate();
    return (
      <Card className="shadow-none bg-content4">
        <CardBody className="gap-2 flex-row flex-wrap justify-around lg:flex-nowrap sm:justify-between">
          <div className="flex gap-3 sm:w-1/3">
            <div className="p-3 bg-primary text-primary-foreground font-bold rounded flex items-center justify-center">
              KR
            </div>
            <div className="flex flex-col gap-3">
              <span>
                <b className="text-foreground-500 text-sm">
                  {' '}
                  {t('entities.keyResult.fields.name')}:{' '}
                </b>
                {profile?.group.permissions.some(
                  (permission) =>
                    permission.entity === AllowedEntities.KEY_RESULT &&
                    permission.methods === AllowedMethods.VIEW,
                ) ? (
                  <Link
                    to={`/enterprise/keyResult/view/${item.id}`}
                    className="no-underline text-foreground cursor-pointer"
                  >
                    {item.name}
                  </Link>
                ) : (
                  <div className="no-underline text-foreground">
                    {item.name}
                  </div>
                )}
              </span>
              <div>
                <span>
                  <b className="text-foreground-500 text-sm">
                    {' '}
                    {t('entities.keyResult.fields.categories')}{' '}
                  </b>
                </span>
                <div className="flex gap-3 pt-1">
                  {item.categories.length === 0 && ' - '}
                  {item.categories.map((category) => (
                    <Chip key={category.id} size="sm" color="primary">
                      {category.name}
                    </Chip>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-3">
            <span>
              <b className="text-foreground-500 text-sm">
                {t('entities.objective.fields.manager')}
              </b>
            </span>
            <div className="h-full flex items-center">
              <Tooltip content={item.manager.fullName}>
                <Avatar
                  name={item.manager.fullName}
                  src={
                    item.manager.avatar &&
                    `https://giveit-system-assets.s3.amazonaws.com/${item.manager.avatar}`
                  }
                  onClick={() =>
                    navigate(`/enterprise/profile/${item.manager.externalUser}`)
                  }
                  className="cursor-pointer"
                  color={
                    item.manager.externalUser === profile?.id
                      ? 'primary'
                      : 'secondary'
                  }
                  isBordered={item.manager.externalUser === profile?.id}
                />
              </Tooltip>
            </div>
          </div>
          <div className="hidden sm:flex flex-col gap-3 items-center">
            <span>
              <b className="text-foreground-500 text-sm">
                {t('entities.keyResult.fields.period')}
              </b>
            </span>
            <div className="h-full flex items-center">
              <ObjectivePeriodLabel
                periodFrom={item.periodFrom}
                periodTo={item.periodTo}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-3">
            <span>
              <b className="text-foreground-500 text-sm">
                {t('entities.keyResult.fields.progress')}
              </b>
            </span>
            <div className="h-full flex items-center">
              <CircularProgress
                value={item.progress}
                size="lg"
                showValueLabel={true}
                color="success"
              />
            </div>
          </div>
          <div className="flex flex-col gap-3 items-center">
            <span>
              <b className="text-foreground-500 text-sm">
                {t('entities.objective.fields.status')}
              </b>
            </span>
            <div className="h-full flex items-center">
              <KeyResultStatusChange
                status={item.status}
                onChange={(newStatus) =>
                  onEditKeyResultStatus(newStatus, item.id)
                }
                disabled={
                  !profile?.group.permissions.some(
                    (permission) =>
                      permission.entity === AllowedEntities.KEY_RESULT &&
                      permission.methods === AllowedMethods.MODIFY,
                  )
                }
              />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  return isLoading ? (
    <LoadingTemplate />
  ) : (
    <div>
      {keyResultsResult.total === 0 ? (
        <EmptyList />
      ) : (
        <div className="py-5 flex flex-col space-y-5">
          {keyResultsResult.results.map((keyResultItem) => (
            <KeyResult key={keyResultItem.id} item={keyResultItem} />
          ))}
          {props.paginable && (
            <div className="flex justify-center">
              <Pagination
                showControls
                color="primary"
                page={page}
                total={Math.ceil(keyResultsResult.total / PAGINATION_TAKE)}
                onChange={(page) => handlerPagination(page)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KeyResultList;
