import ReactFlow, { Controls, Node, Edge } from 'reactflow';
import 'reactflow/dist/style.css';
import TeamDTO from '../dto/team.dto';
import { useEffect, useState } from 'react';
import TeamRepository from '../team.repository';

const TeamGraph = () => {
  const generateTreeFromTypeORM = (teamsList: TeamDTO[]) => {
    const nodes: Array<Node> = [];
    const edges: Array<Edge> = [];
    const generateNodesAndEdges = (
      team: TeamDTO,
      parentId: string | null = null,
    ) => {
      const node = {
        id: team.id,
        data: { label: team.name },
        position: { x: 0, y: nodes.length * 50 },
        sourcePosition: 'right',
        targetPosition: 'left',
        connectable: false,
      };

      if (parentId) {
        const parent = nodes.find((node) => node.id === parentId);
        if (parent) {
          const parentX = parent.position.x;
          const offsetY = nodes.length * 50;
          const offsetX = 250;
          node.position.y = offsetY;
          node.position.x = parentX + offsetX;
        }

        const edge = {
          id: `${parentId}-${team.id}`,
          source: parentId,
          target: team.id,
          animated: true,
          style: { stroke: '#fff' },
        };
        edges.push(edge);
      }
      nodes.push(node as Node);

      team.children.forEach((children) => {
        generateNodesAndEdges(children, team.id);
      });
    };

    teamsList.forEach((team) => {
      generateNodesAndEdges(team);
    });

    return { nodes, edges };
  };

  const [teams, setTeams] = useState<TeamDTO[]>([]);

  const getTeams = async () => {
    const teamResponse = await new TeamRepository().findTree();
    setTeams(teamResponse);
  };

  useEffect(() => {
    getTeams();
  }, []);

  const teamNodesAndEdges = generateTreeFromTypeORM(teams);

  return (
    <div className="h-96 lg:h-full w-full">
      <ReactFlow
        style={{ background: '#1A192B' }}
        className="rounded-lg"
        nodes={teamNodesAndEdges.nodes}
        edges={teamNodesAndEdges.edges}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default TeamGraph;
