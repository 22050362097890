enum NotificationType {
  WEEKLY_SUMMARY = 'WEEKLY_SUMMARY',
  CHALLENGE_FINISHED = 'CHALLENGE_FINISHED',
  CHALLENGE_FINISHED_WINNER = 'CHALLENGE_FINISHED_WINNER',
  TAGGED_USER = 'TAGGED_USER',
  RECOGNITION_SUMMARY = 'RECOGNITION_SUMMARY',
  NEW_LEVEL = 'NEW_LEVEL',
  ATTACHED_POST = 'ATTACHED_POST',
}

export default NotificationType;
