import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateUserGroupDTO from '@modules/userModule/userGroup/dto/createUserGroup';
import UserGroupRepository from '@modules/userModule/userGroup/userGroup.repository';
import UserGroupForm from '@modules/userModule/userGroup/components/userGroupForm';

const UserGroupAdd = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmitForm = async (values: CreateUserGroupDTO) => {
    await new UserGroupRepository().save(values);
    navigate(-1);
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.userGroups.add')}
      </h3>
      <UserGroupForm onSubmit={handleSubmitForm} />
    </div>
  );
};

export default UserGroupAdd;
