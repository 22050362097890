import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyResultDetail from '@modules/objectiveModule/keyResult/components/keyResultDetail';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const KeyResultViewPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <div>
      <div className="flex justify-between items-end">
        <h3 className="text-primary flex gap-5 items-center">
          {' '}
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />{' '}
          {t('user.keyResult.view')}
        </h3>
      </div>
      <KeyResultDetail id={id as string} />
    </div>
  );
};

export default KeyResultViewPage;
