import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import axios from 'axios';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import TeamDTO from './dto/team.dto';
import CreateTeamDTO from './dto/createTeam.dto';
import UpdateTeamDTO from './dto/updateTeam.dto';

export default class TeamRepository {
  private url: string = '/api/team';

  async find(
    options?: FindOptionsDTO<TeamDTO>,
  ): Promise<PaginatedDTO<TeamDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const teamResponse = await axios.get<PaginatedDTO<TeamDTO>>(
      `${this.url}${stringOption}`,
    );
    return teamResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<TeamDTO>,
  ): Promise<TeamDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const teamResponse = await axios.get<TeamDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return teamResponse.data;
  }

  async findTree(options?: FindOptionsDTO<TeamDTO>): Promise<TeamDTO[]> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const teamResponse = await axios.get<TeamDTO[]>(
      `${this.url}/tree${stringOption}`,
    );
    return teamResponse.data;
  }

  async save(team: CreateTeamDTO) {
    const createdTeamResponse = await axios.post<TeamDTO>(`${this.url}`, team);
    return createdTeamResponse.data;
  }

  async update(id: string, changes: UpdateTeamDTO) {
    const updatedTeamResponse = await axios.put<TeamDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedTeamResponse.data;
  }

  async delete(id: string) {
    const deletedTeamResponse = await axios.delete<TeamDTO>(
      `${this.url}/${id}`,
    );
    return deletedTeamResponse.data;
  }
}
