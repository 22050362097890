import IconProps from './common/icon.interface';

const FullscreenExitIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      id="fullscreen-exit"
      className={className}
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path
        d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default FullscreenExitIcon;
