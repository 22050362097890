import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import DocumentTeamDTO from './dto/documentTeam.dto';

export default class DocumentTeamRepository {
  private url: string = '/api/documentTeam';

  async find(
    options?: FindOptionsDTO<DocumentTeamDTO>,
  ): Promise<PaginatedDTO<DocumentTeamDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const teamResponse = await axios.get<PaginatedDTO<DocumentTeamDTO>>(
      `${this.url}${stringOption}`,
    );
    return teamResponse.data;
  }
}
