import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import UserGroupDTO from './dto/userGroup.dto';
import axios, { AxiosError } from 'axios';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import CreateUserGroupDTO from './dto/createUserGroup';
import UpdateUserGroupDTO from './dto/updateUserGroup';
import { toast } from 'react-toastify';

export default class UserGroupRepository {
  private url: string = '/api/userGroup';

  async find(
    options?: FindOptionsDTO<UserGroupDTO>,
  ): Promise<PaginatedDTO<UserGroupDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userGroupResponse = await axios.get<PaginatedDTO<UserGroupDTO>>(
      `${this.url}${stringOption}`,
    );
    return userGroupResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<UserGroupDTO>,
  ): Promise<UserGroupDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userGroupResponse = await axios.get<UserGroupDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return userGroupResponse.data;
  }

  async save(user: CreateUserGroupDTO) {
    const createdUserGroupResponse = await axios.post<UserGroupDTO>(
      `${this.url}`,
      user,
    );
    return createdUserGroupResponse.data;
  }

  async update(id: string, changes: UpdateUserGroupDTO) {
    const updatedUserGroupResponse = await axios.put<UserGroupDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedUserGroupResponse.data;
  }

  async delete(id: string) {
    try {
      const deletedUserGroupResponse = await axios.delete<UserGroupDTO>(
        `${this.url}/${id}`,
      );
      return deletedUserGroupResponse.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        return toast(
          err.response?.data
            .map((err: { errors: string[] }) =>
              err.errors.map((errors) => errors).join(', '),
            )
            .join(', '),
          { type: 'error' },
        );
      }

      throw err;
    }
  }
}
