import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FindOptions from 'lib/paginated/findOptions.dto';
import UserFileDTO from '../dto/userFile.dto';
import UserFileRepository from '../userFile.repository';

interface UserDetailProps {
  id: string;
}

const UserFileDetail = (props: UserDetailProps) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<UserFileDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getUser = async (options?: FindOptions<UserFileDTO>) => {
    setLoading(true);
    const userRequest = await new UserFileRepository().findOne(props.id, {
      ...options,
      relations: Object.assign([], options?.relations, ['user']),
    });
    setUser(userRequest);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Card className="my-5">
      <CardBody className="flex gap-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.taxId')}:
              </strong>{' '}
              {user?.taxId || '-'}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.employeeFileNumber')}:
              </strong>{' '}
              {user?.employeeFileNumber || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.jobTitle')}:
              </strong>{' '}
              {user?.jobTitle || '-'}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.employeeFileNumber')}:
              </strong>{' '}
              {user?.employeeFileNumber || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.hireDate')}:
              </strong>{' '}
              {user?.hireDate?.toString() || '-'}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.birthDate')}:
              </strong>{' '}
              {user?.birthDate?.toString() || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.inTime')}:
              </strong>{' '}
              {user?.inTime || '-'}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.outTime')}:
              </strong>{' '}
              {user?.outTime || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.bankAccountNumber')}:
              </strong>{' '}
              {user?.bankAccountNumber || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.maritalStatus')}:
              </strong>{' '}
              {t(`entities.user.values.maritalStatus.${user?.maritalStatus}`)}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.emergencyName')}:
              </strong>{' '}
              {user?.emergencyName || '-'}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.emergencyPhone')}:
              </strong>{' '}
              {user?.emergencyPhone || '-'}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.secondaryEmergencyName')}:
              </strong>{' '}
              {user?.secondaryEmergencyName || '-'}
            </p>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.user.fields.secondaryEmergencyPhone')}:
              </strong>{' '}
              {user?.secondaryEmergencyPhone || '-'}
            </p>
          </Skeleton>
        </div>
      </CardBody>
    </Card>
  );
};

export default UserFileDetail;
