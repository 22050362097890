import FindOptionsDTO from 'lib/paginated/findOptions.dto';
import UserDTO from './dto/user.dto';
import PaginatedDTO from 'lib/paginated/paginated.dto';
import axios from 'axios';
import CreateUserDTO from './dto/createUser.dto';
import UpdateUserDTO from './dto/updateUser.dto';

export default class UserRepository {
  private url: string = '/api/user';

  async find(
    options?: FindOptionsDTO<UserDTO>,
  ): Promise<PaginatedDTO<UserDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<PaginatedDTO<UserDTO>>(
      `${this.url}${stringOption}`,
    );
    return userResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<UserDTO>,
  ): Promise<UserDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<UserDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return userResponse.data;
  }

  async findMe(options?: FindOptionsDTO<UserDTO>): Promise<UserDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<UserDTO>(
      `${this.url}/me${stringOption}`,
    );
    return userResponse.data;
  }

  async save(user: CreateUserDTO) {
    const createdUserResponse = await axios.post<UserDTO>(`${this.url}`, user);
    return createdUserResponse.data;
  }

  async update(id: string, changes: UpdateUserDTO) {
    const updatedUserResponse = await axios.put<UserDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedUserResponse.data;
  }

  async delete(id: string) {
    const deletedUserResponse = await axios.delete<UserDTO>(
      `${this.url}/${id}`,
    );
    return deletedUserResponse.data;
  }
}
