import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import CommentDTO from './dto/comment.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import axios from 'axios';
import CreateCommentDTO from './dto/createComment.dto';

export default class CommentRepository {
  private url: string = '/api/objective/comment';

  async findRoot(
    options?: FindOptionsDTO<CommentDTO>,
  ): Promise<PaginatedDTO<CommentDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const commentResponse = await axios.get<PaginatedDTO<CommentDTO>>(
      `${this.url}/root${stringOption}`,
    );
    return commentResponse.data;
  }

  async save(comment: CreateCommentDTO) {
    const createdCommentResponse = await axios.post<CommentDTO>(
      `${this.url}`,
      comment,
    );
    return createdCommentResponse.data;
  }

  async updateComment(
    comment: Partial<CommentDTO>,
    id: string,
  ): Promise<CommentDTO> {
    return (await axios.put(`${this.url}/${id}`, comment)).data;
  }

  async deleteComment(id: string): Promise<CommentDTO> {
    return (await axios.delete(`${this.url}/${id}`)).data;
  }
}
