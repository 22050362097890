import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import LicenseTypeDTO from './dto/licenseType.dto';
import CreateLicenseTypeDTO from './dto/createLicenseType.dto';
import UpdateLicenseTypeDTO from './dto/updateLicenseType.dto';
import axios from 'axios';

export default class LicenseTypeRepository {
  private url: string = '/api/licenseType';

  async find(
    options?: FindOptionsDTO<LicenseTypeDTO>,
  ): Promise<PaginatedDTO<LicenseTypeDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<PaginatedDTO<LicenseTypeDTO>>(
      `${this.url}${stringOption}`,
    );
    return userResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<LicenseTypeDTO>,
  ): Promise<LicenseTypeDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<LicenseTypeDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return userResponse.data;
  }

  async save(user: CreateLicenseTypeDTO) {
    const createdUserResponse = await axios.post<LicenseTypeDTO>(
      `${this.url}`,
      user,
    );
    return createdUserResponse.data;
  }

  async update(id: string, changes: UpdateLicenseTypeDTO) {
    const updatedUserResponse = await axios.put<LicenseTypeDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedUserResponse.data;
  }

  async delete(id: string) {
    const deletedUserResponse = await axios.delete<LicenseTypeDTO>(
      `${this.url}/${id}`,
    );
    return deletedUserResponse.data;
  }
}
