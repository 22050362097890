import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  User,
  useDisclosure,
} from '@nextui-org/react';
import PostInteractionDTO from '../dto/postInteraction.dto';
import { useEffect, useState } from 'react';
import HeartSolidIcon from '@components/icons/heartSolid.icon';
import FeedUserDTO from '../../feedUser/dto/feedUser.dto';
import PostsRepository from '../posts.repository';
import { useTranslation } from 'react-i18next';
import ChallengeAnswerInteractionDTO from '@modules/voteModule/challenges/vote/dto/challengeAnswerInteraction.dto';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useNavigate } from 'react-router-dom';

interface LikesProps {
  likes: PostInteractionDTO[] | ChallengeAnswerInteractionDTO[];
  heartClass?: string;
  size?: 'small' | 'regular';
  parentId: string;
  parentType: string;
}

const Likes = ({
  likes,
  heartClass,
  size,
  parentId,
  parentType,
}: LikesProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [users, setUsers] = useState<FeedUserDTO[]>([]);
  const [postsRepository] = useState(new PostsRepository());

  useEffect(() => {
    const usersAux = [];
    for (const interaction of likes) {
      if (interaction.user) usersAux.push(interaction.user);
    }
    setUsers(usersAux);
  }, []);

  const getUserList = async () => {
    if (isOpen) {
      if (parentType === 'post')
        likes = await postsRepository.getInteraction({
          where: { post: { id: parentId } },
        });
      else if (parentType === 'comment')
        likes = await postsRepository.getCommentInteraction({
          where: { comment: { id: parentId } },
        });
      const usersAux = [];
      for (const interaction of likes) {
        if (interaction.user) usersAux.push(interaction.user);
      }
      setUsers(usersAux);
    }
  };

  useEffect(() => {
    getUserList();
  }, [isOpen]);

  return (
    <>
      <span
        onClick={onOpen}
        className={`flex items-center gap-2 text-default-400 dark:text-default-600 cursor-pointer hover:underline ${
          size === 'small' && 'text-xs'
        }`}
      >
        <HeartSolidIcon
          className={`${heartClass} ${size === 'small' ? 'w-3' : 'w-5'}`}
        />
        {likes.length}{' '}
        <span className="hidden lg:inline">
          {t('user.dashboard.feed.posts.likes.likes')}
        </span>
      </span>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent className="pb-5">
          <ModalHeader>
            {t('user.dashboard.feed.posts.likes.title')}
          </ModalHeader>
          <ModalBody>
            {users.length <= 0 ? (
              <p className="text-center text-gray-400">
                {t('user.dashboard.feed.posts.likes.noLikes')}
              </p>
            ) : (
              <div className="flex flex-col gap-2">
                {users.map((user) => (
                  <User
                    name={`${user.fullName}`}
                    description={''} //user.jobTitle
                    avatarProps={{
                      src:
                        user.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`,
                      isBordered: user.email === profile?.email,
                      color:
                        user.email === profile?.email ? 'primary' : 'default',
                    }}
                    key={user.id}
                    className="cursor-pointer w-full justify-start hover:bg-primary-50 p-2"
                    onClick={() => navigate(`/enterprise/profile/${user.id}`)}
                  />
                ))}
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Likes;
