import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
  useDisclosure,
  User,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { LevelDTO } from '../../dto/level.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ViewLevelUsersModalProps {
  level: LevelDTO;
}

const ViewLevelUsersModal = ({ level }: ViewLevelUsersModalProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [usersInLevel, setUsersInLevel] = useState<UserDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUsersInLevel = async () => {
    const users = await new UserRepository().find({
      where: {
        level: { id: level.id },
      },
      relations: ['team'],
    });
    setUsersInLevel(users.results);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) getUsersInLevel();
  }, [isOpen]);

  return (
    <>
      <Button color="primary" variant="flat" size="sm" onPress={onOpen}>
        <FontAwesomeIcon icon={faEye} />
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="lg">
        <ModalContent>
          <ModalHeader>
            {t('entities.level.viewLevel.title', { number: level.number })}
          </ModalHeader>
          <ModalBody className="flex-row flex-wrap justify-center pb-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {usersInLevel.map((user, index) => (
                  <User
                    name={user.firstName + ' ' + user.lastName}
                    avatarProps={{
                      src:
                        user.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`,
                      color: profile?.id === user.id ? 'primary' : 'secondary',
                      isBordered: profile?.id === user.id,
                      className: 'flex-shrink-0',
                    }}
                    description={user.team && user.team.name}
                    className="w-1/3 justify-start cursor-pointer"
                    classNames={{
                      name: 'max-w-full truncate',
                      description: 'max-w-full truncate',
                    }}
                    onClick={() => navigate(`/enterprise/profile/${user.id}`)}
                    key={index}
                  />
                ))}
                {!usersInLevel.length ? (
                  <p>{t('entities.level.viewLevel.noUsers')}</p>
                ) : (
                  <></>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewLevelUsersModal;
