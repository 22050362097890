import IconProps from './common/icon.interface';

const DeleteIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.30773 20.5C6.80901 20.5 6.38306 20.3234 6.02986 19.9701C5.67664 19.6169 5.50003 19.191 5.50003 18.6923V5.99999H5.25003C5.03721 5.99999 4.85901 5.9282 4.71543 5.78462C4.57183 5.64103 4.50003 5.46283 4.50003 5.25002C4.50003 5.0372 4.57183 4.859 4.71543 4.71542C4.85901 4.57182 5.03721 4.50002 5.25003 4.50002H9.00001C9.00001 4.25515 9.08622 4.0465 9.25866 3.87407C9.43109 3.70163 9.63974 3.61542 9.88461 3.61542H14.1154C14.3603 3.61542 14.5689 3.70163 14.7414 3.87407C14.9138 4.0465 15 4.25515 15 4.50002H18.75C18.9628 4.50002 19.141 4.57182 19.2846 4.71542C19.4282 4.859 19.5 5.0372 19.5 5.25002C19.5 5.46283 19.4282 5.64103 19.2846 5.78462C19.141 5.9282 18.9628 5.99999 18.75 5.99999H18.5V18.6923C18.5 19.191 18.3234 19.6169 17.9702 19.9701C17.617 20.3234 17.191 20.5 16.6923 20.5H7.30773ZM17 5.99999H7.00001V18.6923C7.00001 18.782 7.02886 18.8557 7.08656 18.9134C7.14426 18.9711 7.21798 19 7.30773 19H16.6923C16.782 19 16.8558 18.9711 16.9135 18.9134C16.9712 18.8557 17 18.782 17 18.6923V5.99999ZM9.40388 17H10.9039V7.99999H9.40388V17ZM13.0962 17H14.5961V7.99999H13.0962V17Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
