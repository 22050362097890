enum AllowedMethods {
  LIST = 'LIST',
  VIEW = 'VIEW',
  MODIFY = 'MODIFY',
  ADD = 'ADD',
  DELETE = 'DELETE',
  MODIFY_OTHERS = 'MODIFY_OTHERS',
  DELETE_OTHERS = 'DELETE_OTHERS',
  PIN = 'PIN',
  ADMIN = 'ADMIN',
}

export default AllowedMethods;
