import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { Tab, Tabs } from '@nextui-org/react';
import EnterpriseUsers from '@modules/userModule/user/components/userList';
import EnterpriseDetail from '@modules/userModule/enterprise/components/enterpriseDetail';
import UserGroupList from '@modules/userModule/userGroup/components/userGroupList';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import TeamList from '@modules/userModule/team/components/teamList';
import TeamGraph from '@modules/userModule/team/components/teamGraph';
import UserFileList from '@modules/userModule/userFile/components/userFileList';

const EnterpriseView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const [params, setParams] = useSearchParams();
  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.title')}
      </h3>
      <p className="text-foreground">
        {t('user.configuration.enterprise.subtitle')}
      </p>
      {profile?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.ENTERPRISE &&
          permission.methods === AllowedMethods.VIEW,
      ) && <EnterpriseDetail id={profile?.enterprise.id as string} />}
      <Tabs
        onSelectionChange={(key) => setParams({ tab: key.toString() })}
        selectedKey={params.get('tab')}
      >
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.USER &&
            permission.methods === AllowedMethods.LIST,
        ) && (
          <Tab key="users" title={t('user.configuration.enterprise.tabs.user')}>
            <EnterpriseUsers />
          </Tab>
        )}
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.USER &&
            permission.methods === AllowedMethods.LIST,
        ) && (
          <Tab
            key="userFiles"
            title={t('user.configuration.enterprise.tabs.userFile')}
          >
            <UserFileList />
          </Tab>
        )}
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.USER_GROUP &&
            permission.methods === AllowedMethods.LIST,
        ) && (
          <Tab
            key="userGroups"
            title={t('user.configuration.enterprise.tabs.userGroup')}
          >
            <UserGroupList />
          </Tab>
        )}
        {profile?.group.permissions.some(
          (permission) =>
            permission.entity === AllowedEntities.TEAM &&
            permission.methods === AllowedMethods.LIST,
        ) && (
          <Tab key="teams" title={t('user.configuration.enterprise.tabs.team')}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <TeamList />
              <TeamGraph />
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default EnterpriseView;
