import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PulseSurveyMetricRepository from '../pulseSurveyMetric.repository';
import goodIcon from '@assets/icons/goodicon.svg';
import neutralIcon from '@assets/icons/neutralIcon.svg';
import badIcon from '@assets/icons/bardicon.svg';
import DateUtil from '@utils/date';
import { useMetricFiltersContext } from '@modules/metricModule/context/metricFilters.context';

const PulseSurveyVoteMetric = () => {
  const [votes, setVotes] = useState({
    AGREE: 0,
    NOT_QUITE: 0,
    NOT_AGREE: 0,
    TOTAL: 0,
  });
  const { t } = useTranslation();
  const { filters } = useMetricFiltersContext();
  const period = filters.quarter;
  const periodDates = DateUtil.quarterToDate(period);

  const getVotes = async () => {
    const surveys = await new PulseSurveyMetricRepository().find({
      where: [
        {
          dateFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          dateTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ],
      relations: ['votes'],
    });
    const votes = {
      AGREE: 0,
      NOT_QUITE: 0,
      NOT_AGREE: 0,
      TOTAL: 0,
    };

    surveys.results.map((survey) => {
      survey.votes.map((vote) => {
        votes[vote.vote]++;
        votes.TOTAL++;
      });
    });

    setVotes(votes);
  };

  useEffect(() => {
    getVotes();
  }, [period]);

  return (
    <div>
      <h4>
        {t('entities.metric.responses.pulseVotes', { votes: votes.TOTAL })}
      </h4>
      <div className="flex flex-col md:flex-row gap-2 justify-evenly pt-3">
        <div
          className={`flex flex-col md:w-[350px] gap-2 items-center justify-between p-5 bg-success-100 rounded-xl`}
        >
          <img src={goodIcon} className="w-8 m-0" />
          <div className="flex flex-col items-center">
            <span className="text-md font-semibold text-gray-700 dark:text-foreground-700 text-center">
              {t('user.dashboard.surveys.pulse.options.AGREE')}
            </span>
            <span className="text-default-400">
              {t('entities.metric.responses.votesCount', {
                votes: votes.AGREE,
              })}
            </span>
          </div>
          <span className="text-5xl font-bold text-success">
            {Number(
              votes.TOTAL > 0 ? (votes.AGREE / votes.TOTAL) * 100 : 0,
            ).toFixed(0)}
            %
          </span>
        </div>
        <div
          className={`flex flex-col md:w-[350px] gap-2 items-center justify-between p-5 bg-warning-100 rounded-xl`}
        >
          <img src={neutralIcon} className="w-8 m-0" />
          <div className="flex flex-col items-center">
            <span className="text-md font-semibold text-gray-700 dark:text-foreground-700 text-center">
              {t('user.dashboard.surveys.pulse.options.NOT_QUITE')}
            </span>
            <span className="text-default-400">
              {t('entities.metric.responses.votesCount', {
                votes: votes.NOT_QUITE,
              })}
            </span>
          </div>
          <span className="text-5xl font-bold text-warning">
            {Number(
              votes.TOTAL > 0 ? (votes.NOT_QUITE / votes.TOTAL) * 100 : 0,
            ).toFixed(0)}
            %
          </span>
        </div>
        <div
          className={`flex flex-col md:w-[350px] gap-2 items-center justify-between p-5 bg-danger-100 rounded-xl`}
        >
          <img src={badIcon} className="w-8 m-0" />
          <div className="flex flex-col items-center">
            <span className="text-md font-semibold text-gray-700 dark:text-foreground-700 text-center">
              {t('user.dashboard.surveys.pulse.options.NOT_AGREE')}
            </span>
            <span className="text-default-400">
              {t('entities.metric.responses.votesCount', {
                votes: votes.NOT_AGREE,
              })}
            </span>
          </div>
          <span className="text-5xl font-bold text-danger">
            {Number(
              votes.TOTAL > 0 ? (votes.NOT_AGREE / votes.TOTAL) * 100 : 0,
            ).toFixed(0)}
            %
          </span>
        </div>
      </div>
    </div>
  );
};

export default PulseSurveyVoteMetric;
