import { createBrowserRouter } from 'react-router-dom';
import authRoutes from './pages/auth/routes';
import PrivateRoute from '@components/privateRoute/privateRoute.component';
import privateRoutes from '@pages/routes';
import ErrorPage from '@pages/error/errorPage';

const appRoutes = createBrowserRouter([
  {
    path: 'auth',
    children: authRoutes,
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: privateRoutes,
  },
  { path: '*', element: <ErrorPage /> },

  /*{
        path: '/',
        element: <Navbar />,
        children: [
            { path: 'objective', children: objectiveRoutes },
            { path: 'auth', children: authRoutes },
            { path: 'keyResult', children: keyResultRoutes },
            { path: 'initiative', children: initiativeRoutes }
        ]
    }*/
]);

export default appRoutes;
