import IconProps from './common/icon.interface';

const OpenLockIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.20517 13.3333C0.872722 13.3333 0.588722 13.2156 0.353167 12.9801C0.117722 12.7446 0 12.4606 0 12.1281V5.87181C0 5.53937 0.117722 5.25537 0.353167 5.01981C0.588722 4.78437 0.872722 4.66665 1.20517 4.66665H7V3.33331C7 2.77776 6.80556 2.30554 6.41667 1.91665C6.02778 1.52776 5.55556 1.33331 5 1.33331C4.51622 1.33331 4.094 1.48565 3.73333 1.79031C3.37267 2.09509 3.14406 2.47354 3.0475 2.92565C3.01583 3.04698 2.94872 3.14526 2.84617 3.22048C2.74361 3.2957 2.62822 3.33331 2.5 3.33331C2.35811 3.33331 2.23933 3.28609 2.14367 3.19165C2.04789 3.0972 2.01239 2.98587 2.03717 2.85765C2.15428 2.14231 2.49061 1.54276 3.04617 1.05898C3.60172 0.575202 4.253 0.333313 5 0.333313C5.83244 0.333313 6.54056 0.625201 7.12433 1.20898C7.70811 1.79276 8 2.50087 8 3.33331V4.66665H8.79483C9.12728 4.66665 9.41128 4.78437 9.64683 5.01981C9.88228 5.25537 10 5.53937 10 5.87181V12.1281C10 12.4606 9.88228 12.7446 9.64683 12.9801C9.41128 13.2156 9.12728 13.3333 8.79483 13.3333H1.20517ZM1.20517 12.3333H8.79483C8.85472 12.3333 8.90389 12.3141 8.94233 12.2756C8.98078 12.2372 9 12.188 9 12.1281V5.87181C9 5.81192 8.98078 5.76276 8.94233 5.72431C8.90389 5.68587 8.85472 5.66665 8.79483 5.66665H1.20517C1.14528 5.66665 1.09611 5.68587 1.05767 5.72431C1.01922 5.76276 1 5.81192 1 5.87181V12.1281C1 12.188 1.01922 12.2372 1.05767 12.2756C1.09611 12.3141 1.14528 12.3333 1.20517 12.3333ZM5 10.1666C5.32389 10.1666 5.59933 10.0532 5.82633 9.82631C6.05322 9.59931 6.16667 9.32387 6.16667 8.99998C6.16667 8.67609 6.05322 8.40065 5.82633 8.17365C5.59933 7.94676 5.32389 7.83331 5 7.83331C4.67611 7.83331 4.40067 7.94676 4.17367 8.17365C3.94678 8.40065 3.83333 8.67609 3.83333 8.99998C3.83333 9.32387 3.94678 9.59931 4.17367 9.82631C4.40067 10.0532 4.67611 10.1666 5 10.1666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OpenLockIcon;
