import { RouteObject } from 'react-router-dom';
import Dashboard from './dashboard/dashboard';
import PostPage from './post/post.page';

const dashboardRoutes: Array<RouteObject> = [
  {
    children: [{ path: '', element: <Dashboard /> }, {path:'post/:id', element: <PostPage />}],
  },
];

export default dashboardRoutes;
