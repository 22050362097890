import IconProps from './common/icon.interface';

const HeartSolidIcon = ({ className }: IconProps) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.55469 10.3047L2.5 11.25L10 18.75L17.5 11.25L18.4453 10.3047C19.4414 9.30859 20 7.96094 20 6.55469C20 3.625 17.625 1.25 14.6953 1.25C13.2891 1.25 11.9414 1.80859 10.9453 2.80469L10 3.75L9.05469 2.80469C8.05859 1.80859 6.71094 1.25 5.30469 1.25C2.375 1.25 0 3.625 0 6.55469C0 7.96094 0.558594 9.30859 1.55469 10.3047Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HeartSolidIcon;
