import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import React, { useState } from 'react';
import DocumentDTO from '../dto/document.dto';
import { useDocumentContext } from '../context/document.context';
import DocumentRepository from '@modules/documentModule/document.repository';
import { useTranslation } from 'react-i18next';

interface DocumentNameModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
  document?: DocumentDTO;
  parent?: DocumentDTO;
}

const DocumentNameModal = ({
  isOpen,
  onOpenChange,
  document,
  parent,
}: DocumentNameModalProps) => {
  const { t } = useTranslation();
  const { getDocuments, createDocument } = useDocumentContext();
  const [documentRepository] = useState(new DocumentRepository());
  const [title, setTitle] = useState(
    document ? document.title : t('user.document.nameModal.defaultTitle'),
  );

  const updateTitle = async (document: DocumentDTO) => {
    await documentRepository.updateName(title, document.id);
    getDocuments();
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>{t('user.document.nameModal.title')}</ModalHeader>
            <ModalBody>
              <Input
                value={title}
                onValueChange={setTitle}
                variant="bordered"
              ></Input>
            </ModalBody>
            <ModalFooter className="justify-center">
              <Button onPress={onClose} color="primary" variant="flat">
                {t('user.document.nameModal.cancel')}
              </Button>
              <Button
                onPress={() => {
                  if (document) {
                    updateTitle(document);
                  } else {
                    createDocument(title, parent);
                  }
                  onClose();
                }}
                color="primary"
              >
                {t('user.document.nameModal.save')}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DocumentNameModal;
