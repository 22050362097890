import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import KeyResultRepository from '@modules/objectiveModule/keyResult/keyResult.repository';
import InitiativeStatus from '@modules/objectiveModule/initiative/enum/initiativeStatus.enum';
import { Card, CardBody, Skeleton } from '@nextui-org/react';

interface InitiativeMetricProps {
  userId: string;
}

const InitiativeMetric = (props: InitiativeMetricProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);

  const [statuses, setStatuses] = useState({
    COMPLETED: 0,
    PENDING: 0,
    IN_PROGRESS: 0,
  });

  const calculateStatus = async () => {
    setLoading(true);
    const keyResults = await new KeyResultRepository().find({
      where: {
        colaborators: { id: props.userId },
      },
      relations: ['initiatives'],
    });

    const initiativeStatuses = {
      COMPLETED: 0,
      PENDING: 0,
      IN_PROGRESS: 0,
    };

    keyResults.results.map((keyResult) => {
      keyResult.initiatives.map((initiative) => {
        if (initiative.status === InitiativeStatus.COMPLETED)
          initiativeStatuses.COMPLETED++;
        if (initiative.status === InitiativeStatus.PENDING)
          initiativeStatuses.PENDING++;
        if (initiative.status === InitiativeStatus.IN_PROGRESS)
          initiativeStatuses.IN_PROGRESS++;
      });
    });

    setLoading(false);
    setStatuses(initiativeStatuses);
  };

  useEffect(() => {
    calculateStatus();
  }, []);

  useEffect(() => {
    calculateStatus();
  }, [props.userId]);

  return (
    <div className="flex flex-col lg:flex-row gap-3 overflow-x-auto py-5 px-5">
      <Card className="min-w-[240px] md:w-1/3 shadow-md">
        <CardBody>
          <span className="text-gray-600 text-sm">
            {t('entities.metric.objective.initiative.title')}
          </span>
          <span className="font-bold text-lg">
            {t('entities.metric.objective.initiative.status.COMPLETED.label')}
          </span>
          <Skeleton isLoaded={!isLoading}>
            <span className={`text-xl text-success`}>{statuses.COMPLETED}</span>
          </Skeleton>
        </CardBody>
      </Card>
      <Card className="min-w-[240px] md:w-1/3 shadow-md">
        <CardBody>
          <span className="text-gray-600 text-sm">
            {t('entities.metric.objective.initiative.title')}
          </span>
          <span className="font-bold text-lg">
            {t('entities.metric.objective.initiative.status.IN_PROGRESS.label')}
          </span>
          <Skeleton isLoaded={!isLoading}>
            <span className={`text-xl text-warning`}>
              {statuses.IN_PROGRESS}
            </span>
          </Skeleton>
        </CardBody>
      </Card>
      <Card className="min-w-[240px] md:w-1/3 shadow-md">
        <CardBody>
          <span className="text-gray-600 text-sm">
            {t('entities.metric.objective.initiative.title')}
          </span>
          <span className="font-bold text-lg">
            {t('entities.metric.objective.initiative.status.PENDING.label')}
          </span>
          <Skeleton isLoaded={!isLoading}>
            <span className={`text-xl text-danger`}>{statuses.PENDING}</span>
          </Skeleton>
        </CardBody>
      </Card>
    </div>
  );
};

export default InitiativeMetric;
