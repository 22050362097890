import { useTranslation } from 'react-i18next';
import ObjectiveStatus from '../enum/objectiveStatus.enum';

const ObjectiveStatusLabel = ({ status }: { status: ObjectiveStatus }) => {
  const { t } = useTranslation();

  const styles: { [key in ObjectiveStatus]: string } = {
    ON_TIME: 'bg-success-200 text-success-800 border border-success-600',
    LATE: 'bg-warning-200 text-warning-800 border border-warning-600',
    AT_RISK: 'bg-danger-200 text-danger-800 border border-danger-600',
  };

  return (
    <span className={`text-sm px-2 py-3 rounded ${styles[status]}`}>
      {t(`entities.objective.values.status.${status}`)}
    </span>
  );
};

export default ObjectiveStatusLabel;
