import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useDisclosure,
} from '@nextui-org/react';
import DateUtil from '@utils/date';
import OptionsIcon from '@components/icons/options.icon';
import { useEffect, useState } from 'react';
import HeartSolidIcon from '@components/icons/heartSolid.icon';
import HeartIcon from '@components/icons/heart.icon';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useTranslation } from 'react-i18next';
import ChallengesRepository from '../../challenges.repository';
import ChallengeAnswerCommentDTO from '../dto/challengeAnswerComment.dto';
import ChallengeAnswerInteractionType from '../dto/challengeAnswerInteractionType.enum';
import Likes from '@modules/feed/posts/components/likes';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { useNavigate } from 'react-router-dom';

interface ChallengeAnswerCommentProps {
  data: ChallengeAnswerCommentDTO;
  refresh?: () => void;
}

const ChallengeAnswerComment = ({
  data,
  refresh,
}: ChallengeAnswerCommentProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [isLiked, setIsLiked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditButtonLoading, setIsEditButtonLoading] = useState(false);
  const [content, setContent] = useState(data.content);
  const [contentEdition, setContentEdition] = useState(content);
  const [challengeAnswersRepository] = useState(new ChallengesRepository());

  const editComment = async () => {
    setIsEditButtonLoading(true);
    setContent(contentEdition);
    await challengeAnswersRepository.updateComment(
      { content: contentEdition },
      data.id,
    );
    setIsEditing(false);
    setIsEditButtonLoading(false);
  };

  const deleteComment = async () => {
    await challengeAnswersRepository.deleteComment(data.id);
    onClose();
    if (refresh) refresh();
  };

  const likeComment = async () => {
    const response = await challengeAnswersRepository.saveCommentInteraction({
      type: ChallengeAnswerInteractionType.HEART,
      comment: data,
    });
    data.interactions = [
      ...data.interactions,
      {
        id: response.id,
        createdBy: profile ? profile.email : '',
        createdDate: new Date(),
      },
    ];
    setIsLiked(true);
  };

  const removeLike = async () => {
    let like = null;
    for (const interaction of data.interactions) {
      if (interaction.createdBy === profile?.email) {
        like = interaction;
        break;
      }
    }
    if (like != null) {
      await challengeAnswersRepository.deleteCommentInteraction(like.id);
      data.interactions.splice(data.interactions.indexOf(like), 1);
    }
    setIsLiked(false);
  };

  useEffect(() => {
    validateLiked();
  }, []);

  const validateLiked = () => {
    for (const interaction of data.interactions) {
      if (interaction.createdBy === profile?.email) {
        setIsLiked(true);
        return;
      }
    }
    setIsLiked(false);
  };

  return (
    <>
      <div className="grid grid-cols-7 lg:grid-cols-10 grid-rows-2 gap-x-5 md:gap-x-2 bg-content2 rounded-2xl py-2 px-5">
        <Avatar
          showFallback
          src={
            data.user.avatar &&
            `https://giveit-system-assets.s3.amazonaws.com/${data.user.avatar}`
          }
          name={data.user.fullName}
          isBordered={data.createdBy === profile?.email}
          color={data.createdBy === profile?.email ? 'primary' : 'default'}
          className="col-span-1 row-span-1 self-center justify-self-center cursor-pointer"
          onClick={() => navigate(`/enterprise/profile/${data.user.id}`)}
        />
        <div className="col-span-6 lg:col-span-9 flex justify-between row-span-1 self-center">
          <div className="flex flex-col">
            <span
              className="m-0 cursor-pointer hover:underline"
              onClick={() => navigate(`/enterprise/profile/${data.user.id}`)}
            >
              {data.user.fullName}
            </span>
            <span className="m-0 text-xs text-default-400">
              {DateUtil.dateToFullDate(data.createdDate)}
            </span>
          </div>
          {profile?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.CHALLENGE_ANSWER_COMMENT &&
              ((permission.methods === AllowedMethods.MODIFY &&
                profile.id === data.user.id) ||
                permission.methods === AllowedMethods.MODIFY_OTHERS),
          ) &&
            profile?.group.permissions.some(
              (permission) =>
                permission.entity ===
                  AllowedEntities.CHALLENGE_ANSWER_COMMENT &&
                ((permission.methods === AllowedMethods.DELETE &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.DELETE_OTHERS),
            ) && (
              <Dropdown>
                <DropdownTrigger>
                  <Button radius="full" isIconOnly className="bg-transparent">
                    <OptionsIcon />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem
                    onPress={() => setIsEditing(true)}
                    className="font-poppins"
                  >
                    {t(
                      'user.dashboard.feed.posts.comments.options.edit.option',
                    )}
                  </DropdownItem>
                  <DropdownItem onPress={onOpen} className="font-poppins">
                    {t(
                      'user.dashboard.feed.posts.comments.options.delete.option',
                    )}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          {profile?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.POST_COMMENT &&
              ((permission.methods === AllowedMethods.DELETE &&
                profile.id === data.user.id) ||
                permission.methods === AllowedMethods.DELETE_OTHERS),
          ) &&
            !profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.POST_COMMENT &&
                ((permission.methods === AllowedMethods.MODIFY &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.MODIFY_OTHERS),
            ) && (
              <Dropdown>
                <DropdownTrigger>
                  <Button radius="full" isIconOnly className="bg-transparent">
                    <OptionsIcon />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem onPress={onOpen} className="font-poppins">
                    {t(
                      'user.dashboard.feed.posts.comments.options.delete.option',
                    )}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          {profile?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.CHALLENGE_ANSWER_COMMENT &&
              ((permission.methods === AllowedMethods.MODIFY &&
                profile.id === data.user.id) ||
                permission.methods === AllowedMethods.MODIFY_OTHERS),
          ) &&
            !profile?.group.permissions.some(
              (permission) =>
                permission.entity ===
                  AllowedEntities.CHALLENGE_ANSWER_COMMENT &&
                ((permission.methods === AllowedMethods.DELETE &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.DELETE_OTHERS),
            ) && (
              <Dropdown>
                <DropdownTrigger>
                  <Button radius="full" isIconOnly className="bg-transparent">
                    <OptionsIcon />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem
                    onPress={() => setIsEditing(true)}
                    className="font-poppins"
                  >
                    {t(
                      'user.dashboard.feed.posts.comments.options.edit.option',
                    )}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
        </div>
        <div className="row-span-1 col-span-1"></div>
        <div className="row-span-1 col-span-6 lg:col-span-9 overflow-auto">
          {isEditing ? (
            <Textarea
              value={contentEdition}
              onValueChange={setContentEdition}
              endContent={
                <div className="flex gap-2">
                  {/* COMENTADO HASTA DESARROLLAR LA FUNCIONALIDAD DE AGREGAR IMÁGENES */}
                  {/* <ImageIcon className="cursor-pointer" /> */}
                  <div className="flex flex-col gap-2 items-center">
                    <Button
                      onPress={editComment}
                      color="primary"
                      isLoading={isEditButtonLoading}
                    >
                      {t(
                        'user.dashboard.feed.posts.comments.options.edit.edit',
                      )}
                    </Button>
                    <Button onPress={() => setIsEditing(false)}>
                      {t(
                        'user.dashboard.feed.posts.comments.options.edit.cancel',
                      )}
                    </Button>
                  </div>
                </div>
              }
              classNames={{
                inputWrapper:
                  'bg-background group-data-[focus=true]:bg-background data-[hover=true]:bg-background',
              }}
            />
          ) : (
            <div>{content}</div>
          )}
          <div className="flex justify-between items-center">
            <Likes
              likes={data.interactions}
              size="small"
              parentId={data.id}
              parentType="comment"
            />
            {isLiked ? (
              <Button
                onPress={removeLike}
                variant="light"
                className="text-default-400 dark:text-default-600 text-xs"
                startContent={<HeartSolidIcon className="text-red-500 w-3" />}
              >
                {t('user.dashboard.feed.posts.likes.like')}
              </Button>
            ) : (
              <Button
                onPress={likeComment}
                variant="light"
                className="text-default-400 dark:text-default-600 text-xs"
                startContent={<HeartIcon className="w-3" />}
              >
                {t('user.dashboard.feed.posts.likes.like')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                {t('user.dashboard.feed.posts.comments.options.delete.title')}
              </ModalHeader>
              <ModalBody>
                <p>
                  {t('user.dashboard.feed.posts.comments.options.delete.text')}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose}>
                  {t(
                    'user.dashboard.feed.posts.comments.options.delete.cancel',
                  )}
                </Button>
                <Button onPress={deleteComment} color="danger">
                  {t(
                    'user.dashboard.feed.posts.comments.options.delete.delete',
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChallengeAnswerComment;
