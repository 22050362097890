import IconProps from './common/icon.interface';

const HeartIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 5.51953L9.11719 4.63672L8.16797 3.6875C7.41016 2.92578 6.37891 2.5 5.30469 2.5C3.06641 2.5 1.25 4.31641 1.25 6.55469C1.25 7.62891 1.67578 8.66016 2.4375 9.42188L3.38281 10.3672L10 16.9844L16.6172 10.3672L17.5625 9.42188C18.3242 8.66016 18.75 7.62891 18.75 6.55469C18.75 4.31641 16.9336 2.5 14.6953 2.5C13.6211 2.5 12.5898 2.92578 11.8281 3.6875L10.8828 4.63281L10 5.51953ZM10.8828 17.8672L10 18.75L9.11719 17.8672L2.5 11.25L1.55469 10.3047C0.558594 9.30859 0 7.96094 0 6.55469C0 3.625 2.375 1.25 5.30469 1.25C6.71094 1.25 8.05859 1.80859 9.05469 2.80469L9.11719 2.86719L10 3.75L10.8828 2.86719L10.9453 2.80469C11.9414 1.80859 13.2891 1.25 14.6953 1.25C17.625 1.25 20 3.625 20 6.55469C20 7.96094 19.4414 9.30859 18.4453 10.3047L17.5 11.25L10.8828 17.8672Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HeartIcon;
