import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StrengthForm from '@modules/voteModule/recognition/strength/components/strength.form';
import CreateStrengthDTO from '@modules/voteModule/recognition/strength/dto/createStrength.dto';
import StrengthRepository from '@modules/voteModule/recognition/strength/strength.repository';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const StrengthAddPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmitForm = async (values: CreateStrengthDTO) => {
    await new StrengthRepository().save(values);
    navigate(-1);
  };

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.surveys.strength.add')}
      </h3>
      <StrengthForm onSubmit={handleSubmitForm} />
    </div>
  );
};

export default StrengthAddPage;
