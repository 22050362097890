import * as Yup from 'yup';
import ThermometerTemporality from '../enum/thermometerTemporality.enum';
import i18next from 'i18next';

const ThermometerConfigValidation = () => {
  return Yup.object().shape({
    temporality: Yup.mixed<ThermometerTemporality>()
      .oneOf(Object.values(ThermometerTemporality))
      .required(i18next.t('validations.required')),
  });
};

export default ThermometerConfigValidation;
