import ChallengesRepository from '@modules/voteModule/challenges/challenges.repository';
import ChallengeDTO from '@modules/voteModule/challenges/configuration/dto/challenge.dto';
import ChallengeAnswer from '@modules/voteModule/challenges/vote/components/challengeAnswer';
import ChallengeAssignment from '@modules/voteModule/challenges/vote/components/challengeAssignment';
import ChallengeRanking from '@modules/voteModule/challenges/vote/components/challengeRanking';
import CreateChallengeAnswer from '@modules/voteModule/challenges/vote/components/createChallengeAnswer';
import ChallengeAnswerDTO from '@modules/voteModule/challenges/vote/dto/challengeAnswer.dto';
import { Card, CardBody } from '@nextui-org/card';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  Spinner,
  useDisclosure,
} from '@nextui-org/react';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

const ChallengeViewPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const profile = useSelector(selectProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [challenge, setChallenge] = useState<ChallengeDTO>();
  const [challengeAnswers, setChallengeAnswers] =
    useState<ChallengeAnswerDTO[]>();
  const [challengesRepository] = useState(new ChallengesRepository());
  const [moreAnswersAvailable, setMoreAnswersAvailable] = useState(true);
  const [answersShownNumber, setAnswersShownNumber] = useState(PAGINATION_TAKE);
  const rankingModal = useDisclosure();
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [finished, setFinished] = useState(false);

  const getChallenge = async () => {
    setIsLoading(true);
    if (id) {
      const result = await challengesRepository.getOne(id, {
        relations: ['user'],
      });
      setChallenge(result);
      setFinished(result.processedDate ? true : false);
    }
    setIsLoading(false);
  };

  const getChallengeAnswers = async () => {
    setIsLoading(true);
    setAlreadyAnswered(false);
    const results = await challengesRepository.getChallengeAnswers({
      where: { challenge: { id } },
      relations: ['user', 'interactions', 'comments', 'categories'],
      order: { createdDate: 'DESC' },
      take: answersShownNumber,
    });
    results.forEach((answer) => {
      if (answer.user.id === profile?.id) setAlreadyAnswered(true);
    });
    setChallengeAnswers(results.slice());
    if (challengeAnswers && challengeAnswers.length < answersShownNumber)
      setMoreAnswersAvailable(false);
    setIsLoading(false);
  };

  const getMorePosts = () => {
    if (challengeAnswers && challengeAnswers.length < answersShownNumber) {
      return setMoreAnswersAvailable(false);
    }
    setAnswersShownNumber(answersShownNumber + PAGINATION_TAKE);
    getChallengeAnswers();
  };

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
    getChallenge();
  }, []);

  useEffect(() => {
    getChallengeAnswers();
  }, [challenge]);

  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-3 my-3">
          {challenge && challengeAnswers && (
            <>
              <div className="col-span-4 lg:col-span-3 space-y-5">
                <ChallengeAssignment challenge={challenge} />
              </div>
              <div className="col-span-1 row-span-2 hidden lg:block">
                <ChallengeRanking
                  challengeId={challenge.id}
                  amountOfWinners={challenge.amountOfWinners}
                />
              </div>
              {profile?.group.permissions.some(
                (permission) =>
                  permission.entity === AllowedEntities.CHALLENGE &&
                  permission.methods === AllowedMethods.VIEW,
              ) &&
                !alreadyAnswered &&
                !finished && (
                  <div className="col-span-4 lg:col-span-3">
                    <Card className="overflow-visible shadow-none bg-content4 w-full px-5 pt-2">
                      <CardBody className="overflow-visible p-0">
                        <CreateChallengeAnswer
                          challenge={challenge}
                          refresh={getChallengeAnswers}
                        />
                      </CardBody>
                    </Card>
                  </div>
                )}
              <div className="lg:hidden col-span-4">
                <Button
                  onPress={rankingModal.onOpen}
                  className="w-full"
                  color="primary"
                >
                  Ver ranking
                </Button>
                <Modal
                  isOpen={rankingModal.isOpen}
                  onOpenChange={rankingModal.onOpenChange}
                  classNames={{ closeButton: 'z-50' }}
                >
                  <ModalContent>
                    <ModalBody>
                      <ChallengeRanking
                        challengeId={challenge.id}
                        amountOfWinners={challenge.amountOfWinners}
                      />
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </div>
              <div className="col-span-4 lg:col-span-3 space-y-5">
                <InfiniteScroll
                  dataLength={challengeAnswers.length}
                  next={getMorePosts}
                  className="flex flex-col items-center gap-5 mb-28"
                  hasMore={moreAnswersAvailable}
                  loader={<Spinner className="mb-10" />}
                  endMessage={
                    <p>
                      {challengeAnswers.length
                        ? t('user.dashboard.feed.posts.noMorePosts')
                        : t('user.dashboard.feed.posts.noPosts')}
                    </p>
                  }
                >
                  {challengeAnswers.map((answer) => (
                    <ChallengeAnswer
                      key={answer.id}
                      data={answer}
                      refresh={getChallengeAnswers}
                      challenge={challenge}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ChallengeViewPage;
