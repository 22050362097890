import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ObjectiveForm from '@modules/objectiveModule/objective/components/objectiveForm';
import ObjectiveDTO from '@modules/objectiveModule/objective/dto/objective.dto';
import UpdateObjectiveDTO from '@modules/objectiveModule/objective/dto/updateObjective.dto';
import ObjectiveRepository from '@modules/objectiveModule/objective/objective.repository';
import { Spinner } from '@nextui-org/react';
import CompareUtil from '@utils/compare';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const ObjectiveEditPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [objectiveResult, setObjectiveResult] = useState<ObjectiveDTO | null>(
    null,
  );

  const handleSubmit = async (values: UpdateObjectiveDTO) => {
    if (!objectiveResult) return;
    await new ObjectiveRepository().update(objectiveResult?.id as string, {
      ...(CompareUtil.compareObjects(
        objectiveResult,
        values,
      ) as UpdateObjectiveDTO),
      categories: values.categories.length > 0 ? values.categories : [],
      notified: values.notified.length > 0 ? values.notified : [],
    });
    navigate(-1);
  };

  const getObjective = async (options?: FindOptionsDTO<ObjectiveDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const objectiveResult = await new ObjectiveRepository().findOne(
      id as string,
      {
        ...options,
        relations: Object.assign(options?.relations || [], [
          'manager',
          'notified',
          'categories',
        ]),
      },
    );
    if (!objectiveResult) navigate(-1);
    setObjectiveResult(objectiveResult);
    setLoading(false);
  };

  useEffect(() => {
    getObjective();
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  return isLoading || !objectiveResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        {' '}
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.objective.edit')}
      </h3>
      <ObjectiveForm onSubmit={handleSubmit} initialValues={objectiveResult} />
    </div>
  );
};

export default ObjectiveEditPage;
