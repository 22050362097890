import i18next from 'i18next';
import * as Yup from 'yup';

const signinValidation = () => {
  return Yup.object().shape({
    email: Yup.string()
      .required(i18next.t('validations.required'))
      .email(i18next.t('validations.email')),
    password: Yup.string().required(i18next.t('validations.required')),
  });
};

export default signinValidation;
