import React from 'react';
import { Card, CardBody } from '@nextui-org/card';
import { Spinner, CardHeader, Button, CardFooter } from '@nextui-org/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import ChevronIcon from '@components/icons/chevron.icon';
import InitiativeStatusChange from './initiativeStatusChange';
import { Link } from 'react-router-dom';
import InitiativeRepository from '../initiative.repository';
import InitiativeStatus from '../enum/initiativeStatus.enum';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import InitiativeDTO from '../dto/initiative.dto';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

const MobileTasksDashboardCard = () => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initiatives, setInitiatives] = useState<InitiativeDTO[]>([]);

  const getInitiatives = async (options?: FindOptionsDTO<InitiativeDTO>) => {
    setIsLoading(true);
    const initiativeResult = await new InitiativeRepository().find({
      ...options,
      relations: Object.assign(['manager'], options?.relations),
      where: {
        ...options?.where,
        manager: {
          externalUser: profile?.id,
        },
        status: '!=COMPLETED',
      },
      order: { lastModifiedDate: 'DESC' },
      take: PAGINATION_TAKE,
    });
    setInitiatives(initiativeResult.results);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) getInitiatives();
  }, [isOpen]);

  const onChangeStatus = async (
    id: string,
    status: InitiativeStatus,
    isModal: boolean,
  ) => {
    await new InitiativeRepository().update(id, { status });
    getInitiatives(isModal ? {} : { take: 3 });
  };

  const EmptyList = () => {
    return (
      <div className="pt-2">
        <p className="text-center text-[14px]">
          {t('entities.initiative.table.empty')}
        </p>
      </div>
    );
  };

  return (
    <Card className="shadow-none bg-content4 lg:hidden">
      <CardHeader className="justify-center pb-0">
        <span className="text-lg dark:text-primary-600">
          {t('entities.initiative.dashboard.title')}
        </span>
      </CardHeader>
      {isOpen && (
        <CardBody className="flex flex-col gap-2">
          {isLoading ? (
            <Spinner />
          ) : initiatives.length ? (
            initiatives.map((initiative, index) => {
              if (index < 3)
                return (
                  <Card key={index} className="shadow-sm">
                    <CardBody className="flex-row gap-2 p-2 items-center">
                      {profile?.group.permissions.some(
                        (permission) =>
                          permission.entity === AllowedEntities.INITIATIVE &&
                          permission.methods === AllowedMethods.VIEW,
                      ) ? (
                        <Link
                          to={`/enterprise/initiative/view/${initiative.id}`}
                          className="text-sm no-underline w-1/2 text-foreground cursor-pointer line-clamp-2 truncate whitespace-normal"
                        >
                          {initiative.name}
                        </Link>
                      ) : (
                        <div className="text-sm no-underline w-1/2 text-foreground cursor-pointer line-clamp-2 truncate whitespace-normal">
                          {initiative.name}
                        </div>
                      )}
                      <div className="w-1/2">
                        <InitiativeStatusChange
                          status={initiative.status}
                          onChange={(status) =>
                            onChangeStatus(initiative.id, status, false)
                          }
                          disabled={
                            !profile?.group.permissions.some(
                              (permission) =>
                                permission.entity ===
                                  AllowedEntities.INITIATIVE &&
                                permission.methods === AllowedMethods.MODIFY,
                            )
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                );
            })
          ) : (
            <EmptyList />
          )}
        </CardBody>
      )}
      <CardFooter className="justify-center pt-0">
        <Button
          variant="light"
          onPress={() => setIsOpen(!isOpen)}
          color="primary"
        >
          <ChevronIcon
            className={`transition-all ${isOpen ? 'rotate-180' : ''}`}
          />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default MobileTasksDashboardCard;
