import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UserGroupDTO from '@modules/userModule/userGroup/dto/userGroup.dto';
import FindOptions from 'lib/paginated/findOptions.dto';
import UserGroupForm from '@modules/userModule/userGroup/components/userGroupForm';
import UpdateUserGroupDTO from '@modules/userModule/userGroup/dto/updateUserGroup';
import UserGroupRepository from '@modules/userModule/userGroup/userGroup.repository';
import CompareUtil from '@utils/compare';

const UserGroupEdit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [userGroupResult, setUserGroupResult] = useState<UserGroupDTO | null>(
    null,
  );

  const getUserGroup = async (options?: FindOptions<UserGroupDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const userResult = await new UserGroupRepository().findOne(
      id as string,
      options,
    );
    if (!userResult) navigate(-1);
    setUserGroupResult(userResult);
    setLoading(false);
  };

  useEffect(() => {
    getUserGroup({ relations: ['permissions'] });
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  const handleSubmit = async (values: UpdateUserGroupDTO) => {
    if (!userGroupResult) return;
    await new UserGroupRepository().update(userGroupResult?.id as string, {
      ...(CompareUtil.compareObjects(
        userGroupResult,
        values,
      ) as UpdateUserGroupDTO),
      permissions: values.permissions,
    });
    navigate(-1);
  };

  return isLoading || !userGroupResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.userGroups.edit')}
      </h3>
      <UserGroupForm onSubmit={handleSubmit} initialValues={userGroupResult} />
    </div>
  );
};

export default UserGroupEdit;
