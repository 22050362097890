import CommentIcon from '@components/icons/comment.icon';
import HeartIcon from '@components/icons/heart.icon';
import HeartSolidIcon from '@components/icons/heartSolid.icon';
import OptionsIcon from '@components/icons/options.icon';
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useDisclosure,
} from '@nextui-org/react';
import { SetStateAction, useEffect, useState } from 'react';
import DateUtil from '@utils/date';
import ChallengeAnswerComment from './challengeAnswerComment';
import '@blocknote/core/style.css';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useTranslation } from 'react-i18next';
import Likes from '@modules/feed/posts/components/likes';
import ChallengeAnswerDTO from '../dto/challengeAnswer.dto';
import CreateChallengeAnswer from './createChallengeAnswer';
import ChallengesRepository from '../../challenges.repository';
import ChallengeAnswerInteractionType from '../dto/challengeAnswerInteractionType.enum';
import ChallengeDTO from '../../configuration/dto/challenge.dto';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import { useNavigate } from 'react-router-dom';

interface ChallengeAnswerProps {
  challenge: ChallengeDTO;
  data: ChallengeAnswerDTO;
  refresh?: () => void;
}

const ChallengeAnswer = ({
  challenge,
  data,
  refresh,
}: ChallengeAnswerProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const [isLiked, setIsLiked] = useState(false);
  const deleteModal = useDisclosure();
  const editModal = useDisclosure();
  const [viewComments, setViewComments] = useState(false);
  const [challengeAnswersRepository] = useState(new ChallengesRepository());
  const [newComment, setNewComment] = useState('');
  const [isCommentButtonLoading, setIsCommentButtonLoading] = useState(false);
  const [isLikeButtonLoading, setIsLikeButtonLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const deleteChallengeAnswer = async () => {
    await challengeAnswersRepository.deleteChallengeAnswer(data.id);
    deleteModal.onClose();
    if (refresh) refresh();
  };

  const likeChallengeAnswer = async () => {
    setIsLikeButtonLoading(true);
    if (!finished) {
      const response = await challengeAnswersRepository.saveInteraction({
        type: ChallengeAnswerInteractionType.HEART,
        answer: data,
      });
      data.interactions = [
        ...data.interactions,
        {
          id: response.id,
          createdBy: profile ? profile.email : '',
          createdDate: new Date(),
        },
      ];
      setIsLiked(true);
      if (refresh) refresh();
    }
    setIsLikeButtonLoading(false);
  };

  const removeLike = async () => {
    setIsLikeButtonLoading(true);
    if (!finished) {
      let like = null;
      for (const interaction of data.interactions) {
        if (interaction.createdBy === profile?.email) {
          like = interaction;
          break;
        }
      }
      if (like != null) {
        await challengeAnswersRepository.deleteInteraction(like.id);
        data.interactions.splice(data.interactions.indexOf(like), 1);
      }
      setIsLiked(false);
      if (refresh) refresh();
    }
    setIsLikeButtonLoading(false);
  };

  const submitComment = async () => {
    setIsCommentButtonLoading(true);
    await challengeAnswersRepository.saveComment({
      content: newComment,
      answer: data,
    });
    setNewComment('');
    await getComments();
    setIsCommentButtonLoading(false);
  };

  const getComments = async () => {
    setViewComments(false);
    const response = await challengeAnswersRepository.getComments({
      where: { answer: { id: data.id } },
    });
    data.comments = response;
    if (response.length > 0) setViewComments(true);
  };

  const getAndViewComments = async () => {
    if (!viewComments) await getComments();
    else setViewComments(false);
  };

  useEffect(() => {
    setFinished(challenge.processedDate ? true : false);
    validateLiked();
  }, []);

  const validateLiked = () => {
    for (const interaction of data.interactions) {
      if (interaction.createdBy === profile?.email) {
        setIsLiked(true);
        return;
      }
    }
    setIsLiked(false);
  };

  const handleNewCommentChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setNewComment(event.target.value);
  };

  return (
    <>
      <Card className="bg-content4 shadow-none overflow-visible w-full">
        <CardHeader className="flex justify-between pt-5 pb-0 lg:px-10">
          <div className="flex items-center gap-5">
            <Avatar
              showFallback
              src={
                data.user.avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${data.user.avatar}`
              }
              name={data.user.fullName}
              isBordered={data.createdBy === profile?.email}
              color={data.createdBy === profile?.email ? 'primary' : 'default'}
              className="h-full aspect-square cursor-pointer"
              onClick={() =>
                navigate(`/enterprise/profile/${data.user.externalUser}`)
              }
            />
            <div className="flex flex-col">
              <div className="flex flex-col items-start md:flex-row md:items-center md:gap-2">
                <span
                  className="m-0 cursor-pointer leading-none md:leading-normal hover:underline"
                  onClick={() =>
                    navigate(`/enterprise/profile/${data.user.externalUser}`)
                  }
                >
                  {data.user.fullName}
                </span>
                {/* Puesto de quien publica - COMENTADO HASTA PODER ACCEDER AL PUESTO DEL PERFIL*/}
                {/* <span className="m-0 text-gray-400">- Head of Sales</span> */}
              </div>
              <span className="m-0 text-gray-400 text-sm">
                {DateUtil.dateToFullDate(data.createdDate)}
              </span>
            </div>
          </div>
          {!finished &&
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.CHALLENGE_ANSWER &&
                ((permission.methods === AllowedMethods.MODIFY &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.MODIFY_OTHERS),
            ) &&
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.CHALLENGE_ANSWER &&
                ((permission.methods === AllowedMethods.DELETE &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.DELETE_OTHERS),
            ) && (
              <div className="flex items-center">
                <Dropdown>
                  <DropdownTrigger>
                    <Button radius="full" isIconOnly className="bg-transparent">
                      <OptionsIcon />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem
                      onPress={editModal.onOpen}
                      className="font-poppins"
                    >
                      {t('user.dashboard.feed.posts.options.edit.option')}
                    </DropdownItem>
                    <DropdownItem
                      onPress={deleteModal.onOpen}
                      className="font-poppins text-danger"
                    >
                      {t('user.dashboard.feed.posts.options.delete.option')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          {!finished &&
            !profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.CHALLENGE_ANSWER &&
                ((permission.methods === AllowedMethods.MODIFY &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.MODIFY_OTHERS),
            ) &&
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.CHALLENGE_ANSWER &&
                ((permission.methods === AllowedMethods.DELETE &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.DELETE_OTHERS),
            ) && (
              <div className="flex items-center">
                <Dropdown>
                  <DropdownTrigger>
                    <Button radius="full" isIconOnly className="bg-transparent">
                      <OptionsIcon />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem
                      onPress={deleteModal.onOpen}
                      className="font-poppins text-danger"
                    >
                      {t('user.dashboard.feed.posts.options.delete.option')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          {!finished &&
            profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.CHALLENGE_ANSWER &&
                ((permission.methods === AllowedMethods.MODIFY &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.MODIFY_OTHERS),
            ) &&
            !profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.CHALLENGE_ANSWER &&
                ((permission.methods === AllowedMethods.DELETE &&
                  profile.id === data.user.id) ||
                  permission.methods === AllowedMethods.DELETE_OTHERS),
            ) && (
              <div className="flex items-center">
                <Dropdown>
                  <DropdownTrigger>
                    <Button radius="full" isIconOnly className="bg-transparent">
                      <OptionsIcon />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem
                      onPress={editModal.onOpen}
                      className="font-poppins"
                    >
                      {t('user.dashboard.feed.posts.options.edit.option')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
        </CardHeader>
        <CardBody className="overflow-visible py-0 md:py-3 lg:px-10">
          <div
            className="text-default-600"
            dangerouslySetInnerHTML={{ __html: data.content }}
          ></div>
          {data.categories.length ? (
            <div className="flex gap-2 mb-5 max-w-full flex-wrap">
              {data.categories.map((tag) => (
                <span
                  className="text-primary dark:text-primary-600 hover:underline cursor-pointer"
                  key={tag.name}
                >
                  #{tag.name}
                </span>
              ))}
            </div>
          ) : (
            <></>
          )}
          <div className="flex justify-between w-full">
            <div className="flex gap-5">
              <Likes
                likes={data.interactions}
                heartClass="text-red-500"
                parentId={data.id}
                parentType="challengeAnswer"
              />
              <span
                className="text-default-400 dark:text-default-600 flex items-center gap-2 cursor-pointer hover:underline"
                onClick={async () => await getAndViewComments()}
              >
                <CommentIcon className="text-primary dark:text-primary-600 w-5" />
                {data.comments.length}
                <span className="hidden lg:inline"> comentarios</span>
              </span>
            </div>
            {isLiked ? (
              <Button
                onPress={removeLike}
                isLoading={isLikeButtonLoading}
                variant="light"
                className="text-default-400 dark:text-default-600"
                startContent={<HeartSolidIcon className="text-red-500 w-5" />}
              >
                {t('user.dashboard.feed.posts.likes.like')}
              </Button>
            ) : (
              <Button
                onPress={likeChallengeAnswer}
                isLoading={isLikeButtonLoading}
                variant="light"
                className="text-default-400 dark:text-default-600"
                startContent={<HeartIcon className="w-5" />}
              >
                {t('user.dashboard.feed.posts.likes.like')}
              </Button>
            )}
          </div>
        </CardBody>
        <CardFooter className="flex flex-col border-t-2 border-background py-5 lg:px-10">
          {viewComments && (
            <div className="mb-5 w-full space-y-2">
              {data.comments.map((comment) => (
                <ChallengeAnswerComment
                  key={comment.id}
                  data={comment}
                  refresh={getComments}
                />
              ))}
            </div>
          )}
          {!finished &&
            profile?.group.permissions.some(
              (permission) =>
                permission.entity ===
                  AllowedEntities.CHALLENGE_ANSWER_COMMENT &&
                permission.methods === AllowedMethods.ADD,
            ) && (
              <Textarea
                startContent={
                  <div className="mr-2 w-12 aspect-square">
                    <Avatar
                      src={
                        profile.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${profile.avatar}`
                      }
                      name={profile?.email.toUpperCase()}
                      showFallback
                      isBordered
                      color="primary"
                      className=" w-full h-full"
                    />
                  </div>
                }
                placeholder={t(
                  'user.dashboard.feed.posts.comments.input.placeholder',
                )}
                endContent={
                  <div className="flex items-center gap-2">
                    {/* COMENTADO HASTA TENER FUNCIONALIDAD DE AGREGAR IMÁGENES IMPLEMENTADA */}
                    {/* <ImageIcon className="cursor-pointer" /> */}
                    <Button
                      onPress={submitComment}
                      color="primary"
                      isLoading={isCommentButtonLoading}
                    >
                      {t('user.dashboard.feed.posts.comments.input.submit')}
                    </Button>
                  </div>
                }
                classNames={{
                  innerWrapper: 'items-center',
                  inputWrapper:
                    'rounded-full shadow-none bg-background group-data-[focus=true]:bg-background data-[hover=true]:bg-background',
                }}
                minRows={1}
                value={newComment}
                onChange={handleNewCommentChange}
              />
            )}
        </CardFooter>
      </Card>
      <Modal
        isOpen={editModal.isOpen}
        onOpenChange={editModal.onOpenChange}
        size="3xl"
        className="py-5 lg:px-10 font-poppins bg-content4"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalHeader>
            {t('user.dashboard.feed.posts.options.edit.title')}
          </ModalHeader>
          <ModalBody>
            <CreateChallengeAnswer
              challenge={challenge}
              data={data}
              refresh={refresh}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteModal.isOpen}
        onOpenChange={deleteModal.onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                {t('user.dashboard.feed.posts.options.delete.title')}
              </ModalHeader>
              <ModalBody>
                <p>{t('user.dashboard.feed.posts.options.delete.text')}</p>
              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose}>
                  {t('user.dashboard.feed.posts.options.delete.cancel')}
                </Button>
                <Button onPress={deleteChallengeAnswer} color="danger">
                  {t('user.dashboard.feed.posts.options.delete.delete')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChallengeAnswer;
