import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PulseSurveyDetail from '@modules/voteModule/pulse/pulseSurvey/components/pulseSurvey.detail';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const PulseSurveyViewPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.surveys.pulse.detail')}
      </h3>
      <PulseSurveyDetail id={id as string} />
    </div>
  );
};

export default PulseSurveyViewPage;
