import React from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import TeamDTO from '@modules/userModule/team/dto/team.dto';
import TeamRepository from '@modules/userModule/team/team.repository';
import CreateUserDTO from '@modules/userModule/user/dto/createUser.dto';
import UpdateUserDTO from '@modules/userModule/user/dto/updateUser.dto';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import UserFormValidation from '@modules/userModule/user/validation/userForm.validation';
import UpdateUserFileDTO from '@modules/userModule/userFile/dto/updateUserFile.dto';
import MaritalStatus from '@modules/userModule/userFile/enum/maritalStatus.enum';
import UserFileRepository from '@modules/userModule/userFile/userFile.repository';
import UserFileFormValidation from '@modules/userModule/userFile/validation/userFileForm.validation';
import UserGroupDTO from '@modules/userModule/userGroup/dto/userGroup.dto';
import UserGroupRepository from '@modules/userModule/userGroup/userGroup.repository';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react';
import CompareUtil from '@utils/compare';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface UserFileFormProps {
  initialValues?: UpdateUserFileDTO;
  onSubmit: (values: UpdateUserFileDTO) => void | Promise<void>;
}

export const initialUserFileFormValues: UpdateUserFileDTO = {
  taxId: '',
  hireDate: new Date(),
  birthDate: new Date(),
  jobTitle: '',
  address: '',
  phone: '',
  inTime: '',
  outTime: '',
  bankAccountNumber: '',
  employeeFileNumber: '',
  emergencyPhone: '',
  emergencyName: '',
  secondaryEmergencyPhone: '',
  secondaryEmergencyName: '',
  maritalStatus: MaritalStatus.SINGLE,
};

const UserFileForm = ({
  initialValues = initialUserFileFormValues,
  onSubmit,
}: UserFileFormProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFileFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<UpdateUserFileDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="taxId"
              label={t('entities.userFile.fields.taxId')}
              isInvalid={Boolean(errors.taxId)}
              errorMessage={errors.taxId}
              color={errors.taxId ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('taxId', e.target.value)}
              value={values.taxId}
            />
            <Input
              isDisabled={false}
              name="hireDate"
              label={t('entities.userFile.fields.hireDate')}
              type="date"
              isInvalid={Boolean(errors.hireDate)}
              errorMessage={String(errors.hireDate || '')}
              color={errors.hireDate ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('hireDate', e.target.value)}
              value={values.hireDate?.toString() || new Date().toString()}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="birthDate"
              label={t('entities.userFile.fields.birthDate')}
              type="date"
              isInvalid={Boolean(errors.birthDate)}
              errorMessage={String(errors.birthDate || '')}
              color={errors.birthDate ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('birthDate', e.target.value)}
              value={values.birthDate?.toString() || new Date().toString()}
            />
            <Input
              name="jobTitle"
              label={t('entities.userFile.fields.jobTitle')}
              isInvalid={Boolean(errors.jobTitle)}
              errorMessage={errors.jobTitle}
              color={errors.jobTitle ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('jobTitle', e.target.value)}
              value={values.jobTitle}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="address"
              label={t('entities.userFile.fields.address')}
              isInvalid={Boolean(errors.address)}
              errorMessage={errors.address}
              color={errors.address ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('address', e.target.value)}
              value={values.address}
            />
            <Input
              name="phone"
              label={t('entities.userFile.fields.phone')}
              isInvalid={Boolean(errors.phone)}
              errorMessage={errors.phone}
              color={errors.phone ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('phone', e.target.value)}
              value={values.phone}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="inTime"
              label={t('entities.userFile.fields.inTime')}
              isInvalid={Boolean(errors.inTime)}
              errorMessage={errors.inTime}
              color={errors.inTime ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('inTime', e.target.value)}
              value={values.inTime}
            />
            <Input
              name="outTime"
              label={t('entities.userFile.fields.outTime')}
              isInvalid={Boolean(errors.outTime)}
              errorMessage={errors.outTime}
              color={errors.outTime ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('outTime', e.target.value)}
              value={values.outTime}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="bankAccountNumber"
              label={t('entities.userFile.fields.bankAccountNumber')}
              isInvalid={Boolean(errors.bankAccountNumber)}
              errorMessage={errors.bankAccountNumber}
              color={errors.bankAccountNumber ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('bankAccountNumber', e.target.value)
              }
              value={values.bankAccountNumber}
            />
            <Input
              name="employeeFileNumber"
              label={t('entities.userFile.fields.employeeFileNumber')}
              isInvalid={Boolean(errors.employeeFileNumber)}
              errorMessage={errors.employeeFileNumber}
              color={errors.employeeFileNumber ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('employeeFileNumber', e.target.value)
              }
              value={values.employeeFileNumber}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="emergencyPhone"
              label={t('entities.userFile.fields.emergencyPhone')}
              isInvalid={Boolean(errors.emergencyPhone)}
              errorMessage={errors.emergencyPhone}
              color={errors.emergencyPhone ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('emergencyPhone', e.target.value)}
              value={values.emergencyPhone}
            />
            <Input
              name="emergencyName"
              label={t('entities.userFile.fields.emergencyName')}
              isInvalid={Boolean(errors.emergencyName)}
              errorMessage={errors.emergencyName}
              color={errors.emergencyName ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('emergencyName', e.target.value)}
              value={values.emergencyName}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="secondaryEmergencyPhone"
              label={t('entities.userFile.fields.secondaryEmergencyPhone')}
              isInvalid={Boolean(errors.secondaryEmergencyPhone)}
              errorMessage={errors.secondaryEmergencyPhone}
              color={errors.secondaryEmergencyPhone ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('secondaryEmergencyPhone', e.target.value)
              }
              value={values.secondaryEmergencyPhone}
            />
            <Input
              name="secondaryEmergencyName"
              label={t('entities.userFile.fields.secondaryEmergencyName')}
              isInvalid={Boolean(errors.secondaryEmergencyName)}
              errorMessage={errors.secondaryEmergencyName}
              color={errors.secondaryEmergencyName ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('secondaryEmergencyName', e.target.value)
              }
              value={values.secondaryEmergencyName}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Select
              label={t('entities.userFile.fields.maritalStatus')}
              selectedKeys={[values.maritalStatus]}
              onSelectionChange={(value) =>
                setFieldValue(
                  'maritalStatus',
                  new Set(value).values().next().value,
                )
              }
            >
              <SelectItem
                key={MaritalStatus.SINGLE}
                value={MaritalStatus.SINGLE}
              >
                {t(
                  `entities.userFile.values.maritalStatus.${MaritalStatus.SINGLE}`,
                )}
              </SelectItem>
              <SelectItem
                key={MaritalStatus.MARRIED}
                value={MaritalStatus.MARRIED}
              >
                {t(
                  `entities.userFile.values.maritalStatus.${MaritalStatus.MARRIED}`,
                )}
              </SelectItem>
            </Select>
          </div>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.save')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

interface UserFormProps {
  initialValues?: CreateUserDTO;
  onSubmit: (values: CreateUserDTO) => void | Promise<void>;
}

export const initialUserFormValues: CreateUserDTO = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  group: { id: '' },
  team: null,
};

const UserForm = ({
  initialValues = initialUserFormValues,
  onSubmit,
}: UserFormProps) => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [userGroupList, setUserGroupList] = useState<UserGroupDTO[]>([]);
  const [teamList, setTeamList] = useState<TeamDTO[]>([]);

  const getUserGroups = async (options?: FindOptionsDTO<UserGroupDTO>) => {
    const userGroups = await new UserGroupRepository().find(options);
    setUserGroupList(userGroups.results);
  };
  const getTeams = async (options?: FindOptionsDTO<TeamDTO>) => {
    const teams = await new TeamRepository().find(options);
    setTeamList(teams.results);
  };

  useEffect(() => {
    if (
      profile?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.USER_GROUP &&
          permission.methods === AllowedMethods.VIEW,
      )
    )
      getUserGroups();
    if (
      profile?.group.permissions.some(
        (permission) =>
          permission.entity === AllowedEntities.TEAM &&
          permission.methods === AllowedMethods.VIEW,
      )
    )
      getTeams();
  }, []);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateUserDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              isDisabled={true}
              name="email"
              label={t('entities.user.fields.email')}
              isInvalid={Boolean(errors.email)}
              errorMessage={errors.email}
              color={errors.email ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('email', e.target.value)}
              value={values.email}
            />
            <Input
              name="password"
              label={t('entities.user.fields.password')}
              isInvalid={Boolean(errors.password)}
              errorMessage={errors.password}
              color={errors.password ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('password', e.target.value)}
              value={values.password}
              type="password"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <Input
              name="firstName"
              label={t('entities.user.fields.firstName')}
              isInvalid={Boolean(errors.firstName)}
              errorMessage={errors.firstName}
              color={errors.firstName ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('firstName', e.target.value)}
              value={values.firstName}
            />
            <Input
              name="lastName"
              label={t('entities.user.fields.lastName')}
              isInvalid={Boolean(errors.lastName)}
              errorMessage={errors.lastName}
              color={errors.lastName ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('lastName', e.target.value)}
              value={values.lastName}
            />
          </div>
          {profile?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.USER_GROUP &&
              permission.methods === AllowedMethods.VIEW,
          ) && (
            <div>
              <Autocomplete
                isDisabled={true}
                label={t('entities.user.fields.userGroup')}
                items={userGroupList}
                onInputChange={(e) => {
                  getUserGroups({ where: { name: `lk=${e}` } });
                }}
                onSelectionChange={(e) => setFieldValue('group.id', e)}
                selectedKey={values.group.id}
                isInvalid={Boolean(errors.group?.id)}
                errorMessage={errors.group?.id}
                color={errors.group?.id ? 'danger' : 'default'}
              >
                {(item) => (
                  <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>
                )}
              </Autocomplete>
            </div>
          )}
          {profile?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.TEAM &&
              permission.methods === AllowedMethods.VIEW,
          ) && (
            <div>
              <Autocomplete
                isDisabled={true}
                label={t('entities.user.fields.team')}
                items={teamList}
                onInputChange={(e) => {
                  getTeams({ where: { name: `lk=${e}` } });
                }}
                onSelectionChange={(e) =>
                  setFieldValue('team', e ? { id: e } : null)
                }
                selectedKey={values.team?.id || null}
                isInvalid={Boolean(errors.team)}
                errorMessage={errors.team}
                color={errors.team ? 'danger' : 'default'}
              >
                {(item) => (
                  <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>
                )}
              </Autocomplete>
            </div>
          )}
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const PersonalData = ({ profile }: { profile: UserDTO }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmitPersonalData = async (values: any) => {
    if (!profile) return;
    await new UserFileRepository().update(profile.userFile.id as string, {
      ...(CompareUtil.compareObjects(
        profile.userFile,
        values,
      ) as UpdateUserFileDTO),
    });
    navigate(-1);
  };

  const handleSubmit = async (values: UpdateUserDTO) => {
    if (!profile) return;
    await new UserRepository().update(profile?.id as string, {
      ...(CompareUtil.compareObjects(profile, values) as UpdateUserDTO),
      team: values.team,
    });
    navigate(-1);
  };

  return (
    <div>
      <h4>{t('user.profile.private.personalData.title')}</h4>
      <UserFileForm
        initialValues={profile.userFile}
        onSubmit={handleSubmitPersonalData}
      />
      <h4>{t('user.profile.private.personalData.title')}</h4>
      <UserForm initialValues={profile} onSubmit={handleSubmit} />
    </div>
  );
};

export default PersonalData;
