import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import TeamDTO from '../dto/team.dto';
import TeamRepository from '../team.repository';

interface TeamDetailProps {
  id: string;
  options?: FindOptionsDTO<TeamDTO>;
}

const TeamDetail = (props: TeamDetailProps) => {
  const { t } = useTranslation();
  const [teamResult, setTeamResult] = useState<TeamDTO | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getTeam = async (options?: FindOptionsDTO<UserDTO>) => {
    setLoading(true);
    const teamResultData = await new TeamRepository().findOne(
      props.id as string,
      options,
    );
    setTeamResult(teamResultData);
    setLoading(false);
  };

  useEffect(() => {
    getTeam({
      ...props.options,
      relations: Object.assign(['parent'], props.options?.relations),
    });
  }, [props.options]);

  return (
    <Card className="my-5">
      <CardBody>
        <div className="grid grid-cols-1">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.team.fields.name')}
              </strong>{' '}
              {teamResult?.name}
            </p>
          </Skeleton>
        </div>
        <div className="grid grid-cols-1">
          <Skeleton isLoaded={!isLoading} className="rounded-lg">
            <p>
              <strong className="text-foreground">
                {t('entities.team.fields.parent')}
              </strong>{' '}
              {teamResult?.parent?.name || '-'}
            </p>
          </Skeleton>
        </div>
      </CardBody>
    </Card>
  );
};

export default TeamDetail;
