import axios from 'axios';
import ThermometerType from './enum/thermometerType';
import ThermometerVoteDTO from './dto/thermometerVote.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';

export default class ThermometerRepository {
  private url: string = '/api/thermometerVote';

  async vote(type: ThermometerType) {
    return await axios.post(`${this.url}/vote`, {
      vote: type,
    });
  }

  async getLastVote(): Promise<ThermometerVoteDTO> {
    return (await axios.get(`${this.url}/lastVote`)).data;
  }

  async find(
    options?: FindOptionsDTO<ThermometerVoteDTO>,
  ): Promise<PaginatedDTO<ThermometerVoteDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const recognitionResponse = await axios.get<
      PaginatedDTO<ThermometerVoteDTO>
    >(`${this.url}${stringOption}`);
    return recognitionResponse.data;
  }
}
