import { useTranslation } from 'react-i18next';
import CreateLicenseTypeDTO from '../dto/createLicenseType.dto';
import Approvers from '../enum/approvers.enum';
import { Form, Formik, FormikProps } from 'formik';
import LicenseTypeFormValidation from '../validation/licenseTypeValidation';
import { Button, Input, Select, SelectItem } from '@nextui-org/react';

interface LicenseTypeFormProps {
  initialValues?: CreateLicenseTypeDTO;
  onSubmit: (values: CreateLicenseTypeDTO) => void | Promise<void>;
}

export const initialLicenseTypeFormValues: CreateLicenseTypeDTO = {
  name: '',
  days: 0,
  anticipationDays: 14,
  approver: Approvers.BOTH,
  renewalMonth: 0,
  accumulationYears: 1,
};

const LicenseTypeForm = ({
  initialValues = initialLicenseTypeFormValues,
  onSubmit,
}: LicenseTypeFormProps) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LicenseTypeFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateLicenseTypeDTO>) => (
        <Form>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
            <Input
              name="name"
              label={t('entities.licenseType.fields.name')}
              labelPlacement="outside"
              isInvalid={Boolean(errors.name)}
              errorMessage={errors.name}
              color={errors.name ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('name', e.target.value)}
              value={values.name}
            />
            <Input
              name="days"
              labelPlacement="outside"
              label={t('entities.licenseType.fields.days')}
              isInvalid={Boolean(errors.days)}
              errorMessage={errors.days}
              type="number"
              color={errors.days ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('days', e.target.valueAsNumber)}
              value={String(values.days)}
            />
            <Input
              name="anticipationDays"
              labelPlacement="outside"
              label={t('entities.licenseType.fields.anticipationDays')}
              isInvalid={Boolean(errors.anticipationDays)}
              errorMessage={errors.anticipationDays}
              type="number"
              color={errors.anticipationDays ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('anticipationDays', e.target.valueAsNumber)
              }
              value={String(values.anticipationDays)}
            />
            <Select
              labelPlacement="outside"
              label={t('entities.licenseType.fields.approver')}
              selectedKeys={new Set([values.approver])}
              onSelectionChange={(keys) => {
                setFieldValue('approver', new Set(keys).values().next().value);
              }}
            >
              {Object.keys(Approvers).map((approver) => (
                <SelectItem key={approver} value={approver}>
                  {t(`entities.licenseType.values.approver.${approver}`)}
                </SelectItem>
              ))}
            </Select>
            <Select
              labelPlacement="outside"
              label={t('entities.licenseType.fields.renewalMonth')}
              selectedKeys={new Set([values.renewalMonth])}
              onSelectionChange={(keys) => {
                setFieldValue(
                  'renewalMonth',
                  new Set(keys).values().next().value,
                );
              }}
            >
              <SelectItem key={0} value={0}>
                {t(`entities.licenseType.values.renewalMonth.0`)}
              </SelectItem>
              <SelectItem key={1} value={1}>
                {t(`entities.licenseType.values.renewalMonth.1`)}
              </SelectItem>
              <SelectItem key={2} value={2}>
                {t(`entities.licenseType.values.renewalMonth.2`)}
              </SelectItem>
              <SelectItem key={3} value={3}>
                {t(`entities.licenseType.values.renewalMonth.3`)}
              </SelectItem>
              <SelectItem key={4} value={4}>
                {t(`entities.licenseType.values.renewalMonth.4`)}
              </SelectItem>
              <SelectItem key={5} value={5}>
                {t(`entities.licenseType.values.renewalMonth.5`)}
              </SelectItem>
              <SelectItem key={6} value={6}>
                {t(`entities.licenseType.values.renewalMonth.6`)}
              </SelectItem>
              <SelectItem key={7} value={7}>
                {t(`entities.licenseType.values.renewalMonth.7`)}
              </SelectItem>
              <SelectItem key={8} value={8}>
                {t(`entities.licenseType.values.renewalMonth.8`)}
              </SelectItem>
              <SelectItem key={9} value={9}>
                {t(`entities.licenseType.values.renewalMonth.9`)}
              </SelectItem>
              <SelectItem key={10} value={10}>
                {t(`entities.licenseType.values.renewalMonth.10`)}
              </SelectItem>
              <SelectItem key={11} value={11}>
                {t(`entities.licenseType.values.renewalMonth.11`)}
              </SelectItem>
            </Select>
            <Input
              name="accumulationYears"
              labelPlacement="outside"
              label={t('entities.licenseType.fields.accumulationYears')}
              isInvalid={Boolean(errors.accumulationYears)}
              errorMessage={errors.accumulationYears}
              type="number"
              color={errors.accumulationYears ? 'danger' : 'default'}
              onChange={(e) =>
                setFieldValue('accumulationYears', e.target.valueAsNumber)
              }
              value={String(values.accumulationYears)}
            />
          </div>
          <Button
            className="my-5"
            fullWidth
            type="submit"
            color="primary"
            isLoading={isSubmitting}
          >
            {t('entities.licenseType.save')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LicenseTypeForm;
