import i18next from 'i18next';
import * as Yup from 'yup';
import KeyResultStatus from '../enum/keyResultStatus.enum';
import KeyResultMeasurementType from '../enum/keyResultMeasurementType.enum';

const keyResultFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18next.t('validations.required')),
    description: Yup.string(),
    periodFrom: Yup.date().required(i18next.t('validations.required')),
    periodTo: Yup.date().required(i18next.t('validations.required')),
    manager: Yup.object()
      .required(i18next.t('validations.required'))
      .shape({
        id: Yup.string().required(i18next.t('validations.required')),
      }),
    status: Yup.mixed<KeyResultStatus>()
      .oneOf(Object.values(KeyResultStatus))
      .required(i18next.t('validations.required')),
    measurementType: Yup.mixed<KeyResultMeasurementType>()
      .oneOf(Object.values(KeyResultMeasurementType))
      .required(i18next.t('validations.required')),
    initialValue: Yup.number().required(i18next.t('validations.required')),
    expectedValue: Yup.number().required(i18next.t('validations.required')),
  });
};

export default keyResultFormValidation;
