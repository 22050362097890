import { setProfile } from '@modules/userModule/auth/auth.reducer';
import AuthRepository from '@modules/userModule/auth/auth.repository';
import UnauthorizedException from '@modules/userModule/auth/exceptions/unauthorized.exception';
import EnterpriseType from '@modules/userModule/enterprise/enum/enterpriseType.enum';
import { Spinner } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(true);

  const verifyRoute = ({
    enterpriseType,
  }: {
    enterpriseType: EnterpriseType;
  }) => {
    const routeByType = {
      [EnterpriseType.ADMINISTRATOR]: 'admin',
      [EnterpriseType.PARTICULAR]: 'enterprise',
      [EnterpriseType.CONSULTANT]: 'consultant',
    };
    if (!window.location.pathname.includes(routeByType[enterpriseType]))
      navigate(routeByType[enterpriseType]);
  };

  const verifyUserAuth = async () => {
    const authRepository = new AuthRepository();
    try {
      const profile = await authRepository.getProfile();
      dispatch(setProfile(profile));
      verifyRoute({ enterpriseType: profile.enterprise.type });
      setLoading(false);
    } catch (err) {
      if (err instanceof UnauthorizedException) {
        const credentials = authRepository.getCredentials();
        if (!credentials) navigate('/auth/signin');
        else {
          await authRepository.signin(credentials);
          verifyUserAuth();
        }
      } else navigate('/auth/signin');
    }
  };

  useEffect(() => {
    verifyUserAuth();
  });

  const LoadingScreen = () => (
    <div className="flex items-center justify-center h-screen">
      <Spinner />
    </div>
  );

  return isLoading ? <LoadingScreen /> : <Outlet />;
};

export default PrivateRoute;
