import { RouteObject } from 'react-router-dom';
import SurveyConfigurationPage from './surveyConfiguration.page';
import pulseSurveyRoutes from './pulseSurvey/route';
import recognitionRoutes from './recognition/strength/route';

const surveyConfigurationRoutes: Array<RouteObject> = [
  {
    children: [
      { path: '', element: <SurveyConfigurationPage /> },
      { path: 'pulseSurvey', children: pulseSurveyRoutes },
      { path: 'recognition', children: recognitionRoutes },
    ],
  },
];

export default surveyConfigurationRoutes;
