import { Card, CardBody } from '@nextui-org/react';
import React from 'react';
import logo from '@assets/img/giveit.svg';
import { useTranslation } from 'react-i18next';

const ErrorMessage = () => {
  const { t } = useTranslation();
  return (
    <Card className="shadow-none lg:min-h-96">
      <CardBody className="gap-5 lg:justify-center lg:items-center">
        <div className="w-full flex justify-center items-center flex-col gap-4">
          <div className="flex items-center justify-center w-full">
            <img src={logo} alt="Give It logo" className="h-16 m-0 mr-4" />
            <span className="text-6xl font-bold text-primary">- 404</span>
          </div>
          <p className="text-center w-3/4">{t('user.document.error.text')}</p>
          <div className="text-xs">
            <p>{t('user.document.error.reasons.text')}</p>
            <ul className="m-0">
              <li>{t('user.document.error.reasons.reason1')}</li>
              <li>{t('user.document.error.reasons.reason2')}</li>
              <li>{t('user.document.error.reasons.reason3')}</li>
            </ul>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ErrorMessage;
