import IconProps from './common/icon.interface';

const FilterIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.0288 17.5C10.8163 17.5 10.6382 17.428 10.4945 17.2842C10.3507 17.1404 10.2789 16.9622 10.2789 16.7496C10.2789 16.537 10.3507 16.3589 10.4945 16.2154C10.6382 16.0718 10.8163 16 11.0288 16H12.9615C13.174 16 13.3521 16.0719 13.4959 16.2157C13.6396 16.3595 13.7115 16.5377 13.7115 16.7503C13.7115 16.9629 13.6396 17.141 13.4959 17.2846C13.3521 17.4282 13.174 17.5 12.9615 17.5H11.0288ZM7.15383 12.75C6.94134 12.75 6.76323 12.6781 6.61948 12.5343C6.47573 12.3904 6.40385 12.2122 6.40385 11.9997C6.40385 11.7871 6.47573 11.609 6.61948 11.4654C6.76323 11.3218 6.94134 11.25 7.15383 11.25H16.8365C17.049 11.25 17.2271 11.3219 17.3709 11.4657C17.5146 11.6095 17.5865 11.7877 17.5865 12.0003C17.5865 12.2129 17.5146 12.391 17.3709 12.5346C17.2271 12.6782 17.049 12.75 16.8365 12.75H7.15383ZM4.25 7.99998C4.0375 7.99998 3.85938 7.92807 3.71563 7.78425C3.57188 7.64045 3.5 7.46225 3.5 7.24965C3.5 7.03707 3.57188 6.85898 3.71563 6.7154C3.85938 6.5718 4.0375 6.5 4.25 6.5H19.75C19.9625 6.5 20.1406 6.57191 20.2843 6.71573C20.4281 6.85954 20.5 7.03774 20.5 7.25033C20.5 7.46293 20.4281 7.64102 20.2843 7.7846C20.1406 7.92818 19.9625 7.99998 19.75 7.99998H4.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilterIcon;
