import React from 'react';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PrivateProfile from './privateProfile';
import PublicProfile from './publicProfile';

const Profile = () => {
  const { id } = useParams();
  const user = useSelector(selectProfile);

  return user?.id === id ? <PrivateProfile /> : <PublicProfile />;
};

export default Profile;
