import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Skeleton,
  Tooltip,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import RocketsIndicator from './rocketsIndicator';
import favorite from '@assets/gif/favorite.gif';
import InfoIcon from '@components/icons/info.icon';
import LevelRepository from '../../level.repository';
import { UserTendencyDTO } from '../../dto/userTendency.dto';
import { useTranslation } from 'react-i18next';

const TrendingUsers = () => {
  const { t } = useTranslation();
  const [levelRepository] = useState(new LevelRepository());
  const [trendingUsers, setTrendingUsers] = useState<UserTendencyDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const getTendency = async () => {
    setIsLoading(true);
    const users = await levelRepository.findTendency();
    setTrendingUsers(users);
    setIsLoading(false);
  };

  useEffect(() => {
    getTendency();
  }, []);

  return (
    <Card className="shadow-none lg:h-full lg:w-[55%]">
      <CardHeader className="font-bold justify-between pb-8">
        <div className="flex gap-2 items-center">
          <p>{t('user.level.trending.title')}</p>
          <Tooltip
            content={
              <div className="text-xs max-w-40">
                {t('user.level.trending.tooltip')}
              </div>
            }
            isOpen={infoOpen}
            onOpenChange={(open) => setInfoOpen(open)}
            showArrow={true}
            placement="right"
          >
            <Button
              isIconOnly
              variant="light"
              size="sm"
              color="primary"
              onPress={() => setInfoOpen(!infoOpen)}
            >
              <InfoIcon className="text-primary" />
            </Button>
          </Tooltip>
        </div>
        <img src={favorite} alt="Favorite" className="w-8 m-0" />
      </CardHeader>
      <CardBody
        className={`flex-row-reverse gap-2 pt-0 overflow-visible ${trendingUsers.length ? 'items-end ' : ''}`}
      >
        {isLoading ? (
          <>
            <Skeleton className="w-1/3 h-full rounded-lg" />
            <Skeleton className="w-1/3 h-full rounded-lg" />
            <Skeleton className="w-1/3 h-full rounded-lg" />
          </>
        ) : trendingUsers.length ? (
          trendingUsers.map((user, index) => (
            <Card
              key={index}
              className={`overflow-visible w-1/3 shadow-none border-3 bg-primary-50 ${index === 0 ? 'border-primary-300 h-64 lg:h-full' : ''} ${index === 1 ? 'border-primary-100 h-60 lg:h-[90%]' : ''} ${index === 2 ? 'border-primary-100 h-56 lg:h-[80%]' : ''}`}
            >
              <CardBody className="relative pt-5 text-center items-center gap-2 justify-between text-sm overflow-visible">
                <Avatar
                  src={`https://giveit-system-assets.s3.amazonaws.com/${user.avatar}`}
                  className="absolute -top-7"
                />
                <div>
                  <p className="font-bold">
                    {user.firstName} {user.lastName}
                  </p>
                  <p className="text-xs">{user.position}</p>
                </div>
                <RocketsIndicator amount={user.rockets} />
                <div>
                  <p>x{user.rocketsInADay}</p>
                  <p className="text-xs">
                    {t('user.level.trending.amountOfRockets')}
                  </p>
                </div>
              </CardBody>
            </Card>
          ))
        ) : (
          <p className="text-center w-full">
            {t('user.level.trending.noUsers')}
          </p>
        )}
      </CardBody>
    </Card>
  );
};

export default TrendingUsers;
