import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PulseSurveyForm from '@modules/voteModule/pulse/pulseSurvey/components/pulseSurvey.form';
import PulseSurveyDTO from '@modules/voteModule/pulse/pulseSurvey/dto/pulseSurvey.dto';
import UpdatePulseSurveyDTO from '@modules/voteModule/pulse/pulseSurvey/dto/updatePulseSurvey.dto';
import PulseSurveyRepository from '@modules/voteModule/pulse/pulseSurvey/pulseSurvey.repository';
import { Spinner } from '@nextui-org/react';
import CompareUtil from '@utils/compare';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const PulseSurveyEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pulseSurveyResult, setPulseSurveyResult] =
    useState<PulseSurveyDTO | null>(null);

  const handleSubmit = async (values: UpdatePulseSurveyDTO) => {
    if (!pulseSurveyResult) return;
    await new PulseSurveyRepository().update(pulseSurveyResult?.id as string, {
      ...(CompareUtil.compareObjects(
        pulseSurveyResult,
        values,
      ) as UpdatePulseSurveyDTO),
      categories: values.categories,
    });
    navigate(-1);
  };

  const getPulseSurvey = async (options?: FindOptionsDTO<PulseSurveyDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const pulseSurveyResult = await new PulseSurveyRepository().findOne(
      id as string,
      {
        ...options,
        relations: Object.assign(options?.relations || [], ['categories']),
      },
    );
    if (!pulseSurveyResult) navigate(-1);
    setPulseSurveyResult(pulseSurveyResult);
    setLoading(false);
  };

  useEffect(() => {
    getPulseSurvey();
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  return isLoading || !pulseSurveyResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.surveys.pulse.edit')}
      </h3>
      <PulseSurveyForm
        onSubmit={handleSubmit}
        initialValues={pulseSurveyResult}
      />
    </div>
  );
};

export default PulseSurveyEditPage;
