import logo from '@assets/img/giveit.svg';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full h-screen flex justify-center items-center flex-col gap-4">
      <div className="flex items-center justify-center w-full">
        <img src={logo} alt="Give It logo" className="h-16 m-0 mr-4" />
        <span className="text-6xl font-bold text-primary">- 404</span>
      </div>
      <p className="text-center w-3/4">{t('error.404')}</p>
      <Button color="primary" onClick={() => navigate('/')}>
        {t('error.goBack')}
      </Button>
    </div>
  );
};

export default ErrorPage;
