import React from 'react';
import RecognitionVoteDTO from '@modules/voteModule/recognition/recognitionVote/dto/recognitionVote.dto';
import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StrengthMostVotedMetricProps {
  recognitions: RecognitionVoteDTO[];
  className?: string;
}

const StrengthMostVotedMetric = ({
  recognitions,
  className,
}: StrengthMostVotedMetricProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [mostVotedStrength, setMostVotedStrength] = useState<string>('');

  const getMostCommonVotedStrength = (votes: RecognitionVoteDTO[]) => {
    const strengths: { [key: string]: number } = {};

    votes.map((vote) => {
      strengths[vote.strength.strength]
        ? strengths[vote.strength.strength]++
        : (strengths[vote.strength.strength] = 1);
    });

    let mostVotedStrengthName: string = '-';
    let maxVotes = 0;

    Object.entries(strengths).forEach(([strength, count]) => {
      if (count > maxVotes) {
        mostVotedStrengthName = strength;
        maxVotes = count;
      }
    });

    return mostVotedStrengthName;
  };

  const calculateStrength = async () => {
    setLoading(true);
    const mostVotedStrengthResult = getMostCommonVotedStrength(recognitions);
    setMostVotedStrength(mostVotedStrengthResult);
    setLoading(false);
  };

  useEffect(() => {
    calculateStrength();
  }, [recognitions]);

  return (
    <Card className={`${className}`}>
      <CardBody>
        <span className="text-default-600 text-sm">
          {t('entities.metric.recognition.rockets.strength.description')}
        </span>
        <span className="font-bold text-lg">
          {t('entities.metric.recognition.rockets.strength.title')}
        </span>
        <Skeleton isLoaded={!isLoading}>
          <span className={`text-xl text-primary`}>{mostVotedStrength}</span>
        </Skeleton>
      </CardBody>
    </Card>
  );
};

export default StrengthMostVotedMetric;
