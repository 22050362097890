/* eslint-disable @typescript-eslint/no-unused-vars */
import CustomBlockNote, {
  validateBlockNote,
} from '@components/customBlockNote/customBlockNote.component';
import { Button, Spinner, User } from '@nextui-org/react';
import React, { useEffect, useMemo, useState } from 'react';
import CommentDTO from '../dto/comment.dto';
import { BlockNoteEditor } from '@blocknote/core';
import {
  CustomPartialBlock,
  customSchema,
} from '@components/customBlockNote/customSchema';
import { useCreateBlockNote } from '@blocknote/react';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import CommentRepository from '../comment.repository';

interface CreateCommentProps {
  refresh: () => void;
  objective?: string;
  keyResult?: string;
  data?: CommentDTO;
}

const CreateComment = ({
  refresh,
  objective,
  keyResult,
  data,
}: CreateCommentProps) => {
  const profile = useSelector(selectProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [initialContent, setInitialContent] = useState<
    CustomPartialBlock[] | 'loading' | null | undefined
  >('loading');
  const [avatar, setAvatar] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blocknoteError, setBlocknoteError] = useState(false);

  const editor = useMemo(() => {
    if (initialContent === 'loading') {
      return undefined;
    }
    if (initialContent === null) {
      return BlockNoteEditor.create({ schema: customSchema });
    }
    return BlockNoteEditor.create({
      initialContent,
      schema: customSchema,
    });
  }, [initialContent]);

  const functionalEditor = useCreateBlockNote({ schema: customSchema });

  const loadData = async () => {
    setIsLoading(true);
    if (data) {
      const blocks = await functionalEditor.tryParseHTMLToBlocks(data.content);
      setInitialContent(blocks);
      setAvatar(data.user.avatar);
    } else {
      setInitialContent(null);
      if (profile?.avatar) setAvatar(profile.avatar);
    }
    setIsLoading(false);
  };

  if (editor) editor.onEditorContentChange(() => setBlocknoteError(false));

  const submitComment = async () => {
    setIsSubmitting(true);
    if (!editor) return setIsSubmitting(false);
    if (validateBlockNote(editor)) {
      setBlocknoteError(true);
      setIsSubmitting(false);
      return;
    }
    const content = await editor.blocksToHTMLLossy();
    if (data) {
      await new CommentRepository().updateComment({ content }, data.id);
    } else {
      await new CommentRepository().save({
        content,
        parent: null,
        keyResult: keyResult ? { id: keyResult } : undefined,
        objective: objective ? { id: objective } : undefined,
      });
    }
    editor.removeBlocks(editor.document);
    refresh();
    setIsSubmitting(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="py-10 flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="space-y-2">
          <User
            name={
              data
                ? data.user.fullName
                : profile?.firstName + ' ' + profile?.lastName
            }
            description={data ? data.user.email : profile?.email}
            avatarProps={{
              color: !data || data.id === profile?.id ? 'primary' : 'secondary',
              isBordered: !data || data.id === profile?.id,
              src:
                avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${avatar}`,
            }}
          />
          {editor && <CustomBlockNote editor={editor} error={blocknoteError} />}
          <div className="flex justify-end">
            <Button
              color="primary"
              isLoading={isSubmitting}
              onPress={submitComment}
            >
              {data ? 'Editar comentario' : 'Comentar'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateComment;
