import FindOptionsDTO from 'lib/paginated/findOptions.dto';
import PaginatedDTO from 'lib/paginated/paginated.dto';
import axios from 'axios';
import UserFileDTO from './dto/userFile.dto';
import UpdateUserFileDTO from './dto/updateUserFile.dto';

export interface UserEventDTO {
  eventDate: string;
  address: string;
  avatar: string | null;
  birthDate: string;
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  jobTitle: string;
  hireDate: string;
  userId: string;
}

export default class UserFileRepository {
  private url: string = '/api/userFile';

  async find(
    options?: FindOptionsDTO<UserFileDTO>,
  ): Promise<PaginatedDTO<UserFileDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<PaginatedDTO<UserFileDTO>>(
      `${this.url}${stringOption}`,
    );
    return userResponse.data;
  }

  async getNextBirthDates(
    startDate: string,
    endDate: string,
  ): Promise<UserEventDTO[]> {
    const userFileResponse = await axios.get<UserEventDTO[]>(
      `${this.url}/getNextBirthDates?startDate=${startDate}&endDate=${endDate}`,
    );
    return userFileResponse.data;
  }

  async getNextHireDates(
    startDate: string,
    endDate: string,
  ): Promise<UserEventDTO[]> {
    const userFileResponse = await axios.get<UserEventDTO[]>(
      `${this.url}/getNextHireDates?startDate=${startDate}&endDate=${endDate}`,
    );
    return userFileResponse.data;
  }

  async getNextEvents(
    startDate: string,
    endDate: string,
  ): Promise<UserEventDTO[]> {
    const userFileResponse = await axios.get<UserEventDTO[]>(
      `${this.url}/getNextEvents?startDate=${startDate}&endDate=${endDate}`,
    );
    return userFileResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<UserFileDTO>,
  ): Promise<UserFileDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<UserFileDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return userResponse.data;
  }

  async update(id: string, changes: UpdateUserFileDTO) {
    const updatedUserResponse = await axios.put<UserFileDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedUserResponse.data;
  }

  async delete(id: string) {
    const deletedUserResponse = await axios.delete<UserFileDTO>(
      `${this.url}/${id}`,
    );
    return deletedUserResponse.data;
  }
}
