import CheckIcon from '@components/icons/check.icon';
import LockIcon from '@components/icons/lock.icon';
import OpenLockIcon from '@components/icons/openLock.icon';
import { Card, CardBody } from '@nextui-org/react';
import React, { useState } from 'react';
import { LevelDTO } from '../../dto/level.dto';
import { useTranslation } from 'react-i18next';

interface LevelListProps {
  levels: LevelDTO[];
}

const LevelList = ({ levels }: LevelListProps) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(
    levels.findIndex((level) => level.state === 'current'),
  );

  return (
    <Card className="shadow-none h-52 lg:h-1/3">
      <CardBody className="overflow-x-hidden">
        <div className="relative h-full w-full flex gap-2 items-center">
          {levels.map((level, index) => (
            <div
              key={level.number}
              onClick={() => setActiveIndex(index)}
              className={`cursor-pointer shadow-md rounded-2xl absolute inset-0 text-nowrap top-1/2 -translate-y-1/2 flex-shrink-0 ${index === activeIndex ? 'h-full w-[60%] left-1/2 -translate-x-1/2 z-40' : ''} ${index === activeIndex + 1 ? 'w-fit text-sm h-4/5 left-[100%] lg:left-[90%] -translate-x-full z-30' : ''} ${index === activeIndex + 2 ? 'w-fit hidden lg:block text-xs h-2/3 left-full -translate-x-full z-20' : ''} ${index === activeIndex - 1 ? 'w-fit text-sm h-4/5 lg:left-[10%] z-30' : ''} ${index === activeIndex - 2 ? 'w-fit hidden lg:block text-xs h-2/3 z-20' : ''} ${index > activeIndex + 2 || index < activeIndex - 2 ? 'hidden' : ''}`}
              style={{
                transition: 'left 0.3s, right 0.3s, height 0.3s, width 0.3s',
              }}
            >
              {level.state === 'achieved' && (
                <Card className="shadow-none border-2 border-success-100 bg-success-50 h-full">
                  <CardBody className="items-center px-8 justify-evenly">
                    <div className="text-success flex items-center gap-1">
                      <CheckIcon className="h-4" />
                      <span>{t('user.level.levelList.unlocked')}</span>
                    </div>
                    <p className="font-bold text-xl">
                      {t('user.level.level', { number: level.number })}
                    </p>
                    <p>
                      {t('user.level.levelList.objective', {
                        number: level.rockets,
                      })}
                    </p>
                  </CardBody>
                </Card>
              )}
              {level.state === 'current' && (
                <Card className="shadow-none border-2 border-primary-100 bg-primary-50 h-full">
                  <CardBody className="items-center px-8 justify-evenly">
                    <div className="flex items-center gap-2">
                      <OpenLockIcon className="h-4" />
                      <span>{t('user.level.levelList.current')}</span>
                    </div>
                    <p className="font-bold text-xl text-primary">
                      {t('user.level.level', { number: level.number })}
                    </p>
                    <p>
                      {t('user.level.levelList.objective', {
                        number: level.rockets,
                      })}
                    </p>
                  </CardBody>
                </Card>
              )}
              {level.state === 'blocked' && (
                <Card className="shadow-none border-2 border-primary-100 bg-primary-50 h-full">
                  <CardBody className="items-center px-8 text-default-500 justify-evenly">
                    <div className="flex items-center gap-2">
                      <LockIcon className="h-4" />
                      <span>{t('user.level.levelList.locked')}</span>
                    </div>
                    <p className="font-bold text-xl">
                      {t('user.level.level', { number: level.number })}
                    </p>
                    <p>
                      {t('user.level.levelList.objective', {
                        number: level.rockets,
                      })}
                    </p>
                  </CardBody>
                </Card>
              )}
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default LevelList;
