import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import PaginatedDTO from '@lib/paginated/paginated.dto';
import LicenseRequestDTO from './dto/licenseRequest.dto';
import axios from 'axios';
import CreateLicenseRequestDTO from './dto/createLicenseRequest.dto';
import UpdateLicenseRequestDTO from './dto/updateLicenseRequest.dto';

export default class LicenseRequestRepository {
  private url: string = '/api/licenseRequest';

  async find(
    options?: FindOptionsDTO<LicenseRequestDTO>,
  ): Promise<PaginatedDTO<LicenseRequestDTO>> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<PaginatedDTO<LicenseRequestDTO>>(
      `${this.url}${stringOption}`,
    );
    return userResponse.data;
  }

  async findOne(
    id: string,
    options?: FindOptionsDTO<LicenseRequestDTO>,
  ): Promise<LicenseRequestDTO> {
    let stringOption = '';
    if (options)
      stringOption = `?options=${encodeURIComponent(JSON.stringify(options))}`;
    const userResponse = await axios.get<LicenseRequestDTO>(
      `${this.url}/${id}${stringOption}`,
    );
    return userResponse.data;
  }

  async save(user: CreateLicenseRequestDTO) {
    const createdUserResponse = await axios.post<LicenseRequestDTO>(
      `${this.url}`,
      user,
    );
    return createdUserResponse.data;
  }

  async update(id: string, changes: UpdateLicenseRequestDTO) {
    const updatedUserResponse = await axios.put<LicenseRequestDTO>(
      `${this.url}/${id}`,
      changes,
    );
    return updatedUserResponse.data;
  }

  async delete(id: string) {
    const deletedUserResponse = await axios.delete<LicenseRequestDTO>(
      `${this.url}/${id}`,
    );
    return deletedUserResponse.data;
  }
}
