import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateInitiativeDTO from './dto/createInitiative.dto';
import { Form, Formik, FormikProps } from 'formik';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Textarea,
  User,
} from '@nextui-org/react';
import { useState } from 'react';
import ObjectiveUserDTO from '../objectiveUser/dto/objectiveUser.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ObjectiveUserRepository from '../objectiveUser/objectiveUser.repository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface InitiativeFormProps {
  initialValues?: CreateInitiativeDTO;
  onSubmit: (values: CreateInitiativeDTO) => void | Promise<void>;
}

export const initialInitiativeFormValues: CreateInitiativeDTO = {
  name: '',
  description: '',
  keyResult: { id: '' },
  manager: null,
};

const InitiativeForm = ({
  initialValues = initialInitiativeFormValues,
  onSubmit,
}: InitiativeFormProps) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<ObjectiveUserDTO[]>([]);
  const getUsers = async (options?: FindOptionsDTO<ObjectiveUserDTO>) => {
    const users = await new ObjectiveUserRepository().find(options);
    setUsers(users.results);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateInitiativeDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <Input
            name="name"
            label={t('entities.initiative.fields.name')}
            isInvalid={Boolean(errors.name)}
            errorMessage={errors.name}
            color={errors.name ? 'danger' : 'default'}
            onChange={(e) => setFieldValue('name', e.target.value)}
            value={values.name}
          />
          <Textarea
            name="description"
            label={t('entities.initiative.fields.description')}
            isInvalid={Boolean(errors.description)}
            errorMessage={errors.description}
            color={errors.description ? 'danger' : 'default'}
            onChange={(e) => setFieldValue('description', e.target.value)}
            value={values.description}
            minRows={4}
          />
          <Autocomplete
            items={users}
            label={t('entities.keyResult.fields.manager')}
            startContent={<FontAwesomeIcon icon={faSearch} />}
            onSelectionChange={(e) =>
              setFieldValue('manager', e ? { id: e } : null)
            }
            selectedKey={values.manager?.id || null}
            isInvalid={Boolean(errors.manager)}
            errorMessage={errors.manager}
            color={errors.manager ? 'danger' : 'default'}
            onFocus={() => {
              getUsers();
            }}
            onInputChange={(e) => {
              getUsers({ where: { fullName: `lk=${e}` } });
            }}
          >
            {(item) => (
              <AutocompleteItem key={item.id} textValue={item.fullName}>
                <User
                  name={item.fullName}
                  description={item.email}
                  avatarProps={{
                    src:
                      item?.avatar &&
                      `https://giveit-system-assets.s3.amazonaws.com/${item?.avatar}`,
                  }}
                />
              </AutocompleteItem>
            )}
          </Autocomplete>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default InitiativeForm;
