import IconProps from './common/icon.interface';

const FullscreenIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      id="fullscreen"
      className={className}
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path
        d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default FullscreenIcon;
