import React from 'react';
import ObjectiveUserDTO from '@modules/objectiveModule/objectiveUser/dto/objectiveUser.dto';
import CreateKeyResultDTO from '../dto/createKeyResult.dto';
import CategoryDTO from '@modules/objectiveModule/category/dto/category.dto';
import KeyResultStatus from '../enum/keyResultStatus.enum';
import KeyResultMeasurementType from '../enum/keyResultMeasurementType.enum';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import ObjectiveUserRepository from '@modules/objectiveModule/objectiveUser/objectiveUser.repository';
import CategoryRepository from '@modules/objectiveModule/category/category.repository';
import CreateCategoryDTO from '@modules/objectiveModule/category/dto/createCategory.dto';
import { Form, Formik, FormikProps } from 'formik';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Card,
  CardBody,
  Chip,
  Input,
  Select,
  SelectItem,
  Textarea,
  User,
} from '@nextui-org/react';
import DateUtil from '@utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import keyResultFormValidation from '../validation/keyResultForm.validation';

interface CreateKeyResultForm extends CreateKeyResultDTO {
  colaborators: ObjectiveUserDTO[];
  notified: ObjectiveUserDTO[];
  categories: CategoryDTO[];
}

interface KeyResultFormProps {
  initialValues?: CreateKeyResultForm;
  onAddAnother?: (values: CreateKeyResultForm) => void | Promise<void>;
  onSubmit: (values: CreateKeyResultForm) => void | Promise<void>;
}

const KeyResultForm = ({
  initialValues = {
    name: '',
    description: '',
    periodFrom: new Date(),
    periodTo: new Date(),
    manager: null,
    status: KeyResultStatus.ON_TIME,
    measurementType: KeyResultMeasurementType.NUMERIC,
    initialValue: 0,
    expectedValue: 0,
    colaborators: [],
    notified: [],
    categories: [],
  },
  onSubmit,
  onAddAnother,
}: KeyResultFormProps) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<ObjectiveUserDTO[]>([]);
  const [usersCollaborators, setUsersCollaborators] = useState<
    ObjectiveUserDTO[]
  >([]);
  const [usersNotified, setUsersNotified] = useState<ObjectiveUserDTO[]>([]);
  const [categories, setCategories] = useState<CategoryDTO[]>([]);

  const getUsers = async (options?: FindOptionsDTO<ObjectiveUserDTO>) => {
    const users = await new ObjectiveUserRepository().find(options);
    setUsers(users.results);
  };

  const getUsersCollaborators = async (
    options?: FindOptionsDTO<ObjectiveUserDTO>,
  ) => {
    const users = await new ObjectiveUserRepository().find(options);
    setUsersCollaborators(users.results);
  };

  const getUsersNotified = async (
    options?: FindOptionsDTO<ObjectiveUserDTO>,
  ) => {
    const users = await new ObjectiveUserRepository().find(options);
    setUsersNotified(users.results);
  };

  const getCategories = async (options?: FindOptionsDTO<CategoryDTO>) => {
    const categoriesResult = await new CategoryRepository().find(options);
    setCategories(categoriesResult.results);
  };

  const saveCategory = async (newCategory: CreateCategoryDTO) => {
    const categoryCreated = await new CategoryRepository().save(newCategory);
    return categoryCreated;
  };

  const handlerSearcher = async (text: string) => {
    const categoriesResult = await new CategoryRepository().find({
      where: {
        name: `lk=${text}`,
      },
    });
    const categories = categoriesResult.results;
    if (
      !categories.some((category) => category.name.includes(text)) &&
      text.length > 3
    ) {
      categories.push({ name: '+ ' + text } as CategoryDTO);
    }
    setCategories(categories);
  };

  useEffect(() => {
    getUsers();
    getCategories();
  }, []);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={keyResultFormValidation}
      onSubmit={(args) => {
        onSubmit(args);
      }}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
        resetForm,
      }: FormikProps<CreateKeyResultForm>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex flex-col gap-5">
              <Input
                name="name"
                label={t('entities.keyResult.fields.name')}
                isInvalid={Boolean(errors.name)}
                errorMessage={errors.name}
                color={errors.name ? 'danger' : 'default'}
                onChange={(e) => setFieldValue('name', e.target.value)}
                value={values.name}
              />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-5">
                <Input
                  name="periodFrom"
                  label={t('entities.keyResult.fields.periodFrom')}
                  type="date"
                  isInvalid={Boolean(errors.periodFrom)}
                  errorMessage={String(errors.periodFrom || '')}
                  color={errors.periodFrom ? 'danger' : 'default'}
                  onChange={(e) => {
                    setFieldValue('periodFrom', e.target.value);
                  }}
                  value={values.periodFrom?.toString()}
                />
                <Input
                  name="periodTo"
                  label={t('entities.keyResult.fields.periodTo')}
                  type="date"
                  isInvalid={Boolean(errors.periodTo)}
                  errorMessage={String(errors.periodTo || '')}
                  color={errors.periodTo ? 'danger' : 'default'}
                  onChange={(e) => setFieldValue('periodTo', e.target.value)}
                  value={values.periodTo?.toString()}
                />
              </div>
              <div className="flex justify-center gap-3">
                <Button
                  size="sm"
                  color="primary"
                  variant="flat"
                  onClick={() => {
                    const periodFrom = new Date();
                    periodFrom.setDate(1);
                    periodFrom.setMonth(0);
                    const periodTo = new Date();
                    periodTo.setMonth(3);
                    periodTo.setDate(1);
                    periodTo.setDate(0);
                    setFieldValue(
                      'periodFrom',
                      DateUtil.dateForInput(periodFrom),
                    );
                    setFieldValue('periodTo', DateUtil.dateForInput(periodTo));
                  }}
                >
                  Q1
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  variant="flat"
                  onClick={() => {
                    const periodFrom = new Date();
                    periodFrom.setDate(1);
                    periodFrom.setMonth(3);
                    const periodTo = new Date();
                    periodTo.setMonth(6);
                    periodTo.setDate(1);
                    periodTo.setDate(0);
                    setFieldValue(
                      'periodFrom',
                      DateUtil.dateForInput(periodFrom),
                    );
                    setFieldValue('periodTo', DateUtil.dateForInput(periodTo));
                  }}
                >
                  Q2
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  variant="flat"
                  onClick={() => {
                    const periodFrom = new Date();
                    periodFrom.setDate(1);
                    periodFrom.setMonth(6);
                    const periodTo = new Date();
                    periodTo.setMonth(9);
                    periodTo.setDate(1);
                    periodTo.setDate(0);
                    setFieldValue(
                      'periodFrom',
                      DateUtil.dateForInput(periodFrom),
                    );
                    setFieldValue('periodTo', DateUtil.dateForInput(periodTo));
                  }}
                >
                  Q3
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  variant="flat"
                  onClick={() => {
                    const periodFrom = new Date();
                    periodFrom.setDate(1);
                    periodFrom.setMonth(9);
                    const periodTo = new Date();
                    periodTo.setMonth(12);
                    periodTo.setDate(1);
                    periodTo.setDate(0);
                    setFieldValue(
                      'periodFrom',
                      DateUtil.dateForInput(periodFrom),
                    );
                    setFieldValue('periodTo', DateUtil.dateForInput(periodTo));
                  }}
                >
                  Q4
                </Button>
              </div>
            </div>
          </div>
          <Textarea
            name="description"
            label={t('entities.keyResult.fields.description')}
            isInvalid={Boolean(errors.description)}
            errorMessage={errors.description}
            color={errors.description ? 'danger' : 'default'}
            onChange={(e) => setFieldValue('description', e.target.value)}
            value={values.description}
            minRows={4}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <Autocomplete
              items={users}
              label={t('entities.keyResult.fields.manager')}
              startContent={<FontAwesomeIcon icon={faSearch} />}
              onSelectionChange={(e) =>
                setFieldValue('manager', e ? { id: e } : null)
              }
              selectedKey={values.manager?.id || null}
              isInvalid={Boolean(errors.manager)}
              errorMessage={errors.manager}
              color={errors.manager ? 'danger' : 'default'}
              onFocus={() => {
                getUsers();
              }}
              onInputChange={(e) => {
                getUsers({ where: { fullName: `lk=${e}` } });
              }}
            >
              {(item) => (
                <AutocompleteItem key={item.id} textValue={item.fullName}>
                  <User
                    name={item.fullName}
                    description={item.email}
                    avatarProps={{
                      src:
                        item?.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${item?.avatar}`,
                    }}
                  />
                </AutocompleteItem>
              )}
            </Autocomplete>
            <div>
              <Autocomplete
                items={categories}
                label={t('entities.keyResult.fields.categories')}
                startContent={<FontAwesomeIcon icon={faSearch} />}
                onInputChange={(e) => handlerSearcher(e)}
              >
                {(item) => (
                  <AutocompleteItem
                    key={item.id || item.name}
                    onClick={async () => {
                      if (!item.id) {
                        item.name = item.name.substring(2);
                        item = await saveCategory(item);
                      }

                      if (
                        !values.categories.some(
                          (category) => category.id === item.id,
                        )
                      ) {
                        values.categories.push({ ...item });
                        setFieldValue('categories', values.categories);
                      }
                    }}
                    textValue={item.name}
                  >
                    {/*item.id ? (
                      item.name
                    ) : (
                      <div className="flex gap-5 items-center">
                        <FontAwesomeIcon icon={faPlus} color="primary" />
                      </div>
                    )*/}
                    {item.name}
                  </AutocompleteItem>
                )}
              </Autocomplete>
              <div className="flex gap-3 pt-3">
                {values.categories.map((category, index) => {
                  return (
                    <Chip
                      key={category.id}
                      color="primary"
                      onClose={() => {
                        values.categories.splice(index, 1);
                        setFieldValue('categories', values.categories);
                      }}
                    >
                      {category.name}
                    </Chip>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <Select
              label={t('entities.keyResult.fields.status')}
              selectedKeys={new Set([values.status])}
              onSelectionChange={(keys) => {
                setFieldValue('status', new Set(keys).values().next().value);
              }}
            >
              {Object.keys(KeyResultStatus).map((status) => (
                <SelectItem key={status} value={status}>
                  {t(`entities.keyResult.values.status.${status}`)}
                </SelectItem>
              ))}
            </Select>
            <Select
              label={t('entities.keyResult.fields.measurementType')}
              selectedKeys={new Set([values.measurementType])}
              onSelectionChange={(keys) => {
                setFieldValue(
                  'measurementType',
                  new Set(keys).values().next().value,
                );
              }}
            >
              {Object.keys(KeyResultMeasurementType).map((measurementType) => (
                <SelectItem key={measurementType} value={measurementType}>
                  {t(
                    `entities.keyResult.values.measurementType.${measurementType}`,
                  )}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <Input
              name="initialValue"
              label={t('entities.keyResult.fields.initialValue')}
              type="number"
              isInvalid={Boolean(errors.initialValue)}
              errorMessage={String(errors.initialValue || '')}
              color={errors.initialValue ? 'danger' : 'default'}
              onChange={(e) => {
                setFieldValue('initialValue', Number(e.target.value));
              }}
              value={values.initialValue?.toString()}
            />
            <Input
              name="expectedValue"
              label={t('entities.keyResult.fields.expectedValue')}
              type="number"
              isInvalid={Boolean(errors.expectedValue)}
              errorMessage={String(errors.expectedValue || '')}
              color={errors.expectedValue ? 'danger' : 'default'}
              onChange={(e) => {
                setFieldValue('expectedValue', Number(e.target.value));
              }}
              value={values.expectedValue?.toString()}
            />
          </div>
          <div className="grid grid-cols-1 gap-5">
            <Autocomplete
              items={usersCollaborators}
              label={t('entities.keyResult.fields.colaborators')}
              startContent={<FontAwesomeIcon icon={faSearch} />}
              onFocus={() => {
                getUsersCollaborators();
              }}
              onInputChange={(e) => {
                getUsersCollaborators({ where: { fullName: `lk=${e}` } });
              }}
            >
              {(item) => (
                <AutocompleteItem
                  key={item.id}
                  onClick={() => {
                    if (
                      !values.colaborators.some(
                        (colaboratorsUser) => colaboratorsUser.id === item.id,
                      )
                    ) {
                      values.colaborators.push({ ...item });
                      setFieldValue('colaborators', values.colaborators);
                    }
                  }}
                  textValue={item.fullName}
                >
                  <User
                    name={item.fullName}
                    description={item.email}
                    avatarProps={{
                      src:
                        item?.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${item?.avatar}`,
                    }}
                  />
                </AutocompleteItem>
              )}
            </Autocomplete>
            <div className="flex gap-3">
              {values.colaborators.map((colaboratorsUser, index) => (
                <Card key={colaboratorsUser.id}>
                  <CardBody>
                    <div className="flex gap-3 items-center">
                      <User
                        name={colaboratorsUser.fullName}
                        description={colaboratorsUser.email}
                        avatarProps={{
                          src:
                            colaboratorsUser?.avatar &&
                            `https://giveit-system-assets.s3.amazonaws.com/${colaboratorsUser?.avatar}`,
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="cursor-pointer"
                        onClick={() => {
                          values.colaborators.splice(index, 1);
                          setFieldValue('colaborators', values.colaborators);
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5">
            <Autocomplete
              items={usersNotified}
              label={t('entities.keyResult.fields.notified')}
              onFocus={() => {
                getUsersNotified();
              }}
              onInputChange={(e) => {
                getUsersNotified({ where: { fullName: `lk=${e}` } });
              }}
              startContent={<FontAwesomeIcon icon={faSearch} />}
            >
              {(item) => (
                <AutocompleteItem
                  key={item.id}
                  onClick={() => {
                    if (
                      !values.notified.some(
                        (notifiedUser) => notifiedUser.id === item.id,
                      )
                    ) {
                      values.notified.push({ ...item });
                      setFieldValue('notified', values.notified);
                    }
                  }}
                  textValue={item.fullName}
                >
                  <User
                    name={item.fullName}
                    description={item.email}
                    avatarProps={{
                      src:
                        item?.avatar &&
                        `https://giveit-system-assets.s3.amazonaws.com/${item?.avatar}`,
                    }}
                  />
                </AutocompleteItem>
              )}
            </Autocomplete>
            <div className="flex gap-3">
              {values.notified.map((notifiedUser, index) => (
                <Card key={notifiedUser.id}>
                  <CardBody>
                    <div className="flex gap-3 items-center">
                      <User
                        name={notifiedUser.fullName}
                        description={notifiedUser.email}
                        avatarProps={{
                          src:
                            notifiedUser?.avatar &&
                            `https://giveit-system-assets.s3.amazonaws.com/${notifiedUser?.avatar}`,
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="cursor-pointer"
                        onClick={() => {
                          values.notified.splice(index, 1);
                          setFieldValue('notified', values.notified);
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
          </div>
          <div
            className={`grid ${onAddAnother ? 'grid-cols-2' : 'grid-cols-1'} gap-5`}
          >
            {onAddAnother && (
              <Button
                variant="flat"
                color="primary"
                isLoading={isSubmitting}
                onClick={() => {
                  onAddAnother(values);
                  resetForm();
                  setFieldValue('colaborators', []);
                  setFieldValue('categories', []);
                  setFieldValue('notified', []);
                }}
              >
                {t('signup.form.addAnother')}
              </Button>
            )}
            <Button type="submit" color="primary" isLoading={isSubmitting}>
              {t('signup.form.finish')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default KeyResultForm;
