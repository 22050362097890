import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import CreateObjectiveDTO from './dto/createObjective.dto';
import { RootState } from 'store';
import CreateKeyResultDTO from '../keyResult/dto/createKeyResult.dto';

export interface ObjectiveState {
  objective: CreateObjectiveDTO | null;
}

const initialState: ObjectiveState = {
  objective: null,
};

export const objectiveSlice = createSlice({
  name: 'objective',
  initialState,
  reducers: {
    setObjective: (
      state: ObjectiveState,
      action: PayloadAction<CreateObjectiveDTO>,
    ) => {
      state.objective = action.payload;
    },
    addKeyResults: (
      state: ObjectiveState,
      action: PayloadAction<CreateKeyResultDTO>,
    ) => {
      if (state.objective) {
        if (!state.objective.keyResults) state.objective.keyResults = [];
        state.objective.keyResults.push(action.payload);
      }
    },
    deleteKeyResult: (state: ObjectiveState, action: PayloadAction<number>) => {
      if (state.objective) {
        if (!state.objective.keyResults) state.objective.keyResults = [];
        state.objective.keyResults.splice(action.payload, 1);
      }
    },
    clear: (state: ObjectiveState) => {
      state.objective = null;
    },
  },
});

export const { setObjective, addKeyResults, deleteKeyResult, clear } =
  objectiveSlice.actions;

export const getObjective = (state: RootState) =>
  state.objectiveReducer.objective;

export default objectiveSlice.reducer;
