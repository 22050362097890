import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RecognitionAverage from './recognitionAverage';
import DateUtil from '@utils/date';
import ParticipationMetric from './participation';
import StrengthMostVotedMetric from './strengthMostVoted';
import DeliveryRockets from './deliveryRockets';
import StrengthRadar from './strengthRadar';
import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { useMetricFiltersContext } from '@modules/metricModule/context/metricFilters.context';
import RocketsPerTeam from './rocketsPerTeam';
import RecognitionVoteRepository from '@modules/voteModule/recognition/recognitionVote/recognitionVote.repository';
import RecognitionVoteDTO from '@modules/voteModule/recognition/recognitionVote/dto/recognitionVote.dto';

const RecognitionMetric = () => {
  const { t } = useTranslation();
  const { filters } = useMetricFiltersContext();
  const period = filters.quarter;
  const periodDates = DateUtil.quarterToDate(period);
  const [recognitions, setRecognitions] = useState<RecognitionVoteDTO[]>([]);

  const getRecognitions = async () => {
    const recognitionsResults = await new RecognitionVoteRepository().find({
      where: {
        createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
      },
      relations: ['to', 'strength'],
    });
    setRecognitions(recognitionsResults.results);
  };

  useEffect(() => {
    getRecognitions();
  }, [period]);

  return (
    <div className="flex flex-col lg:flex-row gap-5 pb-10">
      <Card className="w-full lg:w-1/4 shadow-none h-fit pb-5">
        <CardBody className="gap-5">
          <DeliveryRockets recognitions={recognitions} period={period} />
          <RocketsPerTeam recognitions={recognitions} />
        </CardBody>
      </Card>
      <Card className="shadow-none w-full lg:w-3/4">
        <CardHeader className="text-2xl">
          {t('entities.metric.recognition.title')}
        </CardHeader>
        <CardBody>
          <div className="flex gap-3 pb-5 px-5 overflow-auto">
            <RecognitionAverage />
            <ParticipationMetric
              dates={{ from: periodDates.from, to: periodDates.to }}
              className="min-w-[240px] md:w-1/3 shadow-md"
              recognitions={recognitions}
            />
            <StrengthMostVotedMetric
              className="min-w-[240px] md:w-1/3 shadow-md"
              recognitions={recognitions}
            />
          </div>
          <StrengthRadar recognitions={recognitions} />
        </CardBody>
      </Card>
    </div>
  );
};

export default RecognitionMetric;
