import { Button } from '@nextui-org/button';
import OpenGift from '../../../../../assets/img/openGift.png';
import Sparks from '../../../../../assets/img/sparks.png';
import Target from '../../../../../assets/img/target.png';
import Countdown, { CountdownApi } from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface ChallengeBannerProps {
  withButton?: boolean;
  className?: string;
  challengeId?: string;
  challengeDateTo?: Date;
}

const ChallengeBanner = ({
  withButton = true,
  className,
  challengeId,
  challengeDateTo,
}: ChallengeBannerProps) => {
  const navigate = useNavigate();
  let countdownApi: CountdownApi | null = null;

  const setRef = (countdown: Countdown | null): void => {
    if (countdown && !countdownApi) {
      countdownApi = countdown.getApi();
    }
  };

  useEffect(() => {
    if (countdownApi && challengeDateTo) {
      countdownApi.start();
    }
  }, [challengeDateTo]);

  const countdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    completed: boolean;
  }) => {
    if (completed) {
      return (
        <div className="flex flex-col items-center">
          <span>DESAFÍO FINALIZADO</span>
        </div>
      );
    } else {
      return (
        <>
          <div className="flex flex-col items-center">
            <span className="text-xl font-bold">{days}</span>
            <span>DÍAS</span>
          </div>
          <span>:</span>
          <div className="flex flex-col items-center">
            <span className="text-xl font-bold">{hours}</span>
            <span>HORAS</span>
          </div>
          <span className="hidden xl:inline">:</span>
          <div className="hidden xl:flex flex-col items-center">
            <span className="text-xl font-bold">{minutes}</span>
            <span>MINUTOS</span>
          </div>
          <span className="hidden xl:inline">:</span>
          <div className="hidden xl:flex flex-col items-center">
            <span className="text-xl font-bold">{seconds}</span>
            <span>SEGUNDOS</span>
          </div>
        </>
      );
    }
  };

  return (
    <div
      className={`${
        className && className
      } relative flex justify-center sm:justify-start items-center gap-10 w-full py-5 px-8 bg-gradient-to-r from-[#8559FF] via-[#5991FF] via-[#6670FB] to-[#7A4BFF]`}
    >
      <div className="h-full flex flex-col justify-between items-center sm:items-start text-white">
        <span>PARTICIPA PARA SUMAR ROCKETS</span>
        <span className="text-2xl font-bold">DESAFÍO EMPRESARIAL</span>
        {withButton && (
          <Button
            className="rounded-full text-white bg-transparent bg-gradient-to-r from-[#C599FE] to-[#C599FE63]"
            onClick={() =>
              navigate(`/enterprise/challenge/view/${challengeId}`)
            }
          >
            ¡Quiero participar!
          </Button>
        )}
      </div>
      <div className="hidden lg:flex items-center gap-2 h-fit py-2 px-5 bg-[#3806A36B] text-white rounded-xl">
        <Countdown
          ref={setRef}
          date={challengeDateTo ? challengeDateTo : Date.now()}
          renderer={countdownRenderer}
          daysInHours={false}
          autoStart={false}
        />
      </div>
      <img
        src={Target}
        className="hidden sm:block absolute h-full right-5 m-0"
        alt="Target"
      />
      <img
        src={OpenGift}
        className="hidden sm:block absolute bottom-5 right-40 m-0"
        alt="Open Gift"
      />
      <img
        src={Sparks}
        className="hidden sm:block absolute right-36 top-2 m-0"
        alt="Sprinkles"
      />
    </div>
  );
};

export default ChallengeBanner;
