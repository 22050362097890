import HeartSolidIcon from '@components/icons/heartSolid.icon';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { Avatar } from '@nextui-org/avatar';
import { Card, CardBody, CardHeader } from '@nextui-org/card';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ChallengeRankingDTO from '../dto/challengeRanking.dto';
import { useEffect, useState } from 'react';
import ChallengesRepository from '../../challenges.repository';
import { Spinner } from '@nextui-org/react';

interface ChallengeRankingProps {
  amountOfWinners: number;
  challengeId: string;
}

const ChallengeRanking = ({
  amountOfWinners,
  challengeId,
}: ChallengeRankingProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const [ranking, setRanking] = useState<ChallengeRankingDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRanking = async () => {
    setIsLoading(true);
    const answers = await new ChallengesRepository().getChallengeRanking({
      where: { challenge: { challengeId } },
      take: amountOfWinners + 10,
    });
    setRanking(answers);
    setIsLoading(false);
  };

  useEffect(() => {
    getRanking();
  }, []);

  return (
    <Card className="shadow-none bg-content4">
      <CardHeader className="flex flex-col items-start">
        <span className="text-primary text-lg dark:text-primary-600">
          {t('entities.challenge.ranking.title')}
        </span>
        <span className="text-sm">
          {t('entities.challenge.ranking.description')}
        </span>
      </CardHeader>
      <CardBody className="space-y-2">
        {isLoading ? (
          <Spinner />
        ) : (
          ranking.map((answer, index) => (
            <div
              key={answer.id}
              className={`flex items-center justify-between gap-2 p-2 rounded-xl ${
                amountOfWinners > index && 'bg-primary-100'
              }`}
            >
              <Avatar
                src={
                  answer.avatar &&
                  `https://giveit-system-assets.s3.amazonaws.com/${answer.avatar}`
                }
                name={answer.fullName}
                className="flex-shrink-0"
                isBordered={profile?.id === answer.userId}
                color={profile?.id === answer.userId ? 'primary' : 'default'}
              />
              <span className="max-w-full text-center truncate">
                {answer.fullName}
              </span>
              <div className="flex items-center gap-1">
                <HeartSolidIcon className="text-red-500 w-3" />
                <span>{answer.interactions}</span>
              </div>
            </div>
          ))
        )}
      </CardBody>
    </Card>
  );
};

export default ChallengeRanking;
