import React from 'react';
import KeyResultDTO from '@modules/objectiveModule/keyResult/dto/keyResult.dto';
import KeyResultStatus from '@modules/objectiveModule/keyResult/enum/keyResultStatus.enum';
import KeyResultRepository from '@modules/objectiveModule/keyResult/keyResult.repository';
import ObjectivePeriodLabel from '@modules/objectiveModule/objective/components/objectivePeriodLabel';
import {
  Button,
  Card,
  CardBody,
  CircularProgress,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Skeleton,
  useDisclosure,
} from '@nextui-org/react';
import DateUtil from '@utils/date';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface NextDeadlinesProps {
  period: 'Q1' | 'Q2' | 'Q3' | 'Q4';
}

const NextDeadlines = (props: NextDeadlinesProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const periodDates = DateUtil.quarterToDate(props.period);
  const [keyResultsResult, setKeyResultsResult] = useState<KeyResultDTO[]>([]);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const getKeyResults = async () => {
    setLoading(true);
    const keyResultResult = await new KeyResultRepository().find({
      where: [
        {
          periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ],
      order: {
        periodTo: 'ASC',
      },
      take: 3,
    });

    setKeyResultsResult(keyResultResult.results);
    setLoading(false);
  };

  useEffect(() => {
    getKeyResults();
  }, [props.period]);

  const KeyResult = ({ item }: { item: KeyResultDTO }) => {
    const styles: { [key in KeyResultStatus]: string } = {
      ON_TIME: 'bg-success-200 text-success-800 border border-success-600',
      LATE: 'bg-warning-200 text-warning-800 border border-warning-600',
      AT_RISK: 'bg-danger-200 text-danger-800 border border-danger-600',
    };

    return (
      <Card className="shadow-md">
        <CardBody className="flex-row flex-wrap lg:flex-nowrap justify-center gap-y-2 gap-x-5 min-h-32 overflow-y-hidden">
          <div className="flex gap-3 lg:w-1/3">
            <div className="flex items-center justify-center h-full">
              <span className="p-3 bg-primary text-primary-foreground font-bold rounded h-full flex items-center justify-center">
                KR
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <span>
                <b className="text-foreground-500">
                  {' '}
                  {t('entities.keyResult.fields.name')}:{' '}
                </b>
                <Link
                  to={`/enterprise/keyResult/view/${item.id}`}
                  className="text-foreground cursor-pointer no-underline"
                >
                  {item.name}
                </Link>
              </span>
            </div>
          </div>
          <div className="hidden sm:flex flex-col gap-3">
            <span>
              <b className="text-foreground-500">
                {t('entities.keyResult.fields.period')}
              </b>
            </span>
            <div className="h-full flex items-center">
              <ObjectivePeriodLabel
                periodFrom={item.periodFrom}
                periodTo={item.periodTo}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span>
              <b className="text-foreground-500">
                {t('entities.keyResult.fields.progress')}
              </b>
            </span>
            <div className="h-full flex items-center">
              <CircularProgress
                value={item.progress}
                size="lg"
                showValueLabel={true}
                color="success"
              />
            </div>
          </div>
          <div className="flex flex-col justify-start">
            <div>
              <b className="text-foreground-500 text-center">
                {t('entities.keyResult.remainingDays')}
              </b>
            </div>
            <div className="h-full flex items-center">
              {DateUtil.dateDaysDiffNotAbs(new Date(), new Date(item.periodTo))}
              {t('entities.keyResult.days')}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span>
              <b className="text-foreground-500">
                {t('entities.objective.fields.status')}
              </b>
            </span>
            <div className="h-full flex items-center">
              <div
                className={`text-sm px-2 py-3 rounded ${styles[item.status]}`}
              >
                {t(`entities.keyResult.values.status.${item.status}`)}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  const KRModal = () => {
    const [allKRs, setAllKRs] = useState<KeyResultDTO[]>([]);
    const [isModalLoading, setIsModalLoading] = useState(false);

    const getKeyResults = async () => {
      setIsModalLoading(true);
      const keyResultResult = await new KeyResultRepository().find({
        where: [
          {
            periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
          {
            periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        ],
        order: {
          periodTo: 'ASC',
        },
      });
      setAllKRs(keyResultResult.results);
      setIsModalLoading(false);
    };

    useEffect(() => {
      if (isOpen) getKeyResults();
    }, [isOpen]);

    return (
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="3xl"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalHeader>{t('entities.keyResult.krsFromPeriod')}</ModalHeader>
          <ModalBody className="pb-5">
            {isModalLoading ? (
              <LoadingTemplate />
            ) : (
              <div className="flex flex-col gap-5">
                {allKRs.map((keyResultItem) => (
                  <KeyResult key={keyResultItem.id} item={keyResultItem} />
                ))}
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const LoadingTemplate = () => {
    return (
      <div className="flex flex-col gap-5">
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
        <Skeleton>
          <div className="h-36"></div>
        </Skeleton>
      </div>
    );
  };

  const EmptyList = () => {
    return (
      <div className="pt-5">
        <p className="text-center">{t('entities.keyResult.table.empty')}</p>
      </div>
    );
  };

  return isLoading ? (
    <LoadingTemplate />
  ) : (
    <div>
      {keyResultsResult.length === 0 ? (
        <EmptyList />
      ) : (
        <div className="mt-5 flex flex-col items-center">
          <p className="text-lg font-bold text-start w-full">
            {t('entities.keyResult.krsFromPeriod')}
          </p>
          <div className="flex flex-col gap-5">
            {keyResultsResult.map((keyResultItem) => (
              <KeyResult key={keyResultItem.id} item={keyResultItem} />
            ))}
          </div>
          <Button
            onPress={onOpen}
            color="primary"
            variant="flat"
            className="mt-5 px-20"
          >
            {t('entities.keyResult.viewAll')}
          </Button>
          <KRModal />
        </div>
      )}
    </div>
  );
};

export default NextDeadlines;
