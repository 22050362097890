import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import { useEffect, useState } from 'react';
import FindOptions from 'lib/paginated/findOptions.dto';
import UserForm from '@modules/userModule/user/components/userForm';
import UpdateUserDTO from '@modules/userModule/user/dto/updateUser.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import CompareUtil from '@utils/compare';

const EditUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [userResult, setUserResult] = useState<UserDTO | null>(null);

  const getUser = async (options?: FindOptions<UserDTO>) => {
    setLoading(true);
    if (!id) navigate(-1);
    const userResult = await new UserRepository().findOne(id as string, {
      ...options,
      relations: Object.assign(options?.relations || [], ['group', 'team']),
    });
    if (!userResult) navigate(-1);
    setUserResult(userResult);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const loading = () => {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  };

  const handleSubmit = async (values: UpdateUserDTO) => {
    if (!userResult) return;
    await new UserRepository().update(userResult?.id as string, {
      ...(CompareUtil.compareObjects(userResult, values) as UpdateUserDTO),
      team: values.team,
    });
    navigate(-1);
  };

  return isLoading || !userResult ? (
    loading()
  ) : (
    <div>
      <h3 className="text-primary flex gap-5 items-center">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />{' '}
        {t('user.configuration.enterprise.users.edit')}
      </h3>
      <UserForm onSubmit={handleSubmit} initialValues={userResult} />
    </div>
  );
};

export default EditUser;
