import * as Yup from 'yup';
import i18next from 'i18next';

const EditLevelValidation = (minRockets: number, maxRockets?: number) => {
  if (!maxRockets)
    return Yup.object().shape({
      rockets: Yup.number()
        .required(i18next.t('validations.required'))
        .moreThan(
          minRockets,
          i18next.t('entities.level.editLevel.errors.moreThan', {
            number: minRockets,
          }),
        ),
      reward: Yup.string(),
    });

  return Yup.object().shape({
    rockets: Yup.number()
      .required(i18next.t('validations.required'))
      .moreThan(
        minRockets,
        i18next.t('entities.level.editLevel.errors.moreThan', {
          number: minRockets,
        }),
      )
      .lessThan(
        maxRockets,
        i18next.t('entities.level.editLevel.errors.lessThan', {
          number: maxRockets,
        }),
      ),
    reward: Yup.string(),
  });
};

export default EditLevelValidation;
