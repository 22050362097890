import React from 'react';
import { RouteObject } from 'react-router-dom';
import ObjectiveListPage from './list/objectiveList.page';
import ObjectiveAddPage from './add/objectiveAdd.page';
import ObjectiveViewPage from './view/objectiveView.page';
import ObjectiveEditPage from './edit/objectiveEdit.page';
import KeyResultAddPage from './add/keyResultAdd.page';
import KeyResultAddToObjective from './add/keyResultAddToObjective.page';

const objectiveRoutes: Array<RouteObject> = [
  {
    children: [
      { path: '', element: <ObjectiveListPage /> },
      { path: 'add', element: <ObjectiveAddPage /> },
      { path: 'edit/:id', element: <ObjectiveEditPage /> },
      { path: 'view/:id', element: <ObjectiveViewPage /> },
      { path: 'keyResult/add', element: <KeyResultAddPage /> },
      { path: ':id/keyResult/add', element: <KeyResultAddToObjective /> },
    ],
  },
];

export default objectiveRoutes;
