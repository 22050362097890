import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React, { useState } from 'react';
import { LevelDTO } from '../../dto/level.dto';
import { Form, Formik, FormikProps } from 'formik';
import { EditLevelDTO } from '../../dto/editLevel.dto';
import Input from '@components/input/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import EditLevelValidation from '../validation/editLevel.validation';
import LevelRepository from '../../level.repository';
import { useTranslation } from 'react-i18next';

interface EditLevelModalProps {
  level: LevelDTO;
  minRockets: number;
  maxRockets?: number;
  refresh?: () => void;
}

const EditLevelModal = ({
  level,
  minRockets,
  maxRockets,
  refresh,
}: EditLevelModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [levelRepository] = useState(new LevelRepository());

  const onSubmit = async (values: EditLevelDTO) => {
    await levelRepository.update(level.id, {
      number: level.number,
      reward: values.reward,
      rockets: values.rockets * 1,
    });
    onClose();
    if (refresh) refresh();
  };

  return (
    <>
      <Button color="warning" variant="flat" size="sm" onPress={onOpen}>
        <FontAwesomeIcon icon={faWrench} />
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          <ModalHeader>
            {t('entities.level.editLevel.title', { number: level.number })}
          </ModalHeader>
          <ModalBody>
            <Formik
              onSubmit={onSubmit}
              initialValues={{
                rockets: level.rockets,
                reward: level.reward ? level.reward : '',
              }}
              validationSchema={() =>
                EditLevelValidation(minRockets, maxRockets)
              }
            >
              {({
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }: FormikProps<EditLevelDTO>) => (
                <Form className="flex flex-col gap-5 pb-4">
                  <Input
                    name="rockets"
                    label={t('entities.level.editLevel.amountOfRockets')}
                    isInvalid={Boolean(errors.rockets)}
                    errorMessage={errors.rockets}
                    color={errors.rockets ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('rockets', e.target.value)}
                    type="number"
                    value={values.rockets.toString()}
                  />
                  <Input
                    name="reward"
                    label={t('entities.level.editLevel.reward')}
                    isInvalid={Boolean(errors.reward)}
                    errorMessage={errors.reward}
                    color={errors.reward ? 'danger' : 'default'}
                    onChange={(e) => setFieldValue('reward', e.target.value)}
                    value={values.reward}
                  />
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    color="primary"
                  >
                    {t('entities.level.editLevel.save')}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditLevelModal;
